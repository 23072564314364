import { PLAYER_EVENTS_LIST } from "@airtel-tv/constants";
import { getRandomNumber } from "@airtel-tv/utils/GlobalUtil";
import { firePixel } from "@airtel-tv/services/PixelApiService";

export default class PlaybackPixelAnalytics {
    constructor({
        playerEvents,
        contentDetails,
        playbackDetails,
        playerFunctions,
        deviceUtil,
        playbackMode,
        pixelPlaybackSessionId,
        controlConfig = {},
    }) {
        this.playerEvents = playerEvents;
        this.contentDetails = contentDetails;
        this.playbackDetails = playbackDetails;
        this.playerFunctions = playerFunctions;
        this.deviceUtil = deviceUtil;
        this.playbackMode = playbackMode;
        this.pixelPlaybackSessionId = pixelPlaybackSessionId;
        this.playDuration = 0;
        this.previousCurTime = 0;
        this.controlConfig = controlConfig;
        const { logTime = [1,2,3,4,5,6,7,8,9,10], logMultiple = 60 } = this.controlConfig;
        this.logTime = logTime;
        this.logMultiple = logMultiple;
        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.LOADSTART, () => this.pixelInitHandler());

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.FIRST_PLAY, () => {
            let eventMeta = this.getEventMeta();
            eventMeta = {
                ...eventMeta,
                random: getRandomNumber(),
                event_name: 'vplay',
            };
            firePixel({ pixelMeta: eventMeta });
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.TIMEUPDATE, (currentTime, videotag) => {
            // current time in seconds
            const curTempTime = Math.floor(currentTime);
            // update oscillation change
            // event is fired every .25 second so check if it is a new second
            if (curTempTime !== this.previousCurTime) {
                this.previousCurTime = curTempTime;

                // increment playback time
                this.playDuration += 1;
                let shouldFirePixel = false;
                if (this.logTime.includes(this.playDuration)
                    || (this.playDuration !== 0 && (this.playDuration % this.logMultiple === 0))
                ) {
                    shouldFirePixel = true;
                }

                if (shouldFirePixel) {
                    this.pixelLogEvent(this.playDuration);
                }
            }
        });
    }

    getEventMeta() {
        const { adsAnalyticsMap = {}, playback } = this.playbackDetails || {};
        const { dpName } = this.controlConfig;
        const { content_provider_id, episode_id, show_id } = adsAnalyticsMap;
        const { playUrl } = playback || {};
        return {
            content_provider_id,
            episode_id,
            show_id,
            playback_id: this.pixelPlaybackSessionId,
            encoded_video_url: playUrl,
            dpName,
            advertisingId: this.deviceUtil.privateFingerPrintHolder,
        };
    }

    pixelInitHandler() {
        let eventMeta = this.getEventMeta();
        if (eventMeta?.encoded_video_url) {
            return;
        }
        eventMeta = {
            ...eventMeta,
            content_provider_id: 'blank',
            episode_id: 'blank',
            show_id: 'blank',
            playback_id: 'ff',
            encoded_video_url: window.location.origin + window.location.pathname,
            random: getRandomNumber(),
            event_name: 'ff',
        };
        firePixel({ pixelMeta: eventMeta });
    }

    pixelLogEvent(playDuration) {
        let eventMeta = this.getEventMeta();
        eventMeta = {
            ...eventMeta,
            random: getRandomNumber(),
            event_name: `vs${playDuration}`,
        };
        firePixel({ pixelMeta: eventMeta });
    }
}
