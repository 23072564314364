import lodashIsEqual from 'lodash/isEqual';
import lodashOmit from 'lodash/omit';
import lodashFunctions from 'lodash/functions';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import React, {
    Component, Suspense, createRef, lazy,
} from 'react';
import { connect } from 'react-redux';
import 'videojs-contrib-eme/dist/videojs-contrib-eme.min';
// import 'video.js/dist/video-js.css';
import idleTimer from 'idle-timer';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import {
    PLAYER_CONFIG, PLAYER_EVENTS_LIST, DEFAULT_SUBTITLE_LANGUAGE, CONTROL_BAR_EVENTS, VIDEO_TAG_ID,
} from '@airtel-tv/constants/PlayerConsts';
import { ThumborUtil } from '@airtel-tv/utils/ThumborUtil';
import {
    addListenerToEvent, removeListenerFromEvent, isOnline, mediaForMobile,
    checkWindowExist, getWindowInnerWidth, checkFullScreen, getF,
} from '@airtel-tv/utils/WindowUtil';
import {
    DEEPLINK_INGRESS_KEYS,
    LOCAL_STORE_KEYS,
    PROGRAM_TYPES,
    PUBLISH_EVENTS,
} from '@airtel-tv/constants/GlobalConst';
import { AuthenticationUtil } from '@airtel-tv/utils/AuthenticationUtil';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { FocusManagementClassNames } from '@airtel-tv/constants/FocusManagmentConst';
import { OS_LIST } from '@airtel-tv/constants/BrowserConst';
import { adsPlaying } from '@airtel-feature/playback/actions/PlaybackActions';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import TileTag from '@airtel-tv/ui-lib/atoms/TileTag/TileTag';
import { checkSdkAdsAllowed } from '@airtel-tv/utils/AdSdkUtil';
import DownloadAirtelNudge from '@airtel-tv/ui-lib/atoms/nudges/DownloadAirtelNudge';
import ScopedWebviewUtil from '@airtel-tv/utils/ScopedWebviewUtil';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import {
    getFullScreen,
} from '@airtel-tv/utils/GlobalUtil';
import PlayerKeyboardControls from '../../controls/PlayerKeyboardControls';
import PlaybackSecurityLabelComponent from '../../../../web/src/modules/shared/components/random-label/PlaybackSecurityLabelComponent';
import PlaybackStorageUtil from '../../PlaybackStorageUtil';
import Emitter from '../../../../web/src/modules/shared/components/services/Emitter';
import { WatermarkComponent } from '../../factories/PlaybackUiComponentFactory';
import { publish, subscribe } from '../../../../tv/src/utilities/Pubsub';
import AdControlBar from '../../controls/AdControlBar/AdControlBar';
import { isWeb } from '../../util/PlaybackUtility';
import { adsDataInit } from '../../../../web/src/modules/app/actions/AppActions';
import SubtitleDisplayer from '../../../../web/src/modules/playbackComponents/components/subtitle-displayer';
import AdSlotWrapper from '../ad-slot/AdSlotWrapper';
import PartnerAdSlotWrapper from '../ad-slot/partner-slot-wrapper/PartnerAdSlotWrapper';
import ContentDescriptor from '../ContentDescriptor';
// import LiveChannelBox from '@airtel-tv/ui-lib/molecules/live-channel-box/LiveChannelBox';
const LiveChannelBox = lazy(() => import('@airtel-tv/ui-lib/molecules/live-channel-box/LiveChannelBox'));

export class AbstractPlayer extends Component {
    constructor(props, playerType) {
        super(props);

        this.deviceUtil = props.deviceUtil;
        this.isScopedWebview = this.deviceUtil.isScopedWebview();
        const videoWidth = getWindowInnerWidth();
        // state vars
        this.state = {
            videoWidth,
            randomLabelChangeRequestNumber: null,
            // showAds: false,
            isWeb: isWeb(),
            showTimer: false,
            isSettingsVisible: false,
            isEpgPageVisible: false,
            showContentDescriptor: null,
            isContentStarted: false,
            subtitleDisplayerEnabled: false,
        };

        // props
        const { reload, contentDescriptorConfig = {}, contentDetails: { cpId } } = this.props;
        this.reload = reload;
        this.placeholderImageSettings = {
            300: 1000,
            800: 1500,
            1900: 1920,
        };
        // local vars
        this.playerType = playerType; // to identify which player is inheriting this abstract player
        this.isHovered = false;
        this.controlBarVisible = false;
        this.waiting = false;
        this.imaAdManager = null;
        this.playerUnload = false;

        this.showOnTouchControlsOverlayTimeout = null;
        this.player = null;
        this.controlsBarInstance = null;
        this.currentTime = 0;
        this.previousCurTime = 0;
        this.adSlotWrapperRef = createRef();
        this.contentDescriptorRef = createRef();
        const descriptorEligible = contentDescriptorConfig?.[cpId];
        this.contentDescriptor = {
            config: descriptorEligible,
            blockFirst: !!descriptorEligible,
            shownForSession: !descriptorEligible,
            timeouts: [],
        };

        // init idle timer
        this.idleTimer = idleTimer({
            callback: () => {
                if (this.deviceUtil.isMobile()) {
                    this.isHovered = false;
                }
                this.hideControlsOverlay({ idle: true });
            },
            activeCallback: () => {
                if (!this.deviceUtil.isMobile()) { // you can not identify hover on phone
                    this.showControlsOverlay();
                }
            },
            idleTime: PLAYER_CONFIG.OVERLAY_TIMEOUT,
        });
        this.controlBarEventEmitter = Emitter;


        // default player key board handling
        this.keyboardControls = new PlayerKeyboardControls({
            playerFunctions: null,
            onPlayerFunctionsNotFound: this.onPlayerFunctionsNotFound,
            deviceUtil: this.deviceUtil,
            controlBarEventEmitter: this.controlBarEventEmitter,
        });
        this.keyboardControls.install();

        // hide show overlay based on tab focus
        if (this.isWeb) {
            addListenerToEvent('blur', this.hideControlsOverlay);
            addListenerToEvent('focus', this.hideControlsOverlay);
        }

        this.isLoaded = false;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { playbackConfig, preLoad, playbackInForeGround } = nextProps;

        let changes = {};

        if (!lodashIsEqual(playbackConfig, prevState.playbackConfig) || !lodashIsEqual(playbackInForeGround, prevState?.playbackInForeGround)) {
            changes = {
                ...prevState,
                playbackConfig,
                preLoad,
                playbackInForeGround,
            };
        }

        return Object.keys(changes).length > 0 ? changes : null;
    }

    enableSubtitleDisplayer() {
        this.setState({ subtitleDisplayerEnabled: true });
    }

    componentDidMount() {
        this.initConfig();

        if (!this.isWeb) {
            // video will be init on update of video width
            this.unSubscribeLeftHandling = subscribe(PUBLISH_EVENTS.LEFT_ARROW, () => {
                this.showOnTouchControlsOverlay();
            });
            this.unSubscribeRightHandling = subscribe(PUBLISH_EVENTS.RIGHT_ARROW, () => {
                this.showOnTouchControlsOverlay();
            });
            this.unSubscribeUpHandling = subscribe(PUBLISH_EVENTS.UP_ARROW, () => {
                this.showOnTouchControlsOverlay();
            });
            this.unSubscribeDownHandling = subscribe(PUBLISH_EVENTS.DOWN_ARROW, () => {
                this.showOnTouchControlsOverlay();
            });
            this.unSubscribeDownHandling = subscribe(PUBLISH_EVENTS.OK_BUTTON, () => {
                this.showOnTouchControlsOverlay();
            });


            this.unSubscribeLeftHandling = subscribe(PUBLISH_EVENTS.MEDIA_STOP_BUTTON, () => {
                this.showOnTouchControlsOverlay();
            });
            this.unSubscribeLeftHandling = subscribe(PUBLISH_EVENTS.MEDIA_REWIND_BUTTON, () => {
                this.showOnTouchControlsOverlay();
            });
            this.unSubscribeLeftHandling = subscribe(PUBLISH_EVENTS.MEDIA_PLAY_BUTTON, () => {
                this.showOnTouchControlsOverlay();
            });
            this.unSubscribeLeftHandling = subscribe(PUBLISH_EVENTS.MEDIA_PAUSE_BUTTON, () => {
                this.showOnTouchControlsOverlay();
            });
            this.unSubscribeLeftHandling = subscribe(PUBLISH_EVENTS.MEDIA_FORWARD_BUTTON, () => {
                this.showOnTouchControlsOverlay();
            });
            this.unSubscribeReturnHandlingMediaStopButton = subscribe(PUBLISH_EVENTS.MEDIA_STOP_BUTTON, (data = {}) => {
                if (this.controlBarVisible && !data.navigateBack && !lodashGet(document, 'activeElement.classList', []).contains(`${FocusManagementClassNames.ONLYFOCUSABLE}`)
                ) {
                // publish(PUBLISH_EVENTS.MEDIA_STOP_BUTTON, { navigateBack: true });
                }
                else {
                    this.showOnTouchControlsOverlay();
                }
            });


            this.unSubscribeReturnHandling = subscribe(PUBLISH_EVENTS.RETURN_BUTTON, (data = {}) => {
                if (this.controlBarVisible && !data.navigateBack && !lodashGet(document, 'activeElement.classList', []).contains(`${FocusManagementClassNames.ONLYFOCUSABLE}`)
                ) {
                // publish(PUBLISH_EVENTS.RETURN_BUTTON, { navigateBack: true });
                }
                else {
                    this.showOnTouchControlsOverlay();
                }
            });
        }
        this.controlBarEventEmitter.on(CONTROL_BAR_EVENTS.SETTINGS_OVERLAY_TOGGLE, this.onTouchSettingsOverlay);
        this.controlBarEventEmitter.on(CONTROL_BAR_EVENTS.CENTER_ICON_DISPLAY, this.showCenterIconOverlay);
        // video will be init on update of video width
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { playbackInForeGround, isEpgPageVisible, showDownloadFloatingNudge } = this.props;
        return (nextState !== this.state) || (playbackInForeGround !== nextProps.playbackInForeGround) || (isEpgPageVisible != nextProps?.isEpgPageVisible || (this.props.adsPlayingFlag !== nextProps.adsPlayingFlag)) || (showDownloadFloatingNudge !== nextProps.showDownloadFloatingNudge);
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            playbackConfig, playbackAdsData, isAuthenticated, fetchAdsToken, preLoad, adsPlayingFlag, sdkAdsPlayingFlag,
        } = this.props;
        const { playbackUrl, drm } = playbackConfig;
        const {
            playbackConfig: previousPlaybackConfig = {},
            preLoad: previousPreLoad,
        } = prevProps;
        const { config, shownForSession } = this.contentDescriptor;
        const daiAssetKey = lodashGet(playbackAdsData, 'imaConfig.daiAssetKey', '');
        const previousPlaybackAdsData = lodashGet(prevProps, 'playbackAdsData', {});
        const previousDaiAssetKey = lodashGet(previousPlaybackAdsData, 'imaConfig.daiAssetKey', '');
        if (
            playbackUrl !== previousPlaybackConfig.playbackUrl
            || daiAssetKey !== previousDaiAssetKey
            || preLoad !== previousPreLoad
            || !lodashIsEqual(lodashOmit(drm, lodashFunctions(drm)), lodashOmit(previousPlaybackConfig.drm, lodashFunctions(previousPlaybackConfig.drm)))) {
            // don't init again if only cookie is changed
            this.initConfig();
        }
        if (prevProps.isAuthenticated !== isAuthenticated) {
            fetchAdsToken();
        }

        if (adsPlayingFlag !== prevProps.adsPlayingFlag || sdkAdsPlayingFlag !== prevProps.sdkAdsPlayingFlag) {
            if (shownForSession || !config) {
                return;
            }

            if (adsPlayingFlag || sdkAdsPlayingFlag) {
                this.hideContentDescriptor();
            }
            else {
                this.controlBarVisible = false;
                this.displayContentDescriptor();
            }
        }

        // TODO: CHECK IF IT IS NEEDED
        // this.showControlsOverlay(); // show overlay by default
    }

    componentWillUnmount() {
        const { dispatchGetAdsPlaying = () => null, adsPlayingFlag } = this.props;
        dispatchGetAdsPlaying({ adsPlayingFlag: false });
        if (this.playerFunctions) {
            this.playerFunctions.dispose && this.playerFunctions.dispose();
            this.playerFunctions = null;
            this.player = null;
        }

        if (this.playerEvents) {
            this.playerEvents.dispose();
            this.playerEvents = null;
        }

        if (isWeb) {
            this.idleTimer.destroy();
        }
        else {
            if (this.unSubscribeReturnHandling) {
                this.unSubscribeReturnHandling.unsubscribe();
            }
            if (this.unSubscribeReturnHandlingMediaStopButton) {
                this.unSubscribeReturnHandlingMediaStopButton.unsubscribe();
            }
        }

        if (this.volumeOverlayTimer) {
            clearTimeout(this.volumeOverlayTimer);
        }

        if (this.keyboardControls) {
            this.keyboardControls.uninstall();
        }

        this.controlBarEventEmitter.off(CONTROL_BAR_EVENTS.SETTINGS_OVERLAY_TOGGLE, this.onTouchSettingsOverlay);
        this.controlBarEventEmitter.off(CONTROL_BAR_EVENTS.CENTER_ICON_DISPLAY, this.showCenterIconOverlay);

        if (isWeb) {
            removeListenerFromEvent('blur', this.hideControlsOverlay);
            removeListenerFromEvent('focus', this.hideControlsOverlay);
            removeListenerFromEvent('online', this.handleOnlineStatusChange);
        }

        (this.contentDescriptor.timeouts || []).map((item) => {
            clearTimeout(item);
        });

        if (adsPlayingFlag) {
            const adManager = this.getAdManager();
            if (adManager) {
                adManager.setVolume(0);
            }
        }
    }

    setPlayerFullScreen = () => {
        const {
            contentAutoplay,
        } = this.props;
        if (this.isWeb && !contentAutoplay && !checkFullScreen() && (!this.isScopedWebview || (this.isScopedWebview && !window.isAppNotFocused))) {
            getFullScreen(this.videoWrapperRef);
        }
    };

    refreshIdleTimer = () => {
        if (this.isWeb) {
            this.idleTimer.activate();
        }
    };

    playbackWrapperClicked = () => {
        if (this.deviceUtil.isMobile()) {
            return;
        } // on touch start will work for mobiles

        this.isHovered = true;
        this.refreshIdleTimer();
        this.showControlsOverlay();
    };

    installKeyboardControls = () => {
        const { playbackConfig: { forwardSkipDuration, backwardSkipDuration, streamType } } = this.props;

        // init keyboard events
        this.keyboardControls.uninstall(); // unintall default
        this.keyboardControls = new PlayerKeyboardControls({
            playerFunctions: this.playerFunctions,
            onPlayerFunctionsNotFound: this.onPlayerFunctionsNotFound,
            forwardSkipDuration,
            backwardSkipDuration,
            deviceUtil: this.deviceUtil,
            streamType,
            controlBarEventEmitter: this.controlBarEventEmitter,
            playerEventsEmitter: this.playerEvents,
        });
        this.keyboardControls.install();
    };

    enableControlBar = () => {
        if (this.controlsBarInstance) {
            this.controlsBarInstance.setPlayerFunctions(this.playerFunctions);
            this.controlsBarInstance.setControlBarEventEmitter(this.controlBarEventEmitter);
            this.controlsBarInstance.initPlayerVolume();
            this.controlsBarInstance.setPlayerEvents(this.playerEvents);
            publish(PLAYER_EVENTS_LIST.PLAYER_EVENTS_EMITTER_ENABLED, this.playerEvents);
            this.controlsBarInstance.enableControlBar();
        }
    };

    handleOnlineStatusChange = () => {
        if (isOnline() && !this.playerFunctions.paused() && this.waiting) {
            if (this.playerFunctions.isLiveStream()) {
                this.reload();
                // this.playerFunctions.goLive();
            }
            else {
                this.playerFunctions.pause();
                this.playerFunctions.play();
            }
        }
    };

    displayContentDescriptor = () => {
        const { config, timeouts } = this.contentDescriptor;

        if (this.controlBarVisible || !config) {
            return;
        }
        this.setState({
            showContentDescriptor: true,
        });
        const timeoutId = setTimeout(() => {
            this.contentDescriptor.shownForSession = true;
            if (this.state.showContentDescriptor) {
                this.setState({
                    showContentDescriptor: false,
                });
            }
        }, config.descriptorDisplayDuration);

        timeouts.push(timeoutId);
    };

    hideContentDescriptor = () => {
        const { config, shownForSession, timeouts } = this.contentDescriptor;
        if (!config || shownForSession) {
            return;
        }
        if (this.state.showContentDescriptor) {
            this.setState({
                showContentDescriptor: false,
            });
        }

        timeouts.map((item) => {
            clearTimeout(item);
        });
    };

    playerEventsHandler = () => {
        const {
            persistBufferOnFirstplay = 8,
            playbackConfig: {
                videoResolutionLimiter = null,
            },
            lastWatchedPositionTrailer = '',
        } = this.props;
        if (this.playerEvents && this.playerEvents.emitter) {
            this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.WAITING, () => {
                this.waiting = true;
                if (!this.contentDescriptor.blockFirst) {
                    this.showControlsOverlay();
                }
            });

            this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH, () => {
                this.waiting = false;

                if (!this.playerFunctions.paused()) {
                    this.hideControlsOverlay();
                }
            });

            // Sony hang
            // this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.FIRST_PLAY, () => {
            //     if (this.player.configure) {
            //         this.player.configure({
            //             streaming: {
            //                 stallEnabled: false,
            //                 startAtSegmentBoundary: true,
            //                 bufferingGoal: PLAYER_CONFIG.GOAL_BUFFER_LENGTH,
            //             },
            //         });
            //     }
            // });
            this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.FIRST_PLAY, () => {
                const { config } = this.contentDescriptor;
                // start form lowest quality then move to auto
                if (lastWatchedPositionTrailer) {
                    this.playerFunctions.seekBarChange(lastWatchedPositionTrailer);
                }
                if (this.player.configure && !videoResolutionLimiter) {
                    const tracks = this.player.getVariantTracks();
                    const activeTrack = tracks.find(t => t.active);
                    const sameLanguageTracks = tracks.filter(t => activeTrack.language === t.language).sort((a, b) => a.width - b.width);
                    if (sameLanguageTracks.length) {
                        this.player.selectVariantTrack(sameLanguageTracks[sameLanguageTracks.length - 1], true, persistBufferOnFirstplay);
                    }
                    this.player.configure('abr.restrictions.maxHeight', undefined);
                    if (config) {
                        setTimeout(() => {
                            this.contentDescriptor.blockFirst = false;
                        }, config.setupTime || 500);
                    }
                }
                // this.showAds("IMA_VIDEO_XSTREAM", this.imaSlotId);
                if (config) {
                    this.displayContentDescriptor();
                }
                // this.showOnTouchControlsOverlay();
                window.atvPlayerFirstPlay = true;
                if (this.isScopedWebview) {
                    this.videoNode.pause();
                    this.videoNode.play();
                }
            });

            this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.PLAY, () => {
                this.hideControlsOverlay({
                    idle: true,
                    showDescriptor: false,
                });
            });

            this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.PAUSE, () => {
                this.showControlsOverlay();
            });

            this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.PLAYING, () => {
                this.hideControlsOverlay({
                    idle: true,
                    showDescriptor: false,
                });
            });

            this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.SEEK_BAR_CHANGE, (data) => {
                this.currentTime = data.newTime;
                this.currentTimeRandomLabelShown = 0;
            });

            this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.TIMEUPDATE, (playbackCurrentTime) => {
                const {
                    playbackDetails: { contentType = '' } = {},
                } = this.props;
                if (checkWindowExist()) {
                    if (window.isAppNotFocused) {
                        this.playerFunctions.pause();
                    }
                }
                const currentTime = Math.floor(playbackCurrentTime);
                if (currentTime !== this.previousCurTime) {
                    this.previousCurTime = currentTime;
                    if (this.state.isWeb && contentType !== PROGRAM_TYPES.TRAILER && this.deviceUtil.isScopedWebview()) {
                        ScopedWebviewUtil.setPlaybackTime(1);
                    }
                }
                if (this.currentTime !== playbackCurrentTime) {
                    this.currentTime = playbackCurrentTime;
                    if (this.controlBarVisible && !this.isHovered) {
                        this.hideControlsOverlay({ idle: true });
                    }

                    // SHOW SECURITY LABEL
                    const { playbackConfig: { playbackSecurityLabelConfig } } = this.props;
                    if (playbackSecurityLabelConfig) {
                        const currentMinute = Math.floor(playbackCurrentTime / 60);

                        if (playbackSecurityLabelConfig.intervalsInMinutes.indexOf(currentMinute) > -1 && currentMinute !== this.currentTimeRandomLabelShown) {
                            this.setState({
                                randomLabelChangeRequestNumber: new Date().getTime(),
                            });
                        }

                        if (currentMinute !== this.currentTimeRandomLabelShown) {
                            this.currentTimeRandomLabelShown = currentMinute;
                        }
                    }
                }
            });

            this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.LOADED, () => {
                this.setPlayerFullScreen();
                this.setState({ isContentStarted: true });
                // show default subtitles
                const subtitleKey = LOCAL_STORE_KEYS.SELECTED_SUBTITLE_FOR_USER.replace('${uid}', AuthenticationUtil.getUid());
                const selectedSubtitles = browserStore.get(subtitleKey) || '';
                if (selectedSubtitles !== '') {
                    this.playerFunctions?.setSelectedSubtitle(selectedSubtitles);
                    this.playerFunctions?.showCaptions(selectedSubtitles !== 'OFF' ? selectedSubtitles : '');
                }
                else {
                    this.playerFunctions.setSelectedSubtitle(DEFAULT_SUBTITLE_LANGUAGE);
                    this.playerFunctions.showCaptions(DEFAULT_SUBTITLE_LANGUAGE);
                }
                if (this.controlsBarInstance) {
                    this.controlsBarInstance.setPlayerQuality();
                }
                if (this.playerFunctions.isLiveStream()) {
                    this.playerFunctions.goLive();
                }
                this.isLoaded = true;
            });

            this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.VOLUME_CHANGE, () => {
                const volumeLevel = this.playerFunctions.getVolumeLevel();
                if (this.isWeb && !this.isScopedWebview) {
                    PlaybackStorageUtil.setPlayerVolumeLevel(volumeLevel);
                }
            });

            this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.CHANGE_BIT_RATE, (event) => {
                if (event.data) {
                    PlaybackStorageUtil.setPlayerQuality(event.data);
                }
                else {
                    PlaybackStorageUtil.removePlayerQuality();
                }
            });

            // online event to resume playback
            addListenerToEvent('online', this.handleOnlineStatusChange);
        }
    };

    // handleChangeEpgPageVisibility = (val) => {
    //     const { handleEpgViewChangeDispatch } = this.props;
    //     handleEpgViewChangeDispatch({
    //         isEpgPageVisible: val,
    //     });
    // };

    onTouchSettingsOverlay = (toggleVal) => {
        if (toggleVal) {
            this.showControlsOverlay();
            this.touchOverlay = true;
        }
        else {
            this.touchOverlay = false;
        }
    };

    showOnTouchControlsOverlay = () => {
        // if buffering then return
        if (this.waiting || this.touchOverlay || this.props.isEpgPageVisible) {
            return;
        }

        const { hideControlsOnIdleStateOnly = false } = this.props;
        if (!this.controlBarVisible) { // show controls on touch
            this.isHovered = true;
            this.showControlsOverlay(true);
        }
        else if (this.isWeb && this.controlsBarInstance && this.player && !(this.playerFunctions && this.playerFunctions.paused()) && !this.waiting && !this.touchOverlay && !hideControlsOnIdleStateOnly) {
            this.controlsBarInstance.fadeOut();
            this.controlBarVisible = false;
        }
        // clear earlier time out if there is any
        if (this.showOnTouchControlsOverlayTimeout) {
            clearTimeout(this.showOnTouchControlsOverlayTimeout);
        }
        this.showOnTouchControlsOverlayTimeout = setTimeout(this.hideControlsOverlay, PLAYER_CONFIG.OVERLAY_TIMEOUT, { idle: true });
    };

    showCenterIconOverlay = (e) => {
        const { option } = e;
        if (this.deviceUtil.isMobile() || mediaForMobile() || !this.isLoaded) {
            return;
        }
        if (this.playerFunctions && this.controlsBarInstance) {
            this.controlsBarInstance.showCenterIcon(option);
            if (this.centerIconOverlayTimer) {
                clearTimeout(this.centerIconOverlayTimer);
            }
            this.centerIconOverlayTimer = setTimeout(this.hideCenterIconOverlay, 200);
        }
    };

    hideCenterIconOverlay = () => {
        if (this.playerFunctions && this.controlsBarInstance) {
            this.controlsBarInstance.hideCenterIcon();
        }
    };

    showControlsOverlay = (touched) => {
        if (this.isWeb && touched || !this.player || this.waiting || this.touchOverlay || !this.playerEvents || this.isHovered || (this.playerFunctions && this.playerFunctions.paused())) {
            if (this.controlsBarInstance) {
                this.contentDescriptor.config && this.hideContentDescriptor();
                this.controlsBarInstance.fadeIn();
                this.controlBarVisible = true;
            }
        }
        else if (this.controlsBarInstance) {
            this.contentDescriptor.config && this.hideContentDescriptor();
            this.controlsBarInstance.fadeIn();
            this.controlBarVisible = true;
        }
        this.refreshIdleTimer();
    };

    hideControlsOverlay = ({ idle, showDescriptor = true } = { idle: false }) => {
        const { isSettingsVisible = false } = this.state;
        const { isEpgPageVisible } = this.props;
        const { config, shownForSession } = this.contentDescriptor;
        const displayDescriptor = config && !shownForSession && showDescriptor;
        // CONDITIONS
        // player must initiated
        if (this.isWeb && this.player && !(this.playerFunctions && this.playerFunctions.paused()) && !this.waiting && !this.touchOverlay && (!this.isHovered || idle)) {
            if (this.controlsBarInstance) {
                this.controlsBarInstance.fadeOut();
                this.controlBarVisible = false;
                if (displayDescriptor) {
                    this.displayContentDescriptor();
                }
            }
        }
        else if (this.player && !(this.playerFunctions && this.playerFunctions.paused()) && !this.waiting && idle) {
            if (!isSettingsVisible && this.controlsBarInstance && !lodashGet(document, 'activeElement.classList', []).contains(`${FocusManagementClassNames.ONLYFOCUSABLE}`) && !isEpgPageVisible) {
                this.controlsBarInstance.fadeOut();
                this.controlBarVisible = false;
                if (displayDescriptor) {
                    this.displayContentDescriptor();
                }
            }
        }
    };

    onPlayerFunctionsNotFound = () => {
        this.reload();
    };

    initControls = (controlsBarInstance) => {
        this.controlsBarInstance = controlsBarInstance;
    };

    videoOnMouseEnter = () => {
        if (this.contentDescriptor.blockFirst && !this.controlBarVisible) {
            return;
        }
        if (this.deviceUtil.isMobile()) {
            return;
        }
        this.isHovered = true;
        //   this.showControlsOverlay(); check for tv
    };

    videoOnMouseLeave = () => {
        // this.isHovered = false; //On Leave no need to hide (content Descriptor flow)
        // this.hideControlsOverlay();
    };

    videoOnMouseMove = () => {
        if (this.contentDescriptor.blockFirst && !this.controlBarVisible) {
            return;
        }

        if (this.deviceUtil.isMobile()) {
            return;
        }
        this.isHovered = true;
        this.showControlsOverlay(true);
    };

    getVideoTagRef = () => this.videoNode;

    getAdUiRef = () => this.adUiNode;

    setAdManager = (imaAdManager) => {
        this.imaAdManager = imaAdManager;
    };

    getAdManager = () => this.imaAdManager;

    getShakaAdUiRef = () => this.shakaAdUiNode;

    getVideoContainer = () => this.videoWrapperRef;

    setShowTimer(val) {
        this.setState({ showTimer: val });
    }

    renewVideoTag = () => {
        const { playbackConfig } = this.props;
        const { videoWidth } = this.state;
        const { posterUrl } = playbackConfig;
        const hasVideoTag = this.videoWrapperRef ? !!Array
            .from(this.videoWrapperRef.childNodes)
            .every(el => el === this.videoNode) : false;
        const imageFormat = this.deviceUtil.getImageFormat(posterUrl);

        if (!hasVideoTag && this.videoWrapperRef) {
            this.videoNode = document.createElement('video');
            this.videoNode.autoplay = true;

            if (playbackConfig.playbackUrlCookies) {
                this.videoNode.crossorigin = 'use-credentials';
            }
            if (posterUrl) {
                this.videoNode.poster = ThumborUtil.getThumborUrl(videoWidth, 0, posterUrl, imageFormat, 10);
            }
            this.videoWrapperRef.appendChild(this.videoNode); // in case of update this.videoNode is being removed, so assigning it back
        }

        this.controlsBarInstance.reset();
        this.showControlsOverlay();

        if (!playbackConfig.playbackUrl) {
            this.videoNode.poster = ThumborUtil.getThumborUrl(videoWidth, 0, playbackConfig.posterUrl, imageFormat, 10);
        }
    };

    setSettingsVisibility = (isSettingsVisible) => {
        this.setState({ isSettingsVisible });
    };

    getVideoElement = ({ videoWidth, playbackConfig, hideAdContainer }) => {
        const {
            playbackConfig: { playbackUrlCookies }, playbackDetails, langInfo = {}, isTrailerTile,
        } = this.props;

        const {
            subtitleDisplayerEnabled,
        } = this.state;
        let { posterUrl = '' } = playbackConfig;
        if (posterUrl) {
            const imageFormat = this.deviceUtil.getImageFormat(posterUrl);
            posterUrl = videoWidth > 0 ? ThumborUtil.getThumborUrl(videoWidth, 0, playbackConfig.posterUrl, imageFormat, 10) : '';
        }
        const subtitleOption = playbackDetails.subs ? Object.keys(playbackDetails.subs) : [];
        if (playbackUrlCookies) {
            return (
            // eslint-disable-next-line jsx-a11y/media-has-caption
                <>
                    <video
                        autoPlay
                        preload="auto"
                        // crossOrigin="use-credentials"
                        id={VIDEO_TAG_ID}
                        poster={posterUrl}
                        ref={(node) => {
                            if (typeof playbackConfig?.setVideoTagRefNode === 'function') {
                                playbackConfig.setVideoTagRefNode(node);
                            }
                            this.videoNode = node;
                        }}
                    >
                        {(subtitleOption && subtitleOption.length) ? subtitleOption.map(item => (
                            <track
                                src={playbackDetails.subs[item]}
                                kind="captions"
                                srcLang={item}
                                label={langInfo[item]}
                            />
                        )) : null
                        }
                    </video>
                    {!hideAdContainer ? (
                        <div
                            id="ad-container"
                            className="ads-container"
                            ref={(node) => {
                                this.adContainer = node;
                            }}
                        />
                    ) : null }
                    <SubtitleDisplayer
                        videoNode={this.videoNode}
                        subtitleDisplayerEnabled={subtitleDisplayerEnabled}
                    />
                </>
            );
        }

        return (
            <>
                <video
                    autoPlay
                    preload="auto"
                    id={VIDEO_TAG_ID}
                    responsive
                    poster={isTrailerTile ? undefined : posterUrl}
                    ref={(node) => {
                        if (typeof playbackConfig?.setVideoTagRefNode === 'function') {
                            playbackConfig.setVideoTagRefNode(node);
                        }
                        this.videoNode = node;
                    }}
                >
                    {subtitleOption.length && subtitleOption.map(item => (
                        <track
                            src={playbackDetails.subs[item]}
                            kind="captions"
                            srcLang={item}
                            label={langInfo[item]}
                        />
                    ))

                    }
                </video>
                { !hideAdContainer ? (
                    <div
                        id="ad-container"
                        className="ads-container"
                        ref={(node) => {
                            this.adContainer = node;
                        }}
                    />
                ) : null }
                <SubtitleDisplayer
                    videoNode={this.videoNode}
                    subtitleDisplayerEnabled={subtitleDisplayerEnabled}
                />
            </>
        );
    };

    getAdSlotContainer = () => {
        const {
            contentDetails, dispatchSdkAdsPlaying, updateKeyboardControlsActionDispatch, playbackDetails, adConfig,
        } = this.props;
        const { contentType, ads = {} } = playbackDetails;

        if (contentType === PROGRAM_TYPES.TRAILER) {
            return null;
        }
        const sdkAdsAllowed = checkSdkAdsAllowed(contentDetails, adConfig);
        if (sdkAdsAllowed) {
            return (
                <AdSlotWrapper
                    contentDetails={contentDetails}
                    ref={this.adSlotWrapperRef}
                    dispatchSdkAdsPlaying={dispatchSdkAdsPlaying}
                    videoNode={this.videoNode}
                    updateKeyboardControlsActionDispatch={updateKeyboardControlsActionDispatch}
                />
            );
        }
        if (Object.keys(ads).length > 0) {
            return (
                <PartnerAdSlotWrapper
                    adsData={ads}
                    dispatchSdkAdsPlaying={dispatchSdkAdsPlaying}
                    contentDetails={contentDetails}
                    updateKeyboardControlsActionDispatch={updateKeyboardControlsActionDispatch}
                    videoNode={this.videoNode}
                />
            );
        }

        return null;
    };


    render() {
        const {
            showContentDescriptor,
            isContentStarted,
        } = this.state;
        const {
            ControlBar,
            playbackConfig,
            isLiveStream,
            playbackAdsData,
            playbackDetails,
            showForwardButton,
            contentDetails,
            playTrailer,
            controlBarProps,
            contentAutoplay,
            playbackTagConfig,
            hideAdContainer = false,
            dispatchSdkAdsPlaying,
            isEpgPageVisible,
            handleEpgViewChangeDispatch,
            updateKeyboardControlsActionDispatch,
            adConfig,
            showDownloadFloatingNudge,
            pageId,
        } = this.props;

        const {
            isDrm,
            browser,
            videoMeta,
            logoUrl,
            forwardSkipDuration,
            backwardSkipDuration,
            enableNativeControls,
            contentId,
            supportsDVR,
            enableBitrateSelector,
            playbackSecurityLabelConfig,
            playbackSessionId,
            nextEpisodeDetails,
            skipIntroDur,
            skipCreditDur,
            previewImageInfo,
            showWatermark,
            watermarkLogoUrl,
            posterUrl,
        } = playbackConfig;

        const { videoWidth, randomLabelChangeRequestNumber } = this.state;
        const { contentType } = playbackDetails;

        const randomLabelElement = playbackSecurityLabelConfig && randomLabelChangeRequestNumber ? (
            <PlaybackSecurityLabelComponent
                lifeSpanInSeconds={playbackSecurityLabelConfig.durationInSeconds}
                uid={AuthenticationUtil.getUid()}
                changeRequestNumber={randomLabelChangeRequestNumber}
                maxTop={this.videoNode.offsetHeight + 10}
                maxLeft={this.videoNode.offsetWidth + 10}
                minTop={10}
                minLeft={10}
            />
        ) : null;

        const watermarkElement = (showWatermark && watermarkLogoUrl) ? (
            <WatermarkComponent logoUrl={watermarkLogoUrl} />
        ) : null;

        const controlBarElement = enableNativeControls ? null : (
            <ControlBar
                handleChangeEpgPageVisibility={handleEpgViewChangeDispatch}
                isEpgPageVisible={isEpgPageVisible}
                supportsDVR={supportsDVR}
                previewImageInfo={previewImageInfo}
                isDrm={isDrm}
                isLiveStream={isLiveStream}
                playbackAdsData={playbackAdsData}
                browser={browser}
                getVideoTag={this.getVideoTagRef}
                onPlayerFunctionsNotFound={this.onPlayerFunctionsNotFound}
                onRef={this.initControls}
                videoWrapperRef={this.getVideoContainer}
                videoWrapper={this.videoWrapperRef}
                contentId={contentId}
                videoMeta={videoMeta}
                logoUrl={logoUrl}
                forwardSkipDuration={forwardSkipDuration}
                backwardSkipDuration={backwardSkipDuration}
                enableBitrateSelector={enableBitrateSelector}
                playbackSessionId={playbackSessionId}
                showForwardButton={showForwardButton}
                contentDetails={contentDetails}
                playTrailer={playTrailer}
                contentAutoplay={contentAutoplay}
                setSettingsVisibility={this.setSettingsVisibility}
                {...(contentType !== PROGRAM_TYPES.TRAILER && {
                    nextEpisodeDetails,
                    skipIntroDur,
                    skipCreditDur,
                })}
                showOnTouchControlsOverlay={this.showOnTouchControlsOverlay}
                {...controlBarProps}
            />
        );
        return (

            <div
                onClick={this.playbackWrapperClicked}
                onMouseEnter={this.videoOnMouseEnter}
                onMouseLeave={this.isWeb ? this.videoOnMouseLeave : null}
                onMouseMove={this.videoOnMouseMove}
                onTouchEnd={this.isWeb ? this.showOnTouchControlsOverlay : null}
                className={`vdo-player__wrapper video-container sbtl-handler ${this.isScopedWebview && !isContentStarted ? 'show-placeholder-image' : ''}`}
                ref={(node) => {
                    this.videoWrapperRef = node;
                }}
            >
                {isEpgPageVisible ? (
                    <Suspense fallback={<div>Loading...</div>}>
                        <LiveChannelBox
                            handleChangeEpgPageVisibility={handleEpgViewChangeDispatch}
                            playerFunctions={this.playerFunctions}
                        />
                    </Suspense>
                ) : null}
                {this.isScopedWebview && !playTrailer ? (
                    <div className="video-placeholder-image-container">
                        <ThumborImage
                            className="video-placeholder-image"
                            src={posterUrl}
                            imageSettings={this.placeholderImageSettings}
                        />
                    </div>
                ) : null}
                {this.getVideoElement({
                    videoWidth,
                    playbackConfig,
                    hideAdContainer,
                })}

                {playbackConfig.os !== OS_LIST.IOS && !hideAdContainer && (
                    <>
                        <AdControlBar
                            getVideoTag={this.getVideoTagRef}
                            videoWrapperRef={this.getVideoContainer}
                            adsVideoNode={this.adsVideoNode}
                            getAdManager={this.getAdManager}
                        />
                    </>
                )}
                {this.getAdSlotContainer()}

                {this.contentDescriptor.config && contentType !== PROGRAM_TYPES.TRAILER && this.isWeb ? (
                    <ContentDescriptor
                        showContentDescriptor={showContentDescriptor}
                        contentDetails={contentDetails}
                    />
                ) : null}

                {playbackTagConfig && (playbackDetails.contentType !== PROGRAM_TYPES.TRAILER) && <TileTag tagConfig={playbackTagConfig} />}

                {controlBarElement}

                {/* show uid on random places */}
                {randomLabelElement}

                {/* show watermark on player */}
                {watermarkElement}

                {showDownloadFloatingNudge?.show ? (
                    <DownloadAirtelNudge
                        classes="player-landscape"
                        showBackIcon={false}
                        pageId={pageId}
                        showLottie
                        ingressName={DEEPLINK_INGRESS_KEYS.PLAYER_NUDGE}
                    />
                ) : null}
            </div>
        );
    }
}

AbstractPlayer.propTypes = {
    reload: PropTypes.func,
    ControlBar: PropTypes.func,
    langInfo: PropTypes.object.isRequired,
    playbackConfig: PropTypes.shape({
        playbackUrlCookies: PropTypes.object, // eslint-disable-line react/forbid-prop-types
        browser: PropTypes.string,
        browserVersion: PropTypes.number,
        os: PropTypes.string,
        enableAutoplay: PropTypes.bool,
        playbackUrl: PropTypes.string,
        playbackType: PropTypes.string.isRequired,
        isDrm: PropTypes.bool,
        contentId: PropTypes.string,
        playbackSecurityLabelConfig: PropTypes.shape({
            intervalsInMinutes: PropTypes.number.isRequired,
            durationInSeconds: PropTypes.number.isRequired,
        }),
        drm: PropTypes.shape({
            drmType: PropTypes.string,
            handleLicenseRequest: PropTypes.func,
            handleLicenseResponse: PropTypes.func,
            licenseUri: PropTypes.string,
            handleCertificateRequest: PropTypes.func, // for fairplay
            certificateUri: PropTypes.string, // for fairplay
        }),
        posterUrl: PropTypes.string,
        logoUrl: PropTypes.string,
        supportsDVR: PropTypes.bool.isRequired,
        isLiveStream: PropTypes.bool.isRequired,
        enableBitrateSelector: PropTypes.bool.isRequired,
        playbackSessionId: PropTypes.string.isRequired,
        enableNativeControls: PropTypes.bool.isRequired,
        forwardSkipDuration: PropTypes.number.isRequired,
        backwardSkipDuration: PropTypes.number.isRequired,
        videoMeta: PropTypes.shape({
            videoTitle: PropTypes.string,
            subTitle: PropTypes.string,
        }),
        playbackDetails: PropTypes.object.isRequired,
    }),
    isAuthenticated: PropTypes.bool.isRequired,
    fetchAdsToken: PropTypes.func.isRequired,
    playbackAdsData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    deviceUtil: PropTypes.object.isRequired,
    dispatchGetAdsPlaying: PropTypes.func, // eslint-disable-line react/forbid-prop-types
    // eslint-disable-next-line react/forbid-prop-types
    playbackDetails: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    langInfo: PropTypes.object.isRequired,
    contentDetails: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    hideAdContainer: PropTypes.bool,
    contentDescriptorConfig: PropTypes.bool.isRequired,
};

AbstractPlayer.defaultProps = {
    reload: () => null,
    ControlBar: () => null,
    dispatchGetAdsPlaying: () => null,
    hideAdContainer: false,
    playbackConfig: {
        playbackSecurityLabelConfig: null,
        playbackUrlCookies: null,
        browser: '',
        browserVersion: '',
        os: '',
        enableAutoplay: false,
        playbackUrl: '',
        isDrm: false,
        contentId: '',
        posterUrl: '',
        videoMeta: {
            videoTitle: '',
            subTitle: '',
        },
        logoUrl: '',
        drm: {
            drmType: '',
            handleLicenseRequest: undefined, // if provided then player will call this funtion to get license instead of get license itself
            handleLicenseResponse: undefined,
            licenseUri: '',
            handleCertificateRequest: undefined, // for fairplay
            certificateUri: '', // for fairplay
            ticket: '',
        },
        contentDetails: {},
    },
};
const mapStateToProps = (state) => {
    const {
        authConfig: { isAuthenticated },
        playbackDetails,
        notify,
    } = state;
    const adsPlayingFlag = lodashGet(playbackDetails, 'adsPlayingFlag', false);
    return {
        isAuthenticated,
        customPlaybackDetails: playbackDetails,
        adsPlayingFlag,
        showForwardButton: notify.showForwardButton,
    };
};

export default connect(mapStateToProps, {
    fetchAdsToken: adsDataInit,
    dispatchGetAdsPlaying: adsPlaying,
})(withDeviceUtil(withRouter(AbstractPlayer)));
