import FairplayCertificateParser from '../parsers/FairplayCertificateParser';
import { PLAYBACK_TYPES } from '@airtel-tv/constants/PlayerConsts';

export default ({ playbackType }) => {
    if (playbackType === PLAYBACK_TYPES.M3U8) {
        return FairplayCertificateParser.agnosticCertificateRequestParserForVideojs;
    }

    // TODO: handle no license parser
    return null;
};
