import lodashGet from 'lodash/get';
import { AppEnv } from '@airtel-tv/utils/GetEnv';
import { PLAYBACK_CHECK, PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';

const env = AppEnv.getEnv();

export const isWeb = () => env.deviceType === PLAYBACK_CHECK.BROWSER;

export const setMultiAudio = (contentDetails, continueWatchingData) => {
    const seriesId = lodashGet(contentDetails, 'seriesId', '');
    const id = lodashGet(contentDetails, 'id', '');
    switch (contentDetails.programType) {
        case PROGRAM_TYPES.EPISODE:
            contentDetails.langId = lodashGet(continueWatchingData[seriesId], 'langId', '');
            break;
        default:
            contentDetails.langId = lodashGet(continueWatchingData[id], 'langId', '');
    }
    return contentDetails;
};
