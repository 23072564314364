import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { stringifyInfinityValuesObject, parseInfinityValuesObject } from '@airtel-tv/utils/GlobalUtil';

class PlaybackStorageUtil {
    static setPlayerVolumeLevel = (volumeLevel) => {
        browserStore.set(LOCAL_STORE_KEYS.VOLUME_LEVEL, volumeLevel);
    };

    static getPlayerVolumeLevel = () => {
        const volumeLevel = browserStore.get(LOCAL_STORE_KEYS.VOLUME_LEVEL);
        if (volumeLevel === undefined || volumeLevel === null) {
            return 1;
        }
        return volumeLevel;
    };

    static getPlayerQuality = () => {
        const option = browserStore.get(LOCAL_STORE_KEYS.BIT_RATE);
        if (option) {
            return JSON.parse(option, parseInfinityValuesObject);
        }
        return option;
    }

    static setPlayerQuality = (qualityLevelOption) => {
        browserStore.set(LOCAL_STORE_KEYS.BIT_RATE, JSON.stringify(qualityLevelOption, stringifyInfinityValuesObject));
    }
    static removePlayerQuality = () => {
        browserStore.remove(LOCAL_STORE_KEYS.BIT_RATE);
    }
}

export default PlaybackStorageUtil;
