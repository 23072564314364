import lodashGet from 'lodash/get';
import LicenseParserFactory from '../factories/LicenseParserFactory';
import LicenseResponseFactory from '../factories/LicenseResponseFactory';
import CertificateParserFactory from '../factories/CertificateParserFactory';
import { DRM_LIST, STREAM_TYPES, BROWSER_LIST } from '@airtel-tv/constants/BrowserConst';
import PlaybackUtil from '../util/PlaybackUtil';
import {
    PLAYBACK_TYPES, PLAYER_CONFIG, PLAYBACK_SECURITY_LABEL, PLAYBACK_DEFAULT_CONFIGS_MINI_PLAYER, PLAYBACK_TYPES_MINI_PLAYER,
} from '@airtel-tv/constants/PlayerConsts';
import { IMAGE_PATHS, VALUE_TYPES, REQUEST_METHODS } from '@airtel-tv/constants/GlobalConst';
import {
    PAYLOAD_KEY_PLACEHOLDER,
    IOS_CERTIFICATE_HEADER_PLACEHOLDER,
    PAYLOAD_IOS_KEY_PLACEHOLDER,
    IOS_LICENSE_HEADER_PLACEHOLDER,
    PAYLOAD_TYPES,
} from '@airtel-tv/constants/PlaybackConsts';
import {
    convertToJSON, decryptSecretKey, parseLiveTvCooke,
} from '@airtel-tv/utils/GlobalUtil';
import { CONTENT_PROVIDERS, CP_CONFIG } from '@airtel-tv/constants/ContentProviderConst';
import FairplayContentIdParser from '../parsers/FairplayContentIdParser';
import { getPosterUrlFromContentDetails } from '@airtel-tv/utils/ContentImageProvider';

export default class PlaybackConfigBuilder {
    static buildConfig({
        os, browser, browserVersion,
        playbackUrl, playbackType, isDrm, drmType, isMsp, licenseUri,
        enableAutoplay, enableNativeControls, contentId, posterUrl,
        logoUrl, videoMeta,

        licenseHeaders,
        licenseResponsePayloadKey,
        licensePayload,
        licensePayloadKey,
        licenseHeaderPlaceholderKey,
        licenseHeaderSecretKey,
        licenseHeaderSecretData,
        licenseHeaderSecretEncryptionType,
        licenseHeaderSecretDataPlaceholderKey,
        licensePayloadFormat,
        licensePayloadType,
        licenseResponsePayloadType,

        certificateUri,
        certificateHeaders,
        certificateHeaderPayloadKey,
        certificatePayloadKey,
        certificateSecretKey,
        certificateSecretData,
        certificateSecretEncryptionType,
        certificateMethod,
        cp,

        forwardSkipDuration,
        backwardSkipDuration,

        supportsDVR,
        streamType,
        playbackUrlCookies,
        overrideNativeVideoJs,
        enableBitrateSelector,

        playbackSecurityLabelConfig,

        playbackSessionId,

        videoResolutionLimiter,
        nextEpisodeDetails,
        skipIntroDur,
        skipCreditDur,
        previewImageInfo,

        showWatermark,
        watermarkLogoUrl,
        daiAssetKey,
    }) {
        let playbackConfig = {
            browser,
            browserVersion,
            os,
            enableAutoplay,
            enableNativeControls,
            playbackUrl,
            playbackType,
            isDrm,
            isMsp,
            contentId,
            posterUrl,
            videoMeta,
            logoUrl,
            forwardSkipDuration,
            backwardSkipDuration,
            supportsDVR,
            streamType,
            playbackUrlCookies,
            overrideNativeVideoJs,
            enableBitrateSelector,
            playbackSecurityLabelConfig,
            playbackSessionId,
            videoResolutionLimiter,
            nextEpisodeDetails,
            skipIntroDur,
            skipCreditDur,
            previewImageInfo,
            showWatermark,
            watermarkLogoUrl,
            daiAssetKey,
        };

        if (isDrm) {
            let handleCertificateRequest = null;
            let handleContentIdRequest = null;
            let handleLicenseRequest = null;
            let handleLicenseResponse = null;

            if (cp === CONTENT_PROVIDERS.MWTV || isMsp) {
                handleContentIdRequest = FairplayContentIdParser.nagraLiveTVContentIdParser;
            }

            // can be null as not all content providers need a license parser
            let licenseParser = null;
            if (licenseHeaders || licenseResponsePayloadKey) {
                licenseParser = LicenseParserFactory({
                    playbackType,
                });
            }

            // ! find a better way to identify if we need to parse the response or not.
            // add license response parser if needed based on 'licenseResponsePayloadKey' :: we need this only for shaka
            const licenseResponseParser = playbackType === PLAYBACK_TYPES.MPD && licenseResponsePayloadKey ? LicenseResponseFactory({
                playbackType,
            }) : null;

            // can be null as not all content provider need a certificate parser
            let certificateParser = null;

            if (certificateHeaders) {
                certificateParser = CertificateParserFactory({
                    playbackType,
                });
            }

            // * HANDLE LICENSE AND CERTIFICATE PARSERS
            if (certificateParser !== null) {
                // payload will be keysystem in case of videojs and request in case of shakaplayer
                handleCertificateRequest = async () => {
                    const response = await certificateParser(
                        certificateUri,
                        certificateHeaders,
                        certificateHeaderPayloadKey,
                        certificatePayloadKey,
                        certificateSecretKey,
                        certificateSecretData,
                        certificateSecretEncryptionType,
                        certificateMethod,
                    );
                    return response;
                };
            }

            if (licenseParser !== null) {
                // payload will be keysystem in case of videojs and request in case of shakaplayer
                handleLicenseRequest = async (payload) => {
                    if (!(typeof payload === 'string' || typeof payload === 'object')) {
                        throw new Error('invalid input payload');
                    }

                    const response = await licenseParser(
                        payload, //  video js: string (actual payload) || shaka player object (request object)
                        licenseHeaders,
                        licensePayload,
                        licensePayloadKey,
                        licenseHeaderPlaceholderKey,
                        licenseHeaderSecretKey,
                        licenseHeaderSecretData,
                        licenseHeaderSecretEncryptionType,
                        licenseHeaderSecretDataPlaceholderKey,
                        licenseResponsePayloadKey,
                        licensePayloadFormat,
                        licenseUri,
                        licensePayloadType,
                        licenseResponsePayloadType,
                    );
                    return response;
                };

                // only shaka use this
                if (licenseResponseParser) {
                    handleLicenseResponse = async (payload) => {
                        const response = await licenseResponseParser(
                            payload, // response object
                            licenseResponsePayloadKey,
                        );
                        return response;
                    };
                }
            }


            // * SET DRM CONFIG
            playbackConfig = {
                ...playbackConfig,

                drm: {
                    drmType,
                    handleLicenseRequest,
                    handleLicenseResponse,
                    licenseUri,
                    handleCertificateRequest,
                    certificateUri,
                    handleContentIdRequest,
                },
            };

            // if (browser === BROWSER_LIST.SAFARI) {
            //     playbackConfig = {
            //         ...playbackConfig,
            //         drm: {
            //             ...playbackConfig.drm,
            //         },
            //     };
            // }
        }

        return playbackConfig;
    }

    static buildWidevineParams({
        playbackDetails,
        isDrm,
    }) {
        // DRM PROPERTIES
        let drmType = DRM_LIST.NON_DRM;
        let { expireCount } = playbackDetails;
        // const { cp } = playbackDetails;
        let licenseUri = '';
        let certificateUri = '';
        let licenseValidity = 0;

        // CP AGNOSTIC PROPERTIES
        let licenseHeaders = null;
        let licensePayloadKey = null;
        let licensePayloadType = null;

        let licenseResponsePayloadKey = null;
        let licensePayload = null;

        if (isDrm === true) {
            // assign drm config to variables
            ({ licenseValidity } = playbackDetails.drm);
            licenseHeaders = playbackDetails.drm.headers;

            licenseResponsePayloadKey = playbackDetails.drm.licensePayloadKey;


            licensePayloadType = lodashGet(playbackDetails, 'drm.licenseResponseDataType');
            licenseUri = playbackDetails.drm.url;
            licensePayload = playbackDetails.drm.payload;
            certificateUri = lodashGet(playbackDetails, 'certificate.url');


            // * 'default' means default behavior of player. so setting it 'null'
            if (licensePayload && Object.keys(licensePayload) < 1) {
                licensePayload = null;
            }
            if (licenseResponsePayloadKey && (licenseResponsePayloadKey === 'default' || licenseResponsePayloadKey === 'custom')) {
                licenseResponsePayloadKey = null;
            }

            // if exist licensePayload :: find the where the pay load needs to be sent
            if (licensePayload) {
                // TODO: ask backend to provide the name of key in which the playload will be passed
                // * find the licensePayloadKey :: it is the key where the value is `EXO_PAYLOAD`

                Object.keys(licensePayload).forEach((key) => {
                    if (licensePayload[key] === PAYLOAD_KEY_PLACEHOLDER) {
                        licensePayloadKey = key;
                    }
                });
            }

            drmType = DRM_LIST.WIDEVINE;

            expireCount = 0; // DRM content expires immediately
        }

        return {
            isDrm: isDrm || false,
            drmType,
            licenseUri,
            certificateUri,
            expireCount,
            licenseValidity,
            licenseHeaders,
            licenseResponsePayloadKey,
            licensePayload,
            licensePayloadKey,
            licensePayloadType,
        };
    }

    static buildFairplayParams({
        playbackDetails,
        authToken,
        isDrm,
    }) {
        // DRM PROPERTIES
        let drmType = DRM_LIST.NON_DRM;
        let { expireCount } = playbackDetails;
        let licenseValidity = 0;

        // CP AGNOSTIC PROPERTIES
        let licenseUri = null;
        let licensePayload = null;
        let licensePayloadType = null;
        let licensePayloadKey = null;
        let licenseHeaders = null;
        let licenseResponsePayloadKey = null;
        let licenseHeaderPlaceholderKey = null;
        let licenseHeaderSecretKey = null;
        let licenseHeaderSecretData = null;
        let licenseHeaderSecretDataPlaceholderKey = null;
        let licenseHeaderSecretEncryptionType = null;
        let licensePayloadFormat = null;
        let licenseResponsePayloadType = null;

        let certificateUri = '';
        let certificateHeaders = null;
        let certificateHeaderPayloadKey = null;
        let certificatePayloadKey = null;
        let certificateSecretKey = null;
        let certificateSecretData = null;
        let certificateSecretEncryptionType = null;
        let certificateMethod = null;

        if (isDrm === true) {
            // assign drm config to variables
            ({ licenseValidity } = playbackDetails.drm);

            licenseHeaders = convertToJSON(lodashGet(playbackDetails, 'drm.headers.format', '{}'));
            if (Object.keys(licenseHeaders).length < 1) {
                licenseHeaders = null;
            }
            else if (Object.keys(licenseHeaders).length < 2) {
                // * application/octet-stream is handled by videojs
                if (licenseHeaders['Content-Type'] === 'application/octet-stream') {
                    licenseHeaders = null;
                }
            }
            else if (licenseHeaders) {
                Object.keys(licenseHeaders).forEach((key) => {
                    if (licenseHeaders[key] === IOS_LICENSE_HEADER_PLACEHOLDER) {
                        licenseHeaderPlaceholderKey = key;
                    }
                });
            }

            licenseHeaderSecretKey = lodashGet(playbackDetails.drm, 'IOS_HEADERS.secretKey', null);

            // *  if certificateSecretKey exists then decrypt the key to get the actual key
            if (licenseHeaderSecretKey) {
                licenseHeaderSecretKey = decryptSecretKey({
                    secretKey: licenseHeaderSecretKey,
                    authToken,
                });
            }

            licenseHeaderSecretEncryptionType = lodashGet(playbackDetails, 'drm.IOS_HEADERS.type', null);

            licenseHeaderSecretData = lodashGet(playbackDetails, 'drm.IOS_HEADERS.format', null);
            // in IOS license header data may have a place holder
            if (licenseHeaderSecretData) {
                if (licenseHeaderSecretData.indexOf(PAYLOAD_IOS_KEY_PLACEHOLDER) > -1) {
                    licenseHeaderSecretDataPlaceholderKey = PAYLOAD_IOS_KEY_PLACEHOLDER;
                }
            }

            licenseUri = playbackDetails.drm.url;

            licenseResponsePayloadKey = playbackDetails.drm.licensePayloadKey;
            // * 'default' means default behavior of player. so setting it 'null'
            if (licenseResponsePayloadKey && licenseResponsePayloadKey === 'default') {
                licenseResponsePayloadKey = null;
            }

            licenseResponsePayloadType = lodashGet(playbackDetails, 'drm.licenseResponseDataType', null);
            licensePayload = convertToJSON(lodashGet(playbackDetails, 'drm.payload.format', '{}'));
            licensePayloadType = typeof licensePayload; // can be json or string

            if (licensePayload && licensePayloadType === VALUE_TYPES.OBJECT && Object.keys(licensePayload) < 1) {
                licensePayload = null;
            }

            // if exist licensePayload :: find the where the pay load needs to be sent
            if (licensePayload && licensePayloadType === VALUE_TYPES.OBJECT) {
                Object.keys(licensePayload).forEach((key) => {
                    if (licensePayload[key] === PAYLOAD_IOS_KEY_PLACEHOLDER) {
                        licensePayloadKey = key;
                    }
                });
            }
            else if (licensePayload && licensePayloadType === VALUE_TYPES.STRING) {
                licensePayloadKey = 'IOS_PAYLOAD';
            }

            // in IOS there is a way to specify the payload format
            licensePayloadFormat = lodashGet(playbackDetails, 'drm.IOS_PAYLOAD.type', null);
            if (licensePayloadFormat === PAYLOAD_TYPES.DEFAULT) {
                licensePayloadFormat = null;
            }

            // safari certificate configs
            certificateUri = lodashGet(playbackDetails, 'certificate.url');
            certificateMethod = lodashGet(playbackDetails, 'certificate.method', REQUEST_METHODS.GET);

            certificatePayloadKey = lodashGet(playbackDetails, 'certificate.certificatePayloadKey', null);
            // default means no handling
            if (certificatePayloadKey === PAYLOAD_TYPES.DEFAULT) {
                certificatePayloadKey = null;
            }

            certificateSecretKey = lodashGet(playbackDetails, 'certificate.IOS_HEADERS.secretKey', null);
            // *  if certificateSecretKey exists then decrypt the key to get the actual key
            if (certificateSecretKey) {
                certificateSecretKey = decryptSecretKey({
                    secretKey: certificateSecretKey,
                    authToken,
                });
            }

            certificateSecretData = lodashGet(playbackDetails, 'certificate.IOS_HEADERS.format', null);
            certificateSecretEncryptionType = lodashGet(playbackDetails, 'certificate.IOS_HEADERS.type', null);
            certificateHeaders = lodashGet(playbackDetails, 'certificate.headers.format', null);
            // in case of IOS certificateHeaders is JSON string so here, parse JSON
            if (certificateHeaders) {
                certificateHeaders = convertToJSON(certificateHeaders);
                // if headers are present then find if there is any place holder
                if (certificateHeaders) {
                    Object.keys(certificateHeaders).forEach((key) => {
                        if (certificateHeaders[key] === IOS_CERTIFICATE_HEADER_PLACEHOLDER) {
                            certificateHeaderPayloadKey = key;
                        }
                    });
                }
            }

            drmType = DRM_LIST.FAIRPLAY;

            expireCount = 0; // DRM content expires immediately
        }

        return {
            drmType,
            licenseUri,
            licenseValidity,
            licenseHeaders,
            licenseResponsePayloadKey,
            licensePayload,
            licensePayloadType,
            licensePayloadKey,


            certificateUri,
            certificateHeaders,
            certificateHeaderPayloadKey,
            certificatePayloadKey,
            certificateSecretKey,
            certificateSecretData,
            certificateSecretEncryptionType,
            certificateMethod,

            licenseHeaderPlaceholderKey,
            licenseHeaderSecretKey,
            licenseHeaderSecretData,
            licenseHeaderSecretEncryptionType,
            licenseHeaderSecretDataPlaceholderKey,
            licensePayloadFormat,
            licenseResponsePayloadType,
            expireCount,
        };
    }

    static buildParams({
        contentDetails,
        playbackDetails,
        authToken,
        deviceUtil,
        playbackSessionId,
        nextEpisodeDetails,
        previewImageCodeBlocked,
        blockBitRateOnLive,
        videoMeta,
    }) {
        // PLAYBACK PROPERTIES
        const playbackUrl = lodashGet(playbackDetails, 'playback.playUrl', undefined);
        const isMsp = lodashGet(playbackDetails, 'mspCp', false);
        const supportsDVR = lodashGet(playbackDetails, 'playback.supportsDVR', false);
        const previewImageInfo = !previewImageCodeBlocked ? lodashGet(playbackDetails, 'spriteInfo', null) : null;
        const showWatermark = lodashGet(playbackDetails, 'watermark', false);
        const imaEnabled = lodashGet(playbackDetails, 'playback.imaEnabled', false);
        let daiAssetKey;
        if (imaEnabled) {
            const imaConfig = lodashGet(playbackDetails, 'playback.imaConfig', {});
            daiAssetKey = imaConfig.daiAssetKey;
        }

        // TODO: REMOVING DVR Support
        // const supportsDVR = true;
        const streamType = playbackDetails.playbackType; // ENUM :: STREAM_TYPES
        const playbackType = PlaybackUtil.getPlaybackExtension(playbackUrl);
        const isDrm = !!playbackDetails.drm;
        const videoMetaDetails = !videoMeta.videoTitle ? { videoTitle: contentDetails?.episodeSeasonNum ? `S${contentDetails?.episodeSeasonNum}:E${contentDetails?.episodeNum}  ${contentDetails.title}` : contentDetails.title } : videoMeta;
        // PLAYER PROPERTIES
        const enableAutoplay = PLAYER_CONFIG.ENABLE_AUTO_PLAY;
        const enableNativeControls = false;

        // CONTENT PROPERTIES
        const { contentId } = playbackDetails;
        const cp = contentDetails.cpId;
        const skipIntroDur = lodashGet(contentDetails, 'skpIn', 0);
        const skipCreditDur = lodashGet(contentDetails, 'skpCr', 0);
        const logoUrl = contentDetails.logoUrl ? contentDetails.logoUrl : IMAGE_PATHS[contentDetails.cpId];
        const posterUrl = getPosterUrlFromContentDetails(contentDetails);
        const watermarkLogoUrl = lodashGet(contentDetails, 'watermarkLogoUrl', '');
        // SYSTEM PROPERTIES
        const browserVersion = deviceUtil.getBrowserVersion();
        const browser = deviceUtil.getBrowserName();
        const os = deviceUtil.getOSName();

        let playbackUrlCookies = null;
        let cookieExpire = null;

        const forwardSkipDuration = (streamType === STREAM_TYPES.LIVE && supportsDVR === true) || streamType !== STREAM_TYPES.LIVE ? PLAYER_CONFIG.BACKWARD_SKIP_DURATION : 0;
        const backwardSkipDuration = (streamType === STREAM_TYPES.LIVE && supportsDVR === true) || streamType !== STREAM_TYPES.LIVE ? PLAYER_CONFIG.BACKWARD_SKIP_DURATION : 0;

        let drmInfo = {};
        let videoResolutionLimiter = null; // {MAX_WIDTH: 0, MAX_HEIGHT: 0,};

        if (playbackType === PLAYBACK_TYPES.MPD) {
            drmInfo = PlaybackConfigBuilder.buildWidevineParams({
                playbackDetails,
                isDrm,
            });

            videoResolutionLimiter = lodashGet(CP_CONFIG, `${cp}.DASH`, 0);
        }
        else {
            drmInfo = PlaybackConfigBuilder.buildFairplayParams({
                playbackDetails,
                authToken,
                isDrm,
            });
            videoResolutionLimiter = lodashGet(CP_CONFIG, `${cp}.HLS`, 0);
        }

        // ! in case of live tv change cookie header to query params
        const cookieHeader = lodashGet(playbackDetails, 'playback.headers.Cookie', null);


        if (cookieHeader) {
            ({
                cookieExpire,
                playbackUrlCookies,
            } = parseLiveTvCooke(cookieHeader));
            drmInfo.expireCount = 0; // LIVE TV content expires immediately
        }


        // cannot set overrideNative to true for the following CPs in video js because
        // CONTENT_PROVIDERS.ALT_BALAJI : playback not working
        // CONTENT_PROVIDERS.HUNGAMA and CONTENT_PROVIDERS.HOICHOI : Green Patch is showing

        const overrideNativeVideoJs = !(browser === BROWSER_LIST.SAFARI
            && [
                CONTENT_PROVIDERS.ALT_BALAJI,
                CONTENT_PROVIDERS.HUNGAMA,
                CONTENT_PROVIDERS.HOICHOI,
            ].indexOf(cp) > -1)
            && !(browser === BROWSER_LIST.SAFARI && isDrm);

        let enableBitrateSelector = false;
        if (streamType === STREAM_TYPES.LIVE && !blockBitRateOnLive) {
            enableBitrateSelector = false;
        }
        else if (playbackType === PLAYBACK_TYPES.MPD) {
            enableBitrateSelector = !videoResolutionLimiter;
        }
        else {
            enableBitrateSelector = overrideNativeVideoJs;
        }
        // SECURITY LABEL FOR STREAM (RANDOM LABEL DISPLAY ON STREAM)
        const playbackSecurityLabelConfig = PLAYBACK_SECURITY_LABEL[cp];

        return {
            ...drmInfo,
            os,
            browser,
            browserVersion,
            playbackUrl,
            playbackType,
            isDrm: isDrm || false,
            isMsp: isMsp || false,

            enableAutoplay,
            enableNativeControls,
            cp,
            contentId,
            posterUrl,

            logoUrl,
            videoMeta: videoMetaDetails,
            streamType,
            supportsDVR,
            previewImageInfo,

            forwardSkipDuration,
            backwardSkipDuration,

            playbackUrlCookies,
            cookieExpire,
            overrideNativeVideoJs,
            enableBitrateSelector,
            playbackSecurityLabelConfig,

            playbackSessionId,
            videoResolutionLimiter,

            nextEpisodeDetails,
            skipIntroDur,
            skipCreditDur,
            watermarkLogoUrl,
            showWatermark,
            daiAssetKey,
        };
    }

    static LitePlayerConfigBuilder({
        contentDetails,
        playbackData,
    }) {
        const playBackType = lodashGet(contentDetails, 'playBackType', PLAYBACK_TYPES_MINI_PLAYER.DEFAULT);
        const availableControls = PLAYBACK_DEFAULT_CONFIGS_MINI_PLAYER[playBackType];
        const streamType = STREAM_TYPES.SHORT_STREAM;
        const playUrl = lodashGet(playbackData, 'playback.playUrl', null);
        const cookieHeader = lodashGet(playbackData, 'playback.headers.Cookie', null);

        let playbackUrlCookies = null;
        let cookieExpire = null;

        if (cookieHeader) {
            ({
                cookieExpire,
                playbackUrlCookies,
            } = parseLiveTvCooke(cookieHeader));
        }

        return {
            playUrl,
            availableControls,
            streamType,
            playbackUrlCookies,
            cookieExpire,
        };
    }
}
