
import React, {
    useRef, useImperativeHandle, useState, useCallback, useEffect,
} from 'react';
import SdkAdControlBar from '@airtel-feature/playback/controls/AdControlBar/SdkAdControlBar';

import { getUuid } from '@airtel-tv/utils/GlobalUtil';
import { addListenerToEvent, removeListenerFromEvent, checkWindowExist } from '@airtel-tv/utils';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { IMAGE_PATHS, VIDEO_TAG_ID } from '@airtel-tv/constants';
import EventType from '@airtel-tv/analytics/EventType';
import useAsyncReference from '@airtel-tv/lib/hooks/useAsyncReference';

const AdSlots = React.forwardRef((props, ref) => {
    const {
        currentSlot,
        handleAdProgress,
        playerEvents,
        handleAdsAnalytics,
        markSlotComplete,
        dispatchSdkAdsPlaying,
        updateKeyboardControlsActionDispatch,
        videoNode
    } = props;

    const [
        showAd,
        setShowAd,
    ] = useState(false);

    const [
        isAdLoading,
        setAdLoading,
    ] = useState(false);

    const videoTag = useAsyncReference(videoNode, true, false);
    const [
        adPaused,
        setAdPaused,
    ] = useState();

    const thisRef = useRef({
        adSlot: null,
        adController: null,
        analyticsData: null,
    }).current;

    // const resize = useCallback(() => {
    //     thisRef.adController.adResize()
    // }, [

    // ]);

    const triggerAd = () => {
        if (!thisRef.adController) {
            return;
        }
        thisRef.adController.adStart({
            height: window.innerHeight,
            width: window.innerWidth,
        }, true);
        dispatchSdkAdsPlaying({ sdkAdsPlayingFlag: true });
        updateKeyboardControlsActionDispatch({ enableKeyboardControls: false });
        // setTimeout(() => thisRef.adController.adPause(), 5000);
        // window.addEventListener("resize",(e) => console.log("ddd",e) )
        // dispatchGetAdsPlaying({ adsPlayingFlag: true, adType: AD_TYPE?.CLIENT_SIDE_AD });
    };

    const handleGetAdsData = (e) => {
        thisRef.adController = e.detail.clientData;
        thisRef.analyticsData = e.detail.analyticsData;
        if (currentSlot?.autoTrigger) {
            triggerAd();
        }

        // console.log('jjjjj', e.detail, thisRef.adController);
    };


    const adPlay = () => {
        // thisRef.adController.adPause();
        window.isSdkAdsPlaying = true;
        // setShowAd(true);
        setAdLoading(false);
    };

    const contentResume = (e) => {
        setShowAd(false);
        markSlotComplete(currentSlot?.slotUniqueIndentifier, e);
        dispatchSdkAdsPlaying({ sdkAdsPlayingFlag: false });
        window.isSdkAdsPlaying = false;
        setTimeout(async () => {
            if (videoTag.current.paused) {
                await videoTag.current.play();
            }
        }, 500);
        updateKeyboardControlsActionDispatch({ enableKeyboardControls: true });
    };
    const onResize = useCallback((e) => {
        if (thisRef.adController) {
            thisRef.adController.adResize({
                width: window.innerWidth,
                height: window.innerHeight,
            }, true);
        }
    }, []);

    const onAdPause = () => {
        setAdPaused(true);
    };

    const resumeAd = () => {
        if (thisRef.adController) {
            thisRef.adController?.adResume();
            setAdPaused(false);
        }
    };
    const handleAdSlotProgress = (e) => {
        if (checkWindowExist()) {
            if (window.isAppNotFocused) {
                thisRef.adController?.adPause();
            }
        }
        handleAdProgress(e);
    };

    const onAdLoad = () => {
        setShowAd(true);
        setAdLoading(true);
    }

    const initAdSlot = (id, node) => {
        node.addEventListener('sdkEvent-AD_PLAYBACK_START', adPlay);
        node.addEventListener('sdkEvent-GET_ADS_DATA', handleGetAdsData);
        node.addEventListener('sdkEvent-AD_LOAD', onAdLoad);
        node.addEventListener('sdkEvent-AD_PROGRESS', handleAdSlotProgress);
        node.addEventListener('sdkEvent-AD_RESUME', resumeAd);
        node.addEventListener('sdkEvent-CONTENT_RESUME', contentResume);
        node.addEventListener('sdkEvent-INTERNAL_AD_ERROR', contentResume);
        node.addEventListener('sdkEvent-AD_PAUSE', onAdPause);
        node.addEventListener('sdkEvent', handleAdsAnalytics);
        // node.addEventListener('sdkEvent-AD_STARTED', handleAdPlayBackState);
        const acsId = getUuid();
        const adSessionId = getUuid();

        const newAdsEventData = {
            detail: {
                type: EventType.AD_CONDITION_SUCCESS,
                analyticsData: {
                    ...thisRef.analyticsData,
                    event_name: EventType?.AD_CONDITION_SUCCESS?.val?.toLowerCase(),
                    acs_id: acsId,
                    playback_session_id: adSessionId,
                    slot_id: id,
                },
            },
        };
        handleAdsAnalytics(newAdsEventData);
        window.adsWebSDK('fetch', [
            {
                slotId: id,
                videoContentId: VIDEO_TAG_ID,
                clientAnalyticsData: {
                    acs_id: acsId,
                    playback_session_id: adSessionId,
                },
                renderingSetting: {
                    enablePreloadMedia: true, // for preloading of media url
                },
            },
        ]);
    };

    // eslint-disable-next-line arrow-body-style
    useImperativeHandle(ref, () => {
        return {
            triggerAd,
        };
    });

    useEffect(() => {
        addListenerToEvent('resize', onResize);
        return () => {
            removeListenerFromEvent('resize', onResize);
        };
    }, []);

    // useUpdateCustomHook(() => {
    //     if (currentSlot?.autoTrigger) {
    //         triggerAd();
    //     }
    // }, [
    //     currentSlot?.autoTrigger,
    // ]);

    return (
        <div className="adslot-container">
            <div
                className={`sdk-ad-slot ${showAd ? 'slot-visible' : ''}`}
                id={currentSlot.slotId}
                ref={(node) => {
                    if (!thisRef.adSlot) {
                        thisRef.adSlot = node;
                        initAdSlot(currentSlot.slotId, node);
                    }
                }}
            />
            {showAd
                ? (
                    <>
                        {adPaused ? (
                            <AnalyticsButtonComponent
                                id="player-play-btn"
                                type="button"
                                onClick={() => resumeAd()}
                                className="play-pause-icon bottom-control-buttons scale-hover"
                                aria-label="Play Button"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    backgroundColor: 'rgba(0 0 0 0.9)',
                                }}
                            >
                                <ThumborImage
                                    src={IMAGE_PATHS.PLAY}
                                    imageSettings={{
                                        500: 32,
                                    }}
                                    imageChanged
                                />
                            </AnalyticsButtonComponent>
                        )
                            : null}
                        <SdkAdControlBar
                            playerEvents={playerEvents}
                            isAdLoading={isAdLoading}
                        />
                    </>
                )
                : null
            }
        </div>
    );
});


export default AdSlots;
