/* eslint-disable no-inner-declarations */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import lodashGet from 'lodash/get';
import lodashIsString from 'lodash/isString';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { connect } from 'react-redux';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './HlsPlayer.scss';
import AbstractPlayer from '../abstract-player/AbstractPlayer';
import { PLAYER_EVENTS_LIST, PLAYER_TYPES } from '@airtel-tv/constants/PlayerConsts';
import getPlayerControls from '../../factories/PlayerControlsFactory';
import { STREAM_TYPES } from '@airtel-tv/constants/BrowserConst';
import getPlayerEvents from '../../factories/PlayerEventsFactory';
import {
    adBreakEndEvent, adBreakStartEvent, adClickEvent, adCompleteEvent, adLoadEvent, adPauseEvent, adResumeEvent, adStartEvent,
} from '@airtel-tv/analytics/FunctionalEvents';
import { ANALYTICS_ASSETS } from '@airtel-tv/constants/AnalyticsConst';
import { getAdsManagerReference, getMidRollAdsPlaying, getShowPreRollAds } from '../../actions/PlaybackActions';

const TEST_ASSET_KEY = 'sN_IYUG8STe1ZzhIIE_ksA';


class HlsPlayerComponent extends AbstractPlayer {
    constructor(props) {
        super(props, PLAYER_TYPES.HLS_PLAYER);
    }

    // componentDidMount() {
    //     this.initConfig();
    // }

    requestLiveStream = (assetKey, apiKey, streamManager) => {
        const streamRequest = new window.google.ima.dai.api.LiveStreamRequest();
        streamRequest.assetKey = assetKey;
        streamRequest.apiKey = apiKey;
        streamManager.requestStream(streamRequest);
    }

    midRollConfig(autoplayAllowed, autoplayRequiresMuted, playPreRollads) {
        const {
            playbackAdsData, playbackConfig, playbackDetails, adsData, dispatchAdsManager, dispatchShowPreRollAds, dispatchGetMidRollAdsPlaying,
        } = this.props;
        const timerElement = window.document.getElementById('ad-timer');
        const learnMoreElement = window.document.getElementById('learn-more');
        const adUiNode = this.getAdUiRef();
        const streamManager = new window.google.ima.dai.api.StreamManager(this.videoNode, adUiNode);
        const daiAssetKey = lodashGet(playbackAdsData, 'imaConfig.daiAssetKey', '');
        const playUrl = lodashGet(playbackAdsData, 'playUrl', '');
        const contentName = lodashGet(playbackConfig, 'videoMeta.subTitle', '');
        const preRollContentName = lodashGet(playbackConfig, 'videoMeta.videoTitle', '');
        const isdrm = lodashGet(playbackConfig, 'isDrm', '');
        const contentId = lodashGet(playbackConfig, 'contentId', '');
        const cpName = lodashGet(playbackDetails, 'cp', '');
        const contentLanguage = lodashGet(playbackDetails, 'plg', '');
        const contentType = lodashGet(playbackDetails, 'playbackType', '');
        const { enabled, videoAds } = adsData;
        const channelType = playbackConfig.contentId.split('_')[0];
        const adsType = videoAds && videoAds.slotConfigs && videoAds.slotConfigs.PRE_ROLL.type[channelType];
        const adsConfigData = videoAds && videoAds.slotConfigs && videoAds.slotConfigs.PRE_ROLL.ads[adsType][0];
        const videoRef = this.videoNode;
        let isAdBreak;
        let totalAds;
        let adcount;
        let adId;
        let adUrl;
        // Define a variable to track whether there are ads loaded and initially set it to false for pre-roll ads
        let adsLoaded = false;
        let adsManager;
        let preRollAds;

        const instanceRef = this.controlsBarInstance;

        const {
            streamType,
        } = playbackConfig || {};
        const isLiveStream = streamType === STREAM_TYPES.LIVE;

        // dispose previous player before loading new content
        if (this.player) {
            // eslint-disable-next-line no-unused-expressions
            this.player.dispose ? this.player.dispose() : null;
            this.player = null;

            this.renewVideoTag();
        }

        // render empty video tag with poster if playback url is not available
        if (!daiAssetKey && this.controlsBarInstance) {
            this.controlsBarInstance.enableControlBar();
            return;
        }
        this.player = new window.Hls({ abrMaxWithRealBitrate: true }); // this.playerjs video player

        const adsAnalyticsMeta = {
            content_name: contentName,
            app_type: ANALYTICS_ASSETS.WEB,
            ad_type: ANALYTICS_ASSETS.MID_ROLL,
            cp_name: cpName,
            bitrate: ANALYTICS_ASSETS.NULL,
            content_language: contentLanguage,
            content_type: contentType,
            content_id: contentId,
            is_drm_content: isdrm,
            channel_id: contentId,
        };
        const preRollAdsAnalyticsMeta = {
            content_name: preRollContentName,
            app_type: ANALYTICS_ASSETS.WEB,
            ad_type: ANALYTICS_ASSETS.PRE_ROLL,
            cp_name: cpName,
            bitrate: ANALYTICS_ASSETS.NULL,
            content_language: contentLanguage,
            content_type: contentType,
            content_id: contentId,
            is_drm_content: isdrm,
            channel_id: contentId,
        };
        let isPlaying = true;
        const adDisplayContainer = new window.google.ima.AdDisplayContainer(adUiNode, this.videoNode);
        const adsLoader = new window.google.ima.AdsLoader(adDisplayContainer);

        // Pre-roll Ads code
        if (enabled && adsType && playPreRollads) {
            function adContainerClick(event) {
                if (this.videoNode.paused) {
                    this.videoNode.play();
                }
                else {
                    this.videoNode.pause();
                }
            }
            adUiNode.addEventListener('click', adContainerClick);

            // On video playing toggle values
            this.videoNode.onplaying = function () {
                isPlaying = true;
            };

            // On video pause toggle values
            this.videoNode.onpause = function () {
                isPlaying = false;
            };

            function onAdError(adErrorEvent) {
                // Handle the error logging.
                console.error(adErrorEvent.getError());
                if (adsManager) {
                    adsManager.destroy();
                }
            }

            function onContentPauseRequested() {
                if (videoRef && !videoRef.paused && isPlaying) {
                    videoRef.muted = true;
                    videoRef.pause();
                }
            }

            function onContentResumeRequested() {
                if (videoRef && videoRef.paused && !isPlaying) {
                    videoRef.muted = false;
                    videoRef.play();
                }
            }

            function onAdLoaded(adEvent) {
                adLoadEvent(preRollAdsAnalyticsMeta);
                const ad = adEvent.getAd();
                if (!ad.isLinear()) {
                    this.videoNode.play();
                }
            }

            function onAdStart(e) {
                preRollAds = true;
                dispatchShowPreRollAds({ preRollAds });
                adId = e.getAd() && e.getAd().getTitle() && e.getAd().getTitle();
                adUrl = lodashGet(e.getAd(), 'j.clickThroughUrl', null);
                adUiNode.style.display = 'block';
                timerElement.style.display = 'block';
                if (adUrl && lodashIsString(adUrl)) {
                    learnMoreElement.style.display = 'block';
                    learnMoreElement.innerHTML = 'Learn More';
                }
                instanceRef.hideControlBar();
                instanceRef.enableAdBreak();
                videoRef.muted = true;
                const adStartAnalyticsMeta = {
                    content_name: contentName,
                    app_type: ANALYTICS_ASSETS.WEB,
                    ad_type: ANALYTICS_ASSETS.PRE_ROLL,
                    cp_name: cpName,
                    bitrate: ANALYTICS_ASSETS.NULL,
                    ad_id: adId,
                    content_language: contentLanguage,
                    content_type: contentType,
                    content_id: contentId,
                    is_drm_content: isdrm,
                    channel_id: contentId,
                };
                adStartEvent(adStartAnalyticsMeta);
            }

            function onAdComplete() {
                preRollAds = false;
                dispatchShowPreRollAds({ preRollAds });
                adUiNode.style.display = 'none';
                videoRef.muted = false;
                timerElement.style.display = 'none';
                learnMoreElement.style.display = 'none';
                instanceRef.showControlBar();
                instanceRef.disableAdBreak();
                adCompleteEvent(preRollAdsAnalyticsMeta);
            }

            function onAdProgress(e) {
                const progressData = e.getAdData();
                const timeRemaining = Math.floor(progressData.duration - progressData.currentTime);
                timerElement.innerHTML = `Ad: (${timeRemaining})`;
            }

            function onAdPause() {
                adPauseEvent(preRollAdsAnalyticsMeta);
            }

            function onAdResume() {
                adResumeEvent(preRollAdsAnalyticsMeta);
            }

            function onAdsManagerLoaded(adsManagerLoadedEvent) {
                // Instantiate the AdsManager from the adsLoader response and pass it the video element
                const adsRenderingSettings = new window.google.ima.AdsRenderingSettings();
                adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;
                adsManager = adsManagerLoadedEvent.getAdsManager(
                    this.videoNode, adsRenderingSettings,
                );
                dispatchAdsManager({ adsManager });
                // Mute the ad if doing muted autoplay.
                const adVolume = (autoplayAllowed && autoplayRequiresMuted) ? 0 : 1;
                adsManager.setVolume(adVolume);

                adsManager.addEventListener(
                    window.google.ima.AdErrorEvent.Type.AD_ERROR,
                    onAdError,
                );
                adsManager.addEventListener(
                    window.google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
                    onContentPauseRequested,
                );
                adsManager.addEventListener(
                    window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
                    onContentResumeRequested,
                );
                adsManager.addEventListener(
                    window.google.ima.AdEvent.Type.LOADED,
                    onAdLoaded,
                );
                adsManager.addEventListener(
                    window.google.ima.AdEvent.Type.STARTED,
                    onAdStart,
                );
                adsManager.addEventListener(
                    window.google.ima.AdEvent.Type.AD_PROGRESS,
                    onAdProgress,
                );
                adsManager.addEventListener(
                    window.google.ima.AdEvent.Type.PAUSED,
                    onAdPause,
                );
                adsManager.addEventListener(
                    window.google.ima.AdEvent.Type.RESUMED,
                    onAdResume,
                );
                adsManager.addEventListener(
                    window.google.ima.AdEvent.Type.COMPLETE,
                    onAdComplete,
                );
            }

            adsLoader.addEventListener(
                window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
                onAdsManagerLoaded,
                false,
            );
            adsLoader.addEventListener(
                window.google.ima.AdErrorEvent.Type.AD_ERROR,
                onAdError,
                false,
            );

            // Let the AdsLoader know when the video has ended
            this.videoNode.addEventListener('ended', () => {
                adsLoader.contentComplete();
            });

            const adsRequest = new window.google.ima.AdsRequest();
            // adsRequest.adTagUrl = 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=';
            adsRequest.adTagUrl = adsConfigData.adUnitId;

            // Specify the linear and nonlinear slot sizes. This helps the SDK to
            // select the correct creative if multiple are returned.
            adsRequest.linearAdSlotWidth = this.videoNode.clientWidth;
            adsRequest.linearAdSlotHeight = this.videoNode.clientHeight;
            adsRequest.nonLinearAdSlotWidth = this.videoNode.clientWidth;
            adsRequest.nonLinearAdSlotHeight = this.videoNode.clientHeight / 3;

            // Pass the request to the adsLoader to request ads
            adsLoader.requestAds(adsRequest);
        }

        // Timed metadata is only used for LIVE streams.
        this.player.on(window.Hls.Events.FRAG_PARSING_METADATA, (event, data) => {
            if (streamManager && data) {
                // For each ID3 tag in the metadata, pass in the type - ID3, the
                // tag data (a byte array), and the presentation timestamp (PTS).
                data.samples.forEach((sample) => {
                    streamManager.processMetadata('ID3', sample.data, sample.pts);
                });
            }
        });

        this.loadUrl = (url) => {
            this.player.loadSource(url);
            this.player.attachMedia(this.videoNode);
            this.player.on(window.Hls.Events.MANIFEST_PARSED, () => {
                this.videoNode.play();
            });
        };

        this.onStreamPause = () => {
            if (isAdBreak) {
                adUiNode.style.display = 'none';
            }
        };

        this.onStreamPlay = (e) => {
            // Pre-roll ads
            // prevent this function from running on every play event
            if (enabled && adsType && playPreRollads) {
                if (adsLoaded) {
                    return;
                }
                adsLoaded = true;

                // prevent triggering immediate playback when ads are loading
                e.preventDefault();

                // Initialize the container. Must be done via a user action on mobile devices.
                if (videoRef.paused && !isPlaying) {
                    videoRef.load();
                }
                // this.videoNode.load();
                adDisplayContainer.initialize();

                const width = this.videoNode.clientWidth;
                const height = this.videoNode.clientHeight;
                try {
                    setTimeout(() => {
                        adsManager.init(width, height, window.google.ima.ViewMode.NORMAL);
                        adsManager.start();
                    }, 500);
                    // adsManager.init(width, height, window.google.ima.ViewMode.NORMAL);
                    // adsManager.start();
                }
                catch (adError) {
                    // Play the video without ads, if an error occurs
                    console.error('AdsManager could not be started', adError);
                    this.videoNode.play();
                }
            }

            // Mid-roll ad
            if (isAdBreak) {
                adUiNode.style.display = 'block';
            }
        };

        this.videoNode.addEventListener('pause', this.onStreamPause);
        this.videoNode.addEventListener('play', this.onStreamPlay);

        this.onStreamEvent = (e) => {
            switch (e.type) {
                case window.google.ima.dai.api.StreamEvent.Type.LOADED:
                    adLoadEvent(adsAnalyticsMeta);
                    this.loadUrl(e.getStreamData().url);
                    break;
                case window.google.ima.dai.api.StreamEvent.Type.ERROR:
                    console.error(`Error loading stream, playing backup stream.${e}`);
                    this.loadUrl(playUrl);
                    break;
                case window.google.ima.dai.api.StreamEvent.Type.AD_BREAK_STARTED:
                    adcount = 0;
                    isAdBreak = true;
                    adUiNode.style.display = 'block';
                    timerElement.style.display = 'block';
                    dispatchGetMidRollAdsPlaying({ isAdBreak });
                    this.controlsBarInstance.hideControlBar();
                    this.controlsBarInstance.enableAdBreak();
                    adBreakStartEvent(adsAnalyticsMeta);
                    break;
                case window.google.ima.dai.api.StreamEvent.Type.STARTED:
                    adId = e.getAd() && e.getAd().getTitle() && e.getAd().getTitle();
                    adUrl = e.getAd() && e.getAd().h && e.getAd().h.j && e.getAd().h.j;
                    timerElement.style.display = 'block';
                    if (adUrl && lodashIsString(adUrl)) {
                        learnMoreElement.style.display = 'block';
                        learnMoreElement.innerHTML = 'Learn More';
                    }
                    // eslint-disable-next-line no-case-declarations
                    const adStartAnalyticsMeta = {
                        content_name: contentName,
                        app_type: ANALYTICS_ASSETS.WEB,
                        ad_type: ANALYTICS_ASSETS.MID_ROLL,
                        cp_name: cpName,
                        bitrate: ANALYTICS_ASSETS.NULL,
                        ad_id: adId,
                        content_language: contentLanguage,
                        content_type: contentType,
                        content_id: contentId,
                        is_drm_content: isdrm,
                        channel_id: contentId,
                    };
                    adcount++;
                    adStartEvent(adStartAnalyticsMeta);
                    break;
                case window.google.ima.dai.api.StreamEvent.Type.AD_PROGRESS:
                    const progressData = e.getStreamData().adProgressData;
                    totalAds = lodashGet(progressData, 'totalAds', '');
                    const timeRemaining = Math.floor(progressData.duration - progressData.currentTime);
                    timerElement.innerHTML = `Ad ${adcount} of ${totalAds}: (${timeRemaining})`;
                    break;
                case window.google.ima.dai.api.StreamEvent.Type.CLICK:
                    adClickEvent(adsAnalyticsMeta);
                    break;
                case window.google.ima.dai.api.StreamEvent.Type.COMPLETE:
                    adCompleteEvent(adsAnalyticsMeta);
                    timerElement.style.display = 'none';
                    learnMoreElement.style.display = 'none';
                    break;
                case window.google.ima.dai.api.StreamEvent.Type.PAUSED:
                    adPauseEvent(adsAnalyticsMeta);
                    break;
                case window.google.ima.dai.api.StreamEvent.Type.RESUMED:
                    adResumeEvent(adsAnalyticsMeta);
                    break;
                case window.google.ima.dai.api.StreamEvent.Type.AD_BREAK_ENDED:
                    adBreakEndEvent(adsAnalyticsMeta);
                    isAdBreak = false;
                    dispatchGetMidRollAdsPlaying({ isAdBreak });
                    this.controlsBarInstance.hideControlBar();
                    this.controlsBarInstance.showControlBar();
                    this.controlsBarInstance.disableAdBreak();
                    adUiNode.style.display = 'none';
                    timerElement.style.display = 'none';
                    learnMoreElement.style.display = 'none';
                    break;
                default:
                    break;
            }
        };

        streamManager.addEventListener(
            [
                window.google.ima.dai.api.StreamEvent.Type.LOADED,
                window.google.ima.dai.api.StreamEvent.Type.ERROR,
                window.google.ima.dai.api.StreamEvent.Type.AD_BREAK_STARTED,
                window.google.ima.dai.api.StreamEvent.Type.PAUSED,
                window.google.ima.dai.api.StreamEvent.Type.RESUMED,
                window.google.ima.dai.api.StreamEvent.Type.STARTED,
                window.google.ima.dai.api.StreamEvent.Type.COMPLETE,
                window.google.ima.dai.api.StreamEvent.Type.AD_BREAK_ENDED,
                window.google.ima.dai.api.StreamEvent.Type.AD_PROGRESS,
                window.google.ima.dai.api.StreamEvent.Type.CLICK,
            ],
            this.onStreamEvent,
            false,
        );

        this.requestLiveStream(daiAssetKey, null, streamManager);

        this.onLearnMoreClick = () => {
            adClickEvent(adsAnalyticsMeta);
            if (adUrl) {
                window.open(adUrl);
            }
        };

        learnMoreElement.addEventListener('click', this.onLearnMoreClick);

        this.playerFunctions = getPlayerControls({
            playerType: PLAYER_TYPES.HLS_PLAYER,
            player: this.player,
            videoTag: this.videoNode,
            isLiveStream,
        });

        this.playerEvents = getPlayerEvents({
            playerType: PLAYER_TYPES.HLS_PLAYER,
            player: this.player,
            videoTag: this.videoNode,
            playerFunctions: this.playerFunctions,
        });

        // local playback event handling e.g. hide and show
        this.playerEventsHandler();

        this.enableControlBar();

        // init keyboard events
        this.installKeyboardControls();

        // let parent know the events so that it can also subscribe and use
        const { playerEventsInitialized } = this.props;
        if (playerEventsInitialized) {
            playerEventsInitialized(this.playerEvents, this.playerFunctions);
        }
    }

    initConfig() {
        let autoplayAllowed;
        let autoplayRequiresMuted;
        let playPreRollads = true;
        const videoRef = this.videoNode;
        this.videoNode.src = 'https://storage.googleapis.com/gvabox/media/samples/stock.mp4';
        /**
         * Attempts autoplay and handles success and failure cases.
         */
        function checkAutoplaySupport() {
            // Test for autoplay support with our content player.
            const playPromise = videoRef.play();
            if (playPromise !== undefined) {
                playPromise.then(onAutoplayWithSoundSuccess).catch(onAutoplayWithSoundFail);
            }
        }

        /**
         * Handles case where autoplay succeeded with sound.
         */
        const onAutoplayWithSoundSuccess = () => {
            // If we make it here, unmuted autoplay works.
            videoRef.pause();
            autoplayAllowed = true;
            autoplayRequiresMuted = false;
            this.videoNode.removeAttribute('src');
            playPreRollads = true;
            // request for ads
            this.midRollConfig(autoplayAllowed, autoplayRequiresMuted, playPreRollads);
        };

        /**
         * Handles case where autoplay fails with sound.
         */
        const onAutoplayWithSoundFail = () => {
            // Unmuted autoplay failed. Now try muted autoplay.
            checkMutedAutoplaySupport();
        };

        /**
         * Checks if video can autoplay while muted.
         */
        const checkMutedAutoplaySupport = () => {
            videoRef.volume = 0;
            videoRef.muted = true;
            const playPromise = videoRef.play();
            if (playPromise !== undefined) {
                playPromise.then(onMutedAutoplaySuccess).catch(onMutedAutoplayFail);
            }
        };

        /**
         * Handles case where autoplay succeeded while muted.
         */
        const onMutedAutoplaySuccess = () => {
            videoRef.volume = 1;
            videoRef.muted = false;
            autoplayAllowed = false;
            autoplayRequiresMuted = false;
            this.videoNode.removeAttribute('src');
            playPreRollads = false;
            // request for ads
            this.midRollConfig(autoplayAllowed, autoplayRequiresMuted, playPreRollads);
            // videoRef.play();
        };

        /**
         * Handles case where autoplay failed while muted.
         */
        const onMutedAutoplayFail = () => {
            // Both muted and unmuted autoplay failed. Fall back to click to play.
            videoRef.volume = 1;
            videoRef.muted = false;
            autoplayAllowed = false;
            autoplayRequiresMuted = false;
            this.videoNode.removeAttribute('src');
            playPreRollads = false;
            // request for ads
            this.midRollConfig(autoplayAllowed, autoplayRequiresMuted, playPreRollads);
        };
        checkAutoplaySupport();
    }
}

HlsPlayerComponent.propTypes = {
    playbackConfig: PropTypes.shape({
        overrideNativeVideoJs: PropTypes.bool,
        playbackUrlCookies: PropTypes.object, // eslint-disable-line react/forbid-prop-types
        browser: PropTypes.string,
        browserVersion: PropTypes.number,
        os: PropTypes.string,
        enableAutoplay: PropTypes.bool,
        playbackUrl: PropTypes.string,
        playbackType: PropTypes.string.isRequired,
        isDrm: PropTypes.bool,
        contentId: PropTypes.string,
        drm: PropTypes.shape({
            drmType: PropTypes.string,
            handleLicenseRequest: PropTypes.func,
            handleLicenseResponse: PropTypes.func,
            licenseUri: PropTypes.string,
            handleCertificateRequest: PropTypes.func, // for fairplay
            certificateUri: PropTypes.string, // for fairplay
        }),
    }),
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    playbackAdsData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    playbackDetails: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    adsData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

HlsPlayerComponent.defaultProps = {
    playbackConfig: {
        overrideNativeVideoJs: true,
        playbackUrlCookies: null,
        browser: '',
        browserVersion: '',
        os: '',
        enableAutoplay: false,
        playbackUrl: '',
        isDrm: false,
        contentId: '',
        drm: {
            drmType: '',
            handleLicenseRequest: undefined, // if provided then player will call this funtion to get license instead of get license itself
            handleLicenseResponse: undefined,
            licenseUri: '',
            handleCertificateRequest: undefined, // for fairplay
            certificateUri: '', // for fairplay
            ticket: '',
        },
    },
};
const mapStateToProps = (state) => {
    const {
        appConfig,
    } = state;
    const adsData = lodashGet(appConfig, 'adsData', {});
    return {
        adsData,
    };
};
export default connect(mapStateToProps, {
    dispatchAdsManager: getAdsManagerReference,
    dispatchShowPreRollAds: getShowPreRollAds,
    dispatchGetMidRollAdsPlaying: getMidRollAdsPlaying,
})(withDeviceUtil(withRouter(withStyles(styles)(HlsPlayerComponent))));
