import { PLAYER_EVENTS_LIST } from '@airtel-tv/constants/PlayerConsts';
import { MAX_INT } from '@airtel-tv/constants/NetworkConst';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';

export default function HeartBeatHelper({
    playerEvents,
    contentId,
    sendHeartBeat,
    heartBeatInterval,
}) {
    if (!playerEvents || !sendHeartBeat || !contentId) {
        return;
    }

    if (!heartBeatInterval || heartBeatInterval === MAX_INT) {
        return;
    }

    // used for time update
    let previousCurTime = 0;
    let continuousPlaybackCount = 0;
    let setHeartBeatIntervalFromLocal = false;
    let heartBeatIntervalSeconds = Math.ceil((heartBeatInterval / 1000));

    playerEvents.emitter.on(PLAYER_EVENTS_LIST.TIMEUPDATE, (currentTime) => {
        // current time in seconds
        const curTempTime = Math.floor(currentTime);
        // event is fired every .25 second so check if it is a new second
        if (curTempTime !== previousCurTime) {
            previousCurTime = curTempTime;

            // increment playback time
            continuousPlaybackCount += 1;

            if (continuousPlaybackCount % heartBeatIntervalSeconds === 0) {
                sendHeartBeat({
                    contentId,
                });
            }
            if (!setHeartBeatIntervalFromLocal) {
                let heartBeatIntervalNew = browserStore.get('heartBeatInterval');
                if (heartBeatIntervalNew) {
                    heartBeatIntervalNew = JSON.parse(heartBeatIntervalNew);
                    if (heartBeatIntervalNew[contentId]) {
                        setHeartBeatIntervalFromLocal = true;
                        heartBeatIntervalSeconds = Math.ceil((heartBeatIntervalNew[contentId] / 1000));
                    }
                }
            }
        }
    });
}
