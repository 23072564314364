import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import EventType from '@airtel-tv/analytics/EventType';
import { IGNORE_EVENTS, IMAGE_PATHS, PARTNER_AD_SLOT, PLAYER_EVENTS_LIST, VIDEO_TAG_ID } from '@airtel-tv/constants';
import useAsyncReference from '@airtel-tv/lib/hooks/useAsyncReference';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { webAdSdkEventDataShaper } from '@airtel-tv/utils/AdSdkUtil';
import { getUuid } from '@airtel-tv/utils/GlobalUtil';
import React, { useEffect, useRef, useState } from 'react'
import { subscribe } from '../../../../../tv/src/utilities/Pubsub';
import SdkAdControlBar from '../../../controls/AdControlBar/SdkAdControlBar';

const IMAGESETTINGS = {
    500: 32,
};
const PartnerAdSlotWrapper = ({ videoNode, adsData, dispatchSdkAdsPlaying, contentDetails, updateKeyboardControlsActionDispatch }) => {
    const [
        showAd,
        setShowAd,
    ] = useState(false);

    const [
        isAdLoading,
        setAdLoading,
    ] = useState(false);

    const [
        adPaused,
        setAdPaused,
    ] = useState();
    const adTagUrl = adsData?.meta?.url;
    if (!adTagUrl || adTagUrl === '') return;
    const videoTag = useAsyncReference(videoNode, true, false);
    const thisRef = useRef({
        adSlot: null,
        adController: null,
        analyticsData: null,
    }).current;

    const triggerAd = () => {
        if (!thisRef.adController) {
            return;
        }
        // hide under ad player
        thisRef.adController.adStart({
            height: window.innerHeight,
            width: window.innerWidth,
        }, true);
        dispatchSdkAdsPlaying({ sdkAdsPlayingFlag: true });
        updateKeyboardControlsActionDispatch({ enableKeyboardControls: false });
        window.isSdkAdsPlaying = true;
        // setTimeout(() => thisRef.adController.adPause(), 5000);
        // window.addEventListener("resize",(e) => console.log("ddd",e) )
        // dispatchGetAdsPlaying({ adsPlayingFlag: true, adType: AD_TYPE?.CLIENT_SIDE_AD });
    };

    const handleGetAdsData = (e) => {
        thisRef.adController = e.detail.clientData;
        thisRef.analyticsData = e.detail.analyticsData;
        triggerAd();
        setCuePoints();
    };

    const setCuePoints = () => {
        const cuePoints = thisRef.adController.getCuePoints();
        if (!cuePoints || cuePoints.length == 0) {
            return;
        }
        thisRef.adCuePoints = cuePoints.map((item, index) => ({
            start: item,
            end: null,
            alreadyPlayed: false,
            slotUniqueIndentifier: `AD_${index}`,
        }));
        emitCuePointChanged();
    }

    const emitCuePointChanged = () => {
        if (!thisRef?.playerEvents?.emitter) {
            return;
        }
        thisRef.playerEvents.emitter.emit(PLAYER_EVENTS_LIST.AD_CUEPOINTS_CHANGED, { adCuePoints: thisRef.adCuePoints });
    };

    const handleAdProgress = (e) => {
        if (!thisRef?.playerEvents?.emitter) {
            return;
        }
        thisRef.playerEvents.emitter.emit(PLAYER_EVENTS_LIST.AD_PROGRESS, e.detail);
    };

    const contentResume = (e) => {
        checkForPostroll(e);
        setAdLoading(false);
        window.isSdkAdsPlaying = false;
        setShowAd(false);
        markcuePointComplete();
        setTimeout(async () => {
            if (videoTag.current.paused) {
                await videoTag.current.play();
            }
        }, 1500);    
        dispatchSdkAdsPlaying({ sdkAdsPlayingFlag: false });
        updateKeyboardControlsActionDispatch({ enableKeyboardControls: true });
    };

    const onAdPause = () => {
        setAdPaused(true);
        if (thisRef.adController) {
            thisRef.adController?.adPause();
            setAdPaused(true);
        }
    };

    const resumeAd = () => {
        if (thisRef.adController) {
            thisRef.adController?.adResume();
            setAdPaused(false);
        }
    };

    const onSeekBarChange = seekData => {
        const { newTime } = seekData;
        if (!newTime || !thisRef?.adCuePoints) {
            return;
        }
        for (let cuePoint of thisRef.adCuePoints) {
            if (cuePoint.start < newTime && !cuePoint.alreadyPlayed) {
                lastCuePoint = cuePoint;
            } else if (cuePoint.start > newTime) {
                break;
            }
        }
    }

    const handleSDkAnalytics = (sdkEvent) => {
        const { id } = contentDetails;
        const { detail } = sdkEvent;
        const { analyticsData, skipOffset, type } = detail;

        const playAnalytics = {
            content_id: id,
            program_id: id,
            content_category: 'Ads',
        };
        const shapedAnalyticsData = webAdSdkEventDataShaper({
            ...analyticsData,
            skipOffset,
        });
        if (!IGNORE_EVENTS.includes(type)) {
            // console.log(sdkEvent, "logging");
            const data = {
                ...playAnalytics,
                ...shapedAnalyticsData,
                isPartnerBased: true
            };
            thisRef?.playerEvents?.emitter?.emit(PLAYER_EVENTS_LIST.ADS_SDK_EVENT, data);
        }
    };

    const checkForPostroll = (adEvent) => {
        setAdLoading(true);
        if (adEvent?.detail?.adPod?.timeOffset === -1) {
            thisRef?.playerEvents?.emitter?.emit(PLAYER_EVENTS_LIST.CONTENT_WITH_POSTROLL_COMPLETION);
        } else if (thisRef.isPostrollRunning && adEvent.detail.error) {
            thisRef?.playerEvents?.emitter?.emit(PLAYER_EVENTS_LIST.CONTENT_WITH_POSTROLL_COMPLETION);
        }
    };

    const onAdLoad = () => {
        setShowAd(true);
        setAdLoading(true);
    }

    const adPlay = () => {
        dispatchSdkAdsPlaying({ sdkAdsPlayingFlag: true });
        updateKeyboardControlsActionDispatch({ enableKeyboardControls: false });
        window.isSdkAdsPlaying = true;
        setAdLoading(false);
    };

    const initAdSlot = (id, node) => {
        node.addEventListener('sdkEvent-AD_PLAYBACK_START', adPlay);
        node.addEventListener('sdkEvent-GET_ADS_DATA', handleGetAdsData);
        node.addEventListener('sdkEvent-AD_PROGRESS', handleAdProgress);
        node.addEventListener('sdkEvent-AD_LOAD', onAdLoad);
        node.addEventListener('sdkEvent-AD_RESUME', resumeAd);
        node.addEventListener('sdkEvent-CONTENT_RESUME', contentResume);
        node.addEventListener('sdkEvent-INTERNAL_AD_ERROR', contentResume);
        node.addEventListener('sdkEvent-AD_PAUSE', onAdPause);
        node.addEventListener('sdkEvent-AD_COMPLETE', checkForPostroll);
        node.addEventListener('sdkEvent-ALL_ADS_COMPLETED', checkForPostroll);
        node.addEventListener('sdkEvent', handleSDkAnalytics);
        subscribe(PLAYER_EVENTS_LIST.PLAYER_EVENTS_EMITTER_ENABLED, (plEvent) => {
            plEvent?.emitter?.on(PLAYER_EVENTS_LIST.TRIGGER_POST_ROLL_AD, () => {
                thisRef.isPostrollRunning = true;
                try {
                    const cuePoints = thisRef.adController.getCuePoints();
                    if (cuePoints.findIndex(point => point === -1) === -1) {
                        thisRef?.playerEvents?.emitter?.emit(PLAYER_EVENTS_LIST.CONTENT_WITH_POSTROLL_COMPLETION);
                    }
                } catch(e) {
                    console.log(e, 'called error');
                    thisRef?.playerEvents?.emitter?.emit(PLAYER_EVENTS_LIST.CONTENT_WITH_POSTROLL_COMPLETION);
                }
            });
            if (thisRef.playerEvents) {
                return;
            }
            thisRef.playerEvents = plEvent;
        });
        const acsId = getUuid();
        const adSessionId = getUuid();

        const newAdsEventData = {
            detail: {
                type: EventType.AD_CONDITION_SUCCESS,
                analyticsData: {
                    ...thisRef.analyticsData,
                    event_name: EventType?.AD_CONDITION_SUCCESS?.val?.toLowerCase(),
                    acs_id: acsId,
                    playback_session_id: adSessionId,
                    slot_id: id,
                },
            },
        };
        handleSDkAnalytics(newAdsEventData);
        let adURLWithVid = adTagUrl.replace('{vid}', contentDetails.id);
        if (contentDetails.cpId === 'SONYLIV_VOD') {
            adURLWithVid = adURLWithVid + '&pp=Airtel_dashmpeg_only';
        }
        window.adsWebSDK('fetch', [
            {
                slotId: PARTNER_AD_SLOT,
                videoContentId: VIDEO_TAG_ID,
                clientAnalyticsData: {
                    acs_id: acsId,
                    playback_session_id: adSessionId,
                },
                clientAdConfig:[
                    {
                        source: "PAL",
                        adUnitIds: [adURLWithVid],
                        slotAdType: "VIDEO"
                    }
                ],
                renderingSetting: {
                    enablePreloadMedia: true, // for preloading of media url
                },
            },
        ]);
    };

    const markcuePointComplete = () => {
        if (!thisRef.adCuePoints || thisRef.adCuePoints.length === 0) {
            return;
        }
        thisRef.adCuePoints = thisRef.adCuePoints.map(item => {
            if (item.start >= 0 && item.start < videoTag.current.currentTime + 5) {
                item = {
                    ...item,
                    alreadyPlayed: true,
                }
            }
            return item;
        });
        emitCuePointChanged();
    };

    return (
        <div className="adslot-container">
                <div
                    className={`sdk-ad-slot ${showAd ? 'slot-visible' : ''}`}
                    id={PARTNER_AD_SLOT}
                    ref={(node) => {
                        if (!thisRef.adSlot) {
                            thisRef.adSlot = node;
                            initAdSlot(PARTNER_AD_SLOT, node);
                        }
                    }}
                />
                {showAd
                    ? (
                        <>
                            {adPaused ? (
                                <AnalyticsButtonComponent
                                    id="player-play-btn"
                                    type="button"
                                    onClick={() => resumeAd()}
                                    className="play-pause-icon bottom-control-buttons scale-hover"
                                    aria-label="Play Button"
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        backgroundColor: 'rgba(0 0 0 0.9)',
                                    }}
                                >
                                    <ThumborImage
                                        src={IMAGE_PATHS.PLAY}
                                        imageSettings={IMAGESETTINGS}
                                        imageChanged
                                    />
                                </AnalyticsButtonComponent>
                            )
                                :null
                                }
                            <SdkAdControlBar
                                playerEvents={thisRef.playerEvents}
                                isAdLoading={isAdLoading}
                            />
                        </>
                    )
                    : null
                }
            </div>
    )
}

export default PartnerAdSlotWrapper;