import lodashGet from 'lodash/get';
import { HLS_PLAYER_EVENTS, PLAYER_EVENTS_LIST } from '@airtel-tv/constants/PlayerConsts';
import { isOnline, raiseEvent } from '@airtel-tv/utils/WindowUtil';
import { DateTime } from '@airtel-tv/utils';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';

export default class HlsPlayerControls {
    constructor(player, videoTag, isLiveStream) {
        this.player = player;
        this.videoTag = videoTag;
        this.liveDuration = 0;
        this.previousVolumeValue = 1;

        if (isLiveStream) {
            const setLiveDuration = () => {
                if (!this.liveDuration || this.liveDuration === Infinity) {
                    this.liveDuration = this.getDVRDurationInSeconds();
                    videoTag.removeEventListener(HLS_PLAYER_EVENTS.LOADED, setLiveDuration);
                }
            };
            if (videoTag) {
                videoTag?.addEventListener(HLS_PLAYER_EVENTS.LOADED, setLiveDuration);
            }
        }
    }

    play = () => {
        if (!this.videoTag || !this.player) {
            return false;
        }

        // TODO: check player has buffered connection
        if (!isOnline()) {
            const error = { code: ERROR_CODES.LOCAL1003 };
            raiseEvent(this.videoTag, HLS_PLAYER_EVENTS.ERROR, error);
        }

        try {
            return this.videoTag.play();
        }
        catch (error) {
            // console.error(error);
            return false;
        }
    };

    pause = () => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        return this.videoTag.pause();
    };

    toggleFullScreen = () => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        if (!this.videoTag.fullscreenElement) {
            return this.videoTag.requestFullscreen();
        }

        return this.videoTag.exitFullscreen();
    };


    isMute = () => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        return this.videoTag.muted || !this.getVolumeLevel();
    }

    getVolumeLevel = () => {
        if (!this.videoTag || !this.player) {
            return 100;
        }
        return this.videoTag.volume;
    }

    toggleVolume = (isMobile) => {
        if (!this.videoTag || !this.player) {
            return false;
        }

        if (this.isMute()) {
            this.videoTag.muted = false;
            this.volumeChange(this.getVolumeLevel() ? this.getVolumeLevel() : this.previousVolumeValue, false, isMobile);
        }
        else {
            this.previousVolumeValue = this.getVolumeLevel();
            this.volumeChange(0, false, isMobile);
            this.videoTag.muted = true;
        }
        return this.videoTag.muted;
    };

    volumeChange = (volumeLevel, isSetFromKeyboard, isMobile) => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        const tempVal = Number.parseFloat(volumeLevel).toFixed(2);
        this.videoTag.volume = Math.max(0, Math.min(tempVal, 1));
        if (this.isMute()) {
            this.videoTag.muted = false;
        }

        if (isSetFromKeyboard) {
            raiseEvent(this.videoTag, HLS_PLAYER_EVENTS.VOLUME_CHANGE, true);
        }
        else if (isMobile) {
            raiseEvent(this.videoTag, HLS_PLAYER_EVENTS.VOLUME_CHANGE, false);
        }
        return this.videoTag.currentTime;
    };

    seekBarChange = (seekDuration) => {
        if (!this.videoTag || !this.player) {
            return false;
        }

        const data = {
            newTime: seekDuration,
            previousTime: this.videoTag.currentTime,
        };

        raiseEvent(this.videoTag, HLS_PLAYER_EVENTS.SEEK_BAR_CHANGE, data);

        this.videoTag.currentTime = seekDuration;
        return this.videoTag.currentTime;
    };

    replay = () => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        this.videoTag.currentTime = 0;
        return 0;
    };

    getBandwidth = () => {
        if (!this.player && this.player.getStats) {
            return 0;
        }

        return null;// lodashGet(this.player.getStats(), 'streamBandwidth') || null;
    }

    getCurrentRepresentationIndex = () => {
        if (!this.player) {
            return false;
        }

        const playingBandwidth = this.getBandwidth();

        let count = -1;
        // this.player.getVariantTracks().find((representation) => {
        //     count += 1;
        //     return representation.bandwidth === playingBandwidth;
        // });

        return count;
    }

    getCurrentBufferTime = () => {
        if (!this.player) {
            return 0;
        }

        const bufferList = [];//lodashGet(this.player,'getBufferedInfo(), 'total', []);

        if (bufferList.length > 0) {
            const lastBufferedItem = bufferList[bufferList.length - 1];
            return lodashGet(lastBufferedItem, 'end', 0) - lodashGet(lastBufferedItem, 'start', 0);
        }

        return 0;
    }

    changeBitrate = (option) => {
        const {
            value: bitrate,
        } = option;
        if (!this.videoTag || !this.player) {
            return false;
        }
        const representationList = [
            ...this.player.levels,
        ]; // immutable

        // videojs
        if (representationList.length < 1) {
            return false;
        }

        // enable all :: auto
        if (!bitrate) {
            representationList.forEach((representation, index) => this.player.currentLevel = index);
            return true;
        }

        // check if there is a need to reverse the list? as high is needed to be on top (descending order)
        if (representationList[0].attrs.BANDWIDTH < representationList[representationList.length - 1].attrs.BANDWIDTH) {
            representationList.reverse(); // high should come first
        }

        // Choosing Highest frame on High selection
        if (bitrate === Infinity) {
            representationList.forEach((representation, index) => {
                if (index === representationList.length - 1) {
                    this.player.currentLevel = index;
                }
            });
        }
        else if (bitrate === -Infinity) {
            representationList.forEach((representation, index) => {
                if (index === 0) {
                    this.player.currentLevel = index;
                }
            });
        }
        else {
            let representationEnabled = false;
            representationList.forEach((representation, index) => {
                if (representation.attrs.BANDWIDTH <= Number(bitrate)) {
                    this.player.currentLevel = index;
                    representationEnabled = true;
                }
            });

            if (!representationEnabled) {
                this.player.currentLevel = 0; // first is lowest rendition
            }
        }

        raiseEvent(this.videoTag, HLS_PLAYER_EVENTS.CHANGE_BIT_RATE, option);
        return true;
    };

    changePlaybackRate = (playbackRate) => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        console.debug(playbackRate);
        this.videoTag.playbackRate = playbackRate;
        return this.videoTag.playbackRate;
    };

    changeAudio = (lang) => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        return this.player.selectAudioLanguage(lang);
    }

    showCaptions = (lang) => {
        if (!this.videoTag || !this.player) {
            return;
        }
        if (!lang) {
            this.player.subtitleDisplay = false;
        }
        else {
            //this.player.selectTextLanguage(lang);
            this.player.subtitleDisplay = true;
        }
    }

    // shaka player git issue: https://github.com/google/shaka-player/issues/147
    // getActiveTextTrack = () => {
    //     if (this.player) {
    //         const tracks = this.player.getTextTracks();
    //         if (tracks && Array.isArray(tracks)) {
    //             const activeTrack = tracks.find(track => track.active);
    //             return activeTrack.language;
    //         }
    //         return null;
    //     }
    //     return null;
    // }

    languageExistsInTextTrack = (lang) => {
        if (this.player) {
            const allLangs = this.player.getTextLanguages();
            return allLangs.indexOf(lang) > -1;
        }
        return false;
    }

    isTextTrackVisible = () => {
        if (this.player) {
            return this.player.isTextTrackVisible();
        }
        return false;
    }

    forward = (skipTime) => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        const { currentTime } = this.videoTag;
        const seekPoint = currentTime + skipTime;

        const duration = this.getDuration();

        if (duration > currentTime && duration > seekPoint) {
            this.seekBarChange(seekPoint);
        }
        else if (duration < seekPoint && !this.paused()) {
            this.seekBarChange(Math.floor(duration));
            this.pause();
        }

        raiseEvent(this.videoTag, PLAYER_EVENTS_LIST.SEEK_FORWARD, seekPoint);
        return seekPoint;
    }

    backward = (skipTime) => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        const { currentTime } = this.videoTag;
        const seekPoint = currentTime - skipTime;
        if (seekPoint > 0) {
            this.seekBarChange(seekPoint);
        }
        else {
            this.seekBarChange(0);
        }

        raiseEvent(this.videoTag, PLAYER_EVENTS_LIST.SEEK_BACKWARD, seekPoint);
        return seekPoint;
    }


    paused = () => {
        if (!this.videoTag || !this.player) {
            return null;
        }
        return this.videoTag.paused;
    }

    getMeta = () => {
        if (!this.videoTag || !this.player) {
            return false;
        }


        const { audioTracks, subtitleTracks } = this.player;

        const textTracks = subtitleTracks
            .filter(track => !!track.language).map(track => track.language);

        const defaultAudioLanguage = audioTracks[0];
        const meta = {
            duration: this.getDuration(),
            playbackRate: 1,
            qualityLevels: [],
            audioTracks,
            defaultAudioLanguage,
            textTracks,
        };
        return meta;
    }

    getDuration = () => {
        if (!this.videoTag || !this.player) {
            return 0;
        }
        return this.videoTag.duration;
    }

    getReadyState = () => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        return this.videoTag.readyState;
    }

    dispose = () => {
        if (this.player) {
            this.player.destroy();
        }
        if (this.emitter) {
            this.emitter.removeAllListeners();
        }
    }

    unload = async () => {
        if (this.player) {
            const result = await this.player.unload();
            return result;
        }
        return Promise.reject(new Error('player not found'));
    }

    getCurrentTime = () => this.videoTag.currentTime;


    forwardLive = (skipTime) => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        const { currentTime } = this.videoTag;
        const seekPoint = currentTime + skipTime;

        const duration = this.getLiveCurrentTimeInSeconds();

        if (duration > currentTime && duration > seekPoint) {
            this.seekBarChange(Math.floor(seekPoint));
        }
        else if (duration < seekPoint) {
            this.seekBarChange(Math.floor(duration));
        }

        raiseEvent(this.videoTag, PLAYER_EVENTS_LIST.SEEK_FORWARD, seekPoint);
        return seekPoint;
    }

    backwardLive = (skipTime) => {
        if (!this.videoTag || !this.player) {
            return false;
        }

        const { currentTime } = this.videoTag;

        const liveTime = this.getLiveCurrentTimeInSeconds();
        const least = liveTime - this.liveDuration;

        const seekPoint = currentTime - skipTime;

        if (seekPoint > least) {
            this.seekBarChange(seekPoint);
        }
        else {
            const liveStartPoint = liveTime - Math.floor(this.liveDuration);
            this.seekBarChange(liveStartPoint);
        }

        raiseEvent(this.videoTag, PLAYER_EVENTS_LIST.SEEK_BACKWARD, seekPoint);
        return seekPoint;
    }

    getDVRDurationInSeconds = () => {
        // DO NOT DELETE
        // const startTime = this.player.seekRange().start;
        // const endTime = this.player.seekRange().end;
        // const dvrDuration = (new Date().getTime() - ((this.player.getManifest().presentationTimeline.getPresentationStartTime() * 1000) + (startTime * 1000))) / 1000
        return 0;
        const { start, end } = this.player.seekRange();
        return end - start;
    }

    getLiveDuration = () => this.liveDuration

    getLiveCurrentTimeInSeconds = () => 0;// this.player.seekRange().end

    goLive = () => {
        const liveTime = this.getLiveCurrentTimeInSeconds();
        this.seekBarChange(liveTime);
        raiseEvent(this.videoTag, HLS_PLAYER_EVENTS.LIVE_BUTTON_CLICK, liveTime);
    }

    isLive = () => {
        const liveTime = this.getLiveCurrentTimeInSeconds();
        const { currentTime } = this.videoTag;
        const timeDiff = liveTime - currentTime;

        // if current time is under min buffer
        if (timeDiff < this.getSegmentDuration()) {
            return true;
        }
        return false;
    }

    getSegmentDuration = () => 0;//this.player.getManifest().minBufferTime

    behindLiveTime() {
        return 0;

        const currentTime = this.getCurrentTime();
        const { end } = this.player.seekRange();

        if (currentTime === 0 || end === 0) {
            return 0;
        }

        const behindLive = Math.floor(end - currentTime);
        return Math.max(0, behindLive);
    }

    getLiveCurrentTime = () => this.liveDuration - this.behindLiveTime()

    getLivePlaybackUTCTime = () => {
        const currentlyBehindTime = this.behindLiveTime();
        // const playbackCurrentTime = moment().subtract(currentlyBehindTime, 'seconds').toDate();
        const playbackCurrentTime = new DateTime().subtract(currentlyBehindTime);
        return playbackCurrentTime;
    }

    isLiveStream = () => true ;//this.player.isLive()
}
