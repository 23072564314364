import lodashGet from 'lodash/get';
import { matchRoutes } from 'react-router';
import {
    playbackErrorEvent,
    playPauseEvent,
    playStatusEvent,
    playStopEvent,
    playerInitEvent,
    seekBarEvent,
    goLiveEvent,
    playerFullScreenEvent,
    exitFullScreenEvent,
    playerForwardEvent,
    playerRewindEvent,
    pipOpenClickEvent,
    pipClosedClickEvent,
} from '@airtel-tv/analytics/FunctionalEvents';
import { PLAYER_EVENTS_LIST } from '@airtel-tv/constants/PlayerConsts';
import { playStartEvent } from '@airtel-tv/analytics';
import {
    PLAYER_ANALYTICS_ACTIONS,
    ANALYTICS_ACTIONS,
    PLAY_STATUS_FIRE_TIME,
} from '@airtel-tv/constants/AnalyticsConst';
import { checkFullScreen, checkIfPlayInPip, checkDocumentHidden } from '@airtel-tv/utils/WindowUtil';
import { playStartGaEvent } from '@airtel-tv/utils/GaEvents';
import {
    getHostFromUrl,
    subtractSmallFromBig,
    getContentType,
} from '@airtel-tv/utils/GlobalUtil';
import { LocationUtil } from '@airtel-tv/utils';
import ROUTE_SOURCE_NAMES from '@airtel-tv/constants/RouteConsts';
import getAllRouters from '../../../web/src/routes/routes';
// import { isWeb } from '../util/PlaybackUtility';

// import { playbackError } from '../../crash-capture/CrashEvents';

const routes = getAllRouters();

export const getUrlSourceName = (pathList, props) => {
    const browserHistoryLastPage = pathList[pathList.length - 2] || pathList[pathList.length - 1];
    let sourceUrl = LocationUtil.getUrlFromProps(props);

    if (browserHistoryLastPage) {
        sourceUrl = browserHistoryLastPage.pathname + browserHistoryLastPage.search;
    }

    let sourceName = ROUTE_SOURCE_NAMES.PLAYBACK;
    const routePresent = matchRoutes(routes, sourceUrl);
    if (routePresent.length > 1) {
        sourceName = ROUTE_SOURCE_NAMES[routePresent[1].match.path];
    }

    return sourceName;
};

export default function AnalyticsHelper({
    playerEvents,
    contentDetails,
    playbackDetails,
    playbackCallInitTimeStamp,
    playerFunctions,
    sourceName,
    playbackSessionId,
    lang,
}) {
    if (!playerEvents) {
        return;
    }

    let statusEventSyncInterval = PLAY_STATUS_FIRE_TIME;

    const playbackLoadedTimeStamp = new Date().getTime();
    let playbackIndexDomain = '';
    let streamingApiResponseTime = null;
    let initBufferTime = 0;
    let firstBitratePicked = 0;
    let clickToPlay = 0;
    let analytics = {};

    // PLAY STATUS VAR DECLARATIONS

    // used for time update
    let previousCurTime = 0;
    let continuousPlaybackCount = 0;

    // used for buffering
    let watlingInitTimeStamp = false;
    let totalWatlingTime = 0;

    // used for oscillations
    let oscillationsChangeCount = 0;
    let previousBitrateIndex = 0;

    // used for seeks
    let totalSeekCount = 0;
    let totalSeekFirstFrameTime = 0;
    let seekStartTS = 0;

    // bitrate on seeks
    let totalBitrateUsedOnSeek = 0;

    // bitrate history
    const bitrateHistory = [];
    let currentBitrate = 0;
    let lastDurationAddedForBitrateHistory = 0;
    const contentType = getContentType({ contentDetails });
    if (lodashGet(playbackDetails, 'analytics', '')) {
        const rules_analytics = JSON.stringify(playbackDetails.analytics);
        analytics = { rules_analytics };
    }

    const bufferingStatusChange = (isBuffering) => {
        if (isBuffering === true) {
            watlingInitTimeStamp = new Date().getTime();
        }
        else {
            totalWatlingTime += new Date().getTime() - watlingInitTimeStamp;
        }
    };

    const calculateAverageBitrate = () => {
        let totalTime = 0;
        let totalBitrate = 0;
        bitrateHistory.forEach((item) => {
            totalTime = item.duration;
            totalBitrate = item.bitrate * item.duration;
        });
        return totalBitrate / totalTime;
    };

    const pushNewBitrateHistory = () => {
        bitrateHistory.push({
            bitrate: currentBitrate,
            duration: continuousPlaybackCount - lastDurationAddedForBitrateHistory,
        });
        lastDurationAddedForBitrateHistory = continuousPlaybackCount;
        currentBitrate = playerFunctions.getBandwidth();
    };

    const onSeekDataCapturing = () => {
        if (seekStartTS === 0) {
            seekStartTS = new Date().getTime();
            totalSeekCount += 1;
        }
    };

    const resetPlayStatusEventVariables = () => {
        statusEventSyncInterval = PLAY_STATUS_FIRE_TIME * 2;
        totalWatlingTime = 0;
        watlingInitTimeStamp = false;
        oscillationsChangeCount = 0;
        previousBitrateIndex = 0;
        totalSeekCount = 0;
        totalSeekFirstFrameTime = 0;
        seekStartTS = 0;
        continuousPlaybackCount = 0;
        totalBitrateUsedOnSeek = 0;
        currentBitrate = playerFunctions.getBandwidth();
    };

    if (playerEvents.emitter) {
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.TIMEUPDATE, (currentTime, videotag) => {
        // current time in seconds
            const curTempTime = Math.floor(currentTime);

            // update oscillation change
            const bitrateIndex = playerFunctions.getCurrentRepresentationIndex();
            if (bitrateIndex !== previousBitrateIndex) {
                oscillationsChangeCount += subtractSmallFromBig(
                    previousBitrateIndex,
                    bitrateIndex,
                );
                previousBitrateIndex = bitrateIndex;

                pushNewBitrateHistory();
            }

            // event is fired every .25 second so check if it is a new second
            if (curTempTime !== previousCurTime) {
                previousCurTime = curTempTime;

                // increment playback time
                continuousPlaybackCount += 1;

                if (continuousPlaybackCount % statusEventSyncInterval === 0) {
                    // in case not bitrate change, and to add last bitrate duration
                    if (lastDurationAddedForBitrateHistory !== statusEventSyncInterval) {
                        pushNewBitrateHistory();
                    }

                    const playingBitrate = playerFunctions.getBandwidth();

                    playStatusEvent({
                        content_id: contentDetails.id,
                        cpName: contentDetails.cpId,
                        content_title: contentDetails.title,
                        live: playerFunctions.isLive(),
                        position: curTempTime,
                        play_session_id: playbackSessionId,
                        source_name: sourceName,
                        rebuffer_percentage:
                ((totalWatlingTime / 1000) * 100) / statusEventSyncInterval,
                        bitrate_oscillations: oscillationsChangeCount,
                        average_click_to_play_on_seek:
                totalSeekFirstFrameTime / 1000 / totalSeekCount,
                        average_bitrate_on_seek: totalBitrateUsedOnSeek / totalSeekCount,
                        average_bitrate: calculateAverageBitrate(),
                        playback_host: playbackIndexDomain,
                        bitrate: playingBitrate,
                        initial_bitrate: firstBitratePicked,
                        playback_bandwidth: playingBitrate / 1024, // converting to kbps
                        user_select_language: lang,
                        content_type: contentType,
                        out_web: checkDocumentHidden(),
                        pip_mode: checkIfPlayInPip(videotag),
                        // ...analytics,
                    });

                    resetPlayStatusEventVariables();
                }
            }
        });
    }
    if (playerEvents.emitter) {
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.WAITING, () => {
            bufferingStatusChange(true);
        });

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH, () => {
            bufferingStatusChange(false);

            if (seekStartTS !== 0) {
                totalBitrateUsedOnSeek += playerFunctions.getBandwidth();
                totalSeekFirstFrameTime += new Date().getTime() - seekStartTS;
                seekStartTS = 0;
            }
        });

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.LOADED_V1, () => {
            playStartGaEvent(contentDetails.id);
            firstBitratePicked = playerFunctions.getBandwidth();
            currentBitrate = firstBitratePicked;

            initBufferTime = playerFunctions.getCurrentBufferTime();
            // playbackLoadedTimeStamp = new Date().getTime();
            playbackIndexDomain = getHostFromUrl(
                lodashGet(playbackDetails, 'playback.playUrl', ''),
            );

            // click to play time
            streamingApiResponseTime = playbackLoadedTimeStamp - playbackCallInitTimeStamp;

            // TODO: Call GetStats Here
            playerInitEvent({
                content_id: contentDetails.id,
                action: PLAYER_ANALYTICS_ACTIONS.PLAY_INIT_ACTION,
                cpName: contentDetails.cpId,
                content_title: contentDetails.title,
                init_buffer_time: initBufferTime,
                live: false,
                source_name: sourceName,
                play_session_id: playbackSessionId,
                streaming_api_response_time: streamingApiResponseTime,
                playback_host: playbackIndexDomain,
                ...analytics,
            });
        });

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.FIRST_PLAY_V1, (videotag) => {
            clickToPlay = new Date().getTime() - playbackLoadedTimeStamp;
            const selectedSubtitle = playerFunctions.getSelectedSubtitle();
            playStartEvent({
                content_id: contentDetails.id,
                cpName: contentDetails.cpId,
                content_title: contentDetails.title,
                source_name: sourceName,
                play_session_id: playbackSessionId,
                click_to_play: clickToPlay,
                playback_host: playbackIndexDomain,
                init_buffer_time: initBufferTime,
                bitrate: playerFunctions.getBandwidth(),
                initial_bitrate: firstBitratePicked,
                user_select_language: lang,
                content_type: contentType,
                out_web: checkDocumentHidden(),
                pip_mode: checkIfPlayInPip(videotag),
                ...analytics,
                ...(selectedSubtitle && { selected_subtitles: selectedSubtitle }),
            });
        });

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.PLAY, (currentTime, videotag) => {
        // playGaEvent(contentDetails.id);
            playPauseEvent({
                content_id: contentDetails.id,
                action: PLAYER_ANALYTICS_ACTIONS.PLAY_ACTION,
                cpName: contentDetails.cpId,
                content_title: contentDetails.title,
                pip_mode: checkIfPlayInPip(videotag),
                out_web: checkDocumentHidden(),
                live: false,
                source_name: sourceName,
                position: Math.floor(currentTime),
                play_session_id: playbackSessionId,
                // ...analytics,
            });
        });

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.PAUSE, (currentTime, videotag) => {
        // pauseGaEvent(contentDetails.id);
            playPauseEvent({
                content_id: contentDetails.id,
                action: PLAYER_ANALYTICS_ACTIONS.PAUSE_ACTION,
                cpName: contentDetails.cpId,
                content_title: contentDetails.title,
                live: false,
                pip_mode: checkIfPlayInPip(videotag),
                out_web: checkDocumentHidden(),
                source_name: sourceName,
                position: Math.floor(currentTime),
                play_session_id: playbackSessionId,
                // ...analytics,
            });
        });

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.ENDEND, (videotag) => {
            playStopEvent({
                init_buffer_time: initBufferTime,
                play_session_id: playbackSessionId,
                user_select_language: lang,
                content_type: contentType,
                pip_mode: checkIfPlayInPip(videotag),
                out_web: checkDocumentHidden(),
                // ...analytics,
            });
        });

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.ERROR, (error) => {
            let { userAgent, platform } = window.navigator;
            platform = LocationUtil.parseNavigatorForWindows(userAgent, platform);
            playbackErrorEvent({
                error_code: error && error.code,
                response: JSON.stringify(error),
                error: JSON.stringify(error),
                failure_reason: JSON.stringify(error),
                error_reason: JSON.stringify(error && error.data),
                play_session_id: playbackSessionId,
                content_id: contentDetails.id,
                cp_name: contentDetails.cpId,
                userAgent,
                source: platform,
                // ...analytics,
            });

            //Analytics Helper V2 will send logs to kibana, hence commented
            // playbackError({
            //     error_code: error && error.code ? error.code : '',
            //     error,
            //     play_session_id: playbackSessionId,
            //     content_id: contentDetails.id,
            //     cp_name: contentDetails.cpId,
            //     userAgent,
            //     platform,
            //     // ...analytics,
            // });
        });

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.SEEK_BAR_CHANGE_V1, (data) => {
            onSeekDataCapturing();

            seekBarEvent({
                content_id: contentDetails.id,
                source_name: sourceName,
                fullscreen: !!checkFullScreen(),
                current_position: data.newTime,
                prev_position: data.previousTime,
                action: ANALYTICS_ACTIONS.CLICK,
                // ...analytics,
            });
        // playDragSeekBarGaEvent(contentDetails.id);
        });

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.LIVE_BUTTON_CLICK, (data) => {
            goLiveEvent({
                content_id: contentDetails.id,
                source_name: sourceName,
                live_position: data,
                action: ANALYTICS_ACTIONS.LIVE_BUTTON_CLICK,
                // ...analytics,
            });
        });
    }

    playerEvents.emitter.on(PLAYER_EVENTS_LIST.SEEK_FORWARD, (data) => {
        playerForwardEvent({
            fullscreen: !!checkFullScreen(),
            content_id: contentDetails.id,
            source_name: sourceName,
            action: ANALYTICS_ACTIONS.SEEK_FORWARD,
            current_position: data,
            play_session_id: playbackSessionId,
            // ...analytics,
        });
    // playForwardBackwardGaEvent(contentDetails.id);
    });

    playerEvents.emitter.on(PLAYER_EVENTS_LIST.SEEK_BACKWARD, (data) => {
        playerRewindEvent({
            fullscreen: data.fullscreen,
            content_id: contentDetails.id,
            source_name: sourceName,
            action: ANALYTICS_ACTIONS.SEEK_BACKWARD,
            current_position: data,
            play_session_id: data.play_session_id,
            // ...analytics,
        });
    // playForwardBackwardGaEvent(contentDetails.id);
    });

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.FULL_SCREEN_CHANGE, () => {
            const isFullScreen = !!checkFullScreen();
            const meta = {
                content_id: contentDetails.id,
                source_name: sourceName,
                action: isFullScreen
                    ? ANALYTICS_ACTIONS.FULL_SCREEN
                    : ANALYTICS_ACTIONS.EXIT_FULL_SCREEN,
                // ...analytics,
            };

            // fullScreenToggleGaEvent(contentDetails.id);

            if (isFullScreen) {
                playerFullScreenEvent(meta);
            }
            else {
                exitFullScreenEvent(meta);
            }
        });

    playerEvents.emitter.on(PLAYER_EVENTS_LIST.OPEN_PIP, () => {
        const meta = {
            content_id: contentDetails.id,
            cp_name: contentDetails.cpId,
            content_type: contentType,
            // ...analytics,
        };
        pipOpenClickEvent(meta);
    });

    playerEvents.emitter.on(PLAYER_EVENTS_LIST.CLOSE_PIP, () => {
        const meta = {
            content_id: contentDetails.id,
            cp_name: contentDetails.cpId,
            content_type: contentType,
            // ...analytics,
        };
        pipClosedClickEvent(meta);
    });

    // playerEvents.emitter.on(PLAYER_EVENTS_LIST.CHANGE_BIT_RATE, () => {
    //     selectPlaybackQualityGaEvent();
    // });
}
