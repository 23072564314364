import React from 'react';
import { DeviceTypeUtil } from '@airtel-tv/utils/DeviceTypeUtil';
import Loadable from 'react-loadable';

//--------WEB------------------//
const WatermarkComponentWEB = Loadable({
    loader: () => import('../../../web/src/modules/playbackComponents/components/overlays/watermark/WatermarkComponent' /* webpackChunkName: "BasicControlBarComponent" */),
    loading: () => (<div />)
});

const NextEpisodeCtaComponentWEB = Loadable({
    loader: () => import('../../../web/src/modules/playbackComponents/components/overlays/cta/NextEpisodeCtaComponent' /* webpackChunkName: "NextEpisodeCtaComponentWEB" */),
    loading: () => (<div />)
});

const SkipIntroButtonComponentWEB = Loadable({
    loader: () => import('../../../web/src/modules/playbackComponents/components/overlays/cta/SkipIntroButtonComponent' /* webpackChunkName: "SkipIntroButtonComponentWEB" */),
    loading: () => (<div />)
});

const OverlayDescriptionWEB = Loadable({
    loader: () => import('../../../web/src/modules/playbackComponents/components/overlays/OverlayDescription' /* webpackChunkName: "OverlayDescriptionWEB" */),
    loading: () => (<div />)
});

const CenterIconComponentWEB = Loadable({
    loader: () => import('../../../web/src/modules/playbackComponents/components/overlays/CenterIconComponent' /* webpackChunkName: "CenterIconComponentWEB" */),
    loading: () => (<div />)
});

const ErrorPlayerWEB = Loadable({
    loader: () => import('../../../web/src/modules/playbackComponents/components/error-player/ErrorPlayer' /* webpackChunkName: "ErrorPlayerWEB" */),
    loading: () => (<div />)
});

const PlayerLoaderComponentWEB = Loadable({
    loader: () => import('../../../web/src/modules/playbackComponents/components/player-controls/PlayerLoaderComponent' /* webpackChunkName: "PlayerLoaderComponentWEB" */),
    loading: () => (<div />)
});

const BasicControlBarComponentWEB = Loadable({
    loader: () => import('../../../web/src/modules/playbackComponents/components/control-bars/BasicControlBarComponent' /* webpackChunkName: "BasicControlBarComponentWEB" */),
    loading: () => (<div />)
});

const LiteControlBarComponentWEB = Loadable({
    loader: () => import('../../../web/src/modules/lite-player/LiteControlBarComponent' /* webpackChunkName: "LiteControlBarComponentWEB" */),
    loading: () => (<div />)
});

const LiveControlBarUIComponentWEB = Loadable({
    loader: () => import('../../../web/src/modules/playbackComponents/components/control-bars/LiveControlBarUIComponent' /* webpackChunkName: "LiveControlBarUIComponentWEB" */),
    loading: () => (<div />)
})

const ServerAdControlBarUIComponentWEB = Loadable({
    loader: () => import('../../../web/src/modules/playbackComponents/components/control-bars/ServerAdControlBarUIComponent' /* webpackChunkName: "LiveControlBarUIComponentWEB" */),
    loading: () => (<div />)
})


//------------TV------------//
const WatermarkComponentLS = Loadable({
    loader: () => import('../../../tv/src/modules/playbackComponents/components/overlays/watermark/WatermarkComponent' /* webpackChunkName: "BasicControlBarComponLS" */),
    loading: () => (<div />)
});

const NextEpisodeCtaComponentLS = Loadable({
    loader: () => import('../../../tv/src/modules/playbackComponents/components/overlays/cta/NextEpisodeCtaComponent' /* webpackChunkName: "NextEpisodeCtaComponentLS" */),
    loading: () => (<div />)
});

const SkipIntroButtonComponentLS = Loadable({
    loader: () => import('../../../tv/src/modules/playbackComponents/components/overlays/cta/SkipIntroButtonComponent' /* webpackChunkName: "SkipIntroButtonComponentLS" */),
    loading: () => (<div />)
});

const OverlayDescriptionLS = Loadable({
    loader: () => import('../../../tv/src/modules/playbackComponents/components/overlays/OverlayDescription' /* webpackChunkName: "OverlayDescriptionLS" */),
    loading: () => (<div />)
});

const CenterIconComponentLS = Loadable({
    loader: () => import('../../../tv/src/modules/playbackComponents/components/overlays/CenterIconComponent' /* webpackChunkName: "CenterIconComponentLS" */),
    loading: () => (<div />)
});

const ErrorPlayerLS = Loadable({
    loader: () => import('../../../tv/src/modules/playbackComponents/components/error-player/ErrorPlayer' /* webpackChunkName: "ErrorPlayerLS" */),
    loading: () => (<div />)
});

const PlayerLoaderComponentLS = Loadable({
    loader: () => import('../../../tv/src/modules/playbackComponents/components/player-controls/PlayerLoaderComponent' /* webpackChunkName: "PlayerLoaderComponentLS" */),
    loading: () => (<div />)
});

const BasicControlBarComponentLS = Loadable({
    loader: () => import('../../../tv/src/modules/playbackComponents/components/control-bars/BasicControlBarComponent' /* webpackChunkName: "BasicControlBarComponentLS" */),
    loading: () => (<div />)
});

const LiteControlBarComponentLS = Loadable({
    loader: () => import('../../../tv/src/modules/lite-player/LiteControlBarComponent' /* webpackChunkName: "LiteControlBarComponentLS" */),
    loading: () => (<div />)
});



const LiveControlBarUIComponentLS = React.lazy(() => import('../../../tv/src/modules/playbackComponents/components/control-bars/LiveControlBarUIComponent'))

// const LiveControlBarUIComponentLS = Loadable({
//     loader: () => import('../../../tv/src/modules/playbackComponents/components/control-bars/LiveControlBarUIComponent' /* webpackChunkName: "LiveControlBarUIComponentLS" */),
//     loading: () => (<div />)
// });

const ServerAdControlBarUIComponentLS = Loadable({
    loader: () => import('../../../tv/src/modules/playbackComponents/components/control-bars/AdControlBar/ServerAdControlBarUIComponent' /* webpackChunkName: "LiveControlBarUIComponentWEB" */),
    loading: () => (<div />)
})


const _ErrorHandler = DeviceTypeUtil.isWeb() ? await import('../../../web/src/modules/error-handler/ErrorHandler').then(module => module.default): await import('../../../tv/src/modules/error-handler/ErrorHandler').then(module => module.default);

export const WatermarkComponent = DeviceTypeUtil.isWeb() ? WatermarkComponentWEB : WatermarkComponentLS;
export const NextEpisodeCtaComponent = DeviceTypeUtil.isWeb() ? NextEpisodeCtaComponentWEB : NextEpisodeCtaComponentLS;
export const SkipIntroButtonComponent = DeviceTypeUtil.isWeb() ? SkipIntroButtonComponentWEB : SkipIntroButtonComponentLS;
export const OverlayDescription = DeviceTypeUtil.isWeb() ? OverlayDescriptionWEB : OverlayDescriptionLS;
export const CenterIconComponent = DeviceTypeUtil.isWeb() ? CenterIconComponentWEB : CenterIconComponentLS;
export const ErrorPlayer = DeviceTypeUtil.isWeb() ? ErrorPlayerWEB : ErrorPlayerLS;
export const PlayerLoaderComponent = DeviceTypeUtil.isWeb() ? PlayerLoaderComponentWEB : PlayerLoaderComponentLS;
export const BasicControlBar = DeviceTypeUtil.isWeb() ? BasicControlBarComponentWEB : BasicControlBarComponentLS;
export const LiteControlBar = DeviceTypeUtil.isWeb() ? LiteControlBarComponentWEB : LiteControlBarComponentLS;
export const LiveControlBarUIComponent = DeviceTypeUtil.isWeb() ? LiveControlBarUIComponentWEB: LiveControlBarUIComponentLS;
export const ServerAdControlBarUIComponent = DeviceTypeUtil.isWeb() ? ServerAdControlBarUIComponentWEB: ServerAdControlBarUIComponentLS;
export const ErrorHandler = _ErrorHandler;