import { stringToBase64, stringToUint8Array } from '@airtel-tv/utils/WindowUtil';
import { PAYLOAD_FORMATS } from '@airtel-tv/constants/PlaybackConsts';

export default class WidevineLicenseParser {
    static async agnosticLicenseRequestParserForShaka(
        request,
        headers,
        body,
        payloadKeyName,
        licenseHeaderPlaceholderKey,
        licenseHeaderSecretKey,
        licenseHeaderSecretData,
        licenseHeaderSecretEncryptionType,
        licenseHeaderSecretDataPlaceholderKey,
        licenseResponsePayloadKey,
        licensePayloadFormat,
        licenseUri,
        licensePayloadType,
    ) {
        const req = request;

        // ADD HEADERS
        if (headers) {
            req.headers = {
                ...request.headers,
                ...headers,
            };
        }

        // ADD BODY
        if (body) {
            let payloadData = null;
            if (licensePayloadType === PAYLOAD_FORMATS.ARRAY_BUFFER) {
                payloadData = stringToUint8Array(req.body);
            }
            else {
                payloadData = stringToBase64(req.body); // original payload from player
            }

            const requestBody = { ...body };

            if (payloadKeyName) {
                requestBody[payloadKeyName] = payloadData;
            }

            request.body = JSON.stringify(requestBody);
        }

        return req;
    }

    static async agnosticLicenseResponseParserForShaka(response, licenseResponsePayloadKey) {
        try {
            const res = response;
            // this is the wrapped license.
            const wrappedArray = new Uint8Array(res.data);
            // convert it to a string.
            const wrappedString = String.fromCharCode.apply(null, wrappedArray);
            // parse the JSON string into an object.
            const responseBody = JSON.parse(wrappedString);

            // this is a base64-encoded version of the raw license.
            const rawLicenseBase64 = responseBody[licenseResponsePayloadKey];

            if (rawLicenseBase64) {
                // decode it to a string.
                const rawLicenseString = atob(rawLicenseBase64);

                // convert that string into a Uint8Array and replace the response data to feed it to the Widevine CDM.
                res.data = new Uint8Array(rawLicenseString.length);

                for (let i = 0; i < rawLicenseString.length; ++i) {
                    res.data[i] = rawLicenseString.charCodeAt(i);
                }

                return res;
            }

            return response;
        }
        catch (error) {
            console.error(error);
            // TODO: handle error
            throw error;
        }
    }


    // static async Zee5LicenseRequestParser({
    //     payload, token,
    // }) {
    //     try {
    //         const request = payload;

    //         request.headers = {
    //             ...request.headers,
    //             'content-type': 'application/octet-stream',
    //             customData: token,
    //         };

    //         return request;
    //     }
    //     catch (error) {
    //         console.error(error);
    //         // TODO: handle error
    //         throw error;
    //     }
    // }

    // video js handling :: zee5 using shaka now
    // static async Zee5LicenseRequestParser({
    //     licenseUri, payload, token,
    // }) {
    //     try {
    //         const response = await RequestUtil.POST({
    //             url: licenseUri,
    //             headers: {
    //                 'content-type': 'application/octet-stream',
    //                 customData: token,
    //             },
    //             data: payload,
    //             options: { responseType: 'arraybuffer' },
    //         });
    //         return response; // return the buffer
    //     }
    //     catch (error) {
    //         console.error(error);
    //         // TODO: handle error
    //         throw error;
    //     }
    // }

    // static async AltBalajiLicenseRequestParser({ payload, contentId, ticket }) {
    //     try {
    //         const request = payload;
    //         request.allowCrossSiteCredentials = true;

    //         const base64Payload = stringToBase64(request.body);

    //         const body = {
    //             ticket,
    //             content_id: contentId,
    //             payload: base64Payload,
    //         };

    //         request.body = JSON.stringify(body);
    //         return request;
    //     }
    //     catch (error) {
    //         console.error(error);
    //         // TODO: handle error
    //         throw error;
    //     }
    // }

    // static async AltBalajiLicenseResponseParser({
    //     payload,
    // }) {
    //     try {
    //         const res = payload;
    //         // this is the wrapped license.
    //         const wrappedArray = new Uint8Array(res.data);
    //         // convert it to a string.
    //         const wrappedString = String.fromCharCode.apply(null, wrappedArray);
    //         // parse the JSON string into an object.
    //         const wrapped = JSON.parse(wrappedString);

    //         // this is a base64-encoded version of the raw license.
    //         const rawLicenseBase64 = wrapped.license;

    //         // decode it to a string.
    //         const rawLicenseString = atob(rawLicenseBase64);

    //         // convert that string into a Uint8Array and replace the response data to feed it to the Widevine CDM.
    //         res.data = new Uint8Array(rawLicenseString.length);
    //         for (let i = 0; i < rawLicenseString.length; ++i) {
    //             res.data[i] = rawLicenseString.charCodeAt(i);
    //         }
    //         return res;
    //     }
    //     catch (error) {
    //         console.error(error);
    //         // TODO: handle error
    //         throw error;
    //     }
    // }
}
