import lodashDebounce from 'lodash/debounce';
import EventEmitter from 'events';
import { PLAYER_EVENTS_LIST, VIDEOJS_PLAYER_EVENTS } from '@airtel-tv/constants/PlayerConsts';
import { documentAddEventListener, documentRemoveEventListener } from '@airtel-tv/utils/WindowUtil';

const FULL_SCREEN_EVENTS = [
    '',
    'webkit',
    'moz',
    'ms',
];
export default class VideoJsEvents {
    firstPlayEventFiredFlag = false;

    readyToPlayEventFiredFlag = false;

    dispose = () => {
        if (this.emitter) {
            //DISPOSE event to capture some back and player unmount
            //Don't call ENDEND because it may lead to next eposide play even in case of error
            this.emitter.emit(PLAYER_EVENTS_LIST.DISPOSE); //NGTD: Check this
            this.emitter.removeAllListeners();
        }
        FULL_SCREEN_EVENTS.forEach(
            prefix => documentRemoveEventListener(`${prefix}fullscreenchange`, this.screenChangeCallback, false),
        );
    };

    emit = (name, data) => {
        this.emitter.emit(name, data);
    }

    constructor({ player, videoTag, playerFunctions }) {
        if (!player || !player.on) {
            return; // cannot subscribe to events as listening method is not provided
        }

        this.emitter = new EventEmitter();

        FULL_SCREEN_EVENTS.forEach(
            prefix => documentAddEventListener(`${prefix}fullscreenchange`, this.screenChangeCallback, false),
        );

        player.on(VIDEOJS_PLAYER_EVENTS.PLAY, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.PLAY, player.currentTime(), videoTag);
        });

        player.on(VIDEOJS_PLAYER_EVENTS.PAUSE, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.PAUSE, player.currentTime(), videoTag);
        });

        player.on(VIDEOJS_PLAYER_EVENTS.CAN_PLAY_THROUGH, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH);
            this.emitter.emit(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH_V2, videoTag);
        });

        // NOT USING VIDEO JS FIRST PLAY AS IT IS GETTING FIRED BEFORE LOADED
        // player.on(VIDEOJS_PLAYER_EVENTS.FIRST_PLAY, () => {
        //     this.emitter.emit(PLAYER_EVENTS_LIST.FIRST_PLAY);
        // });

        player.on(VIDEOJS_PLAYER_EVENTS.ENDEND, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.ENDEND, videoTag);
        });

        player.on(VIDEOJS_PLAYER_EVENTS.ERROR, () => {
            const playerError = player.error();
            this.emitter.emit(PLAYER_EVENTS_LIST.ERROR, playerError);
        });

        videoTag.addEventListener(VIDEOJS_PLAYER_EVENTS.ERROR, (error) => {
            const errorData = error.detail || error.data || error;
            this.emitter.emit(PLAYER_EVENTS_LIST.ERROR, errorData);
        });

        videoTag.addEventListener(VIDEOJS_PLAYER_EVENTS.SEEK_BAR_CHANGE, (event) => {
            //Analytics helper will rely on debounced Player_seek both for logging drag as well as click on seek bar
            //Seek bar change will be supported for Abstract Player as well as blocking pause and play event firings in seek, play-2 cycle 
            this.emitter.emit(PLAYER_EVENTS_LIST.SEEK_BAR_CHANGE, event.data);
            this.emitter.emit(PLAYER_EVENTS_LIST.SEEK_BAR_CHANGE_V1, event.data);
        });

        const emitPlayerSeek = () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.PLAYER_SEEK);
        }

        videoTag.addEventListener(VIDEOJS_PLAYER_EVENTS.SEEKED, lodashDebounce(emitPlayerSeek, 200))
        videoTag.addEventListener(PLAYER_EVENTS_LIST.SEEK_PAUSE, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.SEEK_PAUSE);
        });

        player.on(VIDEOJS_PLAYER_EVENTS.WAITING, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.WAITING);
            this.emitter.emit(PLAYER_EVENTS_LIST.WAITING_V2);
        });

        // const playerTech = playerFunctions.getPlayerTech();
        // // if player tech exists then add listener
        // if (playerTech) {
        //     playerTech.on(VIDEOJS_PLAYER_EVENTS.RETRY_PLAYLIST, () => {
        //         this.emitter.emit(PLAYER_EVENTS_LIST.RETRY_PLAYLIST);
        //     });
        // }

        player.on(VIDEOJS_PLAYER_EVENTS.PLAYING, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.PLAYING);
        });

        player.on(VIDEOJS_PLAYER_EVENTS.TIMEUPDATE, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.TIMEUPDATE, player.currentTime(), videoTag);

            // VIDEOJS DOES NOT HAS A EVENT NAMED 'FIRST_PLAY' SO WE MADE ONE
            if (this.firstPlayEventFiredFlag === false && videoTag.currentTime > 0 && this.readyToPlayEventFiredFlag === true) {
                this.firstPlayEventFiredFlag = true;
                this.emitter.emit(PLAYER_EVENTS_LIST.FIRST_PLAY, videoTag);
                this.emitter.emit(PLAYER_EVENTS_LIST.FIRST_PLAY_V1, videoTag);
            }

            // raise live time update event
            if (!playerFunctions.paused()) {
                const liveStreamCurrentPlayingTime = playerFunctions.isLiveStream() ? playerFunctions.getLivePlaybackUTCTime() : undefined;
                if (liveStreamCurrentPlayingTime) {
                    this.emitter.emit(PLAYER_EVENTS_LIST.LIVE_TIME_UPDATE, liveStreamCurrentPlayingTime, videoTag);
                }
            }
        });

        player.on(VIDEOJS_PLAYER_EVENTS.PROGRESS, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.PROGRESS, player.bufferedEnd());
        });

        player.on(VIDEOJS_PLAYER_EVENTS.DURATION_CHANGE, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.DURATION_CHANGE, playerFunctions.getDuration());
        });

        //v1/////////////       
        const onCanPlayThroughEventHandler = () => {
            if (this.initEventFiredFlag === false) {
                const meta = playerFunctions.getMeta();
                this.initEventFiredFlag = true;
                this.emitter.emit(PLAYER_EVENTS_LIST.LOADED_V1, meta);
                this.emitter.emit(PLAYER_EVENTS_LIST.LOADED, meta);
            }

            //  need only one time, so dispose after use
            player.off(VIDEOJS_PLAYER_EVENTS.CAN_PLAY_THROUGH, onCanPlayThroughEventHandler);
        };

        player.on(VIDEOJS_PLAYER_EVENTS.CAN_PLAY_THROUGH, onCanPlayThroughEventHandler);

        player.on(VIDEOJS_PLAYER_EVENTS.LOADSTART, () => {//Fires init
            this.emitter.emit(PLAYER_EVENTS_LIST.LOADSTART);
            //onCanPlayThroughEventHandler();
        });

        player.on(VIDEOJS_PLAYER_EVENTS.LOADSTART_V1, () => {//Fires init
            this.emitter.emit(PLAYER_EVENTS_LIST.LOADSTART_V1);
            onCanPlayThroughEventHandler();
        });

        //v1

        player.on(VIDEOJS_PLAYER_EVENTS.LOADSTART, () => {//Fires init
            this.emitter.emit(PLAYER_EVENTS_LIST.LOADSTART);
            //onCanPlayThroughEventHandler();
        });

        videoTag.addEventListener('play', () => {
            console.log('lg','play')
        });

        player.on(VIDEOJS_PLAYER_EVENTS.LOADED, () => {
            this.readyToPlayEventFiredFlag = true
            this.emitter.emit(PLAYER_EVENTS_LIST.LOADED, videoTag);
        });

        // player.on(VIDEOJS_PLAYER_EVENTS.LOADSTART, () => {
        //     this.emitter.emit(PLAYER_EVENTS_LIST.LOADSTART);
        //     onCanPlayThroughEventHandler();
        // });

        videoTag.addEventListener(VIDEOJS_PLAYER_EVENTS.LIVE_BUTTON_CLICK, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.LIVE_BUTTON_CLICK, event.data);
        });

        videoTag.addEventListener(PLAYER_EVENTS_LIST.SEEK_FORWARD, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.SEEK_FORWARD, event.data);
        });

        videoTag.addEventListener(PLAYER_EVENTS_LIST.SEEK_BACKWARD, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.SEEK_BACKWARD, event.data);
        });

        videoTag.addEventListener(VIDEOJS_PLAYER_EVENTS.CHANGE_BIT_RATE, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.CHANGE_BIT_RATE, event);
        });

        videoTag.addEventListener(VIDEOJS_PLAYER_EVENTS.VOLUME_CHANGE, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.VOLUME_CHANGE, event);
        });
        // ! DO NOT DELETE
        // player.liveTracker.on(VIDEOJS_PLAYER_EVENTS.SEEKA_BLEEND_CHANGE, () => {
        //     // raise live time update event
        //     const liveStreamCurrentPlayingTime = playerFunctions.isLiveStream() ? playerFunctions.getLivePlaybackUTCTime() : undefined;
        //     raiseEvent(videoTag, VIDEOJS_PLAYER_EVENTS.LIVE_TIME_UPDATE, liveStreamCurrentPlayingTime);
        // });

        videoTag.addEventListener(VIDEOJS_PLAYER_EVENTS.OPEN_PIP, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.OPEN_PIP);
        });

        videoTag.addEventListener(VIDEOJS_PLAYER_EVENTS.CLOSE_PIP, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.CLOSE_PIP);
        });

        videoTag.addEventListener(VIDEOJS_PLAYER_EVENTS.MUTE_V2, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.MUTE_V2, event);
        })

        videoTag.addEventListener(VIDEOJS_PLAYER_EVENTS.UNMUTE_V2, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.UNMUTE_V2, event);
        })

        videoTag.addEventListener(PLAYER_EVENTS_LIST.CHANGE_BIT_RATE_V2, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.CHANGE_BIT_RATE_V2, event);
        })

        videoTag.addEventListener(PLAYER_EVENTS_LIST.CHANGE_AUDIO_LANGUAGE, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.CHANGE_AUDIO_LANGUAGE, event);
        })

        videoTag.addEventListener(PLAYER_EVENTS_LIST.CHANGE_SUBTITLES, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.CHANGE_SUBTITLES, event);
        })

        videoTag.addEventListener(PLAYER_EVENTS_LIST.NEXT_EPISODE_CLICK, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.NEXT_EPISODE_CLICK, event);
        })
    }

    screenChangeCallback = () => {
        this.emitter.emit(PLAYER_EVENTS_LIST.FULL_SCREEN_CHANGE);
    }
}
