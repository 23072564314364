import lodashDebounce from 'lodash/debounce';
import EventEmitter from 'events';
import { PLAYER_EVENTS_LIST, SHAKA_PLAYER_EVENTS } from '@airtel-tv/constants/PlayerConsts';
import { documentAddEventListener, documentRemoveEventListener } from '@airtel-tv/utils/WindowUtil';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';

const FULL_SCREEN_EVENTS = [
    '',
    'webkit',
    'moz',
    'ms',
];
export default class ShakaPlayerEvents {
    firstPlayEventFiredFlag = false;

    readyToPlayEventFiredFlag = false;

    initEventFiredFlag = false;

    canFireTimeUpdate = true;

    dispose = () => {
        if (this.emitter) {
            // DISPOSE event to capture some back and player unmount
            // Don't call ENDEND because it may lead to next eposide play even in case of error
            this.emitter.emit(PLAYER_EVENTS_LIST.DISPOSE, this.videoTag); // @NGTD: Check this
            this.emitter.removeAllListeners();
        }

        FULL_SCREEN_EVENTS.forEach(
            prefix => documentRemoveEventListener(`${prefix}fullscreenchange`, this.screenChangeCallback, false),
        );
    };

    emit = (name, data) => {
        if (this.emitter) {
            this.emitter.emit(name, data);
        }
    };

    setTimeUpdateFlag = ({ enable }) => {
        this.canFireTimeUpdate = enable;
    };

    constructor({ player, videoTag, playerFunctions }) {
        if (!videoTag || !player || !player.load) {
            return; // cannot subscribe to events as listening method is not provided
        }

        FULL_SCREEN_EVENTS.forEach(
            prefix => documentAddEventListener(`${prefix}fullscreenchange`, this.screenChangeCallback, false),
        );

        this.emitter = new EventEmitter();

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.PLAY, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.PLAY, videoTag.currentTime, videoTag);
        });

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.PAUSE, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.PAUSE, videoTag.currentTime, videoTag);
        });

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.CAN_PLAY_THROUGH, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH, videoTag);
        });

        // -------v1---------//
        const onCanPlayThroughEventHandler = () => {
            if (this.initEventFiredFlag === false) {
                const meta = playerFunctions.getMeta();
                this.initEventFiredFlag = true;
                this.emitter.emit(PLAYER_EVENTS_LIST.LOADED_V1, meta);
            }
            //  need only one time, so dispose after use
            videoTag.removeEventListener(SHAKA_PLAYER_EVENTS.CAN_PLAY_THROUGH, onCanPlayThroughEventHandler);
        };

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.CAN_PLAY_THROUGH, onCanPlayThroughEventHandler);

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.LOADSTART_V1, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.LOADSTART_V1);
        });

        // -----v1-------//
        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.ENDEND, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.ENDEND, videoTag);
        });

        // capture error from video tag and player both
        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.ERROR, (error) => {
            const errorData = error.detail || error.data || error;

            if (!this.videoTagError) {
                setTimeout(() => {
                    if (!this.videoTagError) {
                        return;
                    }
                    const error = this.videoTagError;
                    this.videoTagError = null;
                    this.emitter.emit(PLAYER_EVENTS_LIST.ERROR, error);
                }, 5000);
            }
            this.videoTagError = errorData;
        });

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.SEEK_BAR_CHANGE, (event) => {
            // Analytics helper will rely on debounced Player_seek both for logging drag as well as click on seek bar
            // Seek bar change will be supported for Abstract Player as well as blocking pause and play event firings in seek, play-2 cycle
            this.emitter.emit(PLAYER_EVENTS_LIST.SEEK_BAR_CHANGE, event.data);
            this.emitter.emit(PLAYER_EVENTS_LIST.SEEK_BAR_CHANGE_V1, event.data);
        });

        const emitPlayerSeek = () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.PLAYER_SEEK);
        };

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.SEEKED, lodashDebounce(emitPlayerSeek, 200));
        videoTag.addEventListener(PLAYER_EVENTS_LIST.SEEK_PAUSE, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.SEEK_PAUSE);
        });

        player.addEventListener(SHAKA_PLAYER_EVENTS.ERROR, (error) => {
            const errorData = error.detail || error.data || error;
            if (errorData.code === '3018') {
                this.emitter.emit(PLAYER_EVENTS_LIST.RETRY);
            }

            if (errorData.code === ERROR_CODES.SHAKA_1001) { // Case 2: 1001 error during playback
                this.emitter.emit(PLAYER_EVENTS_LIST.ERROR_CDN_RETRY, errorData);
                return;
            }
            this.emitter.emit(PLAYER_EVENTS_LIST.ERROR, errorData);
        });

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.WAITING, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.WAITING);
        });

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.PLAYING, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.PLAYING);
        });

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.PROGRESS, () => {
            if (this.canFireTimeUpdate) {
                const { total } = player.getBufferedInfo();
                if (total.length > 0) {
                    this.emitter.emit(PLAYER_EVENTS_LIST.PROGRESS, total[0].end);
                }
            }
        });

        // Ratechange support changed on newer version
        // videoTag.addEventListener(SHAKA_PLAYER_EVENTS.RATECHANGE, (e) => {
        //     if (videoTag.playbackRate == 0) {
        //         this.emitter.emit(PLAYER_EVENTS_LIST.WAITING_V2);
        //     }
        //     if (videoTag.playbackRate > 0) {
        //         this.emitter.emit(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH_V2, videoTag);
        //     }
        // });

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.TIMEUPDATE, () => {
            if (this.canFireTimeUpdate) {
                this.emitter.emit(PLAYER_EVENTS_LIST.TIMEUPDATE, videoTag.currentTime, videoTag);
            }
            // SHAKA DOES NOT HAS A EVENT NAMED 'FIRST_PLAY' SO WE MADE ONE
            if (this.firstPlayEventFiredFlag === false && videoTag.currentTime > 0 && this.readyToPlayEventFiredFlag === true) {
                this.firstPlayEventFiredFlag = true;
                this.emitter.emit(PLAYER_EVENTS_LIST.FIRST_PLAY, videoTag);
                this.emitter.emit(PLAYER_EVENTS_LIST.FIRST_PLAY_V1, videoTag);
            }

            // raise live time update event
            const liveStreamCurrentPlayingTime = playerFunctions.isLiveStream() ? playerFunctions.getLivePlaybackUTCTime() : undefined;
            if (liveStreamCurrentPlayingTime) {
                this.emitter.emit(PLAYER_EVENTS_LIST.LIVE_TIME_UPDATE, liveStreamCurrentPlayingTime, videoTag);
            }
        });

        // const onCanPlayThroughEventHandler = () => {
        //     if (this.initEventFiredFlag === false) {
        //         const meta = playerFunctions.getMeta();
        //         this.initEventFiredFlag = true;
        //         this.emitter.emit(PLAYER_EVENTS_LIST.LOADED, meta);
        //     }
        //     //  need only one time, so dispose after use
        //     videoTag.removeEventListener(SHAKA_PLAYER_EVENTS.CAN_PLAY_THROUGH, onCanPlayThroughEventHandler);
        // };


        // videoTag.addEventListener(SHAKA_PLAYER_EVENTS.CAN_PLAY_THROUGH, onCanPlayThroughEventHandler);

        // videoTag.addEventListener('play', () => {
        //     console.log('lg','play')
        // });

        // v2 Event
        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.LOADED, () => { // Fires Ready To Play
            console.log('lg', 'rtp');
            this.readyToPlayEventFiredFlag = true;
            this.emitter.emit(PLAYER_EVENTS_LIST.LOADED, videoTag);
        });

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.LOADSTART, () => { // Fires init
            this.emitter.emit(PLAYER_EVENTS_LIST.LOADSTART, videoTag);
        });

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.LIVE_BUTTON_CLICK, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.LIVE_BUTTON_CLICK, event.data);
        });

        videoTag.addEventListener(PLAYER_EVENTS_LIST.SEEK_FORWARD, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.SEEK_FORWARD, event.data);
        });

        videoTag.addEventListener(PLAYER_EVENTS_LIST.SEEK_BACKWARD, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.SEEK_BACKWARD, event.data);
        });

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.CHANGE_BIT_RATE, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.CHANGE_BIT_RATE, event);
        });

        // player.addEventListener(SHAKA_PLAYER_EVENTS.CHANGE_BIT_RATE, (event) => {
        //     this.emitter.emit(PLAYER_EVENTS_LIST.CHANGE_BIT_RATE, event);
        // });

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.VOLUME_CHANGE, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.VOLUME_CHANGE, event);
        });

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.MUTE_V2, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.MUTE_V2, event);
        });

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.UNMUTE_V2, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.UNMUTE_V2, event);
        });

        videoTag.addEventListener(PLAYER_EVENTS_LIST.CHANGE_BIT_RATE_V2, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.CHANGE_BIT_RATE_V2, event);
        });

        videoTag.addEventListener(PLAYER_EVENTS_LIST.CHANGE_AUDIO_LANGUAGE, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.CHANGE_AUDIO_LANGUAGE, event);
        });

        videoTag.addEventListener(PLAYER_EVENTS_LIST.CHANGE_SUBTITLES, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.CHANGE_SUBTITLES, event);
        });

        videoTag.addEventListener(PLAYER_EVENTS_LIST.NEXT_EPISODE_CLICK, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.NEXT_EPISODE_CLICK, event);
        });

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.OPEN_PIP, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.OPEN_PIP);
        });

        videoTag.addEventListener(SHAKA_PLAYER_EVENTS.CLOSE_PIP, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.CLOSE_PIP);
        });
        player.addEventListener(SHAKA_PLAYER_EVENTS.TRACK_CHANGED, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.TRACK_CHANGED);
        });

        player.addEventListener(SHAKA_PLAYER_EVENTS.BUFFERING, (event) => {
            if (event?.buffering) {
                this.emitter.emit(PLAYER_EVENTS_LIST.WAITING_V2);
            }
            else {
                this.emitter.emit(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH_V2, videoTag);
            }
        });
    }

    screenChangeCallback = () => {
        this.emitter.emit(PLAYER_EVENTS_LIST.FULL_SCREEN_CHANGE);
    };
}
