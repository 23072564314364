// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#ad-ui{cursor:pointer;position:absolute;top:0;left:0;max-width:100%;max-height:100%;width:100%;height:100%}#ad-ui div:first-child{width:100% !important;height:100% !important}#ad-timer{display:inline-block;position:absolute;bottom:8px;margin-top:10px;padding:15px;z-index:10 !important;color:#fff}@media screen and (max-width:767px),(max-height:500px)and (max-width:990px){#ad-timer{opacity:1}}#learn-more{display:inline-block;position:absolute;cursor:pointer;top:6px;right:5px;margin-top:10px;padding:15px;z-index:10;color:#fff}@media screen and (max-width:767px),(max-height:500px)and (max-width:990px){#learn-more{opacity:1}}", ""]);
// Exports
exports.locals = {
	"mobileWidth": "767px",
	"mediaLandscapeHeight": "500px",
	"mediaLandscapeWidth": "990px",
	"tabletLargeWidth": "1200px"
};
module.exports = exports;
