export default class FairplayContentIdParser {
    static nagraLiveTVContentIdParser(emeOptions, initData) {
        const skd = new TextDecoder('utf-16').decode(initData);
        let base64string = skd.slice(8);
        const base64StrHasQueryParams = skd.indexOf('?');
        if (base64StrHasQueryParams) {
            base64string = base64string.split('?')[0];
        }
        return atob(base64string);
    }
}
