// import lodashSet from 'lodash/set';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import 'videojs-contrib-ads';
import 'videojs-ima';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import withStyles from 'isomorphic-style-loader/withStyles';
import BuildKeySystem from '../../builders/KeySystemBuilder';
import { isWeb as isWebBrowser } from '../../util/PlaybackUtility';
import {
    PLAYER_CONFIG, PLAYER_TYPES, PLAYER_VERSION,
} from '@airtel-tv/constants/PlayerConsts';
import getPlayerControls from '../../factories/PlayerControlsFactory';
import getPlayerEvents from '../../factories/PlayerEventsFactory';
import { STREAM_TYPES } from '@airtel-tv/constants/BrowserConst';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import { hasEMESupport } from '@airtel-tv/utils/WindowUtil';
import AbstractPlayer from '../abstract-player/AbstractPlayer';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import styles from './videojs.scss';
// eslint-disable-next-line import/order
import { connect } from 'react-redux';
// eslint-disable-next-line import/order
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import {
    adBreakEndEvent, adClickEvent, adCompleteEvent, adLoadEvent, adPauseEvent, adResumeEvent, adStartEvent,
} from '@airtel-tv/analytics/FunctionalEvents';
import { ANALYTICS_ASSETS } from '@airtel-tv/constants/AnalyticsConst';
import {
    adsClickedLearMore, adsPlaying, getAdPausedStatus, isSkippable,
} from '../../actions/PlaybackActions';

class VideoJsComponent extends AbstractPlayer {
    constructor(props) {
        super(props, PLAYER_TYPES.VIDEOJS_PLAYER);
        this.state = {
            isSkippable: false,
            skippableOffset: 0,
        };
    }

    initConfig() {
        // destructors props to use locally
        const {
            playbackConfig, browserNotSupported, contentDetails, adsData, playbackDetails, dispatchGetAdsClickedLearnMore, dispatchGetAdsPlaying
        } = this.props;
        const { enabled, videoAds } = adsData;
        const channelType = playbackConfig && playbackConfig.contentId && playbackConfig.contentId.split('_')[0];
        const adsType = videoAds && videoAds.slotConfigs && videoAds.slotConfigs.PRE_ROLL.type[channelType];
        const adsConfigData = videoAds && videoAds.slotConfigs && videoAds.slotConfigs.PRE_ROLL.ads[adsType] && videoAds.slotConfigs.PRE_ROLL.ads[adsType][0];
        const learnMoreElement = window.document.getElementById('learn-more');
        const contentName = lodashGet(playbackConfig, 'videoMeta.videoTitle', '');
        const cpName = lodashGet(playbackDetails, 'cp', '');
        const contentLanguage = lodashGet(playbackDetails, 'plg', '');
        const playbackContentType = lodashGet(playbackDetails, 'playbackType', '');
        const isdrm = lodashGet(playbackConfig, 'isDrm', '');
        let adUrl;
        let adId;
        if (learnMoreElement) {
            learnMoreElement.addEventListener('click', () => {
                adClickEvent(adsAnalyticsMeta);
                if (adUrl) {
                    window.open(adUrl);
                    let adsClickedLearnMoreFlag = true;
                    dispatchGetAdsClickedLearnMore({ adsClickedLearnMoreFlag });
                    adsClickedLearnMoreFlag = false;
                    dispatchGetAdsClickedLearnMore({ adsClickedLearnMoreFlag });
                }
            });
        }


        // dispose previous player before loading new content
        if (this.player) {
            this.player.dispose();
            this.player = null;
            this.renewVideoTag();
        }

        // case :: UPDATE :: if playback url is not present then empty
        if (!playbackConfig.playbackUrl) {
            if (this.playerFunctions) {
                this.playerFunctions = null;
            }

            if (this.playerEvents) {
                this.playerEvents.dispose();
                this.playerEvents = null;
            }
        }

        // render empty video tag with poster if playback url is not available
        if (!playbackConfig.playbackUrl && this.controlsBarInstance) {
            this.controlsBarInstance.enableControlBar();
            return;
        }

        const {
            enableNativeControls,
            enableAutoplay,
            drm,
            isDrm,
            isMsp,
            contentId,
            browser,
            browserVersion,
            os,
            streamType,
            playbackUrlCookies,
            overrideNativeVideoJs,
        } = playbackConfig || {};

        const adsAnalyticsMeta = {
            content_name: contentName,
            app_type: ANALYTICS_ASSETS.WEB,
            ad_type: ANALYTICS_ASSETS.PRE_ROLL,
            cp_name: cpName,
            bitrate: ANALYTICS_ASSETS.NULL,
            content_language: contentLanguage,
            content_type: playbackContentType,
            content_id: contentId,
            is_drm_content: isdrm,
            channel_id: contentId,
        };

        const isWeb = isWebBrowser();
        const isLiveStream = streamType === STREAM_TYPES.LIVE;
        const timerElement = window.document.getElementById('ad-timer');

        const {
            drmType, licenseUri, handleLicenseRequest, certificateUri, handleCertificateRequest, handleContentIdRequest,
        } = drm || {};


        // get the keysystem for the player
        const keySystems = BuildKeySystem({
            isDrm,
            browser,
            drmType,
            os,
            browserVersion,
            licenseUri,
            handleLicenseRequest,
            certificateUri,
            handleCertificateRequest,
            contentId,
            handleContentIdRequest,
        });


        // CHECK PLAYBACK SUPPORT
        if ((isDrm && !hasEMESupport()) // check browser has drm support
            || (playbackConfig.playbackUrl && playbackConfig.playbackUrl.startsWith('http://')) // make sure stream is https
            || (Object.keys(keySystems).length < 1 && isDrm)) { // make sure platform has drm support
            browserNotSupported({
                code: ERROR_CODES.VIDEOJS_BROWSER_NOT_SUPPORTED,
            });
            return;
        }

        // end handle drm


        // start create config for video player
        this.videoJsOptions = {
            autoplay: enableAutoplay,
            // controls: enableNativeControls,
            controlBar: true,
            controls: false,
            loadingSpinner: enableNativeControls,
            textTrackSettings: enableNativeControls,
            bigPlayButton: enableNativeControls,
            resizeManager: false,
            errorDisplay: false,
            sources: [
                {
                    src: playbackConfig.playbackUrl,
                    type: playbackConfig.playbackType,
                    keySystems,
                },
            ],
        };

        // if drm and safari then do not override native
        if (overrideNativeVideoJs) {
            this.videoJsOptions.html5 = {
                nativeAudioTracks: false,
                nativeVideoTracks: false,
                vhs: {
                    overrideNative: true,
                },
            };
        }

        if (playbackUrlCookies) {
            lodashSet(this.videoJsOptions, 'html5.vhs.withCredentials', true);
        }

        // end create config for video player

        // instantiate Video.js
        this.player = videojs(this.videoNode, this.videoJsOptions, () => {
            if (!this.videoNode) {
                try {
                    this.player.dispose();
                }
                catch (error) {
                    console.error('video js loaded  ', error);
                }
                return;
            }

            // BUG: poster blinking while quality change or buffer


            if (isDrm) {
                this.player.eme();
            }

            videojs.Vhs.xhr.beforeRequest = (options) => {
                if (!isDrm && isMsp) {
//                     const playbackUrlSplitted = playbackConfig.playbackUrl.split('?');
//                     const playbackUrlQueryParam = playbackUrlSplitted[1];
//                     if (options.uri.endsWith('.m3u8')) {
//                         options.uri = options.uri.replace('.m3u8', `.m3u8?${playbackUrlQueryParam}`);
//                     }else if (options.uri.endsWith('.ts')) {
//                         options.uri = options.uri.replace('.ts', `.ts?${playbackUrlQueryParam}`);
//                     }

                    const playbackUrlSplitted = playbackConfig.playbackUrl.split('?');
                    this.playbackUrlQueryParam = playbackUrlSplitted[1];            
                    const uri = new URL(options.uri);
                    const { pathname = '' } = uri;
                    if (pathname.endsWith('.m3u8') || pathname.endsWith('.ts') || pathname.endsWith('.aac')) {
                        // chunklist query params with playback query params for authentication
                        // eslint-disable-next-line no-param-reassign
                        uri.search = uri.search ? `${uri.search}&${this.playbackUrlQueryParam}` : `?${this.playbackUrlQueryParam}`;
                        options.uri = uri;
                    }
                }

                return options;
            };

            this.playerFunctions = getPlayerControls({
                playerType: PLAYER_TYPES.VIDEOJS_PLAYER,
                player: this.player,
                videoTag: this.videoNode,
                isLiveStream,
                contentDetails,
            });

            this.playerEvents = getPlayerEvents({
                playerType: PLAYER_TYPES.VIDEOJS_PLAYER,
                player: this.player,
                videoTag: this.videoNode,
                playerFunctions: this.playerFunctions,
            });

            this.playerFunctions.setPlayerVersion(PLAYER_VERSION.VIDEOJS_PLAYER);

            // local playback event handling e.g. hide and show
            this.playerEventsHandler(this.playerEvents);

            this.enableControlBar();

            // init keyboard events
            this.installKeyboardControls();

            // let parent know the events so that it can also subscribe and use
            const { playerEventsInitialized } = this.props;
            if (playerEventsInitialized) {
                playerEventsInitialized(this.playerEvents, this.playerFunctions);
            }
        });

        this.videoNode.poster = '';
        // && adsConfigData && adsConfigData.adUnitId
        // commenting live tv ads
        // if (enabled && adsConfigData && adsConfigData.adUnitId) {
        //     console.log('browserType....', this.deviceUtil.getBrowserName());
        //     const imaOptions = {
        //         disableAdControls: true,
        //         adTagUrl: adsConfigData.adUnitId,
        //         // adTagUrl: "https://pubads.g.doubleclick.net/gampad/ads?iu=/417241724/Klikk_dsdsdsdsdIMA_Video_Prod&description_url=[placeholder]&tfcd=0&npa=0&sz=640x480&ciu_szs=320x50&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=",
        //         // adTagUrl: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/423477888/Airtel_Xstream/DAI_video_test&description_url=[placeholder]&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=&ad_rule=0',
        //     };
        //     try {
        //         this.player.ima(imaOptions);
        //     }
        //     catch (e) {
        //         console.log('exception in try', e);
        //     }
        // }

        // try {
        //     this.player.on('adsready', () => {
        //         const { dispatchSkippableAction, dispatchGetAdsPlaying } = this.props;
        //         const adsPlayingFlag = true;
        //         dispatchGetAdsPlaying({ adsPlayingFlag });
        //         const videbox = document.getElementById('video-box_ima-ad-container');
        //         if (videbox) {
        //             videbox.style.pointerEvents = 'none';
        //         }
        //         const videboxSecondIfExist = document.getElementById('video_ima-ad-container');
        //         if (videboxSecondIfExist) {
        //             videboxSecondIfExist.style.pointerEvents = 'none';
        //         }
        //         const iframeEle = document.querySelector('iframe');
        //         const elem = document.querySelector('#video_ima-countdown-div');
        //         const elem2 = document.querySelector('#video-box_ima-countdown-div');

        //         if (elem) {
        //             elem.classList.add('d-none');
        //         }
        //         if (elem2) {
        //             elem2.classList.add('d-none');
        //         }

        //         if (iframeEle) {
        //             iframeEle.classList.add('d-none');
        //         }
        //         // this.props.setAdManagerInPlaybackContainer(this.player.ima.getAdsManager());
        //         this.setAdManager(this.player.ima.getAdsManager());
        //         this.player.ima.addEventListener(window.google.ima.AdEvent.Type.STARTED, (e) => {
        //             const { skippable, skipTimeOffset } = e && e.o && e.o.j || {
        //                 skippable: false,
        //                 skipTimeOffset: 0,
        //             };
        //             this.setState({
        //                 isSkippable: skippable,
        //                 skipTimeOffset,
        //             });
        //             dispatchSkippableAction({
        //                 isSkippable: skippable,
        //                 skipTimeOffset,
        //             });

        //             if (learnMoreElement) {
        //                 learnMoreElement.style.display = 'block';
        //                 learnMoreElement.innerHTML = 'Learn More';
        //             }
        //             this.keyboardControls.uninstall();
        //             const adContainer = window.document.getElementById('ads-container');
        //             adContainer.classList.remove('d-none');
        //             adUrl = e.currentTarget && e.currentTarget.l && e.currentTarget.l.j && e.currentTarget.l.j.clickThroughUrl;
        //             adId = e.currentTarget && e.currentTarget.l && e.currentTarget.l.j && e.currentTarget.l.j.adId;
        //             const adStartAnalyticsMeta = {
        //                 content_name: contentName,
        //                 app_type: ANALYTICS_ASSETS.WEB,
        //                 ad_type: ANALYTICS_ASSETS.PRE_ROLL,
        //                 cp_name: cpName,
        //                 bitrate: ANALYTICS_ASSETS.NULL,
        //                 ad_id: adId,
        //                 content_language: contentLanguage,
        //                 content_type: playbackContentType,
        //                 content_id: contentId,
        //                 is_drm_content: isdrm,
        //                 channel_id: contentId,
        //             };
        //             adStartEvent(adStartAnalyticsMeta);
        //         });

        //         this.player.ima.addEventListener(window.google.ima.AdEvent.Type.SKIPPED, () => {
        //             const adsPlayingFlag = false;
        //             dispatchGetAdsPlaying({ adsPlayingFlag });
        //             this.installKeyboardControls();
        //         });
        //         this.player.ima.addEventListener(window.google.ima.AdEvent.Type.AD_ERROR, () => {
        //             const adsPlayingFlag = false;
        //             console.log('error occured while playing ads');
        //             dispatchGetAdsPlaying({ adsPlayingFlag });
        //             this.installKeyboardControls();
        //         });
        //         this.player.ima.addEventListener(window.google.ima.AdEvent.Type.PAUSED, () => {
        //             const isAdsPaused = true;
        //             this.props.dispatchGetAdsPaused({ isAdsPaused });
        //         });
        //         this.player.ima.addEventListener(window.google.ima.AdEvent.Type.RESUMED, () => {
        //             const isAdsPaused = false;
        //             this.props.dispatchGetAdsPaused({ isAdsPaused });
        //         });
        //         this.player.ima.addEventListener(window.google.ima.AdEvent.Type.AD_PROGRESS, (e) => {
        //             let duration = 0;
        //             let currentTime = 0;
        //             let timeRemaining = 0;
        //             if (e.j && e.j.duration) {
        //                 duration = lodashGet(e, 'j.duration', 0);
        //                 currentTime = lodashGet(e, 'j.currentTime', 0);
        //                 timeRemaining = Math.floor(duration - currentTime);

        //                 if (timerElement) {
        //                     timerElement.innerHTML = `Ad: (${timeRemaining})`;
        //                 }
        //             }
        //             else {
        //                 duration = lodashGet(e, 'B.adBreakDuration', 0);
        //                 currentTime = lodashGet(e, 'B.currentTime', 0);
        //                 timeRemaining = Math.floor(duration - currentTime);
        //                 if (timerElement) {
        //                     timerElement.innerHTML = `Ad: (${timeRemaining})`;
        //                 }
        //             }
        //         });


        //         this.player.ima.addEventListener(window.google.ima.AdEvent.Type.COMPLETE, () => {
        //             const adsPlayingFlag = false;
        //             dispatchGetAdsPlaying({ adsPlayingFlag });
        //             this.installKeyboardControls();
        //             console.log('Ad completed');
        //             const adContainer = window.document.getElementById('ads-container');
        //             adContainer.classList.add('d-none');
        //             adBreakEndEvent(adsAnalyticsMeta);
        //             if (learnMoreElement) {
        //                 learnMoreElement.style.display = 'none';
        //             }
        //         });
        //         this.player.ima.addEventListener(window.google.ima.AdEvent.Type.ALL_ADS_COMPLETED, () => {
        //             const adsPlayingFlag = false;
        //             dispatchGetAdsPlaying({ adsPlayingFlag });
        //             this.enableControlBar();
        //             this.installKeyboardControls();
        //             console.log('Ad completed');
        //             this.videoNode.play();
        //             adBreakEndEvent(adsAnalyticsMeta);
        //             if (learnMoreElement) {
        //                 learnMoreElement.style.display = 'none';
        //             }
        //         });
        //     });
        // }
        // catch (e) {
        //     console.log('exception occured in ads playing', e);
        //     const adsPlayingFlag = false;
        //     const { dispatchGetAdsPlaying } = this.props;
        //     dispatchGetAdsPlaying({ adsPlayingFlag });
        // }
        // this.player.dispose();


        // this.player.reloadSourceOnError({
        //     // getSource: (_reload) => {
        //     getSource: () => {
        //         console.debug('Perform any auth renewal / asset URL regeneration here ');

        //         if (this.playerEvents) {
        //             const { playbackConfig: { playbackUrl } } = this.props;
        //             this.playerEvents.emit(PLAYER_EVENTS_LIST.ERROR, { code: ERROR_CODES.LOCAL1006, playbackUrl });
        //         }

        //         // ! DO NOT DELETE
        //         // reload({
        //         //     src: 'https://example.com/index.m3u8?CloudFront-Policy=xxxx',
        //         //     type: 'application/x-mpegURL',
        //         // });
        //     },
        // });

        // Pre Buffer for VideoJs in seconds;
        videojs.Vhs.GOAL_BUFFER_LENGTH = PLAYER_CONFIG.GOAL_BUFFER_LENGTH;


        // DO NOT REMOVE ::  UNCOMMENT BELOW TO SHOW NATIVE CONTROLS
        if (this.videoNode) {
            this.videoNode.classList.add('video-js');
            this.videoNode.classList.add('vjs-default-skin');
        }
    }
}

VideoJsComponent.propTypes = {
    browserNotSupported: PropTypes.func.isRequired,
    reload: PropTypes.func,
    playerEventsInitialized: PropTypes.func,
    ControlBar: PropTypes.func,
    playbackConfig: PropTypes.shape({
        overrideNativeVideoJs: PropTypes.bool,
        playbackUrlCookies: PropTypes.object, // eslint-disable-line react/forbid-prop-types
        browser: PropTypes.string,
        browserVersion: PropTypes.number,
        os: PropTypes.string,
        enableAutoplay: PropTypes.bool,
        playbackUrl: PropTypes.string,
        playbackType: PropTypes.string.isRequired,
        isDrm: PropTypes.bool,
        contentId: PropTypes.string,
        drm: PropTypes.shape({
            drmType: PropTypes.string,
            handleLicenseRequest: PropTypes.func,
            handleLicenseResponse: PropTypes.func,
            licenseUri: PropTypes.string,
            handleCertificateRequest: PropTypes.func, // for fairplay
            certificateUri: PropTypes.string, // for fairplay
        }),
    }),
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    playTrailer: PropTypes.bool,
};

VideoJsComponent.defaultProps = {
    reload: () => null,
    playerEventsInitialized: () => null,
    ControlBar: () => null,
    playbackConfig: {
        overrideNativeVideoJs: true,
        playbackUrlCookies: null,
        browser: '',
        browserVersion: '',
        os: '',
        enableAutoplay: false,
        playbackUrl: '',
        isDrm: false,
        contentId: '',
        drm: {
            drmType: '',
            handleLicenseRequest: undefined, // if provided then player will call this funtion to get license instead of get license itself
            handleLicenseResponse: undefined,
            licenseUri: '',
            handleCertificateRequest: undefined, // for fairplay
            certificateUri: '', // for fairplay
            ticket: '',
        },
    },
};
const mapStateToProps = (state) => {
    const {
        appConfig,
    } = state;
    const adsData = lodashGet(appConfig, 'adsData', {});
    return {
        adsData,
    };
};
export default connect(mapStateToProps, {
    dispatchGetAdsPlaying: adsPlaying,
    dispatchSkippableAction: isSkippable,
    dispatchGetAdsPaused: getAdPausedStatus,
    dispatchGetAdsClickedLearnMore: adsClickedLearMore,
})(withDeviceUtil(withRouter(withStyles(styles)(VideoJsComponent))));
