import { IGNORE_EVENTS, PLAYER_EVENTS_LIST } from '@airtel-tv/constants';
import React, {
    useRef, useState, useMemo, useImperativeHandle, useEffect,
} from 'react';
import lodashThrottle from 'lodash/throttle';
import { connect } from 'react-redux';
import AdSlots from './AdSlots';
import { subscribe } from '../../../../tv/src/utilities/Pubsub';
import { webAdSdkEventDataShaper } from '../../../../libraries/utils/AdSdkUtil';

const SLOT_STATUS = {
    SCHEDULED: 'SCHEDULED',
    TRIGGERED: 'TRIGGERED',
};

const getSlotId = (index) => {
    if (index === 0) {
        return 'IMA_VIDEO_XSTREAM';
    }

    return 'IMA_XSTREAM_2';
};

const AdSlotWrapper = React.forwardRef((props, ref) => {
    const {
        adConfig,
        contentDetails,
        dispatchSdkAdsPlaying,
        updateKeyboardControlsActionDispatch,
        videoNode,
    } = props;

    const [
        playerEvents,
        setPlayerEvents,
    ] = useState(null);
    const [
        currentSlot,
        setCurrentSlot,
    ] = useState(null);
    const thisRef = useRef({
        previousCurrentTime: null,
        nextAdSlot: null,
        completedSlots: [],
    }).current;


    const slotRef = useRef(null);

    const adSlots = useMemo(() => {
        if (!adConfig || !Object.keys(adConfig).length) {
            return [];
        }

        const { programType, cpId } = contentDetails;
        const packages = adConfig?.layoutPackages?.[0]?.zionDisplay;
        let { adConfig: adConfigStr = '' } = packages || {};
        let slotConfig = [];
        if (adConfigStr) {
            try {
                adConfigStr = JSON.parse(adConfigStr);
            }
            catch (err) {
                adConfigStr = [];
            }
        }
        else {
            adConfigStr = [];
        }

        adConfigStr = adConfigStr[cpId];
        if (!adConfigStr) {
            return [];
        }
        let index = 0;
        if (adConfigStr.preRollAdConfig) {
            slotConfig = [
                ...slotConfig,
                // { interval: 'PRE', slotId: adConfigStr.preRollAdConfig.slotId },
                {
                    interval: 0,
                    slotId: adConfigStr.preRollAdConfig.slotId,
                    slotUniqueIndentifier: `AD_${++index}`,
                    type: 'PRE',
                },
                // { interval: 0, slotId: "IMA_VIDEO", slotUniqueIndentifier: ++index, type: 'PRE'},
            ];
        }
        if (adConfigStr?.midRollAdConfig) {
            const pType = (programType === 'TVSHOW' ? 'EPISODE' : programType).toLowerCase();
            const midRollConfig = adConfigStr?.midRollAdConfig[pType];
            if (midRollConfig?.breakPoints?.length) {
                const breakPoints = [
                    ...midRollConfig.breakPoints,
                ];
                let previousPoint = 1;
                slotConfig = [
                    ...slotConfig,
                    ...breakPoints.sort((a, b) => parseInt(a.interval, 10) - parseInt(b.interval, 10)).map((item, id) => {
                        const obj = {
                            interval: +item.interval,
                            start: previousPoint,
                            end: +item.interval,
                            slotId: item.slotId,
                            slotUniqueIndentifier: `AD_${++index}`,
                            type: 'MID',
                        };
                        previousPoint = +item.interval;
                        return obj;
                    }),
                    // ...breakPoints.sort((a, b) => parseInt(a.interval, 10) - parseInt(b.interval, 10)).map((item, index) => ({interval: +item.interval, slotId: getSlotId(index), slotUniqueIndentifier: `AD_${++index}`, type: 'MID'})),
                ];
                // console.log('lllld', slotConfig);
            }
        }
        if (adConfigStr.postRollAdConfig) {
            thisRef.postRollAdConfig = {
                interval: 'POST',
                slotId: adConfigStr.postRollAdConfig.slotId,
                slotUniqueIndentifier: `AD_${++index}`,
                type: 'POST',
            };
        }
        thisRef.adCuePoints = slotConfig.map(item => ({
            start: item.interval,
            end: null,
            alreadyPlayed: false,
            slotUniqueIndentifier: item.slotUniqueIndentifier,
        }));// Handle post roll
        return slotConfig;
    }, [
        adConfig,
        contentDetails,
    ]);

    const findSeekedSlot = (playbackCurrentTime) => {
        const filteredAdSlots = [
            ...adSlots.filter(item => !(thisRef.completedSlots.includes(item.slotUniqueIndentifier) || item.type !== 'MID')),
        ];
        for (let i = filteredAdSlots.length - 1; i >= 0; i--) {
            console.log('ppp kkkkz', filteredAdSlots[i]);
            if (filteredAdSlots[i].interval < playbackCurrentTime) {
                console.log('ppp kkkk1', filteredAdSlots[i]);
                return filteredAdSlots[i];
            }
        }
    };


    const handleAdProgress = (e) => {
        playerEvents.emitter.emit(PLAYER_EVENTS_LIST.AD_PROGRESS, e.detail);
    };

    const handleSDkAnalytics = (sdkEvent) => {
        const { id } = contentDetails;
        const { detail } = sdkEvent;
        const { analyticsData, skipOffset, type } = detail;

        const playAnalytics = {
            content_id: id,
            program_id: id,
            content_category: 'Ads',
        };
        const shapedAnalyticsData = webAdSdkEventDataShaper({
            ...analyticsData,
            skipOffset,
        });
        if (!IGNORE_EVENTS.includes(type)) {
            const data = {
                ...playAnalytics,
                ...shapedAnalyticsData,
                isPartnerBased: false
            };
            playerEvents.emitter.emit(PLAYER_EVENTS_LIST.ADS_SDK_EVENT, data);
        }
    };

    // eslint-disable-next-line arrow-body-style
    // useImperativeHandle(ref, () => {
    //     return {
    //         enableAdControlWrapper,
    //     };
    // }, []);


    const emitCuePointChaned = () => {
        if (!thisRef.playerEvents.emitter) {
            return;
        }
        thisRef.playerEvents.emitter.emit(PLAYER_EVENTS_LIST.AD_CUEPOINTS_CHANGED, { adCuePoints: thisRef.adCuePoints });
    };


    const markSlotComplete = (slotIdentifier) => {
        thisRef.completedSlots.push(slotIdentifier);
        thisRef.adCuePoints = thisRef.adCuePoints.map((item) => {
            if (item.slotUniqueIndentifier === slotIdentifier) {
                item = {
                    ...item,
                    alreadyPlayed: true,
                };
            }
            return item;
        });
        if (slotIdentifier === thisRef.postRollAdConfig?.slotUniqueIndentifier) {
            playerEvents?.emitter?.emit(PLAYER_EVENTS_LIST.CONTENT_WITH_POSTROLL_COMPLETION);
        }
        emitCuePointChaned();
        thisRef.nextAdSlot = null;
        setCurrentSlot(thisRef.nextAdSlot);
    };

    const onTimeUpdate = (playbackCurrentTime) => {
        // console.log('pppp', thisRef.nextAdSlot);
        if (playbackCurrentTime !== thisRef.previousCurrentTime && (adSlots || []).length) {
            // console.log('lld', thisRef.nextAdSlot);
            thisRef.previousCurrentTime = playbackCurrentTime;
            if (!thisRef.nextAdSlot) {
                thisRef.nextAdSlot = adSlots.find((item) => {
                    if (thisRef.completedSlots.includes(item.slotUniqueIndentifier)) {
                        return false;
                    }
                    if (item.type === 'PRE') {
                        return item;
                    }
                    if (+item.interval > playbackCurrentTime) {
                        return item;
                    }
                });
            }

            if (thisRef.nextAdSlot && thisRef.nextAdSlot?.type !== 'PRE' && !(thisRef.nextAdSlot?.start < playbackCurrentTime && playbackCurrentTime < thisRef.nextAdSlot?.end)) {
                let previousSlot;
                for (let i = 0; i < adSlots.length; i++) {
                    const item = adSlots[i];
                    if (thisRef.completedSlots.includes(item.slotUniqueIndentifier) || item.type === 'PRE') {
                        continue;
                    }
                    if (item?.start < playbackCurrentTime && playbackCurrentTime < item?.end) {
                        if (!previousSlot) { // seek from mid to first slot
                            thisRef.nextAdSlot = item;
                        }
                        break;
                    }
                    previousSlot = item;
                }
                if (
                    previousSlot
                    && playbackCurrentTime >= previousSlot.end
                ) {
                    thisRef.nextAdSlot = {
                        ...previousSlot,
                        slotStatus: 'TRIGGERED',
                        autoTrigger: true,
                        triggerTime: playbackCurrentTime,
                    };
                    setCurrentSlot(thisRef.nextAdSlot);
                }
            }

            // console.log("kkkk l", thisRef.nextAdSlot);

            // if (thisRef.nextAdSlot?.slotStatus === 'TRIGGERED' && playbackCurrentTime > thisRef.nextAdSlot.triggerTime + 5) {
            //     // thisRef.completedSlots.push(thisRef.nextAdSlot.slotUniqueIndentifier);
            //     markSlotComplete(thisRef.nextAdSlot.slotUniqueIndentifier);
            //     thisRef.nextAdSlot = null;
            //     setCurrentSlot(thisRef.nextAdSlot);
            //     return;
            // }

            if (thisRef.nextAdSlot?.type === 'PRE') {
                if (!thisRef.nextAdSlot.slotStatus) {
                    thisRef.nextAdSlot = {
                        ...thisRef.nextAdSlot,
                        slotStatus: 'TRIGGERED',
                        autoTrigger: true,
                        triggerTime: playbackCurrentTime,
                    };
                    setCurrentSlot(thisRef.nextAdSlot);
                }
                return;
            }
            if (thisRef.nextAdSlot && thisRef.nextAdSlot.interval < (playbackCurrentTime + 10)) {
                if (!thisRef.nextAdSlot?.slotStatus) {
                    // thisRef.completedSlots.push(thisRef.nextAdSlot.slotUniqueIndentifier);
                    thisRef.nextAdSlot = {
                        ...thisRef.nextAdSlot,
                        slotStatus: 'SCHEDULED',
                    };
                    // console.log("zzz4",thisRef.nextAdSlot);
                    setCurrentSlot(thisRef.nextAdSlot);
                }

                if (thisRef.nextAdSlot?.slotStatus === 'SCHEDULED' && thisRef.nextAdSlot.interval < (playbackCurrentTime + 2) && slotRef.current) {
                    // if (thisRef.nextAdSlot?.slotStatus !== 'SCHEDULED') {
                    //     // Seeked
                    //     const nextAdSlot = findSeekedSlot(playbackCurrentTime);
                    //     if (nextAdSlot) {
                    //         thisRef.nextAdSlot = {
                    //             ...nextAdSlot,
                    //             slotStatus: 'TRIGGERED',
                    //             autoTrigger: true,
                    //             triggerTime: playbackCurrentTime,
                    //         };
                    //         setCurrentSlot(thisRef.nextAdSlot);
                    //         return;
                    //     }
                    // }
                    thisRef.nextAdSlot = {
                        ...thisRef.nextAdSlot,
                        slotStatus: 'TRIGGERED',
                        triggerTime: playbackCurrentTime,
                    };
                    setCurrentSlot(thisRef.nextAdSlot);
                    slotRef.current.triggerAd();
                }
            }
        }
    };

    const throttledOnTimeUpdate = useRef(
        lodashThrottle((event) => {
            onTimeUpdate(event);
        }, 500, {
            leading: true,
            trailing: false,
        }),
    ).current;

    useEffect(() => {
        if (!playerEvents) {
            return;
        }
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.TIMEUPDATE, throttledOnTimeUpdate);
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.TRIGGER_POST_ROLL_AD, () => {
            if (thisRef.postRollAdConfig) {
                thisRef.nextAdSlot = {
                    ...thisRef.postRollAdConfig,
                    slotStatus: 'TRIGGERED',
                    autoTrigger: true,
                    triggerTime: thisRef.previousCurrentTime,
                };
                setCurrentSlot(thisRef.nextAdSlot);
            }
            else {
                playerEvents?.emitter?.emit(PLAYER_EVENTS_LIST.CONTENT_WITH_POSTROLL_COMPLETION);
            }
        });
        emitCuePointChaned();
    }, [
        playerEvents,
    ]);

    useEffect(() => {
        subscribe(PLAYER_EVENTS_LIST.PLAYER_EVENTS_EMITTER_ENABLED, (plEvent) => {
            if (playerEvents) {
                return;
            }
            thisRef.playerEvents = plEvent;
            setPlayerEvents(plEvent);
        });
    }, []);

    if (!currentSlot) {
        return null;
    }

    return (
        <AdSlots
            key={currentSlot.slotUniqueIndentifier}
            currentSlot={currentSlot}
            ref={slotRef}
            dispatchSdkAdsPlaying={dispatchSdkAdsPlaying}
            handleAdProgress={handleAdProgress}
            playerEvents={playerEvents}
            handleAdsAnalytics={handleSDkAnalytics}
            markSlotComplete={markSlotComplete}
            updateKeyboardControlsActionDispatch={updateKeyboardControlsActionDispatch}
            videoNode={videoNode}
        />
    );
});

const mapStateToProps = (state) => {
    const {
        layoutDetails,
        appConfig,
    } = state;

    const { web_pageId: { SDK_AD_CONFIG = "adsConfig" } = {} } = appConfig;
    const adConfig = layoutDetails[SDK_AD_CONFIG];
    return {
        adConfig,
    };
};

export default connect(mapStateToProps, {
}, null, { forwardRef: true })(AdSlotWrapper);
