import { PLAYER_EVENTS_LIST } from '@airtel-tv/constants/PlayerConsts';
import {
    addListenerToEvent,
    removeListenerFromEvent,
} from '@airtel-tv/utils/WindowUtil';
import { PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';
import { isWeb as isWebBrowser } from '../util/PlaybackUtility';
import { subscribe, publish } from '../../../tv/src/utilities/Pubsub';

export default function ContinueWatchingHelper({
    isTrailer = false,
    playerEvents,
    playerFunctions,
    removeContentToContinueWatching,
    addContentToContinueWatching,
    addAndSyncContent,
    contentDetails,
    nextEpisodeDetails,
    continueWatchingDetails = { lastWatchedPosition: 0 },
    contentDetailsFetchAction = () => {},
    history,
    analyticsHelper,
    removeFromCwTime = {
        tvshows: 10,
        movies: 60,
    },
    shouldAddInCW,
    useLegacyShaka = false,
    playAsTrailer = false,
    shouldPlaybackRestart = false,
    setPlayerConfigDispatch = () => {},
}) {
    const contentId = contentDetails.id;
    const isWeb = isWebBrowser();

    const { programType } = contentDetails;
    if (!playerEvents || !playerFunctions || !contentId
         || programType === PROGRAM_TYPES.PROGRAM
        || programType === PROGRAM_TYPES.LIVETVCHANNEL || !shouldAddInCW) {
        return () => { };
    }

    const unlistenHistoryChange = null;

    const getPlaybackData = () => {
        const { playSessionId, playDuration } = analyticsHelper?.getSyncData() || {};
        if (!playSessionId || (!playDuration && playDuration != 0)) {
            return {};
        }

        return {
            playSessionId,
            playDuration,
        };
    };

    const addSyncData = ({ bypassCWThreshold = false } = {}) => {
        if (playerFunctions) {
            const lastWatchedPosition = playerFunctions.getCurrentTime();
            const { durSec, langId: changedAudio } = contentDetails;
            if (durSec - lastWatchedPosition > 10) {
                const playbackData = getPlaybackData();
                addContentToContinueWatching({
                    lastWatchedPosition,
                    contentDetails,
                    changedAudio,
                    bypassCWThreshold,
                    ...playbackData,
                });
            }
        }
    };

    const addAndSyncData = () => {
        if (playerFunctions) {
            const lastWatchedPosition = playerFunctions.getCurrentTime();
            const { durSec, langId: changedAudio } = contentDetails;
            // if (durSec - lastWatchedPosition > 10) {
            const playbackData = getPlaybackData();
            addAndSyncContent({
                lastWatchedPosition,
                contentDetails,
                changedAudio,
                ...playbackData,
            });
            // }
        }
    };

    const removeSyncData = () => {
        if (playerFunctions) {
            removeContentToContinueWatching({
                contentDetails,
                nextEpisodeDetails,
            });
        }
    };

    const unSubscribeChangeAudio = subscribe(PLAYER_EVENTS_LIST.AUDIO_CHANGE, (langId) => {
        contentDetails.langId = langId;
        addSyncData();
    });

    const unSubscribeChangeSubtitle = subscribe(PLAYER_EVENTS_LIST.SUBTITLE_CHANGE, (subtitle) => {
        contentDetails.subtitle = subtitle;
        addSyncData();
    });

    const onPlayStart = () => {
        let playbackEnded = false;
        //addSyncData({ bypassCWThreshold: true }); //On First Play Add content to CW
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.PAUSE, addSyncData);
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.PLAYING, addSyncData);
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.DISPOSE, () => {
            /*
                If user presses back button dispose event is called
                playbackEnded event ensures that the event is not adding
                already completed content to CW as in that case we have to remove content from CW
            */
            if (!playbackEnded) {
                addSyncData();
            }
        });
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.ENDEND, () => {
            /*
                remove content on end of content as well as markplaybackended true so that
                on dispose we don't trigger addSyncData.
            */
            playbackEnded = true;
            removeSyncData();
        });
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.WAITING, addSyncData);
        playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.FULL_SCREEN_CHANGE, () => addSyncData);

        addListenerToEvent('beforeunload', addSyncData);
        // unlistenHistoryChange = history.listen(addSyncData);
        publish(PLAYER_EVENTS_LIST.FIRST_PLAY);
        if (continueWatchingDetails.lastWatchedPosition && !shouldPlaybackRestart) {
            playerFunctions.seekBarChange(continueWatchingDetails.lastWatchedPosition);
        }
        const preferredAudioLanguage = contentDetails.langId;
        if (preferredAudioLanguage) {
            if (useLegacyShaka) {
                let preferredAudioLanguageSet = false;
                playerEvents.emitter.on(PLAYER_EVENTS_LIST.TIMEUPDATE, (playbackCurrentTime) => {
                    const currentTime = Math.floor(playbackCurrentTime);
                    if (currentTime > 8 && !preferredAudioLanguageSet) {
                        preferredAudioLanguageSet = true;
                        playerFunctions.changeAudio(preferredAudioLanguage);
                    }
                });
            }
            else {
                setTimeout(() => {
                    playerFunctions.changeAudio(preferredAudioLanguage);
                }, 1000);
            }
        }
    };

    const onLoadStart = () => {
        // CAN_PLAY_THROUGH will be called whenever player goes from waiting state to playing state so remove the listener
        playerEvents.emitter.removeListener(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH, onLoadStart);

        if (continueWatchingDetails.lastWatchedPosition && !isTrailer && !shouldPlaybackRestart) {
            playerFunctions.seekBarChange(continueWatchingDetails.lastWatchedPosition);
        }
    };

    // event for starting the playback from last watch
    // ON_PAY_START was emitting after 10 seconnds of playback in IOS so,
    // CAN_PLAY_THROUGH USED
    playerEvents.emitter.on(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH, onLoadStart);
    addListenerToEvent('blur', addAndSyncData, true);
    // continue time update should not start before loaded event
    playerEvents.emitter.on(PLAYER_EVENTS_LIST.FIRST_PLAY, onPlayStart);

    const dispose = (handleNextEp = false) => {
        if (isTrailer) {
            return;
        }
        if (handleNextEp) {
            const lastWatchedPosition = playerFunctions.getCurrentTime();
            if (contentDetails.programType === PROGRAM_TYPES.MOVIE && lastWatchedPosition >= contentDetails.durSec - removeFromCwTime.movies) {
                removeSyncData();
            }
            else if (contentDetails.programType === PROGRAM_TYPES.EPISODE && lastWatchedPosition >= contentDetails.durSec - removeFromCwTime.tvshows) {
                if (nextEpisodeDetails != null && nextEpisodeDetails.url) {
                    contentDetailsFetchAction(nextEpisodeDetails.details.refId);
                }
                removeSyncData();
            }
        }

        removeListenerFromEvent('beforeunload', addSyncData);
        removeListenerFromEvent('blur', addAndSyncData);
        playerEvents.emitter.removeListener(PLAYER_EVENTS_LIST.PAUSE, addSyncData);
        playerEvents.emitter.removeListener(PLAYER_EVENTS_LIST.PLAYING, addSyncData);
        playerEvents.emitter.removeListener(PLAYER_EVENTS_LIST.LOADSTART, onLoadStart);
        playerEvents.emitter.removeListener(PLAYER_EVENTS_LIST.FIRST_PLAY, onPlayStart);
        playerEvents?.emitter?.removeListener(PLAYER_EVENTS_LIST.FULL_SCREEN_CHANGE, () => addSyncData);
        if (unSubscribeChangeAudio) {
            unSubscribeChangeAudio.unsubscribe();
        }
        if (unSubscribeChangeSubtitle) {
            unSubscribeChangeSubtitle.unsubscribe();
        }
        const lastWatchedPosition = playerFunctions.getCurrentTime();
        if (contentDetails.programType === PROGRAM_TYPES.MOVIE && lastWatchedPosition >= contentDetails.durSec - removeFromCwTime.movies) {
            removeSyncData();
        }
        // else if (contentDetails.programType === PROGRAM_TYPES.EPISODE && lastWatchedPosition >= contentDetails.durSec - removeFromCwTime.tvshows) {
        //     removeSyncData();
        // }
        if (!isWeb && unlistenHistoryChange) {
            unlistenHistoryChange();
        }
    };

    return dispose;
}
