import React, { lazy } from 'react';
import { PropTypes } from 'prop-types';
// import ShakaPlayerComponent from '../components/shaka-player/ShakaPlayerComponent';
// import VideoJsComponent from '../components/videojs-player/VideoJsComponent';
import { PLAYBACK_TYPES } from '@airtel-tv/constants/PlayerConsts';
// import HlsPlayerComponent from '../components/hls-player/HlsPlayerComponent';


const AsyncShakaPlayerComponent = (
    lazy(() => (
        import('../components/shaka-player/ShakaPlayerComponent')
    ))
);

const AsyncVideoJsComponent = (
    lazy(() => (
        import('../components/videojs-player/VideoJsComponent')
    ))
);

const AsyncHlsPlayerComponentV2 = (
    lazy(() => (
        import('../components/hls-player/HlsPlayerComponentV2')
    ))
);

const AsyncHlsPlayerComponent = (
    lazy(() => (
        import('../components/hls-player/HlsPlayerComponent')
    ))
);


// Return player on the base of content provider
const PlayerFactory = (playerConfig) => {
    const { contentDetails: { cpId } } = playerConfig;
    // removing hls player as sony is not required
    // if (isLiveStream && playbackAdsData && playbackAdsData.imaEnabled) {
    //     return (
    //         <AsyncHlsPlayerComponent
    //             browserNotSupported={browserNotSupported}
    //             reload={reload}
    //             playerEventsInitialized={playerEventsInitialized}
    //             isLiveStream={isLiveStream}
    //             playbackConfig={playbackConfig}
    //             playbackAdsData={playbackAdsData}
    //             playbackDetails={playbackDetails}
    //             ControlBar={ControlBar}
    //             contentDetails={contentDetails}
    //         />
    //     );
    // }
    if (playerConfig.playbackConfig?.daiAssetKey && (playerConfig?.HLS_DAI_SUPPORTED_PLATFORMS || []).includes(cpId)) {
        return (
            <AsyncHlsPlayerComponentV2
                {...playerConfig}
            />
        );
    }
    if ((playerConfig.playbackType === PLAYBACK_TYPES.MPD || !playerConfig.playbackConfig.isDrm || playerConfig.playbackConfig.daiAssetKey)) {
        return (
            <AsyncShakaPlayerComponent
                {...playerConfig}
            />
        );
    }

    return (
        <AsyncVideoJsComponent
            {...playerConfig}
        />
    );
};

PlayerFactory.propTypes = {
    playbackType: PropTypes.string.isRequired,
    isLiveStream: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    playbackAdsData: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    playbackDetails: PropTypes.object.isRequired,
    browserNotSupported: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
    playerEventsInitialized: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    playbackConfig: PropTypes.object.isRequired,
    ControlBar: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    contentDetails: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    langInfo: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    adsData: PropTypes.object.isRequired,
    playTrailer: PropTypes.bool,

};

export default PlayerFactory;
