import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const ContentDescriptor = (props) => {
    const { contentDetails, showContentDescriptor } = props;
    const { contentDescriptors, ageRating } = contentDetails;
    // const modifiedContentDescriptors = useMemo(
    //     () => contentDescriptors.split(',').map((it, index) => {
    //         const capitalize = index === 0 ? 0 : 1;
    //         console.log('ppp', it, (it[capitalize].toUpperCase() + it.slice(capitalize + 1)));
    //         return (it[capitalize].toUpperCase() + it.slice(capitalize + 1));
    //     }).join(', '),
    //     [
    //         contentDescriptors,
    //     ],
    // );
    const descriptorAnimationClass = showContentDescriptor ? 'show-content-discriptor' : showContentDescriptor === false ? 'hide-content-discriptor': '';
    //console.log("ppp", descriptorAnimationClass, showContentDescriptor, props);
    return (
        <div
            className={`hanging-container ${descriptorAnimationClass}`}
        >
            <div className="classification-tag">
                <div className="red-border" />
                <div className="content-display">
                    {ageRating ? <p className="classification-title text-16 text-bold line-height-24 text-ls-24">{`Rated ${ageRating}`}</p> : null}
                    <p className="classification-text text-14 text-normal line-height-20 text-ls-20">{contentDescriptors}</p>
                </div>
            </div>
        </div>
    );
};

export default ContentDescriptor;

ContentDescriptor.propTypes = {
    contentDetails: PropTypes.object.isRequired,
    showContentDescriptor: PropTypes.bool.isRequired,
};

ContentDescriptor.defaultProps = {};
