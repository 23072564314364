import { getDate } from '@airtel-tv/utils/GlobalUtil';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import RequestUtil from '@airtel-tv/utils/RequestUtil';
import { LocationUtil } from '@airtel-tv/utils/LocationUtil';
import { base64ToArrayBuffer } from '@airtel-tv/utils/WindowUtil';
import { ENCRYPTION_TYPES } from '@airtel-tv/constants/PlaybackConsts';
import { REQUEST_METHODS } from '@airtel-tv/constants/GlobalConst';

export default class FairplayCertificateParser {
    static async agnosticCertificateRequestParserForVideojs(
        certificateUri,
        headers,
        headerPayloadKey,
        certificatePayloadKey,
        secretKey,
        secretData,
        secretEncryptionType,
        certificateMethod = REQUEST_METHODS.GET,
    ) {
        let dppSignedHeadersEncrypted = null;

        // encrypt data if there is any data to encrypt
        if (headerPayloadKey && secretData && secretKey && secretEncryptionType) {
            if (secretEncryptionType === ENCRYPTION_TYPES.HMAC) {
                dppSignedHeadersEncrypted = CryptoUtil.Sha256Encryption({
                    key: secretKey,
                    value: secretData,
                });
            }
        }

        let requestHeaders = {};
        if (headers) {
            requestHeaders = { ...headers };
            if (headerPayloadKey) {
                requestHeaders[headerPayloadKey] = dppSignedHeadersEncrypted;
            }
        }

        try {
            const response = await RequestUtil[certificateMethod]({
                url: certificateUri,
                headers: requestHeaders,
                options: {
                    responseType: 'arraybuffer',
                },
            });

            if (certificatePayloadKey) {
                const certificateString = response[certificatePayloadKey];
                const certificateBuff = new Uint8Array(base64ToArrayBuffer(certificateString));
                return certificateBuff;
            }
            if (response) {
                return new Uint8Array(response);
            }

            console.debug('Certificate response failed');
            throw new Error('Certificate response failed');
        }
        catch (error) {
            console.error('Certificate response failed', error);
            throw error;
        }
    }

    // static async AltBalajiCertificateRequestParser({
    //     certificateUri, token, key,
    // }) {
    //     const timeStamp = getDate(new Date(), 'YYYYMMDDHHmmss');

    //     const urlSchema = LocationUtil.parseUrl(certificateUri);
    //     const queryStr = LocationUtil.objectToQueryParams(urlSchema.query);

    //     const dppSignedHeaders = `${timeStamp}|${queryStr}|{}`;
    //     const dppSignedHeadersEncrypted = CryptoUtil.Sha256Encryption({
    //         key,
    //         value: dppSignedHeaders,
    //     });

    //     try {
    //         const response = await RequestUtil.POST({
    //             url: certificateUri,
    //             headers: {
    //                 'content-type': 'application/json',
    //                 Authorization: token,
    //                 'X-Dpp-Date': timeStamp,
    //                 'X-Dpp-SignedHeaders': dppSignedHeadersEncrypted,
    //             },
    //         });

    //         if (response.certificate) {
    //             const certificateString = response.certificate;
    //             const certificateBuff = new Uint8Array(base64ToArrayBuffer(certificateString));
    //             return certificateBuff;
    //         }

    //         console.debug('Certificate response failed');
    //         throw new Error('Certificate response failed');
    //     }
    //     catch (error) {
    //         console.error(error);
    //         throw error;
    //     }
    // }
}
