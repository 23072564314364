// Return player on the base of content provider
import { PLAYER_TYPES } from '@airtel-tv/constants/PlayerConsts';
import ShakaPlayerEvents from '../events/ShakaPlayerEvents';
import VideoJsEvents from '../events/VideoJsEvents';
import LitePlayerEvents from '../events/LitePlayerEvents';
import HLSEvents from '../events/HLSEvents';
import HLSEventsV2 from '../events/HLSEventsV2';

const getPlayerEvents = ({
    playerType, player, videoTag, playerFunctions,
}) => {
    let playerEvents = null;
    switch (playerType) {
        case PLAYER_TYPES.VIDEOJS_PLAYER:
            playerEvents = new VideoJsEvents({
                player,
                videoTag,
                playerFunctions,
            });
            break;

        case PLAYER_TYPES.SHAKA_PLAYER:
            playerEvents = new ShakaPlayerEvents({
                player,
                videoTag,
                playerFunctions,
            });
            break;
        case PLAYER_TYPES.SHAKA_HLS_PLAYER:
            playerEvents = new HLSEventsV2({
                player,
                videoTag,
                playerFunctions,
            });
            break;
        case PLAYER_TYPES.MINI_PLAYER:
            playerEvents = new LitePlayerEvents({
                player,
                videoTag,
                playerFunctions,
            });
            break;
        case PLAYER_TYPES.HLS_PLAYER:
            playerEvents = new HLSEvents({
                player,
                videoTag,
                playerFunctions,
            });
            break;

        default:
            playerEvents = null;
    }

    return playerEvents;
};

export default getPlayerEvents;
