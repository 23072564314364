// Return player on the base of content provider
import { PLAYER_TYPES } from '@airtel-tv/constants/PlayerConsts';
import VideoJsControls from '../controls/VideoJsControls';
import ShakaPlayerControls from '../controls/ShakaPlayerControls';
import HLSControlsV2 from '../controls/HLSControlsV2';
import { LitePlayerControls } from '../../../web/src/modules/lite-player/LitePlayerControlFunctions';
import HLSControls from '../controls/HLSControls';

const getPlayerControls = ({
    playerType, player, videoTag, isLiveStream, contentDetails,
}) => {
    let playerControls = null;

    switch (playerType) {
        case PLAYER_TYPES.VIDEOJS_PLAYER:
            playerControls = new VideoJsControls(player, videoTag, isLiveStream, contentDetails);
            break;
        case PLAYER_TYPES.SHAKA_HLS_PLAYER:
            playerControls = new HLSControlsV2(player, videoTag, isLiveStream);
            break;
        case PLAYER_TYPES.SHAKA_PLAYER:
            playerControls = new ShakaPlayerControls(player, videoTag, isLiveStream, contentDetails);
            break;

        case PLAYER_TYPES.MINI_PLAYER:
            playerControls = new LitePlayerControls(player, videoTag, isLiveStream);
            break;
        case PLAYER_TYPES.HLS_PLAYER:
            playerControls = new HLSControls(player, videoTag, isLiveStream);
            break;
        default:
            playerControls = null;
    }

    return playerControls;
};

export default getPlayerControls;
