import RequestUtil from '@airtel-tv/utils/RequestUtil';
import {
    getDate, replaceAll, convertToJSON,
} from '@airtel-tv/utils/GlobalUtil';
import { CryptoUtil } from '@airtel-tv/utils';
import { LocationUtil } from '@airtel-tv/utils';
// import Config from '../../../config';
import { base64ToArrayBuffer, arrayBufferToBase64, stringToBase64 } from '@airtel-tv/utils/WindowUtil';
import { ENCRYPTION_TYPES, PAYLOAD_FORMATS } from '@airtel-tv/constants/PlaybackConsts';
import XMLParser from './XMLParser';

export default class FairplayLicenseParser {
    static async agnosticLicenseRequestParserForVideojs(
        payload,
        licenseHeaders,
        body,
        licensePayloadKey,
        licenseHeaderPlaceholderKey,
        licenseHeaderSecretKey,
        licenseHeaderSecretData,
        licenseHeaderSecretEncryptionType,
        licenseHeaderSecretDataPlaceholderKey,
        licenseResponsePayloadKey,
        licensePayloadFormat,
        licenseUri,
        licensePayloadType,
        licenseResponsePayloadType,
    ) {
        let formattedPayload = payload;
        if (licensePayloadFormat === PAYLOAD_FORMATS.BASE64) {
            formattedPayload = arrayBufferToBase64(payload);
        }
        let postBody = body;

        if (licensePayloadType === 'object') {
            if (licensePayloadKey) {
                postBody[licensePayloadKey] = formattedPayload;
            }
        }
        else if (licensePayloadType === 'string') {
            if (licensePayloadKey && typeof formattedPayload === 'string') {
                postBody = replaceAll(postBody, licensePayloadKey, formattedPayload);
            }
            else {
                postBody = formattedPayload;
            }
        }

        let dppSignedHeader = licenseHeaderSecretData;
        if (licenseHeaderSecretDataPlaceholderKey) {
            dppSignedHeader = replaceAll(dppSignedHeader, licenseHeaderSecretDataPlaceholderKey, formattedPayload);
        }

        let dppSignedHeadersEncrypted = null;

        if (licenseHeaderSecretEncryptionType === ENCRYPTION_TYPES.HMAC) {
            dppSignedHeadersEncrypted = CryptoUtil.Sha256Encryption({
                key: licenseHeaderSecretKey,
                value: dppSignedHeader,
            });
        }

        const requestHeaders = licenseHeaders;
        if (licenseHeaderPlaceholderKey) {
            requestHeaders[licenseHeaderPlaceholderKey] = dppSignedHeadersEncrypted;
        }

        const options = {};

        // if there is key in response then the response type is not array buffer else it is
        if (!licenseResponsePayloadKey) {
            options.responseType = 'arraybuffer';
        }

        try {
            let response = await RequestUtil.POST({
                url: licenseUri,
                data: postBody,
                headers: requestHeaders,
                options,
            });

            if (licenseResponsePayloadType === 'xml') {
                response = XMLParser.xmlToJSON(response);
            }
            if (licenseResponsePayloadKey) {
                if (typeof response === 'string') {
                    response = convertToJSON(response);
                }

                const licenseString = response[licenseResponsePayloadKey];
                const licenseBuff = base64ToArrayBuffer(licenseString);
                return licenseBuff;
            }
            if (response) {
                try {
                    if (typeof response === 'string') {
                        const licenseBuff = base64ToArrayBuffer(response);
                        return licenseBuff;
                    }
                    return response;
                }
                catch (error) {
                    return response;
                }
            }

            // TODO: Handle error
            console.error('License response failed');
            throw new Error('License response failed');
        }
        catch (error) {
            console.error(error);
            throw error;
        }
    }

    // static async Zee5LicenseRequestParser({
    //     licenseUri, payload, token,
    // }) {
    //     try {
    //         const spc = stringToBase64(payload);
    //         const body = `spc=${spc}&assetId=${Config.fairplayConfig.zee5.defaultAssetId}`;

    //         const response = await RequestUtil.POST({
    //             url: licenseUri,
    //             data: body,
    //             headers: {
    //                 'Content-type': 'plain/text',
    //                 customData: token,
    //             },
    //         });

    //         const buffer = base64ToArrayBuffer(response);
    //         return buffer; // return the buffer
    //     }
    //     catch (error) {
    //         console.error(error);
    //         // TODO: handle error
    //         throw error;
    //     }
    // }

    // static async AltBalajiLicenseRequestParser({
    //     licenseUri, payload, token, ticket, key,
    // }) {
    //     const timeStamp = getDate(new Date(), 'YYYYMMDDHHmmss');

    //     const urlSchema = LocationUtil.parseUrl(licenseUri);
    //     const queryStr = LocationUtil.objectToQueryParams(urlSchema.query);

    //     const base64Payload = arrayBufferToBase64(payload);
    //     const postBody = {
    //         spc: base64Payload,
    //         ticket,
    //     };

    //     const postBodyStringed = JSON.stringify(postBody);
    //     const dppSignedHeaders = `${timeStamp}|${queryStr}|${postBodyStringed}`;
    //     const dppSignedHeadersEncrypted = CryptoUtil.Sha256Encryption({
    //         key,
    //         value: dppSignedHeaders,
    //     });

    //     try {
    //         const response = await RequestUtil.POST({
    //             url: licenseUri,
    //             data: postBody,
    //             headers: {
    //                 'content-type': 'application/json',
    //                 Authorization: token,
    //                 'X-Dpp-Date': timeStamp,
    //                 'X-Dpp-SignedHeaders': dppSignedHeadersEncrypted,
    //             },
    //         });

    //         if (response.licence) {
    //             const licenseString = response.licence;
    //             const licenseBuff = base64ToArrayBuffer(licenseString);
    //             return licenseBuff;
    //         }

    //         // TODO: Handle error
    //         console.error('License response failed');
    //         throw new Error('License response failed');
    //     }
    //     catch (error) {
    //         console.error(error);
    //         throw error;
    //     }
    // }
}
