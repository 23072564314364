import lodashGet from 'lodash/get';
import { Component } from 'react';
import { SHAKA_PLAYER_EVENTS, PLAYER_EVENTS_LIST, PLAYER_TYPES } from '@airtel-tv/constants/PlayerConsts';
import { isOnline, raiseEvent } from '@airtel-tv/utils/WindowUtil';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import { DateTime, DeviceTypeUtil } from '@airtel-tv/utils';

export default class ShakaPlayerControls extends Component {
    constructor(player, videoTag, isLiveStream, contentDetails, props) {
        super(props);
        this.player = player;
        this.videoTag = videoTag;
        this.contentDetails = contentDetails;
        this.cpXstreamLangCodeMap = contentDetails?.cpXstreamLangCodeMap || [];
        this.liveDuration = 0;
        this.changedAudio = '';
        this.previousVolumeValue = 1;
        this.playerType = PLAYER_TYPES.SHAKA_PLAYER;
        this.playerVersion = '';
        this.selectedSubtitle = '';
        this.licenseDownloadTime = 0;
        this.manifestDownloadTime = 0;
        this.isWeb = DeviceTypeUtil.isWeb()
        this.state = {
            changedAudio: '',
        };

        if (isLiveStream) {
            const setLiveDuration = () => {
                if (!this.liveDuration || this.liveDuration === Infinity) {
                    this.liveDuration = this.getDVRDurationInSeconds();
                    this.videoTag?.removeEventListener(SHAKA_PLAYER_EVENTS.LOADED, setLiveDuration);
                }
            };
            if (this.videoTag !== null) {
                this.videoTag.addEventListener(SHAKA_PLAYER_EVENTS.LOADED, setLiveDuration);
            }
        }
    }

    setPlayerVersion = (version) => this.playerVersion = version;

    setSelectedSubtitle = (sub) => this.selectedSubtitle = sub;

    getSelectedSubtitle = () => this.selectedSubtitle;

    setManifestDownloadTime = (time) => {
        this.manifestDownloadTime = time;
    }

    setLicenseDownoadTime = (time) => {
        this.licenseDownloadTime += time;
    }
    play = () => {
        if (!this.videoTag || !this.player) {
            return false;
        }

        // TODO: check player has buffered connection
        if (!isOnline() && !this.isWeb) {
            const error = { code: ERROR_CODES.LOCAL1003 };
            raiseEvent(this.videoTag, SHAKA_PLAYER_EVENTS.ERROR, error);
        }

        try {
            return this.videoTag.play();
        }
        catch (error) {
            // console.error(error);
            return false;
        }
    };

    getPeriodDuration = () => {
        const manifest = this.player.getManifest();

        if(manifest) {
            const { periods } = manifest;
            if(periods && periods.length >= 2) {
                return -periods[1].startTime;
            }
        }
        return 0;
    }

    pause = () => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        return this.videoTag.pause();
    };

    emitEvent = (event) => {
        if(!event) 
            return

        raiseEvent(this.videoTag, event, true);
    }

    toggleFullScreen = () => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        if (!this.videoTag.fullscreenElement) {
            return this.videoTag.requestFullscreen();
        }

        return this.videoTag.exitFullscreen();
    };


    isMute = () => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        return this.videoTag.muted || !this.getVolumeLevel();
    }

    getVolumeLevel = () => {
        if (!this.videoTag || !this.player) {
            return 100;
        }
        return this.videoTag.volume;
    }

    toggleVolume = (isMobile) => {
        if (!this.videoTag || !this.player) {
            return false;
        }

        if (this.isMute()) {
            this.videoTag.muted = false;
            this.volumeChange(this.getVolumeLevel() ? this.getVolumeLevel() : this.previousVolumeValue, false, isMobile);
            //raiseEvent(this.videoTag, SHAKA_PLAYER_EVENTS.UNMUTE_V2)
        }
        else {
            this.previousVolumeValue = this.getVolumeLevel();
            this.volumeChange(0, false, isMobile);
            this.videoTag.muted = true;
        }
        return this.videoTag.muted;
    };

    volumeChange = (volumeLevel, isSetFromKeyboard, isMobile) => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        const prevMute = this.isMute();
        const tempVal = Number.parseFloat(volumeLevel).toFixed(2);
        this.videoTag.volume = Math.max(0, Math.min(tempVal, 1));
        if (this.isMute()) {
            if(this.videoTag.volume === 0) {
                raiseEvent(this.videoTag, SHAKA_PLAYER_EVENTS.MUTE_V2);
            }
            this.videoTag.muted = false;
        } else if(prevMute) {
            raiseEvent(this.videoTag, SHAKA_PLAYER_EVENTS.UNMUTE_V2);
        }

        if (isSetFromKeyboard) {
            raiseEvent(this.videoTag, SHAKA_PLAYER_EVENTS.VOLUME_CHANGE, true);
        }
        else if (isMobile) {
            raiseEvent(this.videoTag, SHAKA_PLAYER_EVENTS.VOLUME_CHANGE, false);
        }
        return this.videoTag.currentTime;
    };

    seekBarChange = (seekDuration) => {
        if (!this.videoTag || !this.player) {
            return false;
        }

        const data = {
            newTime: seekDuration,
            previousTime: this.videoTag.currentTime,
        };

        raiseEvent(this.videoTag, SHAKA_PLAYER_EVENTS.SEEK_BAR_CHANGE, data);

        this.videoTag.currentTime = seekDuration;
        return this.videoTag.currentTime;
    };

    replay = () => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        this.videoTag.currentTime = 0;
        return 0;
    };

    //streamBandwidth:  found in stats is same as the value of bandwidth property found in the currently played variant track, it is often referred to as bitrate in analytics helper
    //estimatedBandwidth: is the available bandwidth cacluated by the player
    getBandwidth = () => {
        if (!this.player && this.player.getStats) {
            return 0;
        }
       //console.log(Object.keys(this.player));
       //console.log("zzz", this.player.getStats(), this.player);
        //console.log(this.player.getPlaybackRate(), this.player.getManifest());

        return lodashGet(this.player.getStats(), 'streamBandwidth') || null;
    }

    getTransformedLanguageTracks = () => {
        const tracks = this.player.getVariantTracks().map(track => {
            let updatedTrack = track;
            if(track.language && this.cpXstreamLangCodeMap[track.language]) {
                updatedTrack = {
                    ...track,
                    language: this.cpXstreamLangCodeMap[track.language]
                }
            }
            return updatedTrack;
        });
        return (tracks || []);
    }

    getTransformedAudioLanguages = () => {
        const updatedAudioTracks = (this.player.getAudioLanguages() || []).map(track => {
            if(this.cpXstreamLangCodeMap[track]) {
                track = this.cpXstreamLangCodeMap[track];
            }
            return track;
        });
        return updatedAudioTracks;
    };

    getOriginalLanguageCode = (langCode) => {
       let origionalLanguageCode = Object.keys(this.cpXstreamLangCodeMap).find(item => this.cpXstreamLangCodeMap[item] === langCode);
        if(!origionalLanguageCode){
            origionalLanguageCode = langCode;
        }
        return origionalLanguageCode;
    }

    getRepresentationMeta = () => {
        if(!this.player)
            return {};

        const tracks = this.getTransformedLanguageTracks();
        return tracks.map(track => (
            {
                displayLang: track.label,
                language: track.language,
                width: track.width,
                height: track.height,
                track_resolution: track.width && track.height ? `${track.width}x${track.height}`: '',
                bandwidth: track.bandwidth
            }
        ));
    }

    getCurrentRepresentationIndex = () => {
        if (!this.player) {
            return false;
        }

        const playingBandwidth = this.getBandwidth();

        let count = -1;
        this.player.getVariantTracks().find((representation) => {
            count += 1;
            return representation.bandwidth === playingBandwidth;
        });

        return count;
    }

    getPlayerStats() {
        if (!this.player) {
            return {}
        }

        const stats = this.player.getStats();
        //console.log("stats===>",stats);

        return {
            networkBandwidth: stats.estimatedBandwidth ? stats.estimatedBandwidth/1000: null, //kbps
            streamBandwidth: stats.streamBandwidth ? stats.streamBandwidth/1000: null, //kbps
            licenseTime : this.licenseDownloadTime, //ms
            manifestDownloadTime: this.manifestDownloadTime, //ms
            //licenseTime: stats.licenseTime ? stats.licenseTime *1000 : 0, //ms
            //manifestDownloadTime: stats.manifestTimeSeconds ? stats.manifestTimeSeconds*1000: this.manifestDownloadTime, //in ms
            playTime: stats.playTime ? stats.playTime * 1000 : null, //ms
            loadLatency: stats.loadLatency ? stats.loadLatency * 1000: null,
        }
    }

    getCurrentTrack = () => { //To Do: Add similar implementation in videjs
        const playingBandwidth = this.getBandwidth();
        const track = this.player.getVariantTracks().find((representation) => {
            return representation.bandwidth === playingBandwidth;
        });
        return track;
    }

    getTrackParams = () => {
        const track = this.getCurrentTrack();
        if(!track)
            return {};
        
        return {
            width: track.width,
            height: track.height,
            track_resolution: track.width && track.height ? `${track.width}x${track.height}`: '',
            video_codec: track.videoCodec,
            audio_codec: track.audioCodec,
            frame_rate: track.frameRate,
            bandwidth: track.bandwidth
        }
    }

    getCurrentBufferTime = () => {
        if (!this.player) {
            return 0;
        }

        const bufferList = lodashGet(this.player.getBufferedInfo(), 'total', []);

        if (bufferList.length > 0) {
            const lastBufferedItem = bufferList[bufferList.length - 1];
            return lodashGet(lastBufferedItem, 'end', 0) - lodashGet(lastBufferedItem, 'start', 0); //time returned in seconds
        }

        return 0;
    }

    setAbrFalse = () => {
        let option = this.backupOption ? this.backupOption : {value: 0, noBackup: true};
        this.changeBitrate(option, false)

        //this.changeBitrate(this.backupOption);
        // let representationList = [
        //     ...this.player.getVariantTracks(),
        // ]; // immutable
        // if (representationList.length > 0 && representationList[representationList.length - 1].height > representationList[0].height) {
        //     representationList.reverse();
        // } 
        // let backupRepresentation = representationList.find(item => !item.active)
        // const representation = representationList.find(item => item.active);
        // this.player.configure({ abr: { enabled: false } });
        // this.player.selectVariantTrack(backupRepresentation, true);
        // setTimeout(() => 
        //  {
        //     representation && this.player.selectVariantTrack(representation, true)
        //  }, 1000)
    }

    changeBitrate = (option, userAction = true, persistBuffer = 5) => {
        if(userAction) {
            this.backupOption = option
        }
        const {
            value: bitrate,
        } = option;
        if (!this.videoTag || !this.player) {
            return false;
        }
        let representationList = [
            ...this.player.getVariantTracks(),
        ]; // immutable
        const meta = this.getMeta();
        if(meta.audioTracks && meta.audioTracks.length> 1){
            representationList = representationList.filter(lan => (lan.language === (this.changedAudio !== '' ? this.changedAudio : meta.defaultAudioLanguage)));
        }

        if (!bitrate) {
            this.player.configure({ abr: { enabled: true } });
            this.player.selectVariantTrack(representationList[representationList.length - 1], true) //Just to clear buffer because abr enabled is already true 
            userAction && raiseEvent(this.videoTag, SHAKA_PLAYER_EVENTS.CHANGE_BIT_RATE, null);
            return true;
        }

        // Shaka Player does not changes already buffered bitrate
        this.player.configure({ abr: { enabled: false } });

        if (representationList.length > 0 && representationList[representationList.length - 1].height > representationList[0].height) {
            representationList.reverse();
        } // high should come first

        const closestBitrateObj = (representationList).reduce((prev, curr) => (Math.abs(curr.bandwidth - bitrate) < Math.abs(prev.bandwidth - bitrate) ? curr : prev), 0);

        const rep = representationList.find(repr => repr.bandwidth === bitrate);
            
        if (rep) {
            this.player.selectVariantTrack(rep, true); // 0 is lowest rendition
            //return isEnabled;
        } else {
            if (bitrate === Infinity) {
                this.player.selectVariantTrack(representationList[0], true, persistBuffer); // 0 is lowest rendition
                return false;
            }
            else if (bitrate === -Infinity) {
                this.player.selectVariantTrack(representationList[representationList.length - 1], true, persistBuffer); // last is highest rendition
                return false;
            } else {
                this.player.selectVariantTrack(closestBitrateObj, true, persistBuffer); // 0 is lowest rendition
                return false;
            }
            //return true;
        }
        
        userAction && raiseEvent(this.videoTag, SHAKA_PLAYER_EVENTS.CHANGE_BIT_RATE, option);
        // if (!representationEnabled && representationList && representationList.length) {
        //     this.player.selectVariantTrack(representationList[representationList.length - 1], true); // 0 is lowest rendition
        //     return true;
        // }
        return true;
    };

    changePlaybackRate = (playbackRate) => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        this.videoTag.playbackRate = playbackRate;
        return this.videoTag.playbackRate;
    };

    changeAudio = (lang) => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        // capturing changed audio lang value for changed audios
        this.changedAudio = lang;
        const originalAudioLang = this.getOriginalLanguageCode(lang);
        return this.player.selectAudioLanguage(originalAudioLang);
    }

    showCaptions = (lang) => {
        if (!this.videoTag || !this.player) {
            return;
        }
        if (!lang) {
            this.player.setTextTrackVisibility(false);
            raiseEvent(this.videoTag, PLAYER_EVENTS_LIST.SUBTITLE_TOGGLE, false);
        }
        else {
            this.player.selectTextLanguage(lang);
            this.player.setTextTrackVisibility(true);
            raiseEvent(this.videoTag, PLAYER_EVENTS_LIST.SUBTITLE_TOGGLE, true);
        }
    }

    // shaka player git issue: https://github.com/google/shaka-player/issues/147
    // getActiveTextTrack = () => {
    //     if (this.player) {
    //         const tracks = this.player.getTextTracks();
    //         if (tracks && Array.isArray(tracks)) {
    //             const activeTrack = tracks.find(track => track.active);
    //             return activeTrack.language;
    //         }
    //         return null;
    //     }
    //     return null;
    // }

    languageExistsInTextTrack = (lang) => {
        if (this.player) {
            const allLangs = this.player.getTextLanguages();
            return allLangs.indexOf(lang) > -1;
        }
        return false;
    }

    isTextTrackVisible = () => {
        if (this.player) {
            return this.player.isTextTrackVisible();
        }
        return false;
    }

    forward = (skipTime) => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        const { currentTime } = this.videoTag;
        const seekPoint = currentTime + skipTime;

        const duration = this.getDuration();

        if (duration > currentTime && duration > seekPoint) {
            this.seekBarChange(seekPoint);
        }
        else if (duration < seekPoint && !this.paused()) {
            this.seekBarChange(Math.floor(duration));
            this.pause();
        }

        raiseEvent(this.videoTag, PLAYER_EVENTS_LIST.SEEK_FORWARD, seekPoint);
        return seekPoint;
    }

    backward = (skipTime) => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        const { currentTime } = this.videoTag;
        const seekPoint = currentTime - skipTime;
        if (seekPoint > 0) {
            this.seekBarChange(seekPoint);
        }
        else {
            this.seekBarChange(0);
        }

        raiseEvent(this.videoTag, PLAYER_EVENTS_LIST.SEEK_BACKWARD, seekPoint);
        return seekPoint;
    }


    paused = () => {
        if (!this.videoTag || !this.player) {
            return null;
        }
        return this.videoTag.paused;
    }

    getMeta = () => {
        if (!this.videoTag || !this.player) {
            return false;
        }

        const availableTextTracks = this.player.getTextTracks()
            .filter(track => !!track.language).map(track => track.language);
        const textTracks = [ ...new Set(availableTextTracks)];

        const audioTracks = this.getTransformedAudioLanguages();
        const representationList = this.getTransformedLanguageTracks();
        const defaultAudioLanguage = this.defaultLanguage ? this.defaultLanguage : audioTracks[0];
        const selectedLang = representationList.length ? representationList.find(audio => audio.active)?.language : defaultAudioLanguage;
        const meta = {
            duration: this.getDuration(),
            playbackRate: this.player.getPlaybackRate(),
            qualityLevels: [],
            audioTracks,
            defaultAudioLanguage,
            textTracks,
            selectedLang,
        };
        return meta;
    }

    getCurrentTime = () => {
        if (!this.videoTag) {
            return 0;
        }
        return this.videoTag.currentTime;
    }

    getDuration = () => {
        if (!this.videoTag || !this.player) {
            return 0;
        }
        return this.videoTag.duration;
    }

    getReadyState = () => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        return this.videoTag.readyState;
    }

    dispose = () => {
        window.atvPlayer = undefined;
        if (this.player) {
            // window.player.detach();
            this.player.detach();
        }
        if (this.emitter) {
            this.emitter.removeAllListeners();
        }
    };

    unload = async () => {
        if (this.player) {
            const result = await this.player.unload();
            return result;
        }
        return Promise.reject(new Error('player not found'));
    }

    getCurrentTime = () => this.videoTag.currentTime;


    forwardLive = (skipTime) => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        const { currentTime } = this.videoTag;
        const seekPoint = currentTime + skipTime;

        const duration = this.getLiveCurrentTimeInSeconds();

        if (duration > currentTime && duration > seekPoint) {
            this.seekBarChange(Math.floor(seekPoint));
        }
        else if (duration < seekPoint) {
            this.seekBarChange(Math.floor(duration));
        }

        raiseEvent(this.videoTag, PLAYER_EVENTS_LIST.SEEK_FORWARD, seekPoint);
        return seekPoint;
    }

    backwardLive = (skipTime) => {
        if (!this.videoTag || !this.player) {
            return false;
        }

        const { currentTime } = this.videoTag;

        const liveTime = this.getLiveCurrentTimeInSeconds();
        const least = liveTime - this.liveDuration;

        const seekPoint = currentTime - skipTime;

        if (seekPoint > least) {
            this.seekBarChange(seekPoint);
        }
        else {
            const liveStartPoint = liveTime - Math.floor(this.liveDuration);
            this.seekBarChange(liveStartPoint);
        }

        raiseEvent(this.videoTag, PLAYER_EVENTS_LIST.SEEK_BACKWARD, seekPoint);
        return seekPoint;
    }

    getDVRDurationInSeconds = () => {
        // DO NOT DELETE
        // const startTime = this.player.seekRange().start;
        // const endTime = this.player.seekRange().end;
        // const dvrDuration = (new Date().getTime() - ((this.player.getManifest().presentationTimeline.getPresentationStartTime() * 1000) + (startTime * 1000))) / 1000

        const { start, end } = this.player.seekRange();
        return end - start;
    }

    getLiveDuration = () => this.liveDuration

    getLiveCurrentTimeInSeconds = () => this.player.seekRange().end

    goLive = () => {
        const liveTime = this.getLiveCurrentTimeInSeconds();
        this.seekBarChange(liveTime);
        raiseEvent(this.videoTag, SHAKA_PLAYER_EVENTS.LIVE_BUTTON_CLICK, liveTime);
    }

    isLive = () => {
        const liveTime = this.getLiveCurrentTimeInSeconds();
        const { currentTime } = this.videoTag;
        const timeDiff = Math.floor(liveTime - currentTime);

        // if current time is under min buffer
        if (timeDiff <= this.getSegmentDuration()) {
            return true;
        }
        return false;
    }

    getSegmentDuration = () => this.player.getManifest()?.minBufferTime

    // eslint-disable-next-line react/sort-comp
    behindLiveTime() {
        const currentTime = this.getCurrentTime();
        const { end } = this.player.seekRange();

        if (currentTime === 0 || end === 0) {
            return 0;
        }

        const behindLive = Math.floor(end - currentTime);
        return Math.max(0, behindLive);
    }

    getLiveCurrentTime = () => this.liveDuration - this.behindLiveTime()

    getLivePlaybackUTCTime = () => {
        const currentlyBehindTime = this.behindLiveTime();
        // const playbackCurrentTime = moment().subtract(currentlyBehindTime, 'seconds').toDate();
        const playbackCurrentTime = new DateTime().subtract(currentlyBehindTime);
        return playbackCurrentTime;
    }

    isLiveStream = () => this.player.isLive()

    emitEvent = (eventType, payload) => {
        raiseEvent(this.videoTag, eventType, payload);
    }
}
