import WidevineLicenseParser from '../parsers/WidevineLicenseParser';
import { PLAYBACK_TYPES } from '@airtel-tv/constants/PlayerConsts';

export default ({
    playbackType,
}) => {
    // mpd will always be shaka player. if using videojs for mpd then will need to do more handling
    if (playbackType === PLAYBACK_TYPES.MPD) {
        return WidevineLicenseParser.agnosticLicenseResponseParserForShaka;
    }

    // TODO: handle no license parser
    return null;
};
