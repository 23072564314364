import lodashGet from 'lodash/get';
import { PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';
import { DateTime } from '@airtel-tv/utils';
import LanguageProvider from '../../../web/src/providers/LanguageProvider';

export const getCatchupTvShowDetailContainer = (playbackContentDetails) => {
    const {
        programType,
        title,
        episodesDetails,
    } = playbackContentDetails;
    const {
        airDate,
    } = episodesDetails;
    let videoTitle;
    const formattedDate = new DateTime(airDate)
        .format('dd DD MM');
    if (programType === PROGRAM_TYPES.LIVETVSHOW) {
        videoTitle = `${title}`;
    }
    else {
        videoTitle = `${episodesDetails.tile}`;
    }
    return {
        videoTitle,
        subTitle: formattedDate,
    };
};

export const getSeriesDetailContainerVideoMeta = (playbackContentDetails) => {
    const LANGUAGE = LanguageProvider();
    const title = lodashGet(playbackContentDetails, 'title', '');
    const {
        episodeSeasonNum,
        episodeNum,
        programType,
        tvShowName,
    } = playbackContentDetails;
    const lowerCasedTitle = title.toLowerCase();
    let videoTitle;
    switch (programType) {
        case PROGRAM_TYPES.TVSHOW:
            videoTitle = `${title} - ${LANGUAGE.SEASON} 1 ${LANGUAGE.EPISODE} 1`;
            break;
        case PROGRAM_TYPES.SEASON:
            videoTitle = `${tvShowName} - ${lowerCasedTitle} ${LANGUAGE.EPISODE} 1`;
            break;
        case PROGRAM_TYPES.EPISODE:
            videoTitle = `${tvShowName}`;
            break;
        default:
            videoTitle = '';
    }


    const subTitle = `${LANGUAGE.SEASON_SHORTHAND_TEXT}${episodeSeasonNum} : ${LANGUAGE.EPISODE_SHORTHAND_TEXT}${episodeNum} - ${playbackContentDetails.title ? playbackContentDetails.title : ''}`;

    return {
        videoTitle,
        subTitle,
    };
};

export const getVodDetailContainerVideoMeta = (playbackContentDetails) => {
    const videoTitle = lodashGet(playbackContentDetails, 'title', '');
    const subTitle = '';
    return {
        videoTitle,
        subTitle,
    };
};

export default {
    getCatchupTvShowDetailContainer,
    getSeriesDetailContainerVideoMeta,
};
