export const PlaybackDetailsActions = {
    PLAYBACK_DETAILS_FETCH: 'PLAYBACK_DETAILS_FETCH',
    PLAYBACK_WITHOUT_LOGIN_DETAILS_FETCH: 'PLAYBACK_WITHOUT_LOGIN_DETAILS_FETCH',
    PLAYBACK_DETAILS_FETCH_ERROR: 'PLAYBACK_DETAILS_FETCH_ERROR',
    PLAYBACK_DETAILS_FETCH_COMPLETED: 'PLAYBACK_DETAILS_FETCH_COMPLETED',

    PLAYBACK_DETAILS_EXPIRED: 'PLAYBACK_DETAILS_EXPIRED',
    PLAYBACK_CLEAR_ALL: 'PLAYBACK_CLEAR_ALL',

    PLAYBACK_DETAILS_COOKIES_FETCH: 'PLAYBACK_DETAILS_COOKIES_FETCH',
    PLAYBACK_DETAILS_COOKIES_FETCH_SUCCESS: 'PLAYBACK_DETAILS_COOKIES_FETCH_SUCCESS',
    PLAYBACK_DETAILS_COOKIES_FETCH_ERROR: 'PLAYBACK_DETAILS_COOKIES_FETCH_ERROR',

    SEND_HEART_BEAT: 'SEND_HEART_BEAT',
    SEND_HEART_BEAT_STOP_PLAYBACK: 'SEND_HEART_BEAT_STOP_PLAYBACK',
    ADSMANAGER_REFERENCE: 'ADSMANAGER_REFERENCE',
    SHOW_PREROLL_ADS: 'SHOW_PREROLL_ADS',
    SHOW_POSTROLL_ADS: 'SHOW_POSTROLL_ADS',
    ADS_PLAYING_FLAG: 'ADS_PLAYING_FLAG',
    SDK_ADS_PLAYING_FLAG: 'SKD_ADS_PLAYING_FLAG',
    PLAYING_MIDROLL_ADS: 'PLAYING_MIDROLL_ADS',
    PREROLL_ADS_PLAYING_FLAG: 'PREROLL_ADS_PLAYING_FLAG',
    SKIPPABLE: 'SKIPPABLE',
    TOGGLE_FULL_SCREEN: 'TOGGLE_FULL_SCREEN',
    ADS_CLICK_LEARN_MORE: 'ADS_CLICK_LEARN_MORE',
    ADS_PAUSED: 'ADS_PAUSED',
    CANNOT_PLAY_VIDEO: 'CANNOT_PLAY_VIDEO',
    CONCURRENT_PLAY_LIMIT_ERROR: 'CONCURRENT_PLAY_LIMIT_ERROR',
    CLOSE_PLAYER: 'CLOSE_PLAYER',
    RESET_PLAYBACK_DETAILS: 'RESET_PLAYBACK_DETAILS',
    APP_IN_APP_HANDLING: 'APP_IN_APP_HANDLING',
};

export const playbackDetailsFetchAction = payload => ({
    type: PlaybackDetailsActions.PLAYBACK_DETAILS_FETCH,
    ...payload,
});

export const getPrerollAdsPlaying = payload => ({
    type: PlaybackDetailsActions.PREROLL_ADS_PLAYING_FLAG,
    ...payload,
});

export const adsPlaying = payload => ({
    type: PlaybackDetailsActions.ADS_PLAYING_FLAG,
    ...payload,
});

export const setSdkAdsPlaying = payload => ({
    type: PlaybackDetailsActions.SDK_ADS_PLAYING_FLAG,
    ...payload,
});

export const adsClickedLearMore = payload => ({
    type: PlaybackDetailsActions.ADS_CLICK_LEARN_MORE,
    ...payload,
});

export const isSkippable = payload => ({
    type: PlaybackDetailsActions.SKIPPABLE,
    ...payload,
});

export const getAdPausedStatus = payload => ({
    type: PlaybackDetailsActions.ADS_PAUSED,
    ...payload,
});

export const getAdsManagerReference = payload => ({
    type: PlaybackDetailsActions.ADSMANAGER_REFERENCE,
    ...payload,
});

export const getShowPreRollAds = payload => ({
    type: PlaybackDetailsActions.SHOW_PREROLL_ADS,
    ...payload,
});

export const getMidRollAdsPlaying = payload => ({
    type: PlaybackDetailsActions.PLAYING_MIDROLL_ADS,
    ...payload,
});

export const playbackDetailsFetchCompletedAction = payload => ({
    type: PlaybackDetailsActions.PLAYBACK_DETAILS_FETCH_COMPLETED,
    ...payload,
});

export const playbackDetailsFetchErrorAction = payload => ({
    type: PlaybackDetailsActions.PLAYBACK_DETAILS_FETCH_ERROR,
    ...payload,
});

export const playbackDetailsExpiredAction = payload => ({
    type: PlaybackDetailsActions.PLAYBACK_DETAILS_EXPIRED,
    ...payload,
});

export const playbackClearAllAction = () => ({
    type: PlaybackDetailsActions.PLAYBACK_CLEAR_ALL,
});

export const playbackDetailsCookiesFetchAction = payload => ({
    type: PlaybackDetailsActions.PLAYBACK_DETAILS_COOKIES_FETCH,
    ...payload,
});

export const playbackDetailsFetchCookiesCompletedAction = payload => ({
    type: PlaybackDetailsActions.PLAYBACK_DETAILS_COOKIES_FETCH_SUCCESS,
    ...payload,
});

export const sendHeartbeatAction = payload => ({
    type: PlaybackDetailsActions.SEND_HEART_BEAT,
    ...payload,
});

export const heartBeatStopPlayback = payload => ({
    type: PlaybackDetailsActions.SEND_HEART_BEAT_STOP_PLAYBACK,
    ...payload,
});

export const playbackWithoutLoginDetailsFetchAction = payload => ({
    type: PlaybackDetailsActions.PLAYBACK_WITHOUT_LOGIN_DETAILS_FETCH,
    ...payload,
});

export const playbackErrorPopUp = payload => ({
    type: PlaybackDetailsActions.CANNOT_PLAY_VIDEO,
    ...payload,
});

export const getShowPostRollAds = payload => ({
    type: PlaybackDetailsActions.SHOW_POSTROLL_ADS,
    ...payload,
});

export const toggleFullScreen = payload => ({
    type: PlaybackDetailsActions.TOGGLE_FULL_SCREEN,
    ...payload,
});

export const concurrentPlayLimitError = payload => ({
    type: PlaybackDetailsActions.CONCURRENT_PLAY_LIMIT_ERROR,
    ...payload,
});


export const closePlayer = payload => ({
    type: PlaybackDetailsActions.CLOSE_PLAYER,
    ...payload,
});

export const resetPlaybackDetails = () => ({
    type: PlaybackDetailsActions.RESET_PLAYBACK_DETAILS,
});

export const appInAppHandling = payload => ({
    type: PlaybackDetailsActions.APP_IN_APP_HANDLING,
    ...payload,
});

export default {
    playbackDetailsFetchAction,
    getAdsManagerReference,
    getShowPreRollAds,
    getMidRollAdsPlaying,
    getPrerollAdsPlaying,
    isSkippable,
    getAdPausedStatus,
    playbackWithoutLoginDetailsFetchAction,
    playbackDetailsFetchCompletedAction,
    playbackDetailsFetchErrorAction,
    playbackDetailsExpiredAction,
    playbackClearAllAction,
    adsPlaying,
    adsClickedLearMore,

    PlaybackDetailsActions,

    playbackDetailsCookiesFetchAction,
    playbackDetailsFetchCookiesCompletedAction,

    sendHeartbeatAction,
    heartBeatStopPlayback,
    playbackErrorPopUp,
    toggleFullScreen,
    concurrentPlayLimitError,
    closePlayer,
    appInAppHandling,
};
