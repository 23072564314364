import lodashGet from 'lodash/get';
import { KEY_CODES, TIZEN_KEYCODES, } from '@airtel-tv/constants/GlobalConst';
import {
    addListenerToEvent, isDocumentExists, getDocumentBody, removeListenerFromEvent,
} from '@airtel-tv/utils/WindowUtil';
import { PLAYER_CONFIG, CENTER_ICONS, CONTROL_BAR_EVENTS, PLAYER_EVENTS_LIST } from '@airtel-tv/constants/PlayerConsts';
import { STREAM_TYPES } from '@airtel-tv/constants/BrowserConst';
import { isWeb } from '../util/PlaybackUtility';
import { StoreUtil } from '@airtel-tv/utils';


export default class PlayerKeyboardControls {
    constructor({
        playerFunctions = null,
        onPlayerFunctionsNotFound = null,
        forwardSkipDuration = 0,
        backwardSkipDuration = 0,
        deviceUtil,
        streamType = STREAM_TYPES.VOD,
        controlBarEventEmitter,
        playerEventsEmitter,
    }) {
        this.playerFunctions = playerFunctions;
        this.onPlayerFunctionsNotFound = onPlayerFunctionsNotFound;
        this.controlBarEventEmitter = controlBarEventEmitter;

        this.forwardSkipDuration = forwardSkipDuration;
        this.backwardSkipDuration = backwardSkipDuration;
        this.playerEventsEmitter = playerEventsEmitter;
        if (isWeb()) {
            this.volumeKeys = {
                [KEY_CODES.UP_ARROW]: {
                    volumeChange: PLAYER_CONFIG.VOLUME_INCREASE_GAP,
                },
                [KEY_CODES.DOWN_ARROW]: {
                    volumeChange: PLAYER_CONFIG.VOLUME_DECREASE_GAP,
                },
            };
        } else {
            this.volumeKeys = {
                [TIZEN_KEYCODES.UP_ARROW]: {
                    volumeChange: PLAYER_CONFIG.VOLUME_INCREASE_GAP,
                },
                [TIZEN_KEYCODES.DOWN_ARROW]: {
                    volumeChange: PLAYER_CONFIG.VOLUME_DECREASE_GAP,
                },
            };
        }
        this.holdSeek = false;
        this.isWeb = isWeb();

        this.deviceUtil = deviceUtil;
        this.streamType = streamType;
    }

    checkKeyboardControlsEnabled = () => {
        const store = StoreUtil?.getStore();
        const notify = lodashGet(store?.getState(), 'notify', {});
        const enableKeyboardControls = lodashGet(notify, 'enableKeyboardControls', true);
        if (!enableKeyboardControls) {
            return false;
        }
        return true;
    };

    seekBackward = (e) => {
        e.preventDefault();
        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound();
            return;
        }

        if (!this.backwardSkipDuration) {
            return;
        }

        if (this.streamType === STREAM_TYPES.VOD) {
            this.playerFunctions.backward(this.backwardSkipDuration - 1);
        }
        else {
            this.playerFunctions.backwardLive(this.backwardSkipDuration);
        }
    }

    seekForward=(e = {}) => {
        e.preventDefault();
        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound();
            return;
        }

        if (!this.forwardSkipDuration) {
            return;
        }
        if (this.streamType === STREAM_TYPES.VOD) {
            this.playerFunctions.forward(this.forwardSkipDuration - 1);
        }
        else {
            this.playerFunctions.forwardLive(this.backwardSkipDuration);
        }
    }

    volumeUpdate = (e) => {
        if (!this.playerFunctions) {
            this.onPlayerFunctionsNotFound();
            return;
        }

        this.playerFunctions.volumeChange(this.playerFunctions.getVolumeLevel() + (!this.isWeb ? this.volumeKeys[e.keyCode].TIZEN_KEYCODESvolumeChange : this.volumeKeys[e.keyCode].volumeChange), true);
    }

    keyupTv = (e) => {
        const store = StoreUtil?.getStore();
        const notify = lodashGet(store?.getState(), 'notify', {});
        const enableKeyboardControls = lodashGet(notify, 'enableKeyboardControls', true);

        if (!window.isSdkAdsPlaying && enableKeyboardControls && lodashGet(document, 'activeElement.id', '') === 'seekbar') {
            if (e.keyCode === TIZEN_KEYCODES.LEFT_ARROW || e.keyCode === TIZEN_KEYCODES.MEDIA_REWIND_BUTTON) {
                if (this.holdSeek) {
                    this.playerEventsEmitter.emit(PLAYER_EVENTS_LIST.SEEK_TO_CURRENT_TIME, -1);
                }
                this.holdSeek = false;
            }
            else if (e.keyCode === TIZEN_KEYCODES.RIGHT_ARROW || e.keyCode === TIZEN_KEYCODES.MEDIA_FORWARD_BUTTON) {
                if (this.holdSeek) {
                    this.playerEventsEmitter.emit(PLAYER_EVENTS_LIST.SEEK_TO_CURRENT_TIME, 1);
                }
                this.holdSeek = false;
            }
        }
        this.holdSeek = false;
    }

    keydownTv = (e) => {
        if (lodashGet(document, 'activeElement.id', '') !== 'seekbar' || window.isSdkAdsPlaying) {
            return;
        }

        if ((e.keyCode === TIZEN_KEYCODES.DOWN_ARROW || e.keyCode === TIZEN_KEYCODES.UP_ARROW)
            && e.target === getDocumentBody()) {
            e.preventDefault();
        }
        if (e.keyCode === TIZEN_KEYCODES.OK_BUTTON && !this.holdSeek) {
            e.preventDefault();
            if (!this.playerFunctions) {
                this.onPlayerFunctionsNotFound();
                return;
            }

            if (this.playerFunctions.paused()) {
                // play
                this.playerFunctions.play();
                this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.CENTER_ICON_DISPLAY,
                    { option: CENTER_ICONS.PLAY });
            }
            else {
                // pause
                this.playerFunctions.pause();
                this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.CENTER_ICON_DISPLAY,
                    { option: CENTER_ICONS.PAUSE });
            }
            this.holdSeek = true;
        }
        else if (e.keyCode === TIZEN_KEYCODES.MEDIA_PAUSE_BUTTON && !this.holdSeek) {
            e.preventDefault();
            if (!this.playerFunctions) {
                this.onPlayerFunctionsNotFound();
                return;
            }
            if (!this.playerFunctions.paused()) {
                // pause
                this.playerFunctions.pause();
                this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.CENTER_ICON_DISPLAY,
                    { option: CENTER_ICONS.PAUSE });
            }
        }
        else if (e.keyCode === TIZEN_KEYCODES.MEDIA_PLAY_BUTTON && !this.holdSeek) {
            e.preventDefault();
            if (!this.playerFunctions) {
                this.onPlayerFunctionsNotFound();
                return;
            }

            if (this.playerFunctions.paused()) {
                // play
                this.playerFunctions.play();
                this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.CENTER_ICON_DISPLAY,
                    { option: CENTER_ICONS.PLAY });
            }
        }
        else if (e.keyCode === TIZEN_KEYCODES.LEFT_ARROW || e.keyCode === TIZEN_KEYCODES.MEDIA_REWIND_BUTTON) {
            if (this.holdSeek) {
                this.playerEventsEmitter.emit(PLAYER_EVENTS_LIST.SEEKTIME, - this.backwardSkipDuration);
            }
            else {
                this.seekBackward(e);
                this.holdSeek = true;
            }
        }
        else if (e.keyCode === TIZEN_KEYCODES.RIGHT_ARROW || e.keyCode === TIZEN_KEYCODES.MEDIA_FORWARD_BUTTON) {
            console.log('seek1');

            if (this.holdSeek) {
                this.holdSeek = true;
                this.playerEventsEmitter.emit(PLAYER_EVENTS_LIST.SEEKTIME, this.forwardSkipDuration);
            }
            else {
                this.seekForward(e);
                this.holdSeek = true;
            }
        }
    }

    keyupWeb = (e) => {
        const store = StoreUtil?.getStore();
        const notify = lodashGet(store?.getState(), 'notify', {});
        const { showSearchNav = true, enableKeyboardControls = true } = notify || {};
        if (enableKeyboardControls && !showSearchNav) {
            if (e.keyCode === KEY_CODES.SPACE) {
                e.preventDefault();
                if (!this.playerFunctions) {
                    this.onPlayerFunctionsNotFound();
                    return;
                }
                if (this.playerFunctions.paused()) {
                    // play
                    this.playerFunctions.play();
                    this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.CENTER_ICON_DISPLAY,
                        { option: CENTER_ICONS.PLAY });
                }
                else {
                    // pause
                    this.playerFunctions.pause();
                    this.controlBarEventEmitter.emit(CONTROL_BAR_EVENTS.CENTER_ICON_DISPLAY,
                        { option: CENTER_ICONS.PAUSE });
                }
            }
            else if (e.keyCode === KEY_CODES.LEFT_ARROW) {
                e.preventDefault();
                if (!this.playerFunctions) {
                    this.onPlayerFunctionsNotFound();
                    return;
                }

                if (!this.backwardSkipDuration) {
                    return;
                }

                if (this.streamType === STREAM_TYPES.VOD) {
                    this.playerFunctions.backward(this.backwardSkipDuration);
                }
                else {
                    this.playerFunctions.backwardLive(this.backwardSkipDuration);
                }
            }
            else if (e.keyCode === KEY_CODES.RIGHT_ARROW) {
                e.preventDefault();
                if (!this.playerFunctions) {
                    this.onPlayerFunctionsNotFound();
                    return;
                }

                if (!this.forwardSkipDuration) {
                    return;
                }
                if (this.streamType === STREAM_TYPES.VOD) {
                    this.playerFunctions.forward(this.forwardSkipDuration);
                }
                else {
                    this.playerFunctions.forwardLive(this.backwardSkipDuration);
                }
            }
        }
    }

    keydownWeb = (e) => {
        if ((e.keyCode === KEY_CODES.SPACE || e.keyCode === KEY_CODES.DOWN_ARROW || e.keyCode === KEY_CODES.UP_ARROW)
            && e.target === getDocumentBody()) {
            e.preventDefault();
        }
        if (e.keyCode === KEY_CODES.DOWN_ARROW || e.keyCode === KEY_CODES.UP_ARROW) {
            if (!this.checkKeyboardControlsEnabled()) {
                return;
            }
            this.volumeUpdate(e);
        }
    };

    install = () => {
        if (!isDocumentExists() || this.deviceUtil.isMobile() || !this.playerFunctions) {
            return false;
        }
        addListenerToEvent('keydown', this.isWeb ? this.keydownWeb : this.keydownTv);
        addListenerToEvent('keyup', this.isWeb ? this.keyupWeb : this.keyupTv);

        return true; // listening keys
    }

    uninstall = () => {
        removeListenerFromEvent('keyup', this.isWeb ? this.keyupWeb : this.keyupTv);
        removeListenerFromEvent('keydown', this.isWeb ? this.keydownWeb : this.keydownTv);
    }
}
