/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-loop-func */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { LanguageProviderUtil } from '@airtel-tv/utils';
import { ARIA_LABEL } from '@airtel-tv/constants';
import { PLAYER_EVENTS_LIST, CONTROL_BAR_EVENTS, VOLUME_BUTTONS } from '@airtel-tv/constants/PlayerConsts';
import { IMAGE_PATHS } from '@airtel-tv/constants/GlobalConst';
import {
    checkFullScreen, exitFullScreen, screenOrientationToLandscape, screenOrientationToNeutral, addListenerToEvent, removeListenerFromEvent, checkIfPlayInPip, closePip,
} from '@airtel-tv/utils/WindowUtil';
import {
    getFullScreen,
} from '@airtel-tv/utils/GlobalUtil';
import './adControlBar.scss';
import PlaybackStorageUtil from '../../PlaybackStorageUtil';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { hidePlayback, showBannerOnPlay } from '../../../../web/src/modules/notify/NotifyActions';


const ELEMENT_WIDTH = {
    THUMBOR_WIDTH: 16,
    VOLUME_BAR_WIDTH: 100,
};

function ClientAdControlBar(props) {
    const {
        playbackDetails, adsPlayingFlag, isSkippable, skipTimeOffset, isAdsPaused, getAdManager, adsClickedLearnMoreFlag,
        deviceUtil,
    } = props;

    const { GO_TO_PREV_PAGE = 'Go to previous page' } = ARIA_LABEL;

    const backButtonClicked = () => {
        const {
            history, hidePlaybackDispatch, location, showPlayback, playingOnBanner, showBannerOnPlayDispatch,
        } = props;
        if (fullScreen) {
            toggleFullScreen();
        }
        else {
            history.go(-1);
            if (showPlayback || location.search.includes('showPlayback')) {
                hidePlaybackDispatch();
            }
            if (!playingOnBanner) {
                showBannerOnPlayDispatch();
            }
        }
    };
    const volumeBarRef = useRef(null);
    const intervalRef = useRef(null);
    const isMobile = useRef(deviceUtil?.isMobile());
    const SKIP_AD = useMemo(() => {
        const LANGUAGE = LanguageProviderUtil.getLanguage();
        return lodashGet(LANGUAGE, 'SKIP_AD', 'Skip Ad');
    }, []);
    // const countRef
    const [
        playing,
        handlePlaying,
    ] = useState(false);
    const [
        volumeLevel,
        handleVolumeLevel,
    ] = useState(PlaybackStorageUtil.getPlayerVolumeLevel());

    const [
        showVolumeSlider,
        toggleSliderVisibility,
    ] = useState(false);

    const [
        fullScreen,
        setFullScreenState,
    ] = useState(false);
    const [
        isMouseDown,
        handleIsMouseDown,
    ] = useState(false);
    const [
        skipTimer,
        handleSkipTimer,
    ] = useState(null);
    const [
        volumeBeforeMute,
        setVolumeBeforeMute,
    ] = useState(PlaybackStorageUtil.getPlayerVolumeLevel());
    useEffect(() => {
        if (adsPlayingFlag) {
            handlePlaying(true);
            const volume = getVolumeLevel();
            handleVolumeLevel(volume);
        }
        else {
            handlePlaying(false);
        }
    }, [
        adsPlayingFlag,
    ]);

    useEffect(() => {
        const { getVideoTag } = props;
        const videoTag = getVideoTag();
        videoTag.volume = volumeLevel;
    }, [
        volumeLevel,
    ]);

    useEffect(() => {
        const { getVideoTag } = props;
        const videoTag = getVideoTag();

        const onAdPause = () => handlePlaying(false);

        const onAdResume = () => handlePlaying(true);

        videoTag.addEventListener(PLAYER_EVENTS_LIST.AD_PAUSE, onAdPause);

        videoTag.addEventListener(PLAYER_EVENTS_LIST.AD_RESUME, onAdResume);

        return () => {
            videoTag.removeEventListener(PLAYER_EVENTS_LIST.AD_PAUSE, onAdPause);
            videoTag.removeEventListener(PLAYER_EVENTS_LIST.AD_RESUME, onAdResume);
        };
    }, []);

    useEffect(() => {
        if (skipTimeOffset) {
            let timer = skipTimeOffset;
            handleSkipTimer(timer);
            const id = setInterval(() => {
                timer -= 1;
                handleSkipTimer(timer);
                if (timer === 0) {
                    clearInterval(id);
                }
                intervalRef.current = id;
                // }
            }, 1000);
        }
    },
    [
        skipTimeOffset,
    ]);
    useEffect(() => {
        if (isAdsPaused && skipTimeOffset) {
            clearInterval(intervalRef.current);
        }
        else if (skipTimer && skipTimeOffset) {
            let timer = skipTimer;
            handleSkipTimer(skipTimer);
            const id = setInterval(() => {
                timer -= 1;
                handleSkipTimer(timer);
                if (timer === 0) {
                    clearInterval(id);
                }
                intervalRef.current = id;
            }, 1000);
        }
        handlePlaying(!isAdsPaused);
    }, [
        isAdsPaused,
    ]);


    useEffect(() => {
        if (adsClickedLearnMoreFlag) {
            setFullScreenState(false);
        }
    }, [
        adsClickedLearnMoreFlag,
    ]);
    const pausePlaySrc = playing ? IMAGE_PATHS.PAUSE : IMAGE_PATHS.PLAY;

    const getVideoElementTag = () => {
        let videoElement;
        if (document.querySelectorAll('[title="Advertisement"]')[0]) {
            videoElement = document.querySelectorAll('[title="Advertisement"]')[0];
        }
        else if (document.getElementById('ads-container').getElementsByTagName('video')[0]) {
            videoElement = document.getElementById('ads-container').getElementsByTagName('video')[0];
        }
        return videoElement;
    };

    const getVolumeLevel = () => {
        const { getVideoTag } = props;
        const imaAdManager = getAdManager();
        return imaAdManager.getVolume();
    };

    function play() {
        const imaAdManager = getAdManager();
        if (playing) {
            handlePlaying(false);
            imaAdManager.pause();
        }
        else {
            handlePlaying(true);
            if (imaAdManager.play) {
                imaAdManager.play();
            }
            else {
                imaAdManager.resume();
            }
        }
    }

    const toggleFullScreen = (checkPip = true) => {
        const { videoWrapperRef, getVideoTag } = props;
        const fullScreenTargetElement = videoWrapperRef();
        const videoTag = getVideoTag();
        const browser = props.deviceUtil.getBrowserName();

        if (!checkFullScreen()) {
            setFullScreenState(true);
            getFullScreen(fullScreenTargetElement);
            screenOrientationToLandscape();
        }
        else {
            setFullScreenState(false);
            exitFullScreen(fullScreenTargetElement);
            screenOrientationToNeutral();
        }

        if (checkPip && checkIfPlayInPip(videoTag)) {
            closePip(browser, videoTag);
        }
    };

    const getThumborPosition = () => {
        const volumeBarToPaint = (ELEMENT_WIDTH.VOLUME_BAR_WIDTH - ELEMENT_WIDTH.THUMBOR_WIDTH / 2);
        const left = Math.max(0, volumeLevel * volumeBarToPaint - ELEMENT_WIDTH.THUMBOR_WIDTH / 2);
        return {
            left: `${left}px`,
            marginLeft: '0px',
            width: `${ELEMENT_WIDTH.THUMBOR_WIDTH}px`,
        };
    };

    const getPositionStyle = () => {
        const volumeBarToPaint = (ELEMENT_WIDTH.VOLUME_BAR_WIDTH - ELEMENT_WIDTH.THUMBOR_WIDTH / 2);
        const width = Math.max(0, volumeLevel * volumeBarToPaint);
        return {
            width: `${width}px`,
        };
    };

    let volumeSrc = '';
    if (volumeLevel === 0) {
        volumeSrc = VOLUME_BUTTONS.MUTE;
    }
    else if (volumeLevel > 0 && volumeLevel < 0.5) {
        volumeSrc = VOLUME_BUTTONS.LOW;
    }
    else {
        volumeSrc = VOLUME_BUTTONS.HIGH;
    }

    const computeVolume = (event) => {
        if (event) {
            const slide = event.pageX - volumeBarRef.current.getBoundingClientRect().left;
            const volumeBarToPaint = (ELEMENT_WIDTH.VOLUME_BAR_WIDTH - ELEMENT_WIDTH.THUMBOR_WIDTH / 2);
            const volume = slide * ELEMENT_WIDTH.VOLUME_BAR_WIDTH / volumeBarToPaint;
            return Math.min(100, volume);
        }
        return 0;
    };
    const volumeChange = (volumeLevel) => {
        const imaAdManager = getAdManager();
        if (!imaAdManager) {
            return false;
        }
        const tempVal = Number.parseFloat(volumeLevel).toFixed(2);
        imaAdManager.setVolume(Math.max(0, Math.min(tempVal, 1)));
        handleVolumeLevel(imaAdManager.getVolume());
        return imaAdManager.getVolume();
    };

    const updateVolume = (event, toggle = false, hover = false) => {
        event.stopPropagation();
        const imaAdManager = getAdManager();
        if (toggle) {
            if (imaAdManager.getVolume() === 0) {
                handleVolumeLevel(volumeBeforeMute);
                imaAdManager.setVolume(volumeBeforeMute);
            }
            else {
                setVolumeBeforeMute(getVolumeLevel());
                handleVolumeLevel(0);
                imaAdManager.setVolume(0);
            }
        }
        else if (isMouseDown) {
            const volume = computeVolume(event);
            volumeChange(volume / 100);
        }
        else if (!hover) {
            const volume = computeVolume(event);
            volumeChange(volume / 100);
        }
    };

    // const volumeChange = (volumeLevel) => {
    //     const { getVideoTag } = props;
    //     const videoTag = getVideoTag();

    //     const tempVal = Number.parseFloat(volumeLevel).toFixed(2);
    //     imaAdManager.setVolume(Math.max(0, Math.min(tempVal, 1)));
    //     handleVolumeLevel(videoTag.volume);
    //     if (imaAdManager.muted) {
    //         imaAdManager.muted = false;
    //     }
    // };

    // const updateVolume = (event, toggle = false, hover = false) => {
    //     event.stopPropagation();
    //     const imaAdManager = getAdManager();
    //     // const { getVideoTag } = props;
    //     // const videoTag = getVideoTag();
    //     // if (toggle) {
    //     //     if (videoTag.muted) {
    //     //         handleVolumeLevel(volumeBeforeMute);
    //     //         videoTag.volume = volumeBeforeMute;
    //     //         videoTag.muted = false;
    //     //     }
    //     //     else {
    //     //         setVolumeBeforeMute(getVolumeLevel());
    //     //         handleVolumeLevel(0);
    //     //         videoTag.volume = 0;
    //     //         videoTag.muted = true;
    //     //     }
    //     // }
    //     // else if (isMouseDown) {
    //     //     const volume = computeVolume(event);
    //     //     volumeChange(volume / 100);
    //     // }
    //     // else if (!hover) {
    //     //     const volume = computeVolume(event);
    //     //     volumeChange(volume / 100);
    //     // }
    //     if (imaAdManager.isMuted()) {
    //         imaAdManager.setVolume(1);
    //     } else {
    //         imaAdManager.setVolume(0);
    //     }
    // };

    const setIsMouseDown = (val, e) => {
        handleIsMouseDown(val);
        // for onCLick event
        if (val) {
            updateVolume(e);
        }
    };

    const skipAdHandler = () => {
        const imaAdManager = getAdManager();
        imaAdManager.skip();
    };

    const volumeSlider = showVolumeSlider ? (
        <div
            id="volume-controller"
            role="slider"
            aria-valuemin="0"
            aria-valuemax="100"
            aria-valuenow={volumeLevel * 100}
            tabIndex={0}
            className="volume-control-bar-extender"
            onMouseDown={e => setIsMouseDown(true, e)}
            onMouseMove={e => updateVolume(e, false, true)}
            onMouseUp={e => setIsMouseDown(false, e)}
        >
            <div
                className="volume-control-bar"
                // ref={(ref) => {
                //     this.volumeBar = ref;
                // }}
                ref={volumeBarRef}
                style={{
                    width: `${ELEMENT_WIDTH.VOLUME_BAR_WIDTH}px`,
                }}
            >
                <div
                    className="volume-level"
                    style={getPositionStyle()}
                />
                <div
                    className="volume-control-bar-thumb"
                    style={getThumborPosition()}
                    // ref={(ref) => {
                    //     this.thumborRef = ref;
                    // }}
                />
            </div>
        </div>
    ) : null;
    return (
        <>
            <div className={`ad-ui-control ${!adsPlayingFlag ? 'd-none' : ''}`}>
                <div id="ad-timer" />
                <AnalyticsButtonComponent
                    id="player-play-btn"
                    type="button"
                    onClick={play}
                    className="bottom-control-buttons scale-hover"
                    aria-label="Play Button"
                >
                    <ThumborImage
                        src={pausePlaySrc}
                        imageChanged
                    />
                </AnalyticsButtonComponent>
                {!isMobile.current ? <div
                    className=" d-flex show-volume-bar scale-hover"
                    onMouseEnter={() => toggleSliderVisibility(true)}
                    onMouseLeave={() => toggleSliderVisibility(false)}
                >
                    <AnalyticsButtonComponent
                        id="volume-bar"
                        type="button"
                        className="bottom-control-buttons"
                        onClick={e => updateVolume(e, true)}
                        aria-label="Volume button"
                    >
                        <ThumborImage
                            src={volumeSrc}
                            imageChanged
                        />
                    </AnalyticsButtonComponent>
                    {volumeSlider}
                </div> : null}

                <AnalyticsButtonComponent
                    id="ad-toggle-fullscreen"
                    type="button"
                    onClick={toggleFullScreen}
                    className="bottom-control-buttons scale-hover fullscreen-toggle"
                    aria-label="Toggle Full Screen"
                >
                    <ThumborImage
                        src={fullScreen ? '/static/play-back-controls/fullscreen_exit.svg' : '/static/play-back-controls/fullscreen-entry.svg'}
                        imageChanged
                    />
                </AnalyticsButtonComponent>
                {isSkippable && (
                    <div className="ad-skip-button-wrapper">
                        {skipTimer ? <span>{skipTimer}</span>
                            : (
                                <button
                                    type="button"
                                    onClick={skipAdHandler}
                                >
                                    {SKIP_AD}
                                </button>
                            )
                        }
                    </div>
                )}
            </div>
            <div className="ad-back-btn">
                <AnalyticsButtonComponent
                    className="vdo-back-btn"
                    type="button"
                    onClick={() => backButtonClicked()}
                    aria-label={GO_TO_PREV_PAGE}
                >
                    <ThumborImage
                        src={IMAGE_PATHS.BACK_ARROW}
                    />

                </AnalyticsButtonComponent>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    const {
        playbackDetails,
    } = state;
    const adsPlayingFlag = lodashGet(playbackDetails, 'adsPlayingFlag', null);
    const adType = lodashGet(playbackDetails, 'adType', null);
    const isSkippable = lodashGet(playbackDetails, 'isSkippable', false);
    const skipTimeOffset = lodashGet(playbackDetails, 'skipTimeOffset', 0);
    const isAdsPaused = lodashGet(playbackDetails, 'isAdsPaused', false);
    const adsClickedLearnMoreFlag = lodashGet(playbackDetails, 'adsClickedLearnMoreFlag', false);

    const {
        notify: {
            showPlayback,
            playingOnBanner,
        },
    } = state;
    return {
        playbackDetails,
        adsPlayingFlag,
        adType,
        isSkippable,
        skipTimeOffset,
        isAdsPaused,
        adsClickedLearnMoreFlag,
        showPlayback,
        playingOnBanner,
    };
};
export default withDeviceUtil(connect(mapStateToProps, {
    hidePlaybackDispatch: hidePlayback,
    showBannerOnPlayDispatch: showBannerOnPlay,
})(withRouter(ClientAdControlBar)));
