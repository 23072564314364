import { BasicControlBar, LiteControlBar } from '../factories/PlaybackUiComponentFactory';
import LiveControlBar from '@airtel-feature/playback/components/control-bars/LiveControlBar';
import { STREAM_TYPES } from '@airtel-tv/constants/BrowserConst';

// Return player on the base of content provider
const getControlBar = (streamType) => {
    switch (streamType) {
        case STREAM_TYPES.LIVE:
            return LiveControlBar;
        case STREAM_TYPES.SHORT_STREAM:
            return LiteControlBar;

        default:
            return BasicControlBar;
    }
};

export default getControlBar;
