import lodashCloneDeep from 'lodash/cloneDeep';
import lodashSet from 'lodash/set';
import { PlaybackDetailsActions } from '../actions/PlaybackActions';
import { getTimeStamp } from '@airtel-tv/utils/GlobalUtil';

//     expireCount: infinity, // same playback can be used enless times by default
//     error: null,
//     eligibleForPlayback: '',
//     playback: {
//         method: '',
//         playUrl: '',
//     },
//     playbackType: '',
//     message: '',
//     bundleInfo: {},
//     cp: '',
//     isDrm: '',
//     drminfo: {},
//     showDialogue: '',
//     pbK: '',
//     contentId: '',
//     plg: '',
//     subtitlesUrlMap: {},

const initialState = JSON.parse('{}');

const PlaybackDetailsReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case PlaybackDetailsActions.PLAYBACK_DETAILS_FETCH:
            changes = {
                [action.contentId]: {
                    fetching: true,
                },
            };
            break;
        case PlaybackDetailsActions.PLAYBACK_DETAILS_EXPIRED:
            changes = {
                ...state,
            };
            if (changes && changes[action.contentId]){
                changes[action.contentId].expireCount = action?.expireCount;
            }
            break;

        case PlaybackDetailsActions.PLAYBACK_DETAILS_FETCH_ERROR:
            changes = {
                ...action.payload,
            };
            break;

        case PlaybackDetailsActions.PLAYBACK_DETAILS_FETCH_COMPLETED:
            changes = {
                ...action.payload,
            };
            break;

        case PlaybackDetailsActions.PLAYBACK_DETAILS_COOKIES_FETCH_SUCCESS: {
            const content = lodashCloneDeep({ ...(state[action.contentId] || {}) }); // immutable
            lodashSet(content, 'playback.headers.Cookie', action.cookie);
            changes = {
                [action.contentId]: content,
            };
            break;
        }

        case PlaybackDetailsActions.PLAYBACK_CLEAR_ALL:
            return {};

        case PlaybackDetailsActions.SEND_HEART_BEAT_STOP_PLAYBACK: {
            const content = lodashCloneDeep({ ...(state[action.contentId] || {}) }); // immutable
            lodashSet(content, 'stopPlaybackTS', getTimeStamp());
            lodashSet(content, 'heartBeatError', action.errorData || {});

            changes = {
                [action.contentId]: content,
            };
            break;
        }
        case PlaybackDetailsActions.ADSMANAGER_REFERENCE:
            changes = {
                adsManager: action.adsManager,
            };
            break;
        case PlaybackDetailsActions.SHOW_PREROLL_ADS:
            changes = {
                showPreRollAds: action.preRollAds,
            };
            break;
        case PlaybackDetailsActions.SHOW_POSTROLL_ADS:
            changes = {
                showPostRollAds: action.postRollAds,
            };
            break;
        case PlaybackDetailsActions.PLAYING_MIDROLL_ADS:
            changes = {
                playingMidRollAds: action.isAdBreak,
            };
            break;
        case PlaybackDetailsActions.PREROLL_ADS_PLAYING_FLAG:
            changes = {
                preRollAdsPlaying: action.preRollAdsPlaying,
            };
            break;
        case PlaybackDetailsActions.TOGGLE_FULL_SCREEN:
            changes = {
                toggleFullScreenFlag: action.toggleFullScreenFlag,
            };
            break;
        case PlaybackDetailsActions.ADS_PLAYING_FLAG:
            changes = {
                adsPlayingFlag: action.adsPlayingFlag,
                ...(action.adType && { adType: action.adType }),
            };
            break;
        case PlaybackDetailsActions.SDK_ADS_PLAYING_FLAG:
            changes = {
                sdkAdsPlayingFlag: action.sdkAdsPlayingFlag,
                ...(action.sdkAdType && { sdkAdType: action.sdkAdType }),
            };
            break;
        case PlaybackDetailsActions.ADS_CLICK_LEARN_MORE:
            changes = {
                adsClickedLearnMoreFlag: action.adsClickedLearnMoreFlag,
            };
            break;
        case PlaybackDetailsActions.SKIPPABLE:
            changes = {
                isSkippable: action.isSkippable,
                skipTimeOffset: action.skipTimeOffset,
            };
            break;
        case PlaybackDetailsActions.ADS_PAUSED:
            changes = {
                isAdsPaused: action.isAdsPaused,
            };
            break;

        case PlaybackDetailsActions.CONCURRENT_PLAY_LIMIT_ERROR:
            changes = { ...action.payload };
            break;
        case PlaybackDetailsActions.CLOSE_PLAYER:
            changes = {
                closePlayer: action.closePlayer,
            };
            break;
        case PlaybackDetailsActions.RESET_PLAYBACK_DETAILS:
            return initialState;
        default:
            return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default PlaybackDetailsReducer;
