import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import { sessionStore } from '@airtel-tv/utils/BrowserStoreUtil';
import { getDeviceResolution, getPortraitorLandscape, readStitchId } from '@airtel-tv/utils/GlobalUtil';
import { LocationUtil } from '@airtel-tv/utils';
import { apiInitEvent, apiResponseEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';

class ApiAnalytics {
   constructor(contentDetails, playbackDetails) {
        this.contentDetails = contentDetails
        this.playbackDetails = playbackDetails
   }

//    setInitTime() {
//         this.initTime = new Date().getTime();
//    }

   setResponseTime(responseTime) {
        this.responseTime = responseTime;
   }

   getEventMeta = () => {
    const analyticsMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META);
    const stitchId = readStitchId(false);
    const { userSessionId } = analyticsMeta || {};
    let obj = {
        device_resolution: getDeviceResolution().resolution,
        orientation: getPortraitorLandscape(),
        content_id: this.contentDetails.contentId,
        stream_mode_online: 'true',
        playback_funnel_v2_enabled: 'true',
        user_session_id: userSessionId,
        playback_stitch_key: stitchId,
     }
    // if(playerEvent) {
    //     obj = {
    //         ...obj,
    //         player_name: this.playerFunctions.playerType,
    //         stream_mode_online: true,
    //         user_session_id: userSessionId
    //         //player_version
    //         //is_trailer_auto
    //         //bw
    //     }
    // }

    return obj;
    }

    getCorePlaybackMeta = () => {
        const { url, category } = this.playbackDetails
        const urlSchema = LocationUtil.parseUrl(url);
        return {
            api_host: urlSchema.hostname,
            api_category: category
        }
    }

    fireApiInit = () => {
        const eventMeta = this.getEventMeta();
        const playbackMeta = this.getCorePlaybackMeta();
        apiInitEvent(eventMeta, playbackMeta);
        //this.setInitTime()
    }

    fireApiResponse = (error = null, headers, requestBody) => {
        const responseHeaders = headers ? JSON.stringify(headers): '';
        let { responseText } = requestBody || {};
        let responseMeta = error ? {
            api_status: 'failure',
            api_error_code: error?.errorcode ? error.errorcode : ERROR_CODES.ATV_CL_700,
            ...(this.responseTime && {api_response_time: `${this.responseTime}`}),
            ...(responseHeaders && {response_headers: responseHeaders}),
            ...(responseText && {response_body: responseText}),
        }: {
            api_status: 'success',
            ...(this.responseTime && {api_response_time: `${this.responseTime}`}),
        }
       
        const eventMeta = this.getEventMeta();
        const playbackMeta = this.getCorePlaybackMeta();
        responseMeta = {
            ...responseMeta,
            ...playbackMeta,
        }
        apiResponseEvent(eventMeta, responseMeta)
    }

}


export default ApiAnalytics;