import EventEmitter from 'events';
import { PLAYER_EVENTS_LIST, LITE_PLAYER_EVENTS } from '@airtel-tv/constants/PlayerConsts';


export default class LitePlayerEvents {
    firstPlayEventFiredFlag = false;

    initEventFiredFlag = false;

    dispose = () => {
        if (this.emitter) {
            this.emitter.removeAllListeners();
        }
    };

    emit = (name, data) => {
        this.emitter.emit(name, data);
    }

    constructor({ player, videoTag, playerFunctions }) {
        if (!videoTag || !player || !player.load) {
            return; // cannot subscribe to events as listening method is not provided
        }

        this.emitter = new EventEmitter();

        videoTag.addEventListener(LITE_PLAYER_EVENTS.PLAY, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.PLAY, videoTag.currentTime);
        });

        videoTag.addEventListener(LITE_PLAYER_EVENTS.CAN_PLAY_THROUGH, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH);
        });

        videoTag.addEventListener(LITE_PLAYER_EVENTS.ENDEND, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.ENDEND);
        });

        // capture error from video tag and player both
        videoTag.addEventListener(LITE_PLAYER_EVENTS.ERROR, (error) => {
            const errorData = error.detail || error.data || error;
            this.emitter.emit(PLAYER_EVENTS_LIST.ERROR, errorData);
        });


        player.addEventListener(LITE_PLAYER_EVENTS.ERROR, (error) => {
            const errorData = error.detail || error.data || error;
            this.emitter.emit(PLAYER_EVENTS_LIST.ERROR, errorData);
        });

        videoTag.addEventListener(LITE_PLAYER_EVENTS.WAITING, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.WAITING);
        });

        videoTag.addEventListener(LITE_PLAYER_EVENTS.PLAYING, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.PLAYING);
        });

        videoTag.addEventListener(LITE_PLAYER_EVENTS.TIMEUPDATE, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.TIMEUPDATE, videoTag.currentTime);

            // SHAKA DOES NOT HAS A EVENT NAMED 'FIRST_PLAY' SO WE MADE ONE
            if (this.firstPlayEventFiredFlag === false && videoTag.currentTime > 0 && this.initEventFiredFlag === true) {
                this.firstPlayEventFiredFlag = true;
                this.emitter.emit(PLAYER_EVENTS_LIST.FIRST_PLAY);
            }

            // raise live time update event
            const liveStreamCurrentPlayingTime = playerFunctions.isLiveStream() ? playerFunctions.getLivePlaybackUTCTime() : undefined;
            if (liveStreamCurrentPlayingTime) {
                this.emitter.emit(PLAYER_EVENTS_LIST.LIVE_TIME_UPDATE, liveStreamCurrentPlayingTime);
            }
        });


        videoTag.addEventListener(LITE_PLAYER_EVENTS.LOADSTART, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.LOADSTART);
        });

        videoTag.addEventListener(LITE_PLAYER_EVENTS.CHANGE_BIT_RATE, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.CHANGE_BIT_RATE, event);
        });

        videoTag.addEventListener(LITE_PLAYER_EVENTS.VOLUME_CHANGE, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.VOLUME_CHANGE, event);
        });
    }
}
