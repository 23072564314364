import React, {
    useEffect, useState, useRef, useMemo,
} from 'react';
import { PropTypes } from 'prop-types';
import lodashGet from 'lodash/get';
import { LanguageProviderUtil } from '@airtel-tv/utils';
import { VOLUME_BUTTONS, PLAYER_EVENTS_LIST } from '@airtel-tv/constants/PlayerConsts';
import {
    checkFullScreen,
} from '@airtel-tv/utils/WindowUtil';
import PlaybackStorageUtil from '../../PlaybackStorageUtil';
import { ServerAdControlBarUIComponent } from '../../factories/PlaybackUiComponentFactory';


const ELEMENT_WIDTH = {
    THUMBOR_WIDTH: 16,
    VOLUME_BAR_WIDTH: 100,
};


export default function ServerAdControlBar(props) {
    const {
        adsPlayingFlag, skipTimeOffset, isAdsPaused, getAdManager, adsClickedLearnMoreFlag,
    } = props;
    const intervalRef = useRef(null);
    // const countRef
    const [
        playing,
        handlePlaying,
    ] = useState(false);
    const [
        volumeLevel,
        handleVolumeLevel,
    ] = useState(PlaybackStorageUtil.getPlayerVolumeLevel());

    const [
        showVolumeSlider,
        toggleSliderVisibility,
    ] = useState(false);

    const [
        fullScreen,
        setFullScreenState,
    ] = useState(false);
    const [
        isMouseDown,
        handleIsMouseDown,
    ] = useState(false);
    const [
        skipTimer,
        handleSkipTimer,
    ] = useState(null);
    const [
        mute,
        handleMute,
    ] = useState(false);

    useEffect(() => {
        const { getVideoTag } = props;
        const videoTag = getVideoTag();

        const onAdPause = () => handlePlaying(false);

        const onAdResume = () => handlePlaying(true);

        videoTag.addEventListener(PLAYER_EVENTS_LIST.PAUSE, onAdPause);

        videoTag.addEventListener(PLAYER_EVENTS_LIST.PLAY, onAdResume);

        return () => {
            videoTag.removeEventListener(PLAYER_EVENTS_LIST.PAUSE, onAdPause);
            videoTag.removeEventListener(PLAYER_EVENTS_LIST.PLAY, onAdResume);
        };
    }, []);
    const SKIP_AD = useMemo(() => {
        const LANGUAGE = LanguageProviderUtil.getLanguage();
        return lodashGet(LANGUAGE, 'SKIP_AD');
    }, []);

    const fullScreenRef = useRef(false);
    const [
        volumeBeforeMute,
        setVolumeBeforeMute,
    ] = useState(PlaybackStorageUtil.getPlayerVolumeLevel());
    useEffect(() => {
        if (adsPlayingFlag) {
            handlePlaying(true);
            const { getVideoTag } = props;
            const videoTag = getVideoTag();
            handleVolumeLevel(videoTag.volume);
        }
        else {
            handlePlaying(false);
        }
    }, [
        adsPlayingFlag,
    ]);
    useEffect(() => {
        if (skipTimeOffset) {
            let timer = skipTimeOffset;
            handleSkipTimer(timer);
            const id = setInterval(() => {
                timer -= 1;
                handleSkipTimer(timer);
                if (timer === 0) {
                    clearInterval(id);
                }
                intervalRef.current = id;
                // }
            }, 1000);
        }
    },
    [
        skipTimeOffset,
    ]);
    useEffect(() => {
        if (isAdsPaused && skipTimeOffset) {
            clearInterval(intervalRef.current);
        }
        else if (skipTimer && skipTimeOffset) {
            let timer = skipTimer;
            handleSkipTimer(skipTimer);
            const id = setInterval(() => {
                timer -= 1;
                handleSkipTimer(timer);
                if (timer === 0) {
                    clearInterval(id);
                }
                intervalRef.current = id;
            }, 1000);
        }
        handlePlaying(!isAdsPaused);
    }, [
        isAdsPaused,
    ]);


    useEffect(() => {
        if (adsClickedLearnMoreFlag) {
            setFullScreenState(false);
        }
    }, [
        adsClickedLearnMoreFlag,
    ]);
    const getVideoElementTag = () => {
        let videoElement;
        if (document.querySelectorAll('[title="Advertisement"]')[0]) {
            videoElement = document.querySelectorAll('[title="Advertisement"]')[0];
        }
        else if (document.getElementById('ads-container').getElementsByTagName('video')[0]) {
            videoElement = document.getElementById('ads-container').getElementsByTagName('video')[0];
        }
        return videoElement;
    };

    const getVolumeLevel = () => {
        const { getVideoTag } = props;
        const videoTag = getVideoTag();
        return videoTag.volume;
    };

    const play = () => {
        const { getVideoTag } = props;
        const videoTag = getVideoTag();
        if (playing) {
            handlePlaying(false);
            videoTag.pause();
        }
        else {
            handlePlaying(true);
            videoTag.play();
        }
        // const imaAdManager = getAdManager();
        // if (playing) {
        //     handlePlaying(false);
        //     imaAdManager.pause();
        // }
        // else {
        //     handlePlaying(true);
        //     imaAdManager.resume();
        // }
    };

    const toggleFullScreen = (checkPip = true) => {
        const { videoWrapperRef, getVideoTag, dispatchToggleFullScreen } = props;
        // const fullScreenTargetElement = videoWrapperRef();
        const videoTag = getVideoTag();
        const browser = props.deviceUtil.getBrowserName();

        if (!checkFullScreen()) {
            setFullScreenState(true);
            // getFullScreen(fullScreenTargetElement);
            // screenOrientationToLandscape();
        }
        else {
            setFullScreenState(false);
            // exitFullScreen(fullScreenTargetElement);
            // screenOrientationToNeutral();
        }
        fullScreenRef.current = !fullScreenRef.current;
        dispatchToggleFullScreen({ toggleFullScreenFlag: fullScreenRef.current });

        // if (checkPip && checkIfPlayInPip(videoTag)) {
        //     closePip(browser, videoTag);
        // }
    };

    const getThumborPosition = () => {
        const volumeBarToPaint = (ELEMENT_WIDTH.VOLUME_BAR_WIDTH - ELEMENT_WIDTH.THUMBOR_WIDTH / 2);
        const left = Math.max(0, volumeLevel * volumeBarToPaint - ELEMENT_WIDTH.THUMBOR_WIDTH / 2);
        return {
            left: `${left}px`,
            marginLeft: '0px',
            width: `${ELEMENT_WIDTH.THUMBOR_WIDTH}px`,
        };
    };

    const getPositionStyle = () => {
        const volumeBarToPaint = (ELEMENT_WIDTH.VOLUME_BAR_WIDTH - ELEMENT_WIDTH.THUMBOR_WIDTH / 2);
        const width = Math.max(0, volumeLevel * volumeBarToPaint);
        return {
            width: `${width}px`,
        };
    };

    let volumeSrc = '';
    if (volumeLevel === 0 || mute) {
        volumeSrc = VOLUME_BUTTONS.MUTE;
    }
    else if (volumeLevel > 0 && volumeLevel < 0.5) {
        volumeSrc = VOLUME_BUTTONS.LOW;
    }
    else {
        volumeSrc = VOLUME_BUTTONS.HIGH;
    }

    const computeVolume = (event) => {
        if (event) {
            const volumeBarControl = document.getElementById('id_volume-control-bar');
            if (!volumeBarControl) {
                return;
            }
            const slide = event.pageX - volumeBarControl.getBoundingClientRect().left;
            const volumeBarToPaint = (ELEMENT_WIDTH.VOLUME_BAR_WIDTH - ELEMENT_WIDTH.THUMBOR_WIDTH / 2);
            const volume = slide * ELEMENT_WIDTH.VOLUME_BAR_WIDTH / volumeBarToPaint;
            return Math.min(100, volume);
        }
        return 0;
    };
    // const volumeChange = (volumeLevel) => {
    //     const imaAdManager = getAdManager();
    //     if (!imaAdManager) {
    //         return false;
    //     }
    //     const tempVal = Number.parseFloat(volumeLevel).toFixed(2);
    //     imaAdManager.setVolume(Math.max(0, Math.min(tempVal, 1)));
    //     handleVolumeLevel(imaAdManager.getVolume());
    //     if (mute) {
    //         handleMute(false);
    //     }
    //     return imaAdManager.getVolume();
    // };

    // const updateVolume = (event, toggle = false, hover = false) => {
    //     event.stopPropagation();
    //     const imaAdManager = getAdManager();
    //     if (toggle) {
    //         if (mute) {
    //             handleVolumeLevel(volumeBeforeMute);
    //             imaAdManager.setVolume(volumeBeforeMute);
    //         }
    //         else {
    //             setVolumeBeforeMute(getVolumeLevel());
    //             handleVolumeLevel(0);
    //             imaAdManager.setVolume(0);
    //         }
    //         handleMute(prev => !prev);
    //     }
    //     else if (isMouseDown) {
    //         const volume = computeVolume(event);
    //         volumeChange(volume / 100);
    //     }
    //     else if (!hover) {
    //         const volume = computeVolume(event);
    //         volumeChange(volume / 100);
    //     }
    // };

    const volumeChange = (volumeLevel) => {
        const { getVideoTag } = props;
        const videoTag = getVideoTag();

        const tempVal = Number.parseFloat(volumeLevel).toFixed(2);
        videoTag.volume = Math.max(0, Math.min(tempVal, 1));
        handleVolumeLevel(videoTag.volume);
        if (videoTag.muted) {
            videoTag.muted = false;
        }
        return videoTag.volume;
    };

    const updateVolume = (event, toggle = false, hover = false) => {
        event.stopPropagation();
        // const imaAdManager = getAdManager();
        const { getVideoTag } = props;
        const videoTag = getVideoTag();
        if (toggle) {
            if (videoTag.muted) {
                handleVolumeLevel(volumeBeforeMute);
                videoTag.volume = volumeBeforeMute;
                videoTag.muted = false;
            }
            else {
                setVolumeBeforeMute(getVolumeLevel());
                handleVolumeLevel(0);
                videoTag.volume = 0;
                videoTag.muted = true;
            }
        }
        else if (isMouseDown) {
            const volume = computeVolume(event);
            volumeChange(volume / 100);
        }
        else if (!hover) {
            const volume = computeVolume(event);
            volumeChange(volume / 100);
        }
    };
    const setIsMouseDown = (val, e) => {
        handleIsMouseDown(val);
        // for onCLick event
        if (val) {
            updateVolume(e);
        }
    };

    const skipAdHandler = () => {
        const imaAdManager = getAdManager();
        imaAdManager.skip();
    };

    if (!props.deviceUtil.isWeb()) {
        return (
            <ServerAdControlBarUIComponent
                {...props}
                playing={playing}
                play={play}
                getPositionStyle={getPositionStyle}
                SKIP_AD={SKIP_AD}
                skipAdHandler={skipAdHandler}
            />
        );
    }

    return (
        <ServerAdControlBarUIComponent
            {...props}
            showVolumeSlider={showVolumeSlider}
            volumeLevel={volumeLevel}
            updateVolume={updateVolume}
            playing={playing}
            play={play}
            toggleFullScreen={toggleFullScreen}
            getPositionStyle={getPositionStyle}
            getThumborPosition={getThumborPosition}
            setIsMouseDown={setIsMouseDown}
            fullScreen={fullScreen}
            toggleSliderVisibility={toggleSliderVisibility}
            SKIP_AD={SKIP_AD}
            skipAdHandler={skipAdHandler}
            volumeSrc={volumeSrc}
        />
    );
}
