import lodashGet from 'lodash/get';
import { DRM_SUPPORT, DRM_LIST } from '@airtel-tv/constants/BrowserConst';

export default ({
    isDrm, browser, drmType, os, browserVersion, licenseUri, handleLicenseRequest, certificateUri, handleCertificateRequest, contentId, handleContentIdRequest,
}) => {
    // start handle drm
    let keySystemsData = {};

    if (isDrm) {
        const browserDrmInfo = lodashGet(DRM_SUPPORT, [
            drmType,
            os,
            browser,
        ], { leastSupportedVersion: Infinity });

        // browser version should be equal or greater than supported version
        const supported = browserVersion >= browserDrmInfo.leastSupportedVersion;

        // TODO: check user has already toggled the enable DRM flag

        // browser can play DRM seamlessly

        if (supported && !browserDrmInfo.settingsToggle) {
            // build widevine keysystem
            if (DRM_LIST.WIDEVINE === drmType) {
                // handle license
                if (handleLicenseRequest) {
                    keySystemsData = {
                        [drmType]: {
                            async getLicense(emeOptions, keyMessage, callback) {
                                const payload = new Uint8Array(keyMessage);
                                const licenseArrayBuffer = await handleLicenseRequest(payload);
                                callback(null, licenseArrayBuffer);
                            },
                        },
                    };
                }
                else if (licenseUri) {
                    keySystemsData = {
                        [drmType]: licenseUri,
                    };
                }
            }

            // build fairplay keysystem
            else if (DRM_LIST.FAIRPLAY === drmType) {
                // handle license
                if (handleLicenseRequest) {
                    keySystemsData = {
                        [drmType]: {
                            getLicense: async (emeOptions, assetId, keyMessage, callback) => {
                                const payload = new Uint8Array(keyMessage);
                                try {
                                    const licenseArrayBuffer = await handleLicenseRequest(payload);
                                    callback(null, licenseArrayBuffer);
                                }
                                catch (error) {
                                    callback(error);
                                }
                            },
                        },
                    };
                }
                else if (licenseUri) {
                    keySystemsData = {
                        [drmType]: {
                            licenseUri,
                        },
                    };
                }
                // handle get content id
                if (handleContentIdRequest) {
                    keySystemsData[drmType].getContentId = handleContentIdRequest;
                }
                else {
                    keySystemsData[drmType].getContentId = () => contentId;
                }

                // handle certificate
                if (handleCertificateRequest) {
                    keySystemsData[drmType].getCertificate = async (emeOptions, callback) => {
                        const certificateArrayBuffer = await handleCertificateRequest();
                        callback(null, certificateArrayBuffer);
                    };
                }
                else if (certificateUri) {
                    keySystemsData[drmType].certificateUri = certificateUri;
                }
            }
        }
        // browser can play DRM after toggling settings
        else if (supported && browserDrmInfo.settingsToggle) {
            // TODO: make ui to show user message to toggle

        }
        // browser cannot play DRM
        else {
            // TODO: make ui to show browser not supported
        }
    }


    return keySystemsData;
};
