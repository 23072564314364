export default class XMLParser {
    static xmlToJSON(xml = '<c>ccc</c><dom>dd</dom>') {
        // get all tags in an array
        const tagsPattern = /(<.+?>.*?<\/.+?>)/g;
        const tags = xml.match(tagsPattern);

        // pattern to get keys and value of a tag
        const tagKeyPattern = /<(.+?)>/;
        const valPattern = /<.+?>(.*?)<\/.+?>/;

        // get all keys and values and map it into parsedObj
        const parsedObj = {};
        tags.forEach((tag) => {
            const key = tag.match(tagKeyPattern)[1]; // for <c>val</c> it returns ['<c>', 'c']
            const val = tag.match(valPattern)[1]; // for <c>val</c> it returns ['c>val</c>', 'val']

            parsedObj[key] = val; // { ...parsedObj, c: val}
        });

        return parsedObj;
    }
}
