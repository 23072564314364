import Cookies from 'js-cookie';
import lodashGet from 'lodash/get';
import lodashIsEqual from 'lodash/isEqual';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashOmit from 'lodash/omit';
import lodashThrottle from 'lodash/throttle';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { RoutingUtil } from '@airtel-tv/utils/RoutingUtil';
import { DistroUtil } from '@airtel-tv/utils/DistroUtil';
// import { ErrorPlayer } from './factories/PlaybackUiComponentFactory';
import {
    DEFAULT_CP_CONTROL_CONFIG, MAX_AUTO_RETRY_LIMIT, PLAYBACK_MODES, PLAYBACK_TYPES, PLAYER_EVENTS_LIST,
} from '@airtel-tv/constants/PlayerConsts';
import {
    COOKIE_REFRESH_BEFORE_EXPIRE_SECONDS, DOWNLOAD_APP_ACTIONS, IMAGE_PATHS, MODAL_POPUP, APP_REDIRECT_CPS, PROGRAM_TYPES, DEVICE_TYPES, PLAYBACK_CHECK, HLS_DAI_PLATFORMS,
    DEEPLINK_INGRESS_KEYS,
    LOCAL_STORE_KEYS,
} from '@airtel-tv/constants/GlobalConst';
import {
    checkWindowExist, isOnline, addListenerToEvent, checkDocumentHidden, removeListenerFromEvent, getWindowLocation, getCurrentWindowSourceName, checkAndAddScript,
} from '@airtel-tv/utils/WindowUtil';
import { BROWSER_LIST, STREAM_TYPES } from '@airtel-tv/constants/BrowserConst';
import {
    onInstallClick, parseLiveTvCooke, getContentType, isPlatformSupported, getRandomNumber, getUuid, getAppVersion,
    checkDirectPlaybackContainer,
    getPlaySessionId,
} from '@airtel-tv/utils/GlobalUtil';
import { DeviceTypeUtil, LanguageProviderUtil, LocationUtil } from '@airtel-tv/utils';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { CONTENT_IMAGE_TYPES } from '@airtel-tv/constants/ImagesConst';
import { AuthenticationUtil } from '@airtel-tv/utils/AuthenticationUtil';
import {
    syncContentAdd, syncContentInit, addAndSyncContent, syncContentRemove,
} from '@airtel-tv/sync/SyncActions';
import { getParentContentId } from '@airtel-tv/sync/SyncHelper';
import { BLOCK_CODES } from '@airtel-tv/constants/BlockCodeConst';
import {
    switchToAnotherTabEvent, switchBackToXstreamTabEvent, subscriptionClickEvent, claimNowClickEvent, screenClosedEvent,
} from '@airtel-tv/analytics/FunctionalEvents';
// import { redirectToSubscription } from '../../service/end-points/SubscriptionApiService';
import { SubscriptionUtil } from '@airtel-tv/utils/SubscriptionUtil';
import { LOGIN_SOURCE, SUBSCRIPTION_SOURCE } from '@airtel-tv/constants/EventsConst';
import { INVALID_PLAYBACK_URL, SHOW_PLAYBACK_FALSY_PARAM, SHOW_PLAYBACK_TRUTHY_PARAM } from '@airtel-tv/constants/PlaybackConsts';
// import { PREFFERED_PARTNER_LIST } from '../subscription-details/constant/SubscriptionConstant';
import { ANALYTICS_ASSETS } from '@airtel-tv/constants/AnalyticsConst';
import { contentDetailsFetchAction } from '@airtel-feature/content-details/content-details/actions/ContentDetailsAction';
import ROUTE_PATHS from '@airtel-tv/constants/RouteConsts';
import {
    NOTIFYIDS, ERROR_ACTIONS, ERROR_CODES, HTTP_STATUS_CODE,
} from '@airtel-tv/constants/ErrorCodes';
import { ContentImageProvider, getPosterUrlFromContentDetails } from '@airtel-tv/utils/ContentImageProviderWeb';
import { ERROR_HANDLING_TYPES } from '@airtel-tv/constants/ErrorConst';
import browserStore, { sessionStore } from '@airtel-tv/utils/BrowserStoreUtil';
import { checkPartnerAdsinPlayback, checkSdkAdsAllowed } from '@airtel-tv/utils/AdSdkUtil';
import PlaybackParamsBuilder from '@airtel-tv/utils/PlaybackParamsBuilder';
import ScopedWebviewUtil from '@airtel-tv/utils/ScopedWebviewUtil';
import { subscriptionInit } from '../../web/src/modules/subscription/SubscriptionAction';
import { togglePlaybackAction, updateNextContentPlayedCountAction } from '../../tv/src/modules/notify/NotifyActions';
import { setMultiAudio } from './util/PlaybackUtility';
import { hideModalComponentAction, showModalComponentAction } from '../../web/src/modules/modal-popup/ModalPopupActions';
import PlaybackUtil from './util/PlaybackUtil';
import { PlayerLoaderComponent, ErrorHandler } from './factories/PlaybackUiComponentFactory';
import { triggerLogin } from '../../web/src/modules/auth/AuthAction';
import HeartBeatHelper from './helpers/HeartBeatHelper';
import ContinueWatchingHelper from './helpers/ContinueWatchingHelper';
import { openContentInApp } from '../../libraries/services/DeeplinkApiService';
import {
    modifyInitialPlaybackDetails,
    setPlayerConfig,
} from '../../tv/src/modules/homepage/actions/QuickViewActions';
import {
    adsPlaying,
    playbackClearAllAction,
    playbackDetailsCookiesFetchAction,
    playbackDetailsExpiredAction,
    playbackDetailsFetchAction,
    playbackDetailsFetchCompletedAction,
    playbackWithoutLoginDetailsFetchAction,
    sendHeartbeatAction,
    resetPlaybackDetails,
} from './actions/PlaybackActions';
import AnalyticsHelper from './helpers/AnalyticsHelper';
import AnalyticsHelperV2, { getUrlSourceName } from './helpers/AnalyticsHelperV2';
import PlaybackPixelAnalytics from './helpers/PlaybackPixelAnalytics';
import PlaybackConfigBuilder from './builders/PlaybackConfigBuilder';
import getControlBar from './factories/ControlBarFactory';
import PlayerFactory from './factories/PlayerFactory';
import { handleEpgViewChange } from '../notify/NotifyActions';

const windowLocation = getWindowLocation();

export const VISIBILITY_CHANGE_EVENT = 'visibilitychange';

let ErrorPlayer;

class PlaybackContainer extends Component {
    constructor(props) {
        super(props);
        checkAndAddScript('https://cdn.jsdelivr.net/npm/hls.js@latest/dist/hls.min.js');
        if (DeviceTypeUtil.isWeb()) {
            checkAndAddScript('dist/shaka-player.ui.js');
            // checkAndAddScript('https://imasdk.googleapis.com/js/sdkloader/ima3_dai.js');
            // checkAndAddScript('https://imasdk.googleapis.com/js/sdkloader/ima3.js');
            // checkAndAddScript('https://cdn.jsdelivr.net/npm/hls.js@latest');
            checkAndAddScript('https://googleads.github.io/videojs-ima/node_modules/video.js/dist/video-js.min.css', { type: 'link' });
            checkAndAddScript('https://googleads.github.io/videojs-ima/node_modules/videojs-contrib-ads/dist/videojs.ads.css', { type: 'link' });
            checkAndAddScript('https://googleads.github.io/videojs-ima/dist/videojs.ima.css', { type: 'link' });
            checkAndAddScript('https://googleads.github.io/videojs-ima/node_modules/video.js/dist/video.min.js');
            checkAndAddScript('https://googleads.github.io/videojs-ima/node_modules/videojs-contrib-ads/dist/videojs.ads.min.js');
            checkAndAddScript('https://googleads.github.io/videojs-ima/dist/videojs.ima.js');
        }
        this.playerFunctions = null;

        this.deviceUtil = props.deviceUtil;

        this.autoRetryCount = 0;
        this.playbackCallInitTimeStamp = new Date().getTime();
        this.onLoadPlaybackErrorIgnored = false;

        this.playerErrorComponent = null;
        this.LANGUAGE = LanguageProviderUtil.getLanguage();
        this.isWeb = DeviceTypeUtil.isWeb(); // true for web and false for TV
        this.isTv = DeviceTypeUtil?.isTV();
        this.state = {
            showErrorPlayer: false,
            isPlaybackUrlValid: '',
            contentDetails: {},
            playbackDetails: {},
            shouldPlaybackRestartState: false,
            showDownloadFloatingNudge: { show: false },
        };

        this.playbackUrlExpireCount = Infinity;

        this.disposeContinueWatching = null;

        this.throttledRefreshCookie = lodashThrottle(this.refreshCookie, 10000, {
            leading: true,
            trailing: false,
        });
        this.imaAdManager = null;
        this.isPlaybackEnded = false;
        this.loginAfterTrailer = false;
        this.cdnErrorDict = {};
        this.loadLazyComponents();
        this.playerEvents = null;
        this.isScopedWebview = this.deviceUtil.isScopedWebview();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            playbackDetails,
            closePlayer,
            contentDetails,
            userEmail,
            uid,
            nextEpisodeDetails,
        } = nextProps;
        const {
            closePlayer: prevClosePlayer,
        } = prevState;
        let changes = {};
        if (closePlayer !== prevClosePlayer) {
            changes = {
                ...prevState,
                closePlayer,
            };
        }
        if (playbackDetails.stopPlaybackTS !== prevState.playbackDetails.stopPlaybackTS) {
            changes = {
                ...prevState,
                triggerStopPlayback: true,
            };
        }

        // in case of EPG title can change
        if (contentDetails.title !== prevState.contentDetails.title) {
            changes = {
                ...prevState,
                ...changes,
                contentDetails,
                playbackSessionId: AuthenticationUtil.generateSessionId(),
            };
        }

        const isExpired = lodashGet(playbackDetails, 'expireCount', 0) < 1;
        if (contentDetails.id !== prevState.contentDetails.id
            || playbackDetails.playback !== lodashGet(prevState, 'playbackDetails.playback')
            || !lodashIsEqual(playbackDetails.drm, prevState.playbackDetails.drm)) {
            // if only cookie is changed then set cookie and set should update to false
            const playbackUrlCookies = lodashGet(nextProps, 'playbackDetails.playback.headers.Cookie', null);
            const oldPlaybackUrlCookies = lodashGet(prevState, 'playbackDetails.playback.headers.Cookie', null);

            const nextPlaybackDetailsWithoutCookies = lodashOmit(nextProps.playbackDetails, [
                'playback.headers.Cookie',
            ]);
            const currentPlaybackDetailsWithoutCookies = lodashOmit(prevState.playbackDetails, [
                'playback.headers.Cookie',
            ]);

            const isAuthCookieChanged = playbackUrlCookies && playbackUrlCookies !== oldPlaybackUrlCookies;
            const onlyAuthCookieRefreshed = (playbackUrlCookies && playbackUrlCookies !== oldPlaybackUrlCookies)
                && lodashIsEqual(currentPlaybackDetailsWithoutCookies, nextPlaybackDetailsWithoutCookies);
            // it the data is already used then do not update
            if (isExpired === false || onlyAuthCookieRefreshed) {
                changes = {
                    ...prevState,
                    ...changes,
                    showErrorPlayer: false,
                    playbackDetails,
                    contentDetails,
                    isAuthCookieChanged,
                    onlyAuthCookieRefreshed,
                };
            }
            else {
                changes = {
                    ...prevState,
                    ...changes,
                    showErrorPlayer: false,
                    playbackDetails: {},
                    contentDetails,
                };
            }
        }

        if (playbackDetails.error !== prevState.playbackDetails.error) {
            changes = {
                ...prevState,
                ...changes,
                showErrorPlayer: false,
                playbackDetails,
            };
        }


        if (playbackDetails.heartBeatError !== prevState.playbackDetails.heartBeatError) {
            changes = {
                ...prevState,
                ...changes,
                showErrorPlayer: true,
                playbackDetails,
            };
        }

        if (playbackDetails.fetching === true
            || (prevState?.playbackDetails?.fetching && playbackDetails.fetching !== prevState?.playbackDetails?.fetching)
        ) {
            changes = {
                ...prevState,
                ...changes,
                playbackDetails,
            };
        }

        if (userEmail !== prevState.userEmail) {
            const emailIdErrorResolved = userEmail && userEmail.length > 0; // in previous state user email was not there but now user has email
            changes = {
                ...prevState,
                ...changes,
                userEmail,
                emailIdErrorResolved,
            };
        }

        if (uid !== prevState.uid) {
            changes = {
                ...prevState,
                ...changes,
                uid,
            };
        }


        if (prevState.nextEpisodeDetails !== nextEpisodeDetails) {
            changes = {
                ...prevState,
                ...changes,
                nextEpisodeDetails,
            };
        }


        // if (
        //     !lodash.isEqual(playbackDetails.drm, prevState.playbackDetails.drm)
        // ) {
        //     const isExpired = lodash.get(playbackDetails, 'expireCount', true);

        //     // it the data is already used then do not update
        //     if (isExpired === false) {
        //         changes = {
        //             ...prevState,
        //             ...changes,
        //             showErrorPlayer: false,
        //             playbackDetails,
        //         };
        //     }
        //     else {
        //         changes = {
        //             ...prevState,
        //             ...changes,
        //             showErrorPlayer: false,
        //             playbackDetails: {},
        //         };
        //     }
        // }

        return Object.keys(changes).length > 0 ? changes : null;
    }

    /*
        In case of network error we may not be able to fetch errorComponent chunk
        because there is no network. Hence we load it at mount time in Playback Container
    */
    loadLazyComponents() {
        this.isWeb ? import('../../web/src/modules/playbackComponents/components/error-player/ErrorPlayer').then(module => ErrorPlayer = module.default) : import('../../tv/src/modules/playbackComponents/components/error-player/ErrorPlayer' /* webpackChunkName: "ErrorPlayerLS" */).then(module => ErrorPlayer = module.default);
    }


    componentDidMount() {
        const { preLoad } = this.props;
        this.getPlaybackData({
            isReload: !this.isWeb,
            preLoad,
        });
        this.setPlaybackUrlCookies(this.props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {
            playbackInForeGround, showModal, preLoad, concurrentPlaybackLimitError, isEpgPageVisible,
        } = this.props;

        const {
            isAuthCookieChanged,
            onlyAuthCookieRefreshed,
        } = nextState;

        if (isAuthCookieChanged) {
            this.setPlaybackUrlCookies(nextProps);
        }

        if (playbackInForeGround !== nextProps?.playbackInForeGround || concurrentPlaybackLimitError !== nextProps.concurrentPlaybackLimitError || (isEpgPageVisible !== nextProps?.isEpgPageVisible)) {
            return true;
        }
        if (nextState === this.state && ((this.isWeb && showModal === nextProps.showModal) || (!this.isWeb && nextProps.preLoad === preLoad))) {
            return false; // nothing changed just :: no update
        }


        if (onlyAuthCookieRefreshed) {
            return false;
        }
        return true;
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            emailIdErrorResolved,
            triggerStopPlayback,
            closePlayer,
        } = this.state;
        const { playbackInForeGround: prevPlaybackInForeGround, shouldPlaybackRestart: prevShouldPlaybackRestart } = prevProps;
        const { isWeb } = this;
        const {
            contentDetails, playbackDetails, playbackDetails: { heartBeatError, fetching }, isAuthenticated, channelName, history, showModal, isTrailer, cpId, playableId,
            playbackInForeGround, shouldPlaybackRestart, resetPlaybackDetailsDispatch,
            concurrentPlaybackLimitError: { deviceLimitExceeded = false } = {}, location,
            modalComponent, retryPlayback,
        } = this.props;
        if (prevShouldPlaybackRestart !== shouldPlaybackRestart && shouldPlaybackRestart) {
            this.setState({ shouldPlaybackRestartState: true });
        }
        const contentAutoplay = lodashGet(contentDetails, 'contentAutoplay', false);
        if (this.playerEvents && playbackInForeGround !== prevPlaybackInForeGround && playbackInForeGround) {
            this.playerEvents?.setTimeUpdateFlag({ enable: true });
        }
        if (closePlayer && prevState.closePlayer !== closePlayer) {
            if (this.playerFunctions) {
                this.playerFunctions.dispose();
            }
            this.closePlayer();
            if (!deviceLimitExceeded) {
                resetPlaybackDetailsDispatch();
            }
        }
        if (isWeb && isTrailer && this.isPlaybackEnded && prevProps.showModal && !showModal) {
            const meta = {
                source_name: ANALYTICS_ASSETS.TRAILER_END_POPUP_EVENT,
                asset_name: 'trailer_buy_plan',
                action: ANALYTICS_ASSETS.TRAILER_END_POPUP_CLOSED,
            };
            screenClosedEvent(meta);
            history.go(-1);
        }

        if (triggerStopPlayback && this.playerFunctions) {
            this.playerFunctions.pause();
        }
        const imageUrl = ContentImageProvider({
            imageMap: (contentDetails && contentDetails.images),
            imgType: CONTENT_IMAGE_TYPES.LANDSCAPE_169,
        });
        // CHECK IF NEED TO SHOW ERROR PLAYER
        if (playbackDetails.error && playbackDetails.error !== prevProps.playbackDetails.error) {
            const {
                error: {
                    errorcode = null,
                    appErrorMessage = '',
                },
            } = playbackDetails;
            const isConcurrentScreen = [
                ERROR_CODES.ATV207,
                ERROR_CODES.ATV208,
            ].includes(errorcode);


            if (this.onLoadPlaybackErrorIgnored || playbackInForeGround) {
                // if previous error was email not entered and its email id is present the do not handle
                if (!(emailIdErrorResolved && errorcode === ERROR_CODES.ATV032)) {
                    const { browser, os } = (this.playbackConfigParams || {});
                    const parsedError = ErrorHandler({
                        error: playbackDetails.error,
                        code: errorcode,
                        browser,
                        os,
                        doNotHandle: errorcode === ERROR_CODES.ATV202,
                        errorTitle: appErrorMessage,
                    });

                    if ((parsedError?.handledBy || '') === ERROR_HANDLING_TYPES.ERROR_PLAYER_COMPONENT || isConcurrentScreen) {
                        const { errorData } = parsedError;
                        const programType = lodashGet(contentDetails, 'programType', '');
                        const contentName = (programType === PROGRAM_TYPES.EPISODE) ? lodashGet(contentDetails, 'episodeTvShowName', '') : lodashGet(contentDetails, 'title', '');
                        this.playbackUrlExpired();
                        this.playerErrorComponent = (
                            <ErrorPlayer
                                imageUrl={imageUrl}
                                reload={this.reload}
                                redirect={this.subscribe}
                                buttonText={errorData.buttonText}
                                message={errorData.message}
                                subText={errorData.subText}
                                rentButtonText={errorData.rentButtonText}
                                seeOtherPlanText={errorData.seeOtherPlanText}
                                getAccessText={errorData.getAccessText}
                                redirectionError={errorData.redirection}
                                rentalPlan={errorData.rentalPlan}
                                svodPlan={errorData.svodPlan}
                                channelName={channelName}
                                closeAction={() => history.go(-1)}
                                extendedMsg={errorData.extendedMsg}
                                contentName={contentName}
                                cpId={cpId}
                                contentAutoplay={contentAutoplay}
                                location={location}
                            />
                        );

                        // eslint-disable-next-line react/no-did-update-set-state
                        this.setState({
                            showErrorPlayer: true,
                        });
                    }
                }
            }
        }
        if (heartBeatError && !fetching && heartBeatError !== prevProps?.playbackDetails?.heartBeatError) {
            if (this.analyticeHelperV2 && isWeb) {
                this.analyticeHelperV2.handleHeartBeatErrorAnalytics(heartBeatError);
            }

            this.playerErrorComponent = (
                <ErrorPlayer
                    imageUrl={imageUrl}
                    reload={this.reload}
                    redirect={this.subscribe}
                    buttonText={heartBeatError.buttonText}
                    message={heartBeatError.message}
                    subText={heartBeatError.subText}
                    redirectionError={heartBeatError.redirection}
                    resetPlaybackDetails
                    channelName={channelName}
                    cpId={cpId}
                    closeAction={() => history.go(-1)}
                    contentAutoplay={contentAutoplay}
                    location={location}
                />
            );

            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                showErrorPlayer: true,
            });
        }
        // don't show error on first load :: therefore reset onLoadPlaybackErrorIgnored
        if (lodashGet(prevProps, 'contentDetails.id') !== lodashGet(this.props, 'contentDetails.id')) {
            if (this.onLoadPlaybackErrorIgnored === true) {
                this.onLoadPlaybackErrorIgnored = false;
            }

            // time stamp start
            this.playbackCallInitTimeStamp = new Date().getTime();
        }

        // auto retry on new login
        let isReload = prevProps.isAuthenticated !== isAuthenticated && isAuthenticated === true;

        // distro directplauback container reload check for concurrent limit retry
        isReload = isReload || (
            showModal !== prevProps.showModal
            && showModal === false
            && modalComponent === MODAL_POPUP.CONCURRENT_PLAY_LIMIT_REACHED
            && checkDirectPlaybackContainer()
            && retryPlayback
        );

        if ((prevProps.contentDetails?.id !== this.props.contentDetails?.id && this.props.contentDetails?.id)
            || isReload || (prevProps?.playableId !== playableId)
        ) {
            if (!prevProps.trailerRefId) {//On Back of trailer on mweb, prevent content playback call
                this.getPlaybackData({
                    isReload,
                    prevProps,
                });
            }
        }
    }

    componentWillUnmount() {
        const {
            playbackLoaded, trailerRefId = '', setPlayerConfigDispatch,
        } = this.props;
        if (trailerRefId) {
            setPlayerConfigDispatch({
                isTrailerEnded: false,
                isTrailerPlaying: false,
            });
        }
        if (this.disposeContinueWatching) {
            this.disposeContinueWatching(true);
        }
        if (playbackLoaded) {
            setPlayerConfig({
                playbackLoaded: false,
            });
        }
        const { hideModalComponentActionDispatch, loginTriggered } = this.props;
        if (loginTriggered) {
            hideModalComponentActionDispatch({
                showModalValue: false,
                componentNameValue: null,
                overrideCrossButtonValue: null,
            });
        }
        removeListenerFromEvent(VISIBILITY_CHANGE_EVENT, this.visibilityChangeCallback);
        if (this.unSubscribeReturnHandling) {
            this.unSubscribeReturnHandling.unsubscribe();
        }
        if (this.unSubscribeMediaStopHandling) {
            this.unSubscribeMediaStopHandling.unsubscribe();
        }
    }

    setPlaybackUrlCookies = (props) => {
        const cookieString = lodashGet(props, 'playbackDetails.playback.headers.Cookie', null);
        const playbackUrl = lodashGet(props, 'playbackDetails.playback.playUrl', null);

        const {
            playbackUrlCookies,
        } = parseLiveTvCooke(cookieString);

        if (playbackUrlCookies && playbackUrl) {
            const playbackUrlSchema = LocationUtil.parseUrl(playbackUrl);
            const domainSplit = playbackUrlSchema.host.split('.');
            const cookieDomain = `.${domainSplit[domainSplit.length - 2]}.${domainSplit[domainSplit.length - 1]}`;

            Object.keys(playbackUrlCookies).forEach((key) => {
                const value = playbackUrlCookies[key];
                Cookies.set(key, value, { domain: cookieDomain });
            });
        }
    };

    reload = () => {
        if (this.isWeb) {
            this.getPlaybackData({ isReload: true });
        }
        else {
            const {
                togglePlaybackActionDispatch, playbackClearAllDispatch, resetPlaybackDetailsDispatch, directPlayback, history,
            } = this.props;
            playbackClearAllDispatch();

            if (directPlayback) {
                history.go(-1);
                setTimeout(() => {
                    resetPlaybackDetailsDispatch();
                }, 100);
            }
            else {
                togglePlaybackActionDispatch(false);
                resetPlaybackDetailsDispatch();
            }
        }
    };

    setAdManagerInPlaybackContainer = (imaAdManager) => {
        this.imaAdManager = imaAdManager;
    };

    subscribe = () => {
        const {
            history, contentDetails, preferredPartnerPlans, circle, sid,
        } = this.props;
        const cpId = lodashGet(contentDetails, 'cpId', '');
        const contentUrl = windowLocation.pathname;
        subscriptionClickEvent({
            asset_name: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
            source_name: getCurrentWindowSourceName(),
            channel_name: cpId,
            sid,
            ingressIntent: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
        });
        history.push({
            pathname: RoutingUtil.getSubscriptionPage(),
            state: {
                ...contentDetails,
                origin: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE,
                contentUrl,
            },
        });

        // subscriptionInitDispatch({
        //     id: channelId,
        //     successRedirection: SUBSCRIPTION_SUCCESSFUL_PAGE_REDIRECTION.CURRENT,
        //     source: SUBSCRIPTION_SOURCE.PLAYER,
        // });
    };

    // canPlayPlayback = () => {
    //     const {
    //         maxAllowedPlaybackTime,
    //         userConfig,
    //         contentDetails,
    //         isSubscribed,
    //         isXppUser,
    //         isNonAirtelUser,
    //     } = this.props;
    //     const totalPlaybackTime = ScopedWebviewUtil.getPlaybackTime();
    //     const maxAllowedPlaybackTimeInSecs = maxAllowedPlaybackTime * 60;
    //     if (!this.deviceUtil.isScopedWebview() || isNonAirtelUser) {
    //         return true;
    //     }
    //     if (this.deviceUtil.isIOS()) {
    //         return false;
    //     }
    //     if (isXppUser) {
    //         return maxAllowedPlaybackTimeInSecs > totalPlaybackTime;
    //     }
    //     return contentDetails?.free ? maxAllowedPlaybackTimeInSecs > totalPlaybackTime : false;
    // };

    getPlaybackData = (
        { isReload, prevProps, preLoad } = { isReload: false },
    ) => {
        const {
            emailIdErrorResolved, playbackDetails, showErrorPlayer,
        } = this.state;
        const { isWeb } = this;

        const {
            isAuthenticated,
            contentDetails,
            playbackDetailsFetchDispatch,
            triggerLoginDispatch,
            loginTriggered,
            match,
            logoutTriggered,
            cpId,
            playableId,
            history,
            isTrailer,
            location,
            playbackDetailsFetchCompletedActionDispatch,
            playbackWithoutLoginDetailsFetchActionDispatch,
            isSubscribed,
            playAsTrailer: playAsTrailFromProps,
            trailerRefId: trailerRefIdFromProps,
            CP_CONTROL_CONFIG,
            continueWatchingDetails,
            maxAllowedPlaybackTime,
            userContentProperties,
            isXppUser,
            showModalComponentActionDispatch,
            pageId,
            appConfig,
            deepLinkConfig,
        } = this.props;
        const playAsTrailer = playAsTrailFromProps || contentDetails?.programType === PROGRAM_TYPES.TRAILER;
        const trailerUrl = isTrailer ? contentDetails?.trailers?.find(ele => ele.refId === playableId)?.trailerUrl : undefined;
        const trailerRefId = isTrailer ? trailerRefIdFromProps || contentDetails?.trailers?.find(ele => ele.refId === playableId)?.refId : playAsTrailer ? playableId : undefined;
        const playurl = isTrailer && trailerUrl ? trailerUrl : lodashGet(playbackDetails, 'playback.playUrl', '');
        if (!isWeb) {
            if (playurl) {
                const isPlaybackUrlValidValue = PlaybackUtil.getPlaybackExtension(playurl);
                if (isPlaybackUrlValidValue === INVALID_PLAYBACK_URL && !showErrorPlayer) {
                    const imageUrl = ContentImageProvider({
                        imageMap: (contentDetails && contentDetails.images),
                        imgType: CONTENT_IMAGE_TYPES.LANDSCAPE_169,
                    });
                    this.setState({ isPlaybackUrlValid: isPlaybackUrlValidValue });
                    this.playerErrorComponent = (
                        <ErrorPlayer
                            imageUrl={imageUrl}
                            reload={this.reload}
                            buttonText={this.LANGUAGE.BUTTON_RETRY}
                            message={this.LANGUAGE.UNKNOWN}
                            cpId={cpId}
                            closeAction={() => history.go(-1)}
                            location={location}
                        />
                    );
                    this.setState({
                        showErrorPlayer: true,
                    });
                    return;
                }
            }
        }

        if (playbackDetails.fetching === true) {
            return;
        }

        const contentId = playableId || contentDetails.id;

        // if login popup is shown then donot go ahead
        if (loginTriggered || logoutTriggered) {
            return;
        }

        // if user is not logged in
        if (!isAuthenticated) {
            if (this.onLoadPlaybackErrorIgnored || isReload) {
                if (!isTrailer) {
                    this.onLoadPlaybackErrorIgnored = true;
                    triggerLoginDispatch({
                        loginTriggered: true,
                        sourceOfLoginTrigger: LOGIN_SOURCE.PLAY_CLICK,
                    });
                    return;
                }
            }
        }

        if (isTrailer || playAsTrailer) {
            playbackWithoutLoginDetailsFetchActionDispatch({
                isTrailer: true,
                contentId: trailerRefId,
                contentDetails,
            });
            return;
        }

        if (lodashIsEmpty(contentDetails) || !contentId) {
            return;
        }

        if (contentDetails.fetching) {
            // Clicking on back once playback is run results in playback call being sent again
            return;
        }

        if (!isReload) {
            const isExpired = lodashGet(playbackDetails, 'expireCount', 0) < 1;

            // Commented because this results in playback call not being sent again once we have fetched trailer url
            // if (contentId === playbackDetails.contentId && playbackDetails && playbackDetails.expireCount > 0) {
            //     return;
            // }

            // if error exists then return if its hot reload or email error is not resolved
            if (playbackDetails.error && !(emailIdErrorResolved && playbackDetails.error.errorcode === ERROR_CODES.ATV032)) {
                if (isTrailer && prevProps?.playbackDetails !== playbackDetails) {
                    const { errorcode = '', notifyId = '' } = playbackDetails?.error;
                    ErrorHandler({
                        code: errorcode,
                        error: playbackDetails?.error,
                        contentId,
                    });
                    return;
                }
            }

            if (prevProps) {
                if (prevProps.contentDetails.id === contentDetails.id && prevProps.match === match && !playbackDetails.error) {
                    return;
                }
            }
        }

        // set first error ignored to true : we do not show error on first page load (business requirement)
        if (!this.onLoadPlaybackErrorIgnored && (playbackDetails.playback || playbackDetails.error || (isSubscribed && contentDetails.contentAutoplay))) {
            this.onLoadPlaybackErrorIgnored = true;
        }

        // time stamp start
        this.playbackCallInitTimeStamp = new Date().getTime();
        if (isAuthenticated) {
            const playbackParams = PlaybackParamsBuilder.buildPlayUrlParams({
                contentId,
                contentDetails,
                deviceUtil: this.deviceUtil,
                CP_CONTROL_CONFIG,
                continueWatchingDetails,
            });
            if (contentDetails?.cpId === 'DISTROTV') {
                this.distroPlaySessionId = playbackParams.distroPlaySessionId;
            }

            playbackDetailsFetchDispatch({
                contentId,
                playbackParams,
                scopedWebviewCallback: () => {
                    showModalComponentActionDispatch({
                        showModalValue: true,
                        componentNameValue: MODAL_POPUP.DOWNLOAD_XSTREAM_APP_POPUP,
                        overrideCrossButtonValue: true,
                        payload: {
                            contentDetails,
                            isSubscribed: isXppUser,
                            pageId,
                            meta: {
                                popup_id: 'download_hard_popup',
                                source_name: 'mweb_player',
                                content_category: 'Content',
                            },
                            notNowBtnHandler: () => {
                                history.go(-1);
                            },
                            downloadNowBtnHandler: () => {
                                ScopedWebviewUtil.navigateToDeepLinkOnCta(deepLinkConfig, DEEPLINK_INGRESS_KEYS.HARD_POPUP);
                            },
                        },
                    });
                },
            });
            // if (contentDetails?.cpId === 'DISTROTV') {
            //     this.loadDistroPlayback(contentId);
            // }
            // else {
            //     playbackDetailsFetchDispatch({
            //         contentId,
            //     });
            // }
        }
    };

    loadDistroPlayback = async (contentId) => {
        const { playbackDetailsFetchDispatch, contentDetails, CP_CONTROL_CONFIG } = this.props;
        const distroConfig = CP_CONTROL_CONFIG?.[contentDetails?.cpId];

        this.distroUtil = new DistroUtil({
            contentDetails,
            deviceUtil: this.deviceUtil,
            deviceTypeUtil: DeviceTypeUtil,
            controlConfig: distroConfig,
        });
        const { width, height } = this.distroUtil.calculatePlayerHeight();
        const userAgent = this.distroUtil.composeUserAgent();

        this.distroPlaySessionId = getUuid();
        const deviceMakeDetails = this.distroUtil?.getDeviceMakeDetails?.();
        let pageUrl = window.location.hostname === 'localhost' ? 'https://uat.airtelxstream.in' : window.location.origin + window.location.pathname;
        pageUrl = encodeURIComponent(pageUrl);
        const playbackParams = {
            playerWidth: width,
            playerHeight: height,
            appCategory: distroConfig?.appCategory || 'Entertainment',
            appBundle: window.location.host,
            appName: encodeURI(distroConfig?.appName || 'Airtel Xstream Play'),
            appDomain: window.location.host,
            appVersion: getAppVersion(),
            cacheBuster: getRandomNumber(),
            distroPlaySessionId: this.distroPlaySessionId,
            userAgent,
            deviceMake: deviceMakeDetails.deviceMake,
            pageUrl,
            advertisingId: this.deviceUtil.privateFingerPrintHolder,
        };
        playbackDetailsFetchDispatch({
            contentId,
            playbackParams,
        });
    };

    playerErrorHandling = (error, doNotHandle = false) => {
        console.error(error);
        const err = error.error ? error.error : error;

        if (!err.code) {
            return;
        }
        const {
            browser, os, isDrm, isMsp,
        } = (this.playbackConfigParams || {});

        if (err.code === ERROR_CODES.SHAKA_1001 && err.is_recoverable) {
            this.reload();
            return;
        }

        const parsedError = ErrorHandler({
            error: err,
            code: err.code,
            browser,
            os,
            doNotHandle,
        });
        const { errorData, handledBy, action: errorAction } = parsedError;

        const {
            contentDetails, history, appConfig, location,
        } = this.props;
        const contentAutoplay = lodashGet(contentDetails, 'contentAutoplay', false);
        const imageUrl = lodashGet(contentDetails, [
            'images',
            'LANDSCAPE_169',
        ], IMAGE_PATHS.DEFAULT_PLAYER_BACKGROUND);
        const LANGUAGE = LanguageProviderUtil.getLanguage();

        // For handling Safari browser error message
        if (!this.isWeb && BROWSER_LIST.SAFARI === browser && isDrm && isMsp && ![
            ERROR_CODES.VIDEOJS_BROWSER_NOT_SUPPORTED,
            ERROR_CODES.SAFARI_DRM_UNSUPPORTED_MOBILE_PUSH,
        ].includes(err.code) && !this.deviceUtil.isMobile()) {
            errorData && (errorData.message = LANGUAGE.OPEN_IN_SUPPORTED_BROWSER_OPEN_CHROME);
            errorData && (errorData.buttonText = LANGUAGE.BUTTON_RETRY);
        }
        if (handledBy === ERROR_HANDLING_TYPES.ERROR_PLAYER_COMPONENT) {
            const errorPlayerHandler = () => {
                switch (errorAction) {
                    case ERROR_ACTIONS.RETRY_PLAYBACK:
                        this.reload();
                        break;
                    case ERROR_ACTIONS.MOBILE_APP_PUSH: {
                        if (contentDetails.shortUrl) {
                            openContentInApp({
                                contentIdShortLink: contentDetails.shortUrl,
                                isMobile: this.deviceUtil.isMobile(),
                            });
                        }
                        else {
                            onInstallClick({
                                clickItem: DOWNLOAD_APP_ACTIONS.COMMON_APP_DOWNLOAD,
                                isPlayerError: true,
                                appConfig,
                            });
                        }
                        break;
                    }

                    case ERROR_ACTIONS.SAFARI_DRM_MOBILE_APP_PUSH: {
                        onInstallClick({
                            clickItem: DOWNLOAD_APP_ACTIONS.CP_UNSUPPORTED_REDIRECT,
                            isPlayerError: true,
                            appConfig,
                        });
                    }
                        break;
                    default:
                        this.reload();
                        break;
                }
            };

            const { cpId = '' } = this.props;
            const {
                buttonText = '', message = '', subText = '', extendedMsg = '',
            } = errorData;
            let hidePrimaryCta = false;
            if (cpId === 'DISTROTV') {
                hidePrimaryCta = true;
            }
            this.playerErrorComponent = (
                <ErrorPlayer
                    imageUrl={imageUrl}
                    reload={errorPlayerHandler}
                    buttonText={buttonText}
                    message={message}
                    subText={subText}
                    cpId={cpId}
                    closeAction={() => history.go(-1)}
                    extendedMsg={extendedMsg}
                    contentAutoplay={contentAutoplay}
                    location={location}
                    hidePrimaryCta={hidePrimaryCta}
                />
            );

            this.setState({
                showErrorPlayer: true,
            });
        }
    };

    browserNotSupported = (error) => {
        this.playerErrorHandling(error);
    };

    autoRetryIfIndexFileFailed = () => {
        // CHECK NETWORK CONNECTION
        if (this.autoRetryCount < MAX_AUTO_RETRY_LIMIT) {
            this.autoRetryCount += 1;
            this.reload();
        }
        else {
            this.playerErrorHandling({ code: ERROR_CODES.LOCAL1008 });
        }
    };

    refreshCookie = () => {
        const { playbackDetailsCookiesFetchActionDispatch, contentDetails } = this.props;
        const contentId = contentDetails.id;

        const cookieString = lodashGet(this.props, 'playbackDetails.playback.headers.Cookie', null);

        const {
            cookieExpire,
        } = parseLiveTvCooke(cookieString);

        if (isOnline() && cookieExpire) {
            const expireDT = new Date((cookieExpire) * 1000);
            expireDT.setSeconds(expireDT.getSeconds() - COOKIE_REFRESH_BEFORE_EXPIRE_SECONDS);
            if (new Date().getTime() > expireDT.getTime()) {
                playbackDetailsCookiesFetchActionDispatch({
                    contentId,
                });
            }
        }
    };

    visibilityChangeCallback = () => {
        const { contentDetails } = this.props;
        const meta = {
            content_id: contentDetails.id,
            cpName: contentDetails.cpId,
            content_type: getContentType({ contentDetails }),
        };
        if (checkDocumentHidden()) {
            switchToAnotherTabEvent(meta);
        }
        else if (!checkDocumentHidden()) {
            switchBackToXstreamTabEvent(meta);
        }
    };

    addVisibilityChangeEvent = () => {
        const { sdkAdsPlayingFlag, adsPlayingFlag } = this.props;
        if (!(sdkAdsPlayingFlag || adsPlayingFlag)) {
            addListenerToEvent(VISIBILITY_CHANGE_EVENT, this.visibilityChangeCallback);
            setTimeout(() => this.playerFunctions.play(), 300);
        }
    };

    removeVisibilityChangeEvent = () => {
        const { sdkAdsPlayingFlag, adsPlayingFlag } = this.props;
        if (!(sdkAdsPlayingFlag || adsPlayingFlag)) {
            setTimeout(() => this.playerFunctions.play(), 300);
            removeListenerFromEvent(VISIBILITY_CHANGE_EVENT, this.visibilityChangeCallback);
        }
    };

    seePlanHandler = () => {
        const {
            history, hideModalComponentActionDispatch, isAuthenticated, triggerLoginDispatch,
        } = this.props;
        if (!isAuthenticated) {
            triggerLoginDispatch({
                loginTriggered: true,
                sourceOfLoginTrigger: LOGIN_SOURCE.TRAILER_XSTREAM_SUBSCRIPTION,
                redirectUrl: ROUTE_PATHS.SUBSCRIPTION_PAGE,
            });
        }
        else {
            history.push(ROUTE_PATHS.SUBSCRIPTION_PAGE);
            hideModalComponentActionDispatch({
                showModalValue: false,
                componentNameValue: null,
                overrideCrossButtonValue: null,
            });
        }
    };

    handleCdnErrorReporting(error) {
        if (error.code == ERROR_CODES.SHAKA_1001) {
            const { data } = error;
            if (data && data.length >= 1 && data[1] === HTTP_STATUS_CODE.FORBIDDEN_403) {
                if (this.cdnErrorDict[data[0]]) {
                    this.cdnErrorDict[data[0]]++;
                    error.is_recoverable = false;
                }
                else {
                    this.cdnErrorDict[data[0]] = 1;
                    error.is_recoverable = true;
                }
            }
        }
        return error;
    }

    onContentEnd = (playerEvents, playerFunctions) => {
        const {
            isTrailer, showModalComponentActionDispatch, isSubscribed, contentDetails, trailerRefId = '', setPlayerConfigDispatch, playbackInForeGround, navigate, isTrailerPlaying,
            isCDP = false, nextEpisodeDetails, contentDetailsFetchActionDispatch, modifyInitialPlaybackDetailsDispatch,
            cdpTabOpen, railCtaText, directPlayback, resetPlaybackDetailsDispatch, contentPlayableId, history,
        } = this.props;

        this.isPlaybackEnded = true;
        if (this.isWeb) {
            if (nextEpisodeDetails != null && nextEpisodeDetails.url) {
                contentDetailsFetchActionDispatch(nextEpisodeDetails.details.refId);
            }
            else if (!isTrailer) {
                navigate(-1);
                return;
            }
            if (isTrailer && !isSubscribed) {
                // showModalComponentActionDispatch({
                //     showModalValue: true,
                //     componentNameValue: MODAL_POPUP.BUY_PLAN_AFTER_TRAILER_POPUP,
                //     overrideCrossButtonValue: false,
                //     payload: {
                //         seePlanHandler: this.seePlanHandler,
                //         contentDetails,
                //         replay: playerFunctions.play,
                //     },
                // });
            }
        }
        else if (((!nextEpisodeDetails || (nextEpisodeDetails && !Object.keys(nextEpisodeDetails).length)) && !isTrailerPlaying && playbackInForeGround) || !isCDP || (isTrailerPlaying && contentPlayableId)) {
            modifyInitialPlaybackDetailsDispatch({
                cdpTabOpen,
                isSubscribed,
                railCtaText,
            });
        }
        if (!this.isWeb) {
            if (nextEpisodeDetails && Object.keys(nextEpisodeDetails).length && !isTrailerPlaying && playbackInForeGround) {
                contentDetailsFetchActionDispatch(nextEpisodeDetails.details.refId);
            }
            else if (directPlayback) {
                history.go(-1);
                setTimeout(() => {
                    resetPlaybackDetailsDispatch();
                }, 100);
            }
        }

        setPlayerConfigDispatch({
            playbackLoaded: false,
            isTrailerPlaying: false,
            isTrailerEnded: !!trailerRefId,
        });
    };


    showPlaybackLimitSoftNudge = () => {
        const { playbackSoftNudgeTimerList = [] } = this.props;
        const softNudgeList = playbackSoftNudgeTimerList;
        const totalPlaybackTime = ScopedWebviewUtil.getPlaybackTime();
        const softNudgeIndex = ScopedWebviewUtil.getPlaybackSoftNudgeIndex();
        if (softNudgeList.length) {
            if (softNudgeIndex >= softNudgeList.length) {
                // setState for showing floating download nudge
                this.setState({ showDownloadFloatingNudge: { show: true } });
                return false;
            }
            const timer = (+(softNudgeList[softNudgeIndex])) * 60;
            const showNudge = totalPlaybackTime > timer;
            if (showNudge) {
                ScopedWebviewUtil.setPlaybackSoftNudgeIndex(softNudgeIndex + 1);
                return showNudge;
            }
            return false;
        }
        return false;
    };

    // LOCAL HANDLING OF PLAYER ERRORS
    playerEventHandler = (playerEvents, playerFunctions) => {
        if (!playerEvents || !playerEvents.emitter) {
            return;
        }
        const {
            contentDetails,
            adConfig,
            playbackDetails,
        } = this.props;
        const { streamType } = this.playbackConfigParams ? this.playbackConfigParams : {};
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.ENDEND, () => {
            if (checkSdkAdsAllowed(contentDetails, adConfig) || (checkPartnerAdsinPlayback(playbackDetails))) {
                playerEvents.emitter.emit(PLAYER_EVENTS_LIST.TRIGGER_POST_ROLL_AD);
                return;
            }
            this.onContentEnd(playerEvents, playerFunctions);
        });
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.CONTENT_WITH_POSTROLL_COMPLETION, () => {
            this.onContentEnd(playerEvents, playerFunctions);
        });

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.RETRY, () => {
            this.props.dispatchGetAdsPlaying({ adsPlayingFlag: false });
            this.playerFunctions.setAbrFalse();
        });

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.ERROR_CDN_RETRY, (error) => {
            // Playback Container process error and fires PLAYER_EVENTS_LIST.ERROR
            if (streamType !== STREAM_TYPES.LIVE) {
                error = this.handleCdnErrorReporting(error);
            }
            playerEvents.emit(PLAYER_EVENTS_LIST.ERROR, error);
        });

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.ERROR, (error) => {
            console.error(error);
            const code = lodashGet(error, 'code', '').toString();
            // TODO: REFACTOR below logic
            if ((code === ERROR_CODES.SHAKA_1002
                || code === ERROR_CODES.SHAKA_1001
                || code === ERROR_CODES.LOCAL1006)
                && streamType === STREAM_TYPES.LIVE
                && isOnline()) {
                console.error(error);

                // if (isOnline() && !playerFunctions.paused()) {
                //     this.autoRetryIfIndexFileFailed();
                // }
            }
            // OTHER ERRORS
            else {
                this.playerErrorHandling(error, true);
            }
        });

        // LIVE CFS AUTO RETRY
        if (this.playbackConfigParams.playbackUrlCookies) {
            // SPECIAL HANDLING FOR SAFARI DRM LIVE STREAM COOKIE EXPIRE
            // if (BROWSER_LIST.SAFARI) {
            playerEvents.emitter.on(PLAYER_EVENTS_LIST.PLAY, this.throttledRefreshCookie);
            // }

            playerEvents.emitter.on(PLAYER_EVENTS_LIST.PAUSE, this.throttledRefreshCookie);

            playerEvents.emitter.on(PLAYER_EVENTS_LIST.WAITING, this.throttledRefreshCookie);


            // LIVE
            if (this.playerFunctions.isLiveStream()) {
                playerEvents.emitter.on(PLAYER_EVENTS_LIST.LIVE_TIME_UPDATE, this.throttledRefreshCookie);
            }
            // VOD
            else {
                playerEvents.emitter.on(PLAYER_EVENTS_LIST.TIMEUPDATE, this.throttledRefreshCookie);
            }

            // playerEvents.emitter.on(PLAYER_EVENTS_LIST.RETRY_PLAYLIST, () => {
            //     if (isOnline() && !playerFunctions.paused()) {
            //         this.autoRetryIfIndexFileFailed();
            //     }
            // });
        }

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.OPEN_PIP, this.addVisibilityChangeEvent);

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.CLOSE_PIP, this.removeVisibilityChangeEvent);

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.LOADED, () => {
            this.playbackUrlExpired();
            this.autoRetryCount = 0;
        });

        playerEvents?.emitter.on(PLAYER_EVENTS_LIST.FIRST_PLAY, () => {
            const {
                playbackLoaded, setPlayerConfigDispatch,
            } = this.props;
            if (!playbackLoaded) {
                setPlayerConfigDispatch({
                    playbackLoaded: true,
                });
            }
        });

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.PLAYING, () => {
            const {
                trailerRefId = '', setPlayerConfigDispatch,
            } = this.props;
            if (trailerRefId) {
                setPlayerConfigDispatch({
                    isTrailerPlaying: true,
                });
            }
        });
        playerEvents.emitter.on(PLAYER_EVENTS_LIST.TIMEUPDATE, () => {
            const {
                showModalComponentActionDispatch, hideModalComponentActionDispatch, playbackDetails: { contentType = '' } = {}, isSubscribed, history, pageId, isXppUser, playbackSoftNudgeTimerList, maxAllowedPlaybackTime, userContentProperties, deepLinkConfig, showModal, modalComponent,
            } = this.props;
            if (this.isWeb && contentType !== PROGRAM_TYPES.TRAILER) {
                if (!ScopedWebviewUtil.canPlayPlayback(maxAllowedPlaybackTime, userContentProperties, contentDetails)) {
                    const { userSessionId, analyticsPlaySessionId } = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META) || {};
                    this.playerFunctions.pause();
                    showModalComponentActionDispatch({
                        showModalValue: true,
                        componentNameValue: MODAL_POPUP.DOWNLOAD_XSTREAM_APP_POPUP,
                        overrideCrossButtonValue: true,
                        payload: {
                            contentDetails,
                            isSubscribed: isXppUser,
                            pageId,
                            meta: {
                                popup_id: 'download_hard_popup',
                                source_name: 'mweb_player',
                                content_category: 'Content',
                                ...(userSessionId ? { user_session_id: userSessionId } : {}),
                            },
                            ...(analyticsPlaySessionId ? {
                                corePlaybackMeta: { play_session_id: analyticsPlaySessionId },
                            } : {}),
                            notNowBtnHandler: () => {
                                hideModalComponentActionDispatch({
                                    showModalValue: false,
                                    componentNameValue: null,
                                    overrideCrossButtonValue: false,
                                });
                                history.go(-1);
                            },
                            downloadNowBtnHandler: () => {
                                ScopedWebviewUtil.navigateToDeepLinkOnCta(deepLinkConfig, DEEPLINK_INGRESS_KEYS.HARD_POPUP);
                            },
                        },
                    });
                    return;
                }
                if (this.showPlaybackLimitSoftNudge()) {
                    const { userSessionId, analyticsPlaySessionId } = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META) || {};
                    this.playerFunctions.pause();
                    // showing soft nudge, dissmisable
                    showModalComponentActionDispatch({
                        showModalValue: true,
                        componentNameValue: MODAL_POPUP.DOWNLOAD_XSTREAM_APP_POPUP,
                        overrideCrossButtonValue: true,
                        showBackButton: true,
                        payload: {
                            contentDetails,
                            closeBtnClickedHandler: () => {
                                this.playerFunctions.play();
                            },
                            isSubscribed: isXppUser,
                            pageId,
                            meta: {
                                popup_id: 'download_soft_popup',
                                source_name: 'mweb_player',
                                content_category: 'Content',
                                ...(userSessionId ? { user_session_id: userSessionId } : {}),
                            },
                            ...(analyticsPlaySessionId ? {
                                corePlaybackMeta: { play_session_id: analyticsPlaySessionId },
                            } : {}),
                            popupShowTime: playbackSoftNudgeTimerList[ScopedWebviewUtil.getPlaybackSoftNudgeIndex() - 1],
                            notNowBtnHandler: () => {
                                this.playerFunctions.play();
                                hideModalComponentActionDispatch({
                                    showModalValue: false,
                                    componentNameValue: null,
                                    overrideCrossButtonValue: false,
                                });
                            },
                            downloadNowBtnHandler: () => {
                                ScopedWebviewUtil.navigateToDeepLinkOnCta(deepLinkConfig, DEEPLINK_INGRESS_KEYS.SOFT_POPUP);
                            },
                        },
                    });
                }
                // }
            }
        });
    };

    playerEventsInitialized = (playerEvents, playerFunctions, useLegacyShaka) => {
        const {
            playbackEventsInitialized,
            browserHistory,
            syncContentAddDispatch,
            syncContentInitDispatch,
            syncAndAddContentDispatch,
            syncContentRemoveDispatch,
            continueWatchingDetails,
            contentDetailsFetchActionDispatch,
            playbackDetails: { heartBeatInterval },
            history,
            lang,
            syncContent,
            playbackDetails,
            sendHeartbeatActionDispatch,
            nextEpisodeDetails,
            removeFromCwTime,
            disableCWforcps,
            flagControl,
            shouldAddInCW,
            shouldPlaybackRestart,
            setPlayerConfigDispatch = () => {},
            contentDetails: { cpId: cpName, playAsTrailer },
            isTrailer,
            playbackInForeGround,
            isContentAutoplay,
            explicitTrailerRefId,
            CP_CONTROL_CONFIG,
        } = this.props;
        let { contentDetails } = this.props;
        const { HLS_DAI_SUPPORTED_PLATFORMS = HLS_DAI_PLATFORMS } = CP_CONTROL_CONFIG;
        this.playerFunctions = playerFunctions;
        if (!playerEvents || !playerEvents.emitter) {
            return;
        }
        if (playerEvents.setTimeUpdateFlag && isTrailer && !playbackInForeGround && !this.isWeb) {
            playerEvents.setTimeUpdateFlag({ enable: false });
            this.playerEvents = playerEvents;
        }
        // providing parent the player events
        playbackEventsInitialized(playerEvents, this.playbackConfigParams);

        this.playerEventHandler(playerEvents, playerFunctions); // local handling of player errors


        const { playbackSessionId, daiAssetKey } = this.playbackConfigParams;

        const { playerAnalyticsV1 = true, playerAnalyticsV2 = false } = flagControl;
        if (contentDetails?.cpId === 'DISTROTV') {
            this.pixelAnalytics = new PlaybackPixelAnalytics({
                playerEvents,
                contentDetails,
                playbackDetails,
                playerFunctions,
                deviceUtil: this.deviceUtil,
                playbackMode: HLS_DAI_SUPPORTED_PLATFORMS.includes(cpName) ? PLAYBACK_MODES.DAI : PLAYBACK_MODES.PLAY_URL,
                pixelPlaybackSessionId: this.distroPlaySessionId,
                controlConfig: CP_CONTROL_CONFIG?.DISTROTV,
            });
        }
        if (playerAnalyticsV2 && !daiAssetKey) {
            this.analyticeHelperV2 = new AnalyticsHelperV2({
                playerEvents,
                contentDetails,
                playbackDetails,
                playbackCallInitTimeStamp: this.playbackCallInitTimeStamp,
                playerFunctions,
                sourceName: getUrlSourceName(browserHistory.paths, this.props),
                playbackSessionId,
                lang,
                isTrailerAuto: isTrailer && !explicitTrailerRefId,
                isContentAutoplay,
            });
        }
        if (isTrailer) {
            return; // disable cw, analytics v1 and heart beat for tailer
        }

        if (playerAnalyticsV1 || DeviceTypeUtil.getDevice() === PLAYBACK_CHECK.TV) {
            AnalyticsHelper({
                playerEvents,
                contentDetails,
                playbackDetails,
                playbackCallInitTimeStamp: this.playbackCallInitTimeStamp,
                playerFunctions,
                sourceName: getUrlSourceName(browserHistory.paths, this.props),
                playbackSessionId,
                lang,
            });
        }

        // CONTINUE WATCHING HELPER
        if (this.disposeContinueWatching) {
            this.disposeContinueWatching();
        }

        if ((syncContent.continueWatchingData)) {
            contentDetails = setMultiAudio(contentDetails, syncContent.continueWatchingData);
        }
        if (!disableCWforcps.includes(cpName && cpName.toUpperCase() || '')) {
            const { skipCreditDur = null } = this.playbackConfigParams || {};
            if (skipCreditDur) {
                if (contentDetails?.programType === PROGRAM_TYPES.EPISODE) {
                    removeFromCwTime.tvshows = skipCreditDur;
                }
                else {
                    removeFromCwTime.movies = skipCreditDur;
                }
            }
            this.disposeContinueWatching = ContinueWatchingHelper({
                isTrailer,
                playerEvents,
                contentDetails,
                playerFunctions,
                addContentToContinueWatching: syncContentAddDispatch,
                removeContentToContinueWatching: syncContentRemoveDispatch,
                addContentToContinueWatching: syncContentAddDispatch,
                syncContentInit: syncContentInitDispatch,
                addAndSyncContent: syncAndAddContentDispatch,
                contentDetailsFetchAction: contentDetailsFetchActionDispatch,
                continueWatchingDetails,
                nextEpisodeDetails,
                history,
                removeFromCwTime,
                useLegacyShaka,
                analyticsHelper: this.analyticeHelperV2,
                shouldAddInCW,
                playAsTrailer,
                shouldPlaybackRestart,
                setPlayerConfigDispatch,
            });
        }

        // HEART BEAT HELPER
        HeartBeatHelper({
            playerEvents,
            contentId: contentDetails.id,
            sendHeartBeat: sendHeartbeatActionDispatch,
            heartBeatInterval,
        });
    };

    closePlayer = () => {
        const {
            location,
            history,
            setPlayerConfigDispatch,
            concurrentPlaybackLimitError: { deviceLimitExceeded = false } = {},
            playbackInForeGround,
            // hideModalComponentActionDispatch,
        } = this.props;
        const { search = '', pathname = '' } = location;
        let newSearch = '';
        if (DeviceTypeUtil?.isTV()) {
            if (deviceLimitExceeded) {
                setPlayerConfigDispatch({
                    playbackInForeGround: false,
                    startPlayback: false,
                    playbackLoaded: false,
                });
            }
            // else {
            //     hideModalComponentActionDispatch({
            //         showModalValue: false,
            //         componentNameValue: MODAL_POPUP.CONCURRENT_PLAY_LIMIT_REACHED,
            //         overrideCrossButtonValue: null,
            //     });
            // }
            modifyInitialPlaybackDetails({});
            return;
        }
        try {
            if (search.includes(SHOW_PLAYBACK_TRUTHY_PARAM)) {
                newSearch = search.replace(SHOW_PLAYBACK_TRUTHY_PARAM, '');
            }
            else if (search.includes(SHOW_PLAYBACK_FALSY_PARAM)) {
                newSearch = search.replace(SHOW_PLAYBACK_FALSY_PARAM, '');
            }
        }
        catch (err) {
            console.log('ERRRORRRR: closePlayer fn -> url query replace', err);
        }
        if (newSearch) {
            history.replace({
                pathname,
                search: newSearch,
            });
        }

        return newSearch;
    };

    // concurrentLimitErrorHandler = () => {
    //     const {
    //         showModalComponentActionDispatch, concurrentPlaybackLimitError, location, history,
    //     } = this.props;
    //     const { pathname = '' } = location;
    //     const { deviceLimitExceeded, deviceResponse: { devices = [] } = {} } = concurrentPlaybackLimitError;
    //     if (deviceLimitExceeded && devices?.length) {
    //         const newSearch = this.closePlayer();
    //         showModalComponentActionDispatch({
    //             showModalValue: true,
    //             componentNameValue: MODAL_POPUP.CONCURRENT_PLAY_LIMIT_REACHED,
    //             overrideCrossButtonValue: true,
    //             payload: {
    //                 popUpcustomClass: 'second-pop-up',
    //             },
    //         });
    //     }
    // };


    getVideoContainer = () => {
        const {
            contentDetails, authToken, nextEpisodeDetails, previewImageCodeBlocked, videoMeta, blockBitRateOnLive, langInfo, adsData,
            preLoad = false, useLegacyShakaCps, isTrailer, CP_CONTROL_CONFIG, concurrentPlaybackLimitError,
            playbackInForeGround, resetPlaybackDetailsDispatch,
            bufferingGoal, playAsTrailer, handleEpgViewChangeDispatch = () => {}, isEpgPageVisible = false, adConfig,
            lastWatchedPositionTrailer = '', pageId,
            contentAutoplay,
        } = this.props;
        const {
            playbackDetails, showErrorPlayer, playbackSessionId, isWeb, shouldPlaybackRestartState, showDownloadFloatingNudge,
        } = this.state;

        let errorPlayer = null;
        // if (concurrentPlaybackLimitError?.deviceLimitExceeded) {
        //     this.concurrentLimitErrorHandler();
        //     return;
        // }

        if (showErrorPlayer) {
            errorPlayer = this.playerErrorComponent;
            this.playerFunctions = null;
            if (this.disposeContinueWatching) {
                this.disposeContinueWatching();
            }
            if (this.isTv) {
                if (!playbackInForeGround) {
                    resetPlaybackDetailsDispatch();
                }
                else {
                    this.onLoadPlaybackErrorIgnored = true;
                }
            }
            else {
                this.onLoadPlaybackErrorIgnored = true;
            }
        }
        // go in when playbackDetails has some details
        if ((playbackDetails.playback || playbackDetails.error || (playbackDetails.heartBeatError && !isWeb)) && (contentDetails.id || contentDetails.contentId)) {
            const playbackConfigParams = PlaybackConfigBuilder.buildParams({
                playbackDetails,
                contentDetails,
                authToken,
                deviceUtil: this.deviceUtil,
                playbackSessionId,
                nextEpisodeDetails,
                previewImageCodeBlocked,
                blockBitRateOnLive,
                videoMeta,
            });

            // when player events are initialized then this info will be passed to the playbackEventsInitialized for parent
            this.playbackConfigParams = playbackConfigParams;
            if (!errorPlayer) {
                const { browser, os, isDrm } = this.playbackConfigParams;
                if (!isPlatformSupported(browser, os)) {
                    this.playerErrorHandling({ code: ERROR_CODES.SAFARI_DRM_UNSUPPORTED_MOBILE_PUSH });
                }
            }
            const {
                streamType,
                playbackType,
                expireCount,
            } = playbackConfigParams;

            // will be updated to reducer after successful load event
            this.playbackUrlExpireCount = expireCount;

            const playbackConfig = PlaybackConfigBuilder.buildConfig(playbackConfigParams);
            /*
                Non HLS content, Non live content, Non Trailer, whose cp is controlled by cms(sony) recive useLegacy flag
                Content which have adsAllowed also don't get useLegacy flag
            */
            if (useLegacyShakaCps.includes(contentDetails.cpId) && !isTrailer && playbackConfig.streamType !== STREAM_TYPES.LIVE && playbackType !== PLAYBACK_TYPES.M3U8) {
                const { additionalProperties: { adsAllowed = 'false' } = {} } = playbackDetails;
                if (adsAllowed && adsAllowed.toLowerCase() === 'true') {
                    playbackConfig.useLegacyShaka = false;
                }
                else {
                    playbackConfig.useLegacyShaka = true;
                }
            }
            playbackConfig.useLegacyShaka = false;

            if (browserStore.get('daiAssetKey')) {
                console.log('dai====>', browserStore.get('daiAssetKey'));
                playbackConfig.daiAssetKey = browserStore.get('daiAssetKey');
            }
            // playbackConfig.daiAssetKey = 'sN_IYUG8STe1ZzhIIE_ksA';
            const controlBar = getControlBar(streamType);
            const playbackAdsData = lodashGet(playbackDetails, 'playback', '');
            const { HLS_DAI_SUPPORTED_PLATFORMS = HLS_DAI_PLATFORMS } = CP_CONTROL_CONFIG;
            const isLiveStream = streamType === STREAM_TYPES.LIVE;
            const playerElement = errorPlayer || (
                <PlayerFactory
                    playbackType={playbackType}
                    playbackDetails={playbackDetails}
                    playbackAdsData={playbackAdsData}
                    isLiveStream={isLiveStream}
                    browserNotSupported={this.browserNotSupported}
                    reload={this.reload}
                    playerEventsInitialized={this.playerEventsInitialized}
                    playbackConfig={playbackConfig}
                    adsData={adsData}
                    langInfo={langInfo}
                    ControlBar={controlBar}
                    contentDetails={contentDetails}
                    getAdManagerInPlaybackContainer={this.getAdManagerInPlaybackContainer}
                    preLoad={preLoad}
                    contentAutoplay={contentDetails?.contentAutoplay}
                    hideControlsOnIdleStateOnly={this.deviceUtil.isMobile()}
                    HLS_DAI_SUPPORTED_PLATFORMS={HLS_DAI_SUPPORTED_PLATFORMS}
                    playbackInForeGround={playbackInForeGround}
                    key={shouldPlaybackRestartState}
                    bufferingGoal={bufferingGoal}
                    playTrailer={playAsTrailer}
                    handleEpgViewChangeDispatch={handleEpgViewChangeDispatch}
                    isEpgPageVisible={isEpgPageVisible}
                    adConfig={adConfig}
                    lastWatchedPositionTrailer={lastWatchedPositionTrailer}
                    // canPlayPlaybackCallback={ScopedWebviewUtil.canPlayPlayback}
                    showDownloadFloatingNudge={showDownloadFloatingNudge}
                    pageId={pageId}
                    contentAutoplay={contentAutoplay}
                />
            );

            return (
                <Suspense fallback={this.getPlaceHolder(this.reload)}>
                    <div className="col-xl-8 ">
                        <div className={`current-vdo__player ${playbackInForeGround ? 'z-index-99' : ''}`}>
                            <div
                                id="video-player"
                                className="vdo-player"
                            >
                                {playerElement}
                            </div>
                        </div>
                    </div>
                </Suspense>
            );
        }

        return this.getPlaceHolder(this.reload);
    };

    getPlaceHolder = (reload) => {
        const {
            contentDetails,
            deviceUtil,
            isAuthenticated,
            concurrentPlaybackLimitError,
            playbackInForeGround,
        } = this.props;
        // if (concurrentPlaybackLimitError?.deviceLimitExceeded) {
        //     this.concurrentLimitErrorHandler();
        //     return;
        // }

        const posterUrl = deviceUtil.isBot() || !isAuthenticated ? getPosterUrlFromContentDetails(contentDetails) : '';
        console.log('player posterUrl', posterUrl);
        const centerIconElement = isAuthenticated ? <PlayerLoaderComponent /> : (
            <div className="vdo-player__overlay fade-in">
                <div className="vdo-player__center-controls d-flex justify-content-between align-items-center">
                    <div className="vdo-player__play-options">
                        <div className="d-flex justify-content-center align-items-center disable-select">
                            <div className="vdo-player__play-icon">
                                <button
                                    onClick={reload}
                                    className="button button-icon button-icon-pp"
                                    type="button"
                                >
                                    <i className="center-control-icon-size icon-player-play" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        return (
            <div className="col-xl-8 ">
                <div className={`current-vdo__player ${playbackInForeGround ? 'z-index-99' : ''}`}>
                    <div
                        id="video-player"
                        className="vdo-player"
                    >
                        <div className="vdo-player__wrapper ">
                            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                            {!this.isScopedWebview
                                ? <video poster={posterUrl} /> : null}
                            {centerIconElement}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    playbackUrlExpired() {
        // the data is used so set the stale key
        const { playbackDetailsExpiredActionDispatch, contentDetails } = this.props;
        playbackDetailsExpiredActionDispatch({
            contentId: contentDetails.id,
            expireCount: this.playbackUrlExpireCount,
        });
    }

    render() {
        return checkWindowExist() ? this.getVideoContainer() : this.getPlaceHolder(this.reload);
    }
}

const mapStateToProps = (state, props) => {
    const {
        playbackDetails,
        playbackDetails: {
            closePlayer = false,
            concurrentPlaybackLimitError = {},
        },
        appConfig,
        authConfig,
        userConfig,
        browserHistory,
        authConfig: { loginTriggered },
        syncContent,
        planConfig,
        modalConfig: { showModal = 'false', componentName: modalComponent, componentProps: { retryPlayback } = {} } = {},
        notify: { nextPlayedContentCount = 0, isEpgPageVisible = false },
        homepageConfig: {
            playerConfig: {
                playbackLoaded = false, playbackInForeGround = false, isTrailerPlaying = false, startPlayback = false, shouldPlaybackRestart = false,
                cdpTabOpen,
                railCtaText,
                directPlayback = false,
                isContentAutoplay = false,
                contentPlayableId = '',
            } = {},
        } = {},
        contentDetails: contentCollection,
        layoutDetails,
    } = state;
    const {
        blockFeature,
        cpDetailsById,
        removeFromCwTime = {
            tvshows: 10,
            movies: 60,
        },
        useLegacyShakaCps = [
            'SONYLIV_VOD',
        ],
        flagControl = {},
        disableCWforcps = [],
        CP_CONTROL_CONFIG = DEFAULT_CP_CONTROL_CONFIG,
        web_pageId,
        maxAllowedPlaybackTime = 20,
        playback_soft_nudge_timer_list: playbackSoftNudgeTimerList,
        deepLinkConfig,
    } = appConfig;
    const { SDK_AD_CONFIG = 'adsConfig' } = web_pageId;
    const adConfig = layoutDetails[SDK_AD_CONFIG];
    const { nextEpisodeDetails, contentDetails } = props;
    const { videoMeta } = props;
    const { playableId } = props;

    const byPassPartnerChannelCode = lodashGet(blockFeature, BLOCK_CODES.PARTNER_CHANNEL, false);
    const previewImageCodeBlocked = lodashGet(blockFeature, BLOCK_CODES.PREVIEW_IMAGE, true);
    const blockBitRateOnLive = lodashGet(blockFeature, BLOCK_CODES.BIT_RATE_FOR_LIVE, true);


    const cpId = lodashGet(contentDetails, 'cpId', '');
    const channelId = SubscriptionUtil.getChannelId({
        cpDetailsById,
        cpId,
    });
    const { userContentProperties: { ut } = {} } = userConfig || {};
    const channelName = lodashGet(cpDetailsById, `[${channelId}].title`, '');
    const playbackData = playbackDetails[playableId] || playbackDetails[contentDetails.id] || {};
    const contentId = getParentContentId(contentDetails);
    const oSeg = lodashGet(userConfig, 'userContentProperties.oSeg', '');
    const continueWatchingDetails = syncContent.continueWatchingData[contentId] || { lastWatchedPositionTrailer: 0 };
    const { adsPlayingFlag = false, showPostRollAds = false, sdkAdsPlayingFlag = false } = playbackDetails || {};
    const preferredPartnerPlans = lodashGet(userConfig, 'preferredPartnerPlans', null);
    const isXppUser = !!(oSeg || '').split(',').filter(item => item === 'xstreampremium_paid' || item === 'xstreampremium_telco_paid').length;
    const isNonAirtelUser = (ut || '').toLowerCase() === 'nonairtel';
    const sid = lodashGet(planConfig, 'sid', '');
    const browsePlans = lodashGet(planConfig, 'browsePlans', []);
    const subscribedPlans = lodashGet(planConfig, 'subscribedPlans', []);
    const circle = lodashGet(planConfig, 'userDetails.circle', '');
    const langInfo = !DeviceTypeUtil.isWeb() ? appConfig.largescreen_languages_codes : appConfig.web_languages_codes;
    const { userContentProperties } = userConfig;
    return {
        isAuthenticated: authConfig.isAuthenticated,
        cpId,
        langInfo,
        authToken: authConfig.token,
        logoutTriggered: authConfig.logoutTriggered,
        playbackDetails: playbackData,
        userEmail: userConfig.userInfo ? userConfig.userInfo.email : null,
        uid: authConfig.uid,
        browserHistory,
        adsData: appConfig.adsData,
        loginTriggered,
        continueWatchingDetails,
        lang: userConfig.userInfo ? userConfig.userInfo.lang : null,
        previewImageCodeBlocked,
        blockBitRateOnLive,
        channelName,
        channelId,
        byPassPartnerChannelCode,
        preferredPartnerPlans,
        browsePlans,
        subscribedPlans,
        circle,
        sid,
        nextEpisodeDetails,
        videoMeta,
        nextPlayedContentCount,
        adsPlayingFlag,
        showPostRollAds,
        syncContent,
        useLegacyShakaCps,
        disableCWforcps,
        showModal,
        modalComponent,
        removeFromCwTime,
        flagControl,
        appConfig,
        userConfig,
        contentDetails,
        CP_CONTROL_CONFIG,
        concurrentPlaybackLimitError,
        playbackLoaded,
        playbackInForeGround,
        isTrailerPlaying,
        startPlayback,
        shouldPlaybackRestart,
        cdpTabOpen,
        railCtaText,
        directPlayback,
        isContentAutoplay,
        contentPlayableId,
        closePlayer,
        retryPlayback,
        adConfig,
        isEpgPageVisible,
        sdkAdsPlayingFlag,
        maxAllowedPlaybackTime,
        playbackSoftNudgeTimerList,
        isXppUser,
        isNonAirtelUser,
        userContentProperties,
        deepLinkConfig,

    };
};

PlaybackContainer.defaultProps = {
    playbackEventsInitialized: () => null,
    authToken: '',
    loginTriggered: false,
    lang: [],
    nextEpisodeDetails: null,
    videoMeta: {
        videoTitle: '',
        subTitle: '',
    },
    shouldAddInCW: true,
    adsPlayingFlag: false,
    showPostRollAds: false,
    preLoad: false,
    shouldPlaybackRestart: false,
    togglePlaybackRestartFlag: () => {},
    playbackInForeGround: false,
    setPlayerConfigDispatch: () => {},
    CP_CONTROL_CONFIG: {},
    sdkAdsPlayingFlag: false,
};

PlaybackContainer.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    authToken: PropTypes.string,
    playbackDetailsFetchDispatch: PropTypes.func.isRequired,
    playbackDetailsExpiredActionDispatch: PropTypes.func.isRequired,
    contentDetails: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    playbackDetails: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    playbackEventsInitialized: PropTypes.func,
    playbackDetailsCookiesFetchActionDispatch: PropTypes.func.isRequired,
    sendHeartbeatActionDispatch: PropTypes.func.isRequired,
    browserHistory: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    triggerLoginDispatch: PropTypes.func.isRequired,
    continueWatchingDetails: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    loginTriggered: PropTypes.bool,
    userEmail: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    syncContentAddDispatch: PropTypes.func.isRequired,
    syncContentInitDispatch: PropTypes.func.isRequired,
    syncAndAddContentDispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    // eslint-disable-next-line react/forbid-prop-types
    lang: PropTypes.any,
    nextEpisodeDetails: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    langInfo: PropTypes.object.isRequired,
    adsData: PropTypes.object.isRequired,
    logoutTriggered: PropTypes.bool.isRequired,
    previewImageCodeBlocked: PropTypes.bool.isRequired,
    blockBitRateOnLive: PropTypes.bool.isRequired,
    videoMeta: PropTypes.shape({
        videoTitle: PropTypes.string,
        subTitle: PropTypes.string,
    }),
    channelName: PropTypes.string.isRequired,
    channelId: PropTypes.string.isRequired,
    subscriptionInitDispatch: PropTypes.func.isRequired,
    syncContentRemoveDispatch: PropTypes.func.isRequired,
    nextPlayedContentCount: PropTypes.number.isRequired,
    seriesDetailsFetchActionDispatch: PropTypes.func.isRequired,
    updateNextContentPlayedActionDispatch: PropTypes.func.isRequired,
    adsPlayingFlag: PropTypes.bool,
    showPostRollAds: PropTypes.bool,
    preLoad: PropTypes.bool,
    navigate: PropTypes.func,
    isTrailer: PropTypes.bool,
    showModalComponentActionDispatch: PropTypes.func.isRequired,
    hideModalComponentActionDispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    concurrentPlaybackLimitError: PropTypes.object.isRequired,
    playbackInForeGround: PropTypes.bool,
    isTrailerPlaying: PropTypes.bool.isRequired,
    setPlayerConfigDispatch: PropTypes.func,
    CP_CONTROL_CONFIG: PropTypes.object,
    adConfig: PropTypes.object.isRequired,
    sdkAdsPlayingFlag: PropTypes.bool,
    maxAllowedPlaybackTime: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, {
    playbackDetailsFetchDispatch: playbackDetailsFetchAction,
    playbackDetailsExpiredActionDispatch: playbackDetailsExpiredAction,
    syncContentRemoveDispatch: syncContentRemove,
    playbackDetailsCookiesFetchActionDispatch: playbackDetailsCookiesFetchAction,
    sendHeartbeatActionDispatch: sendHeartbeatAction,
    triggerLoginDispatch: triggerLogin,
    syncContentAddDispatch: syncContentAdd,
    syncContentInitDispatch: syncContentInit,
    syncAndAddContentDispatch: addAndSyncContent,
    subscriptionInitDispatch: subscriptionInit,
    showModalComponentActionDispatch: showModalComponentAction,
    // seriesDetailsFetchActionDispatch: seriesDetailsFetchAction,
    updateNextContentPlayedActionDispatch: updateNextContentPlayedCountAction,
    togglePlaybackActionDispatch: togglePlaybackAction,
    playbackClearAllDispatch: playbackClearAllAction,
    contentDetailsFetchActionDispatch: contentDetailsFetchAction,
    hideModalComponentActionDispatch: hideModalComponentAction,
    playbackDetailsFetchCompletedActionDispatch: playbackDetailsFetchCompletedAction,
    playbackWithoutLoginDetailsFetchActionDispatch: playbackWithoutLoginDetailsFetchAction,
    dispatchGetAdsPlaying: adsPlaying,
    setPlayerConfigDispatch: setPlayerConfig,
    resetPlaybackDetailsDispatch: resetPlaybackDetails,
    modifyInitialPlaybackDetailsDispatch: modifyInitialPlaybackDetails,
    handleEpgViewChangeDispatch: handleEpgViewChange,
})(withDeviceUtil(withRouter(PlaybackContainer)));
