import lodashGet from 'lodash/get';
import { SUBTITLES_MODES, VIDEOJS_PLAYER_EVENTS, HLS_PLAYER_EVENTS, PLAYER_EVENTS_LIST, PLAYER_TYPES } from '@airtel-tv/constants/PlayerConsts';
import { isOnline, raiseEvent } from '@airtel-tv/utils/WindowUtil';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import { BROWSER_LIST } from '@airtel-tv/constants/BrowserConst';
import { DateTime } from '@airtel-tv/utils';
import {isWeb} from '../util/PlaybackUtility';
import { showToastr } from '@airtel-tv/utils/NotifyUtil';
import LanguageProvider from '../../../web/src/providers/LanguageProvider';

export default class VideoJsControls {
    constructor(player, videoTag, isLiveStream, contentDetails) {
        this.player = player;
        this.videoTag = videoTag;
        this.contentDetails = contentDetails;
        this.previousVolumeValue = 1;
        this.liveDuration = 0;
        this.LANGUAGE = LanguageProvider();
        this.isWeb= isWeb();
        this.playerType = PLAYER_TYPES.VIDEOJS_PLAYER;
        this.playerVersion = '';

        if (isLiveStream) {
            const setLiveDuration = () => {
                if (!this.liveDuration || this.liveDuration === Infinity) {
                    this.liveDuration = this.getDVRDurationInSeconds();
                    player.off(HLS_PLAYER_EVENTS.CAN_PLAY_THROUGH, setLiveDuration);
                }
            };
            player.on(HLS_PLAYER_EVENTS.CAN_PLAY_THROUGH, setLiveDuration);
        }
    }

    setPlayerVersion = (version) => this.playerVersion = version;

    setManifestDownloadTime = (time) => {
        this.manifestDownloadTime = time;
    }

    setLicenseDownoadTime = (time) => {
        this.licenseDownloadTime = time;
    }

    getPlayerTech = () => {
        if (!this.player || !this.player.tech({ IWillNotUseThisInPlugins: true }) || !this.player.tech({ IWillNotUseThisInPlugins: true }).hls) {
            return false;
        }
        return this.player.tech({ IWillNotUseThisInPlugins: true });
    }

    play = () => {
        if (!this.player || !this.videoTag) {
            return false;
        }

        // TODO: check player has buffered connection
        if (!isOnline()) {
            const error = { code: ERROR_CODES.LOCAL1003 };
            raiseEvent(this.videoTag, HLS_PLAYER_EVENTS.ERROR, error);
        }

        return this.player.play().catch(() => false);
    };

    pause = () => {
        if (!this.player) {
            return false;
        }
        return this.player.pause();
    };

    emitEvent = (event) => {
        if(!event) 
            return
        raiseEvent(this.videoTag, event, true);
    }

    toggleFullScreen = () => {
        if (!this.player) {
            return false;
        }
        if (!this.player.isFullscreen()) {
            return this.player.requestFullscreen();
        }

        return this.player.exitFullscreen();
    };

    isMute = () => {
        if (!this.player) {
            return false;
        }
        return this.player.muted() || !this.getVolumeLevel();
    }

    getVolumeLevel = () => {
        if (!this.player) {
            return 100;
        }
        return this.player.volume();
    }

    toggleVolume = (isMobile) => {
        if (!this.player) {
            return false;
        }
        if (this.isMute()) {
            this.volumeChange(this.getVolumeLevel() ? this.getVolumeLevel() : this.previousVolumeValue, false, isMobile);
            raiseEvent(this.videoTag, VIDEOJS_PLAYER_EVENTS.UNMUTE_V2);
            return this.player.muted(false);
        }
        this.previousVolumeValue = this.getVolumeLevel();
        this.volumeChange(0, false, isMobile);
        raiseEvent(this.videoTag, VIDEOJS_PLAYER_EVENTS.MUTE_V2);
        return this.player.muted(true);
    };

    volumeChange = (volumeLevel, isSetFromKeyboard, isMobile) => {
        if (!this.player) {
            return false;
        }
        if (this.isMute()) {
            this.player.muted(false);
        }
        const tempVal = Number.parseFloat(volumeLevel).toFixed(2);
        this.player.volume(Math.max(0, Math.min(tempVal, 1)));
        if (isSetFromKeyboard) {
            raiseEvent(this.videoTag, HLS_PLAYER_EVENTS.VOLUME_CHANGE, true);
        }
        else if (isMobile) {
            raiseEvent(this.videoTag, HLS_PLAYER_EVENTS.VOLUME_CHANGE, false);
        }
        return true;
    };

    seekBarChange = (seekDuration) => {
        if (!this.player) {
            return false;
        }

        const data = {
            newTime: seekDuration,
            previousTime: this.getCurrentTime(),
        };

        raiseEvent(this.videoTag, HLS_PLAYER_EVENTS.SEEK_BAR_CHANGE, data);

        return this.player.currentTime(seekDuration);
    };

    replay = () => {
        if (!this.player) {
            return false;
        }
        return this.player.currentTime(0);
    };

    getCurrentRepresentationIndex = (currentBitrate) => {
        const tech = this.getPlayerTech();
        if (!tech || !currentBitrate) {
            return false;
        }

        const representationList = tech.hls.representations;

        if (representationList.length < 1) {
            return false;
        }

        const currentRepresentationIndex = 0; // TODO: getRepresentationIndexFromBitrate(currentBitrate,representationList); // common Util

        return currentRepresentationIndex;
    }

    getBandwidth = () => {
        const tech = this.getPlayerTech();
        if (!tech || !tech.hls || !tech.hls.selectPlaylist) {
            return 0;
        }

        const { attributes } = tech.hls.selectPlaylist();

        if (attributes) {
            return attributes.BANDWIDTH || null;
        }

        return 0;
    }

    getCurrentRepresentationIndex = () => {
        const tech = this.getPlayerTech();
        if (!tech) {
            return false;
        }

        const playingBandwidth = this.getBandwidth();

        let count = -1;
        tech.hls.representations().find((representation) => {
            count += 1;
            return representation.bandwidth === playingBandwidth;
        });

        return count;
    }

    getCurrentBufferTime = () => {
        const tech = this.getPlayerTech();
        if (!tech) {
            return false;
        }

        const bufferList = lodashGet(tech, 'hls.stats.buffered', []);

        if (bufferList.length > 0) {
            const lastBufferedItem = bufferList[bufferList.length - 1];
            return lodashGet(lastBufferedItem, 'end', 0) - lodashGet(lastBufferedItem, 'start', 0);
        }

        return 0;
    }

    changeBitrate = (option, browser) => {
        const {
            value: bitrate,
        } = option;
        const tech = this.getPlayerTech();
        if (!tech) {
            return false;
        }

        // TODO: WORKAROUND :: VIDEOJS BUG :: FIND SOLUTION
        const seekVideoWorkAroundForSafari = () => {
            if (browser === BROWSER_LIST.SAFARI) {
                setTimeout(() => {
                    const currentTime = this.player.currentTime();
                    this.seekBarChange(currentTime + 0.1);
                }, 200);
            }
        };

        const representations = tech.hls.representations();

        if (representations.length < 1) {
            return false;
        }

        const representationList = [
            ...representations,
        ]; // immutable

        if (representationList.length < 1) {
            return false;
        }

        // enable all :: auto
        if (!bitrate) {
            representationList.forEach(representation => representation.enabled(true));
            seekVideoWorkAroundForSafari();
            return true;
        }

        // check if there is a need to reverse the list? as high is needed to be on top (descending order)
        if (representationList[0].bandwidth < representationList[representationList.length - 1].bandwidth) {
            representationList.reverse(); // high should come first
        }

        // Choosing Highest frame on High selection
        if (bitrate === Infinity) {
            representationList.forEach((representation, index) => {
                if (index !== 0) {
                    representation.enabled(false);
                }
                else {
                    representation.enabled(true);
                }
            });
        }
        else if (bitrate === -Infinity) {
            representationList.forEach((representation, index) => {
                if (index === representationList.length - 1) {
                    representation.enabled(true);
                }
                else {
                    representation.enabled(false);
                }
            });
        }
        else {
            let representationEnabled = false;
            representationList.forEach((representation) => {
                if (representation.bandwidth <= Number(bitrate)) {
                    representation.enabled(true);
                    representationEnabled = true;
                }
                else {
                    representation.enabled(false);
                }
            });

            if (!representationEnabled) {
                representationList[representationList.length - 1].enabled(true); // last is lowest rendition
            }
        }

        seekVideoWorkAroundForSafari();
        raiseEvent(this.videoTag, HLS_PLAYER_EVENTS.CHANGE_BIT_RATE, option);

        return true;
    };

    changePlaybackRate = (playbackRate) => {
        if (!this.player) {
            return false;
        }

        return this.player.playbackRate(playbackRate);
    };

    changeAudio = (lang) => {
        if (!this.player) {
            return false;
        }
        return !this.player.audioTracks().tracks_.every((track) => { // eslint-disable-line no-underscore-dangle
            const enable = track.language === lang;
            if (enable) {
                track.enabled = true; // eslint-disable-line no-param-reassign
            }
            return !enable;
        });
    }

    currentAudioTrack = () => {
        if (!this.player) {
            return false;
        }
        let selectedLanguage = null;

        this.player.audioTracks().tracks_.every((track) => { // eslint-disable-line no-underscore-dangle
            if (track.enabled) {
                selectedLanguage = track.language;
            }
            return !track.enabled;
        });

        return selectedLanguage;
    }

    disableCaptions = () => {
        if (!this.player) {
            return false;
        }
        return this.player.textTracks().tracks_.forEach((track) => { // eslint-disable-line no-underscore-dangle
            track.mode = SUBTITLES_MODES.HIDDEN; // eslint-disable-line no-param-reassign
        });
    }

    showCaptions = (lang) => {
        if (!this.player) {
            return false;
        }
        if (!lang) {
            // disable all
            this.disableCaptions();
            return true;
        }

        this.player.textTracks().tracks_.forEach((track) => { // eslint-disable-line no-underscore-dangle
            track.mode = track.language === lang ? SUBTITLES_MODES.SHOWING : SUBTITLES_MODES.HIDDEN;// eslint-disable-line no-param-reassign
        });

        return true;
    }

    languageExistsInTextTrack = (lang) => {
        if (this.player) {
            // eslint-disable-next-line no-underscore-dangle
            const allLangs = this.player.textTracks().tracks_;
            if (allLangs && Array.isArray(allLangs)) {
                return !!allLangs.find(track => track.language === lang);
            }
        }
        return false;
    }

    isTextTrackVisible = () => {
        if (this.player) {
            // eslint-disable-next-line no-underscore-dangle
            const allLangs = this.player.textTracks().tracks_;
            if (allLangs && Array.isArray(allLangs)) {
                return !!allLangs.find(track => track.mode === SUBTITLES_MODES.SHOWING);
            }
        }
        return false;
    }

    forward = (skipTime) => {
        if (!this.player) {
            return false;
        }
        const currentTime = this.player.currentTime();
        const seekPoint = currentTime + skipTime;

        const duration = this.getDuration();

        if (duration > currentTime && duration > seekPoint) {
            this.seekBarChange(seekPoint);
        }
        else if (this.getDuration() < seekPoint) {
            this.seekBarChange(duration);
        }

        raiseEvent(this.videoTag, PLAYER_EVENTS_LIST.SEEK_FORWARD, seekPoint);
        return seekPoint;
    }

    backward = (skipTime) => {
        if (!this.player) {
            return false;
        }
        const currentTime = this.player.currentTime();
        const seekPoint = currentTime - skipTime;
        if (seekPoint > 0) {
            this.seekBarChange(seekPoint);
        }
        else {
            this.seekBarChange(0);
        }
        raiseEvent(this.videoTag, PLAYER_EVENTS_LIST.SEEK_BACKWARD, seekPoint);
        return seekPoint;
    }

    paused = () => {
        if (!this.player) {
            return false;
        }
        return this.player.paused();
    }

    getMeta = () => {
        if (!this.player) {
            return {};
        }

        let defaultAudioLanguage = {};

        const audioTracks = this.player.audioTracks().tracks_ // eslint-disable-line no-underscore-dangle
            .filter(track => !!track.language)
            .map((track) => {
                if (track.enabled) {
                    defaultAudioLanguage = this?.contentDetails?.langId ? this?.contentDetails?.langId : track.language;
                }
                return track.language;
            });
        let defaultSubtitleLanguage;
        const textTracks = this.player.textTracks().tracks_ // eslint-disable-line no-underscore-dangle
            .filter(track => !!track.language).map((track) => {
                // hide subtitles by default
                defaultSubtitleLanguage = this?.contentDetails?.subtitle ? this?.contentDetails?.subtitle : track.language;
                track.mode = SUBTITLES_MODES.HIDDEN; // eslint-disable-line no-param-reassign
                return track.language;
            });

        // Playback quality not supported in safari
        const qualityLevels = this.getPlayerTech() ? this.getPlayerTech().hls.representations() : [];

        const meta = {
            duration: this.getDuration(),
            playbackRate: this.player.playbackRate(),
            audioTracks,
            textTracks,
            qualityLevels,
            defaultAudioLanguage,
            defaultSubtitleLanguage,
        };

        return meta;
    }

    getPlayerStats = () => {
        return {

        }
    }

    getCurrentTrack = () => { //To Do: Add similar implementation in videojs
        return {}
    }

    getTrackParams = () => {
        return {}
    }

    getRepresentationMeta = () => {
        return [];
    }

    getDuration = () => {
        if (!this.player) {
            return 0;
        }
        const duration = Math.floor(Number(this.player.duration()));
        return (Number.isNaN(duration) || !Number.isFinite(duration)) ? 0 : duration;
    }

    getReadyState = () => {
        if (!this.player) {
            return false;
        }
        return this.player.getReadyState();
    }

    dispose = () => {
        if (this.player) {
            this.player.dispose();
        }
    }

    unload = () => this.dispose()

    getCurrentTime = () => {
        if (!this || !this.player || this.player === null) {
            return null;
        }
        let currentTime = null;
        try {   
            currentTime = this.player.currentTime();
        } catch(err) {
            currentTime = this.videoTag.currentTime;
        }
        return currentTime;
    }

    // TODO: LIVE TV IMPLEMENTATION
    forwardLive = (skipTime) => {
        if (!this.videoTag || !this.player) {
            return false;
        }
        const currentTime = this.getCurrentTime();
        const seekPoint = currentTime + skipTime;

        const duration = this.getLiveCurrentTimeInSeconds();

        if (duration > currentTime && duration > seekPoint) {
            this.seekBarChange(Math.floor(seekPoint));
        }
        else if (duration < seekPoint) {
            this.seekBarChange(Math.floor(duration));
        }
        raiseEvent(this.videoTag, PLAYER_EVENTS_LIST.SEEK_FORWARD, seekPoint);
        return seekPoint;
    }

    backwardLive = (skipTime) => {
        if (!this.videoTag || !this.player) {
            return false;
        }

        const currentTime = this.getCurrentTime();

        const liveTime = this.getLiveCurrentTimeInSeconds();
        const least = liveTime - this.liveDuration;
        const seekPoint = currentTime - skipTime;

        if (seekPoint > least) {
            this.seekBarChange(seekPoint);
        }
        else {
            const liveStartPoint = liveTime - Math.floor(this.liveDuration);
            this.seekBarChange(liveStartPoint);
        }
        raiseEvent(this.videoTag, PLAYER_EVENTS_LIST.SEEK_BACKWARD, seekPoint);
        return seekPoint;
    }

    getDVRDurationInSeconds = () => {
        if (this.player.seekable && this.player.seekable().length > 0) {
            return this.player.seekable().end(0) - this.player.seekable().start(0);
        }
        return this.getDuration();
    }

    getLiveDuration = () => this.liveDuration

    getLiveCurrentTimeInSeconds = () => this.player.liveTracker.seekableEnd();

    goLive = () => {
        if (this.player.seekable && this.player.seekable().length > 0) {
            this.seekBarChange(this.player.liveTracker.lastSeekEnd_); // eslint-disable-line no-underscore-dangle
            raiseEvent(this.videoTag, HLS_PLAYER_EVENTS.LIVE_BUTTON_CLICK, this.player.seekable().end(0));
        }
    };

    getSegmentDuration = () => {
        const tracks = this.player.textTracks();
        for (let i = 0; i < tracks.length; i++) {
            if (tracks[i].label === 'segment-metadata' && tracks[i].cues) {
                const cuesLength = tracks[i].cues.length;
                if (cuesLength > 1) {
                    return tracks[i].cues[1].startTime - tracks[i].cues[0].startTime;
                }
                if (cuesLength) {
                    return tracks[i].cues[0].endTime - tracks[i].cues[0].startTime;
                }
            }
        }
        return 0;
    }

    isLive = () => {
        const liveTime = this.getLiveCurrentTimeInSeconds();
        const { currentTime } = this.videoTag;
        const timeDiff = liveTime - currentTime;
        const segmentDuration = this.getSegmentDuration();
        return !this.player.liveTracker.behindLiveEdge() || timeDiff < segmentDuration;
    }

    behindLiveTime() {
        if (this.player.seekable && this.player.seekable().length > 0) {
            const currentTime = this.player.currentTime();
            const end = this.player.seekable().end(0);

            if (currentTime === 0 || end === 0) {
                return 0;
            }

            const behindLive = Math.floor(end - currentTime);
            return Math.max(0, behindLive);
        }
        return 0;
    }

    getLiveCurrentTime = () => this.liveDuration - this.behindLiveTime()

    getLivePlaybackUTCTime = () => {
        const currentlyBehindTime = this.behindLiveTime();
        // const playbackCurrentTime = moment().subtract(currentlyBehindTime, 'seconds').toDate();
        const playbackCurrentTime = new DateTime().subtract(currentlyBehindTime);
        return playbackCurrentTime;
    }

    isLiveStream = () => true;//this.player.liveTracker.isLive()
}
