import EventEmitter from 'events';
import { PLAYER_EVENTS_LIST, HLS_PLAYER_EVENTS } from '@airtel-tv/constants/PlayerConsts';
import { documentAddEventListener, documentRemoveEventListener } from '@airtel-tv/utils/WindowUtil';

const FULL_SCREEN_EVENTS = [
    '',
    'webkit',
    'moz',
    'ms',
];
export default class HlsPlayerEvents {
    firstPlayEventFiredFlag = false;

    initEventFiredFlag = false;

    dispose = () => {
        if (this.emitter) {
            this.emitter.removeAllListeners();
        }

        FULL_SCREEN_EVENTS.forEach(
            prefix => documentRemoveEventListener(`${prefix}fullscreenchange`, this.screenChangeCallback, false),
        );
    };

    emit = (name, data) => {
        this.emitter.emit(name, data);
    }

    constructor({ player, videoTag, playerFunctions }) {
        if (!videoTag || !player) {
            return; // cannot subscribe to events as listening method is not provided
        }

        FULL_SCREEN_EVENTS.forEach(
            prefix => documentAddEventListener(`${prefix}fullscreenchange`, this.screenChangeCallback, false),
        );

        this.emitter = new EventEmitter();

        videoTag.addEventListener(HLS_PLAYER_EVENTS.PLAY, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.PLAY, videoTag.currentTime, videoTag);
        });

        videoTag.addEventListener(HLS_PLAYER_EVENTS.PAUSE, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.PAUSE, videoTag.currentTime, videoTag);
        });

        videoTag.addEventListener(HLS_PLAYER_EVENTS.CAN_PLAY_THROUGH, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH);
        });

        videoTag.addEventListener(HLS_PLAYER_EVENTS.ENDEND, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.ENDEND, videoTag);
        });

        // capture error from video tag and player both
        videoTag.addEventListener(HLS_PLAYER_EVENTS.ERROR, (error) => {
            const errorData = error.detail || error.data || error;
            this.emitter.emit(PLAYER_EVENTS_LIST.ERROR, errorData);
        });

        videoTag.addEventListener(HLS_PLAYER_EVENTS.SEEK_BAR_CHANGE, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.SEEK_BAR_CHANGE, event.data);
        });

        // player.addEventListener(HLS_PLAYER_EVENTS.ERROR, (error) => {
        //     const errorData = error.detail || error.data || error;
        //     this.emitter.emit(PLAYER_EVENTS_LIST.ERROR, errorData);
        // });

        videoTag.addEventListener(HLS_PLAYER_EVENTS.WAITING, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.WAITING);
        });

        videoTag.addEventListener(HLS_PLAYER_EVENTS.PLAYING, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.PLAYING);
        });

        videoTag.addEventListener(HLS_PLAYER_EVENTS.PROGRESS, () => {
            // const { total } = player.getBufferedInfo();
            // if (total.length > 0) {
            //     this.emitter.emit(PLAYER_EVENTS_LIST.PROGRESS, total[0].end);
            // }
        });

        videoTag.addEventListener(HLS_PLAYER_EVENTS.TIMEUPDATE, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.TIMEUPDATE, videoTag.currentTime, videoTag);

            // SHAKA DOES NOT HAS A EVENT NAMED 'FIRST_PLAY' SO WE MADE ONE
            if (this.firstPlayEventFiredFlag === false && videoTag.currentTime > 0 && this.initEventFiredFlag === true) {
                this.firstPlayEventFiredFlag = true;
                this.emitter.emit(PLAYER_EVENTS_LIST.FIRST_PLAY, videoTag);
            }

            // raise live time update event
            const liveStreamCurrentPlayingTime = playerFunctions.isLiveStream() ? playerFunctions.getLivePlaybackUTCTime() : undefined;
            if (liveStreamCurrentPlayingTime) {
                this.emitter.emit(PLAYER_EVENTS_LIST.LIVE_TIME_UPDATE, liveStreamCurrentPlayingTime, videoTag);
            }
        });

        const onCanPlayThroughEventHandler = () => {
            if (this.initEventFiredFlag === false) {
                const meta = playerFunctions.getMeta();
                this.initEventFiredFlag = true;
                this.emitter.emit(PLAYER_EVENTS_LIST.LOADED, meta);
            }
            //  need only one time, so dispose after use
            videoTag.removeEventListener(HLS_PLAYER_EVENTS.CAN_PLAY_THROUGH, onCanPlayThroughEventHandler);
        };


        videoTag.addEventListener(HLS_PLAYER_EVENTS.CAN_PLAY_THROUGH, onCanPlayThroughEventHandler);

        videoTag.addEventListener(HLS_PLAYER_EVENTS.LOADSTART, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.LOADSTART);
        });

        videoTag.addEventListener(HLS_PLAYER_EVENTS.LIVE_BUTTON_CLICK, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.LIVE_BUTTON_CLICK, event.data);
        });

        videoTag.addEventListener(PLAYER_EVENTS_LIST.SEEK_FORWARD, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.SEEK_FORWARD, event.data);
        });

        videoTag.addEventListener(PLAYER_EVENTS_LIST.SEEK_BACKWARD, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.SEEK_BACKWARD, event.data);
        });

        videoTag.addEventListener(HLS_PLAYER_EVENTS.CHANGE_BIT_RATE, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.CHANGE_BIT_RATE, event);
        });

        // player.addEventListener(HLS_PLAYER_EVENTS.CHANGE_BIT_RATE, (event) => {
        //     this.emitter.emit(PLAYER_EVENTS_LIST.CHANGE_BIT_RATE, event);
        // });

        videoTag.addEventListener(HLS_PLAYER_EVENTS.VOLUME_CHANGE, (event) => {
            this.emitter.emit(PLAYER_EVENTS_LIST.VOLUME_CHANGE, event);
        });

        videoTag.addEventListener(HLS_PLAYER_EVENTS.OPEN_PIP, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.OPEN_PIP);
        });

        videoTag.addEventListener(HLS_PLAYER_EVENTS.CLOSE_PIP, () => {
            this.emitter.emit(PLAYER_EVENTS_LIST.CLOSE_PIP);
        });
    }

    screenChangeCallback = () => {
        this.emitter.emit(PLAYER_EVENTS_LIST.FULL_SCREEN_CHANGE);
    }
}
