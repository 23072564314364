import { BROWSER_LIST, HARDWARE_LIST } from '@airtel-tv/constants/BrowserConst';
import { pictureInPictureEnabled } from '@airtel-tv/utils/WindowUtil';

export const hasPipSafari = (isMobile, videoElement, deviceName) => {
    const isIpad = deviceName === HARDWARE_LIST.IPAD;


    if (isIpad || !isMobile || !videoElement) {
        return videoElement.webkitSupportsPresentationMode && typeof videoElement.webkitSetPresentationMode === 'function';
    }

    return true;
};

export const hasPipChrome = () => pictureInPictureEnabled();

export const hasPip = (isMobile, videoElement, deviceName, browserName) => {
    switch (browserName) {
        case BROWSER_LIST.CHROME:
            return hasPipChrome();

        case BROWSER_LIST.SAFARI:
            return hasPipSafari(isMobile, videoElement, deviceName);

        default:
            return false;
    }
};

export default { hasPip };
